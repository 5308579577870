<template>
  <div>
    <div
      v-if="data.length"
      class="position-relative mb-2 px-5 py-1"
      :class="isDarkSkin ? 'dark-bg-card' : 'light-bg-card'"
    >
      <header-grid-report />

      <div class="container-report pt-1 px-1" ref="container-report">
        <div
          class="container-grid mb-1"
          v-for="(items, index) in data"
          :key="index"
        >
          <div
            class="shadow w-auto p-2 rounded position-relative"
            v-for="(account, index) in items"
            :key="index"
          >
            <div
              class="d-flex justify-content-center align-items-center h-100 text-muted"
              v-if="account.empty"
            >
              <feather-icon
                icon="SlashIcon"
                size="16"
                class="cursor-pointer text-end text-info text-muted"
                style="margin-right: 3px"
              />
              <p class="m-0">NO CONTENT</p>
            </div>
            <div
              class="d-flex justify-content-start pr-3"
              v-for="(infoAc, index) in account.content"
              :key="index"
            >
              <span class="mr-1 font-weight-bolder">{{ infoAc.key }}: </span>
              <span class="text-truncate" v-b-tooltip.hover.top="infoAc.value">
                {{ infoAc.value }}
              </span>

              <div
                class="action-data"
                v-if="
                  isEnabledForEditAndDelete &&
                  !isFromModalFinancial &&
                  !account.empty
                "
              >
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      v-b-tooltip.hover.right="'Actions'"
                      class="align-middle text-info"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.modal-primary
                    @click="openModalTracking(account)"
                  >
                    <feather-icon
                      icon="ListIcon"
                      size="16"
                      class="mr-1 cursor-pointer text-end text-info"
                    />
                    <span class="align-middle">View Tracking</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="isSpecialist"
                    v-b-modal.modal-primary
                    @click="editPublicRecord(account)"
                  >
                    <feather-icon
                      size="16"
                      class="mr-1 cursor-pointer text-end text-warning"
                      icon="EditIcon"
                    />
                    <span class="align-middle">Edit Account</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="isSpecialist"
                    v-b-modal.modal-primary
                    @click="
                      deletePublicRecord(
                        items,
                        account.id,
                        account.cr_accounts_id
                      )
                    "
                  >
                    <feather-icon
                      size="16"
                      class="mr-1 cursor-pointer text-danger"
                      icon="TrashIcon"
                    />
                    <span class="align-middle">Delete Account</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <b-badge
              class="float-right mt-1"
              v-if="!account.empty"
              :variant="getColorStatus(account.status)"
            >
              {{ account.status_name }}
            </b-badge>
          </div>
        </div>
      </div>

      <new-edit-public-record-modal
        v-if="editPrModalController"
        :itemPr="itemPr"
        :userId="currentUser.user_id"
        @close="editPrModalController = false"
      />

      <modal-tracking-letter
        v-if="modalTrackingController"
        :pItem="itemPr"
        :client-name="client.client_name"
        :account="client.account"
        @close="closeModalTracking"
      />
    </div>
    <div v-else class="text-center py-5">There are no records to show</div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalTrackingLetter from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalTrackingLetter.vue";
import HeaderGridReport from "@/views/commons/components/clients/dashboard/options/report-module/components/HeaderGridReport.vue";
import NewEditPublicRecordModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/NewEditPublicRecordModal.vue";
import { mapGetters, mapActions } from "vuex";

// Service Client Report
import ClientReportService from "@/views/commons/components/clients/dashboard/services/client.report.js";

export default {
  components: {
    ModalTrackingLetter,
    HeaderGridReport,
    NewEditPublicRecordModal,
  },
  props: {
    clientId: {
      type: String,
    },
    scoreId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      iconIsDown: false,
      modalTrackingController: false,
      busy: true,
      editPrModalController: false,
      itemPr: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      getRefreshReport: "ReportStore/G_REFRESH_REPORT",
    }),

    isFromModalFinancial() {
      return this.currentUser.moduleId == 23;
    },

    isEnabledForEditAndDelete() {
      return (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 3 ||
        this.currentUser.role_id == 22
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
  },

  async created() {
    this.getPublicRecordReport();
  },

  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),
    async getPublicRecordReport() {
      try {
        this.addPreloader();
        let params = {
          idClientAccount: this.$route.params.idClient || this.clientId,
          idScore: this.scoreId,
        };
        const data = await ClientReportService.getPublicRecordsByScore(params);
        if (data.status == 200) {
          this.$emit("uploadedData");
        }
        this.data = data.data;
        this.busy = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something has gone wrong with the report!"
        );
      } finally {
        this.removePreloader();
      }
    },

    openModalTracking(account) {
      this.itemPr = account;
      this.modalTrackingController = true;
    },

    closeModalTracking() {
      this.modalTrackingController = false;
    },

    async editPublicRecord(account) {
      this.itemPr = account;
      this.editPrModalController = true;
    },

    async deletePublicRecord(items, id, id_acd) {
      try {
        const itemCount = items.filter((item) => !item.empty).length;
        const multipleAccount = itemCount > 1;
        const swal = await this.showQuestionSwal(
          multipleAccount
            ? "Do you want to delete only the account of this office or all of them?"
            : "Are you sure?",
          !multipleAccount && "You won't be able to revert this!",
          multipleAccount ? "This account" : "Yes",
          "Cancel",
          "info",
          {
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCloseButton: true,
            showDenyButton: multipleAccount,
            denyButtonText: "All Accounts",
            customClass: {
              confirmButton: "btn btn-outline-success mr-1",
              denyButton: "btn btn-outline-warning mr-1",
              cancelButton: "btn btn-outline-danger",
            },
            focusConfirm: false,
          }
        );
        if (swal.isConfirmed) {
          this.addPreloader();
          const data =
            await ClientsOptionsServices.deleteSingleOneAccountReportPr({
              id,
              id_acd,
              iduser: this.currentUser.user_id,
            });
          if (data.status == 200) {
            this.showSuccessSwal("Inquire has been deleted successfully");
            await this.getPublicRecordReport();
          }
        }
        if (swal.isDenied) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteDelteAccountPr({
            iduser: this.currentUser.user_id,
            id: id_acd,
          });
          if (data.status == 200) {
            this.showSuccessSwal("Inquire has been deleted successfully");
            await this.getPublicRecordReport();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    getColorStatus(number) {
      let setColorStatus = {
        1: "light-warning",
        2: "light-danger",
        5: "light-primary",
        6: "light-danger",
      };

      return setColorStatus[number] || "light-success";
    },

    async removeExceptDelete() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.deleteForException({
            account_id: this.$route.params.idClient || this.clientId,
            id_user: this.currentUser.user_id,
            type: 4,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            await this.getPublicRecordReport();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
  watch: {
    async getRefreshReport(state) {
      if (state == 3) {
        await this.getPublicRecordReport();
        this.updateRefreshReport(0);
      }
    },
  },
};
</script>

<style scoped>
* {
  --light-bg-color: #f1f1f1;
  --light-bg-color2: #fafafa;
  --dark-bg-color1: #232328;
  --dark-bg-color2: #17171a;
}
.dark-bg-card {
  background-color: var(--dark-bg-color2) !important;
  border-radius: 10px;
}
.light-bg-card {
  background-color: var(--light-bg-color2) !important;
  border-radius: 10px;
}
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 1em;
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.container-report {
  overflow-y: auto;
}

.action-data {
  position: absolute;
  right: -10px;
  top: 0;
}

.icon-collapse {
  position: absolute;
  right: 15px;
  top: 25%;
}

.btn-delete-all {
  position: absolute;
  left: 15px;
  top: 25%;
}
</style>
