<template>
  <b-modal
    v-model="onControl"
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="TRACKING REQUEST WORKPLAN"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <div>
      <b-row>
        <b-col cols="12" md="6">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ dataWp.client_account || "-" }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span class="title-tag">NAME</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ dataWp.client_name || "-" }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="table-responsive">
      <b-table
        ref="trackingTable"
        :items="myProvider"
        :fields="visibleFields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        class="blue-scrollbar mt-2"
        :busy="isBusy"
        no-provider-filtering
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(created_by)="{ item }">
          {{ item.user_name }}
        </template>
        <template #cell(created_at)="{ item }">
          {{ item.created_at | myGlobalDay }}
        </template>
        <template #cell(status)="{ item }">
          <div class="text-info text-center">
            <b-badge class="w-100" :variant="badgeColor(item.status_id)">
              {{ statusName(item.status) }}
            </b-badge>
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

export default {
  props: {
    dataWp: {
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      fields: [
        {
          key: "created_by",
          label: "Created by",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
        {
          key: "created_at",
          label: "Created at",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          visible: true,
        },
        {
          key: "obs",
          label: "Observation",
          visible: true,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  created() {
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    async myProvider() {
      try {
        const params = {
          workplan_id: this.dataWp.id,
        };
        const { data } = await RequestWorkPlanService.getTracking(params);
        return data;
      } catch (ex) {
        return [];
      }
    },

    badgeColor(badge) {
      let badgeClass = {
        1: "light-warning", // PENDING
        3: "light-info", // RETURNED
        7: "light-success", // COMPLETED
        6: "light-primary",
        8: "light-danger",
      };
      return badgeClass[badge];
    },
    statusName(status) {
      switch (status) {
        case "WAITING FOR APPROVAL":
          return "TO REVISION";
        case "ANNUL":
          return "DELETED";
        default:
          return status;
      }
    },
  },
};
</script>
