<template>
  <b-modal
    v-model="onControl"
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="TRACKING: OTHER SERVICES"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <div>
      <b-row>
        <b-col cols="12" md="6">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ clientData.client_account || "-" }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span class="title-tag">NAME</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ clientData.client_name || "-" }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="table-responsive">
      <b-table
        ref="trackingTable"
        :items="myProvider"
        :fields="visibleFields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        class="blue-scrollbar mt-2"
        :busy="isBusy"
        no-provider-filtering
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(created_by)="{ item }">
          {{ item.user_name }}
        </template>
        <template #cell(created_at)="{ item }">
          {{ item.created_at | myGlobalDay }}
        </template>
        <template #cell(status)="{ item }">
          <div class="text-info text-center">
            <b-badge class="w-100 text-uppercase" :variant="badgeColor(item.status_id)">
              <!-- {{ item.status }} : {{ item.statusService ? ` ${item.statusService}` : 'created' }} -->
              {{ getStatusText(item) }}
            </b-badge>
          </div>
        </template>
        <template #cell(motive_description)="{ item }">
          {{ item.motive_description || "-" }}
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>
import AppointmentService from "@/views/commons/components/other-services/service/appointment.service";

export default {
  props: {
    clientData: {
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      fields: [
        {
          key: "created_by",
          label: "Created by",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          visible: true,
        },
        {
          key: "comment",
          label: "Comment",
          class: "text-center",
          visible: true,
        },
        {
          key: "created_at",
          label: "Created at",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  created() {
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    getStatusText(item) {
      if(item.status_id == 2) {
        return item.status
      } else {
        return `${item.status}: ${item.statusService ? item.statusService : 'created'}`
      }
    },

    async myProvider() {
      try {
        const params = {
          id: this.clientData.id,
        };
        const { data } = await AppointmentService.searchTracking(params);
        return data;
      } catch (ex) {
        return [];
      }
    },

    badgeColor(badge) {
      let badgeClass = {
        1: "light-info", // PENDING
        2: "light-success", // COMPLETED
        3: "light-danger", // DELETED
        4: "light-warning", // EXPIRED
        5: "light-primary", // RESCHEDULED
      };
      return badgeClass[badge];
    },


  },
};
</script>
