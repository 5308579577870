<template>
  <div>
    <div class="mb-1">
      <b-button variant="info" class="mb-2" @click="closeModal">
        <feather-icon icon="ChevronLeftIcon" /> RETURN
      </b-button>

      <div class="mb-1">
        <div
          v-for="item in itemBureau"
          :key="item.length"
          class="d-flex flex-column ml-2"
        >
          <b-row>
            <div style="width: 100px; display: flex; align-items: center">
              <span v-if="item.bureau_id === 1" class="font-weight-bolder">
                Trans<span style="color: #4d917a">Union</span>
              </span>
              <span
                v-if="item.bureau_id === 2"
                style="color: #0566b7"
                class="font-weight-bolder"
              >
                Experian
              </span>
              <span
                v-if="item.bureau_id === 3"
                style="color: #822a3a"
                class="font-weight-bolder"
              >
                EQUIFAX
              </span>
            </div>

            <!-- PERSONAL INFORMATION -->
            <div v-if="bureauType == 1" style="gap: 25px" class="d-flex">
              <b-form-group label="Content" :disabled="true">
                <b-form-input v-model="item.content" />
              </b-form-group>
            </div>

            <!-- INQUIRIES -->
            <div v-else-if="bureauType == 2" style="gap: 25px" class="d-flex">
              <b-form-group label="Date" :disabled="true">
                <b-form-input v-model="item.date" />
              </b-form-group>
              <b-form-group label="Content" :disabled="true">
                <b-form-input v-model="item.content" />
              </b-form-group>
            </div>

            <!-- PUBLIC RECORDS -->
            <div v-else-if="bureauType == 3" style="gap: 25px" class="d-flex">
              <b-form-group label="Public Record" :disabled="true">
                <b-form-input v-model="item.public_record"></b-form-input>
              </b-form-group>
              <b-form-group label="Date" :disabled="true">
                <b-form-input v-model="item.date"></b-form-input>
              </b-form-group>
              <b-form-group label="Court Case" :disabled="true">
                <b-form-input v-model="item.court_sase"></b-form-input>
              </b-form-group>
            </div>

            <!-- ACCOUNTS -->
            <div v-else style="gap: 25px" class="d-flex">
              <b-form-group label="Account Type" :disabled="true">
                <b-form-input v-model="item.account_type" />
              </b-form-group>
              <b-form-group label="Account Name" :disabled="true">
                <b-form-input v-model="item.account_name" />
              </b-form-group>
              <b-form-group label="Account Number" :disabled="true">
                <b-form-input v-model="item.account_number" />
              </b-form-group>
              <b-form-group label="Balance" :disabled="true">
                <b-form-input v-model="item.balance" />
              </b-form-group>
            </div>
          </b-row>
          <br />
        </div>
      </div>

      <ValidationObserver ref="form">
        <b-row>
          <b-col>
            <b-form-group>
              <label class="mr-2 mb-1">Reasons</label>
              <ValidationProvider
                v-slot="{ errors }"
                name="reason"
                rules="required"
              >
                <!-- <b-button
                  variant="primary"
                  style="width: 5px; height: 5px"
                  @click="openReasonModal"
                  ><feather-icon
                    icon="PlusIcon"
                    style="position: relative; right: 6px; bottom: 6px"
                  />
                </b-button> -->
                <b-form-select
                  :class="errors[0] == 'is required' && 'border-danger rounded'"
                  id="select"
                  v-model="reason"
                  :options="itemReasons"
                  text-field="text"
                  value-field="value"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label class="mr-2 mb-1">Instructions</label>
              <ValidationProvider
                v-slot="{ errors }"
                name="instruction"
                rules="required"
              >
                <!-- <b-button
                  variant="primary"
                  style="width: 5px; height: 5px"
                  @click="openInstructionModal"
                  ><feather-icon
                    icon="PlusIcon"
                    style="position: relative; right: 6px; bottom: 6px"
                  />
                </b-button> -->
                <b-form-select
                  :class="errors[0] == 'is required' && 'border-danger rounded'"
                  id="select"
                  v-model="instruction"
                  :options="itemInstructions"
                  text-field="text"
                  value-field="value"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <b-button variant="success" @click="saveLetterRound()">SAVE</b-button>
    </div>

    <create-reason-and-instruction-modal
      v-if="createReasonAndInstructionModal"
      :create-type="createType"
      @reload="refresh"
      @close="closeModalCRI"
    />
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import CreateReasonAndInstructionModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/personal-info-form-round-modal/sub-modal/CreateReasonAndInstructionModal.vue";
import RequestWorkplanServices from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PersonalInfoFormRoundModal",
  components: {
    CreateReasonAndInstructionModal,
  },
  props: {
    idRound: {
      type: String,
      default: null,
    },
    bureauData: {
      type: Array,
      default: null,
    },
    bureauType: {
      type: Number,
      default: null,
    },
    roundClassification: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      reason: null,
      instruction: null,
      createReasonAndInstructionModal: false,
      createType: null,
      itemReasons: [],
      itemInstructions: [],
      arrayId: [],
      itemBureau: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_SUM_TU: "SpecialistsDigitalRoundLettersStore/G_SUM_TU",
      G_SUM_EX: "SpecialistsDigitalRoundLettersStore/G_SUM_EX",
      G_SUM_EQ: "SpecialistsDigitalRoundLettersStore/G_SUM_EQ",
    }),
  },
  async created() {
    await this.getReasonsInstructions();
    await this.getDetailLetter();
  },
  methods: {
    openReasonModal() {
      this.createType = 1;
      this.createReasonAndInstructionModal = true;
    },
    openInstructionModal() {
      this.createType = 2;
      this.createReasonAndInstructionModal = true;
    },
    closeModalCRI() {
      this.createReasonAndInstructionModal = false;
    },
    async getDetailLetter() {
      this.bureauData.forEach((e) => this.arrayId.push(e.id));
      const params = {
        id: this.arrayId,
        type: this.bureauType,
      };
      const data = await ClientsOptionsServices.getDetailLetter(params);
      if (data.status === 200) {
        this.itemBureau = data.data;
      }
    },
    async getReasonsInstructions() {
      if (this.roundClassification != null) {
        const data =
          await RequestWorkplanServices.getDisputeReasonsInstructions({
            type: this.roundClassification,
          });
        this.itemReasons = data.reasons;
        this.itemInstructions = data.instructions;
      } else {
        this.getReasons();
        this.getInstructions();
      }
    },
    setDataOptions(data, options) {
      data.forEach((item) => {
        options.push({
          value: item.id,
          text: item.value,
        });
      });
    },
    async getReasons() {
      const data = await ClientsOptionsServices.getReasons();
      if (data.status === 200) {
        this.setDataOptions(data.data, this.itemReasons);
      }
    },
    async getInstructions() {
      const data = await ClientsOptionsServices.getInstructions();
      if (data.status === 200) {
        this.setDataOptions(data.data, this.itemInstructions);
      }
    },
    updateBureauCounters() {
      this.bureauData.forEach((data) => {
        if (data.bureau_id == 1) {
          this.M_SET_SUM_TU(this.G_SUM_TU+1);
        } else if (data.bureau_id == 2) {
          this.M_SET_SUM_EX(this.G_SUM_EX+1);
        } else {
          this.M_SET_SUM_EQ(this.G_SUM_EQ+1);
        }
      });
    },
    async saveLetterRound() {
      try {
        const result = await this.$refs.form.validate();

        if (result) {
          this.addPreloader();
          const params = {
            idletter: this.arrayId.length > 0 ? this.arrayId : [],
            idround: this.idRound,
            instruction: this.instruction,
            reason: this.reason,
            type: this.bureauType,
            user: this.currentUser.user_id,
          };

          const response = await ClientsOptionsServices.saveLetterRound(params);
          if (response.status === 200) {
            this.saveAndClose();
            this.REFRESH_DISPUTE_TABLE(true);
            this.A_UPDATE_TABLE(true);
            await this.updateBureauCounters();
            this.showToast(
              "success",
              "top-right",
              "Congratulations!",
              "CheckIcon",
              "Successfull process"
            )
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Oops!",
              "AlertOctagonIcon",
              "Failed process"
            );
          }
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader();
      }
    },
    async refresh() {
      await this.getInstructions();
      await this.getReasons();
    },
    closeModal() {
      this.$emit("close");
    },
    saveAndClose() {
      this.$emit("saveAndClose");
    },
    ...mapMutations({
      M_SET_SUM_TU: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_TU",
      M_SET_SUM_EX: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_EX",
      M_SET_SUM_EQ: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_EQ",
    }),
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
      REFRESH_DISPUTE_TABLE:
        "SpecialistsDigitalRoundLettersStore/CHANGE_REFRESH_TABLE",
    }),
  },
};
</script>
