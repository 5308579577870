export const fieldsSyncronization = [
  {
    key: "a_name",
    label: "A. Name",
    match: false,
    visible: true,
  },
  {
    key: "creditor_name",
    label: "C. Name",
    match: true,
    visible: false,
  },
  {
    key: "classification",
    label: "Classification",
    match: false,
    visible: true,
  },
  {
    key: "a_number",
    label: "A. Number",
    match: true,
    visible: true,
  },
  {
    key: "balance",
    label: "Balance",
    type: "money",
    match: false,
    visible: true,
  },
  {
    key: "a_type",
    label: "A. Type",
    match: true,
    visible: true,
  },
  {
    key: "name_status",
    label: "Status",
    match: false,
    visible: true,
  },
  {
    key: "c_limit",
    label: "Credit Limit",
    type: "money",
    match: true,
    visible: true,
  },
  {
    key: "h_credit",
    label: "H. Credit",
    type: "money",
    match: true,
    visible: true,
  },
  {
    key: "monthly",
    label: "M. Payment",
    type: "money",
    match: false,
    visible: true,
  },
  {
    key: "p_status",
    label: "P. Status",
    match: false,
    visible: true,
  },
  {
    key: "date",
    label: "Date Opened",
    match: false,
    visible: true,
  },
];