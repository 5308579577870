<template>
  <div>
    <template v-if="busy">
      <div
        class="d-flex justify-content-center align-items-center"
        style="height: 40px"
      >
        <b-spinner variant="primary" label="Spinning" class="mr-1" /><span
          class="font-weight-bolder"
          >Loading Account History ...</span
        >
      </div>
    </template>
    <div v-if="validContent" class="mb-2 px-5 py-1" :class="isDarkSkin ? 'dark-bg-card' : 'light-bg-card'">
      <div>
        <div class="d-flex justify-content-between" @click="iconIsDown = !iconIsDown">
          <div class="d-flex">
            <h4 class="m-0 p-0 font-weight-bolder">ACCOUNT HISTORY</h4>
            <b-button
              v-if="!isFromModalFinancial && isSpecialist"
              variant="danger"
              size="sm"
              style="margin-left: 10px"
              @click.stop="removeExceptDelete()"
            >
              REMOVE ALL
            </b-button>
          </div>
          <span class="cursor-pointer text-dark" style="margin-top: 2px">
            <tabler-icon
              :icon="iconIsDown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="20"
            />
          </span>
        </div>
        <hr v-if="iconIsDown" class="text-dark" style="border-top: 3px groove" />
      </div>
      <b-collapse
        id="collapse-ah-report"
        class="mt-1 px-1"
        v-model="iconIsDown"
        v-for="(item, idx) in accountHistoryItems"
        :key="idx"
      >
        <account-history-item
          :title="item.title"
          color="item.color"
          :textColor="isDarkSkin ? item.darkColor : item.lightColor"
          :background="isDarkSkin ? item.darkBackground : item.lightBackground"
          :data="data[item.info]"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

import AccountHistoryItem from "@/views/commons/components/clients/dashboard/options/report-module/components/AccountHistoryItem.vue";
import { mapGetters, mapActions } from "vuex";

// Service Client Report
import ClientReportService from "@/views/commons/components/clients/dashboard/services/client.report.js";

export default {
  components: {
    AccountHistoryItem,
  },

  props: {
    clientId: {
      type: String,
    },
  },

  data() {
    return {
      iconIsDown: false,
      data: [],
      busy: true,
      accountHistoryItems: [
        {
          title: "DELETED ACCOUNTS",
          color: "success",
          lightBackground: "#d5ffe7",
          lightColor: "#00d25b",
          darkBackground: "#005023",
          darkColor: "#00d25b",
          info: "dispute_results",
        },
        {
          title: "POTENTIALLY NEGATIVE ACCOUNTS",
          color: "danger",
          lightBackground: "#ffd3d3",
          lightColor: "#f10000",
          darkBackground: "#730000",
          darkColor: "#f10000",
          info: "potentially_negative_accounts",
        },
        {
          title: "POSITIVE ACCOUNTS",
          color: "primary",
          lightBackground: "#eef1ff",
          lightColor: "#232a46",
          darkBackground: "#00166c",
          darkColor: "#3f7afa",
          info: "positive_accounts",
        },
      ],
    };
  },

  async created() {
    await this.getHistoryAccountReport();
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      getRefreshReport: "ReportStore/G_REFRESH_REPORT",
    }),
    isFromModalFinancial() {
      return this.moduleId == 23;
    },
    validContent() {
      return (
        this.data?.dispute_results?.length ||
        this.data?.potentially_negative_accounts?.length ||
        this.data?.positive_accounts?.length
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
  },

  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),
    async getHistoryAccountReport() {
      try {
        this.loading = true;
        let params = {
          idClientAccount: this.$route.params.idClient || this.clientId,
        };
        const data = await ClientReportService.getAccountHistory(params);
        if (data.status == 200) {
          this.$emit("uploadedData");
        }
        this.data = data.data;
        this.busy = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something has gone wrong with the report!"
        );
      } finally {
        this.loading = false;
      }
    },

    async removeExceptDelete() {
      try {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "Disputed accounts will also be deleted, you won't be able to revert this!"
        );
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.deleteForException({
            account_id: this.$route.params.idClient || this.clientId,
            id_user: this.currentUser.user_id,
            type: 1,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            // await this.getHistoryAccountReport();
            this.updateRefreshReport(4);
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
  watch: {
    async getRefreshReport(state) {
      if (state == 4) {
        await this.getHistoryAccountReport();
        this.updateRefreshReport(0);
      }
    },
  },
};
</script>

<style scoped>
*{
  --light-bg-color: #f1f1f1;
  --light-bg-color2: #fafafa;
  --dark-bg-color1: #232328;
  --dark-bg-color2: #17171A;
}
.dark-bg-card{
  background-color: var(--dark-bg-color2) !important;
  border-radius: 10px;
}
.light-bg-card{
  background-color: var(--light-bg-color2) !important;
  border-radius: 10px;
}
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 1em;
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.container-report {
  overflow-y: auto;
}

.action-data {
  position: absolute;
  right: -10px;
  top: 0;
}

.icon-collapse {
  position: absolute;
  right: 15px;
  top: 25%;
}

.btn-delete-all {
  position: absolute;
  left: 15px;
  top: 25%;
}
</style>
