<template>
  <div>
    <template v-if="notReady">
      <div class="d-flex justify-content-center">
        <span
          v-if="!statusPending.value && item[nameProp] != 'IN PROCESS'"
          :class="[StatusColorText(item[nameProp])]"
        >
          {{ item[nameProp] }}</span
        >
        <feather-icon
          v-if="!statusPending.value && item[nameProp] == 'PENDING'"
          icon="EditIcon"
          class="text-warning cursor pointer"
          v-b-tooltip.hover.right="'Edit'"
          style="margin-left: 5px"
          size="15"
          @click="changeStatusPending(item)"
        />
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="statusPending.value || !item[nameProp] || item[nameProp] == 'IN PROCESS'"
      >
        <div v-if="!showSelector && service != 'aplications'">
          <div class="d-flex justify-content-center">
            <span class="text-warning"> {{ item[nameProp] ? item[nameProp] : 'PENDING' }} </span>
            <feather-icon
              icon="EditIcon"
              size="15"
              class="text-warning cursor-pointer"
              v-b-tooltip.hover.right="'Edit'"
              style="margin-left: 5px"
              @click="showSelector = true"
            />
          </div>
        </div>
        <div v-else>
          <b-input-group size="sm">
            <b-form-select v-model="status" @change="openModalAction">
              <b-form-select-option
                :value="item"
                v-for="(item, index) in options"
                :key="index"
              >
                {{ item }}
              </b-form-select-option>
            </b-form-select>
            <b-input-group-append is-text @click="hideSelector()">
              <feather-icon
                icon="XIcon"
                class="text-danger cursor-pointer"
                size="20"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </template>
    <div v-else class="text-center">
      <span :class="[StatusColorText(item[nameProp])]">{{
        item[nameProp]
      }}</span>
    </div>
    <ModalActions
      :data="item"
      v-if="modalActionsView"
      @close="closeModalAction"
      :action="status"
      :option="option"
      @send="(value) => $emit('send', value)"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalActions from "@/views/commons/financial-approval/ModalActions";
import PotentialAppService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";

export default {
  name: "ChangeStatusP",
  props: {
    inSpecialist: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    nameProp: {
      type: String,
      required: true,
    },
    option: {
      type: String,
      required: true,
    },
    service: {
      type: String,
      default: null,
    },
  },
  components: {
    ModalActions,
  },
  data() {
    return {
      statusPending: {
        id: 0,
        value: false,
      },
      status: "",
      modalActionsView: false,
      showSelector: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    notReady() {
      return (
        this.inSpecialist &&
        (this.item[this.nameProp] == "PENDING" ||
          this.item[this.nameProp] == "IN PROCESS" ||
          this.item[this.nameProp] === null)
      );
    },
  },
  created() {
    // remove in process option if it's current status
    if(this.item[this.nameProp] == 'IN PROCESS') {
      this.options = this.options.filter(option => option !== 'IN PROCESS');
    }
  },
  methods: {
    StatusColorText(status) {
      switch (status) {
        case "PENDING":
        case  "IN PROCESS":
          return "text-warning";
        case "APPROVED":
        case "YES":
          return "text-success";
        case "DENIED":
        case "NO":
          return "text-danger";
        default:
          return "bg-secondary text-light";
      }
    },
    changeStatusPending(item) {
      if (!item[this.nameProp] || item[this.nameProp] == "PENDING") {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = item.id;
        this.status = "";
      }
    },
    hideSelector() {
      this.status = null;
      this.showSelector = false;
      this.statusPending.value = false;
    },
    async openModalAction() {
      if (this.status === "IN PROCESS") {        
        await this.setPotentialInProcess();
      } else if (this.option == "StatusPaid" && this.status === "YES") {
        this.$emit("send", {
          ...this.item,
          n_action: "YES",
          n_option: "StatusPaid",
        });
      } else {
        if (this.status != "") {
          this.modalActionsView = !this.modalActionsView;
        }
      }
    },
    async setPotentialInProcess() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          this.addPreloader();
          await PotentialAppService.setPotentialInProcess({
            potential_id: this.item.id,
            user_id: this.currentUser.user_id,
            client_account_id: this.item.account_id,
          });
          this.$emit("refresh");
        } catch (error) {
          console.error(error);
        } finally {
          this.removePreloader();
          return;
        }
      }
    },
    closeModalAction() {
      this.modalActionsView = false;
    },
  },
};
</script>
