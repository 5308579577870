var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.length)?_c('div',{staticClass:"position-relative mb-2 px-5 py-1",class:_vm.isDarkSkin ? 'dark-bg-card' : 'light-bg-card'},[_c('header-grid-report'),_c('div',{ref:"container-report",staticClass:"container-report pt-1 px-1"},_vm._l((_vm.data),function(items,index){return _c('div',{key:index,staticClass:"container-grid mb-1"},_vm._l((items),function(account,index){return _c('div',{key:index,staticClass:"shadow w-auto p-2 rounded position-relative"},[(account.empty)?_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100 text-muted"},[_c('feather-icon',{staticClass:"cursor-pointer text-end text-info text-muted",staticStyle:{"margin-right":"3px"},attrs:{"icon":"SlashIcon","size":"16"}}),_c('p',{staticClass:"m-0"},[_vm._v("NO CONTENT")])],1):_vm._e(),_vm._l((account.content),function(infoAc,index){return _c('div',{key:index,staticClass:"d-flex justify-content-start pr-3"},[_c('span',{staticClass:"mr-1 font-weight-bolder"},[_vm._v(_vm._s(infoAc.key)+": ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(infoAc.value),expression:"infoAc.value",modifiers:{"hover":true,"top":true}}],staticClass:"text-truncate"},[_vm._v(" "+_vm._s(infoAc.value)+" ")]),(
                _vm.isEnabledForEditAndDelete &&
                !_vm.isFromModalFinancial &&
                !account.empty
              )?_c('div',{staticClass:"action-data"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Actions'),expression:"'Actions'",modifiers:{"hover":true,"right":true}}],staticClass:"align-middle text-info",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],on:{"click":function($event){return _vm.openModalTracking(account)}}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer text-end text-info",attrs:{"icon":"ListIcon","size":"16"}}),_c('span',{staticClass:"align-middle"},[_vm._v("View Tracking")])],1),(_vm.isSpecialist)?_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],on:{"click":function($event){return _vm.editPublicRecord(account)}}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer text-end text-warning",attrs:{"size":"16","icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Edit Account")])],1):_vm._e(),(_vm.isSpecialist)?_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],on:{"click":function($event){return _vm.deletePublicRecord(
                      items,
                      account.id,
                      account.cr_accounts_id
                    )}}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer text-danger",attrs:{"size":"16","icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Delete Account")])],1):_vm._e()],1)],1):_vm._e()])}),(!account.empty)?_c('b-badge',{staticClass:"float-right mt-1",attrs:{"variant":_vm.getColorStatus(account.status)}},[_vm._v(" "+_vm._s(account.status_name)+" ")]):_vm._e()],2)}),0)}),0),(_vm.editPrModalController)?_c('new-edit-public-record-modal',{attrs:{"itemPr":_vm.itemPr,"userId":_vm.currentUser.user_id},on:{"close":function($event){_vm.editPrModalController = false}}}):_vm._e(),(_vm.modalTrackingController)?_c('modal-tracking-letter',{attrs:{"pItem":_vm.itemPr,"client-name":_vm.client.client_name,"account":_vm.client.account},on:{"close":_vm.closeModalTracking}}):_vm._e()],1):_c('div',{staticClass:"text-center py-5"},[_vm._v("There are no records to show")])])}
var staticRenderFns = []

export { render, staticRenderFns }