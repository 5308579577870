<template>
  <div>
    <b-modal
      ref="custom-modal"
      v-model="showModal"
      size="md"
      @hide="closeModal"
      hide-footer
      no-close-on-backdrop
      header-class="p-0"
      content-class="custom-content"
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
      no-enforce-focus
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            HISTORY OF REPORT DETAIL
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModal"
            />
          </div>
        </div>
      </template>
      <div class="mb-2">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <!-- <span class="title-tag">CLIENT NAME</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client | {{ client.client_name }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="pt-md-0 pt-1">
            <!-- <span class="title-tag">CLIENT ACCOUNT</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client Account | {{ client.account }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="trackingTable"
        class="table-new-customization blue-scrollbar custom-table"
        :fields="fields"
        :items="myProvider"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(file)="data">
          <a
            v-if="data.item.url_pdf != ''"
            target="_blank"
            :href="data.item.url"
          >
            <amg-icon
              icon="FilePdfIcon"
              size="20"
              class="text-danger"
              v-b-tooltip.hover.right="'Show report'"
          /></a>
        </template>
        <template #cell(created_by)="data">
          <div>{{ data.item.created_by }}</div>
          <div>{{ data.item.created_at | myGlobal }}</div>
          <div>{{ data.item.created_at | myHourTime }}</div>
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.statement_status == 1">Email</span
          ><span v-else>Correspondence</span>
        </template>

        <template #cell(status)="data">
          <div v-if="data.item.status == 'COMPLETED'" style="color: #00cc00">
            SENT
          </div>
          <div v-if="data.item.status == 'PENDING'" style="color: red">
            {{ data.item.status }}
          </div>
          <div v-if="data.item.sent_date != null">
            {{ data.item.sent_date | myGlobal }}
          </div>
          <div v-if="data.item.sent_date != null">
            {{ data.item.sent_date | myHourTime }}
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>
  <script>
import ServiceClientReport from "@/views/commons/components/clients/dashboard/services/client.report.js";
export default {
  props: {
    client: Object,
  },

  data() {
    return {
      showModal: false,
      isBusy: false,
      fields: [
        {
          key: "created_by",
          label: "CREATED By",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "status",
          label: "Status",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "type",
          label: "TYPE",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "file",
          label: "Report",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      dataHistory: [],
    };
  },
  computed: {},
  created() {
    this.openModal();
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$emit("close");
    },
    async myProvider() {
      const formData = {
        client_account_id: this.$route.params.idClient,
      };
      try {
        const data = await ServiceClientReport.getTrackingReportDetails(
          formData
        );
        this.dataHistory = data;
        return this.dataHistory;
      } catch {
        console.log("Tracking Modal");
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
.custom-header {
  border-radius: 15px !important;
}
.custom-content {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.custom-modal {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

</style>