export default [
  {
    key: "created_at",
    label: "Request date",
    thClass: "text-center",
  },
  {
    key: "emit_date",
    label: "Report date",
    thClass: "text-center",
  },
  {
    key: "service",
    label: "Service",
    thClass: "text-center",
  },
  {
    key: "status",
    label: "Status",
    thClass: "text-center",
  },
  {
    key: "file",
    label: "File",
    thClass: "text-center",
  },
  {
    key: "transunion",
    label: "Transunion",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "experian",
    label: "Experian",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "equifax",
    label: "Equifax",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "to_remove",
    label: "Remove",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "to_settlement",
    label: "Settlement",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "personal_info",
    label: "Personal Info",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "inquiries",
    label: "Inquiries",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "public_records",
    label: "Public Records",
    thClass: "text-center",
    tdClass: "text-center",
  },
];
