<template>
  <div>
    <portal-target name="sidebar-notes" slim />

    <b-nav pills class="mb-0 border-bottom-primary custom-tab-amg" justified>
      <b-nav-item
        v-for="(item, index) in listNav"
        exact-active-class="active border-radius-tabs"
        :link-classes="['h-full px-3', bgTabsNavs]"
        exact
        :key="index"
        :active="selectedTab === item.id"
        @click="selectTab(item)"
        >{{ item.name }}
      </b-nav-item>
    </b-nav>
    <ValidationObserver ref="accounts" tag="div">
      <PersonalProfile
        v-if="selectedTab == 1"
        :score="datos.score"
        :day_processed_format="day_processed_format"
        :datos="datos"
        :personal_info="personal_info"
        :process="process"
        :day_processed="day_processed"
        :round_id="round_id"
        :idSection="this.rowData.id"
        :section="section"
      />

      <CreditSummary
        v-if="selectedTab == 2"
        :datos="datos"
        :val="plataform_id"
      />

      <CreditInquiries
        v-if="selectedTab == 3"
        :process="process"
        :inquires="inquires"
        :day_processed="day_processed"
        :idSection="this.rowData.id"
        :round_id="round_id"
        :section="section"
      />

      <PublicInformation
        v-if="selectedTab == 4"
        :public_info="public_info"
        :day_processed="day_processed"
        :round_id="round_id"
        :process="process"
        :idSection="this.rowData.id"
        @setPublicRecord="setPublicRecord = $event"
      />

      <Accounts
        v-if="selectedTab == 5"
        :accounts="accounts"
        :process="process"
        :day_processed="day_processed"
        :round_id="round_id"
        :idSection="this.rowData.id"
        :section="section"
        @setAccounts="setAccounts = $event"
        :rowData="rowData"
        :isBusy="isBusy"
        @reload="getDataOfScore()"
      />
    </ValidationObserver>

    <ModalRound
      v-if="showModalRound"
      :roundLetterId="round_id"
      :dataClient="requestWpdataClient"
      @close="showModalRound = false"
      :idSection="this.rowData.id"
      :section="section"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CreditSummary from "@/views/commons/components/ncr/components/report/components/CreditSummary.vue";
import CreditInquiries from "@/views/commons/components/ncr/components/report/components/CreditInquiries.vue";
import PublicInformation from "@/views/commons/components/ncr/components/report/components/PublicInformation.vue";
import Accounts from "@/views/commons/components/ncr/components/report/components/AccountsNew.vue";
import PersonalProfile from "@/views/commons/components/ncr/components/report/components/PersonalProfile.vue";
import ModalRound from "@/views/commons/components/ncr/components/report/modal/ModalRoundWorkPlan.vue";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";

export default {
  components: {
    PersonalProfile,
    ModalRound,
    CreditSummary,
    CreditInquiries,
    PublicInformation,
    Accounts,
  },
  props: {
    section: {
      type: [Number, String, Boolean],
      default: "workplan",
    },
    rowData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      plataform_id: null,
      datos: {},
      status: [],
      process: "",
      data: [],
      personal_info: [],
      inquires: [],
      public_info: [],
      accounts: [],
      showModalRound: false,
      requestWpdataClient: null,
      round_id: "",
      setAccounts: {
        reason: [],
        instruction: [],
      },
      setPublicRecord: {
        reason: [],
        instruction: [],
      },
      day_processed: null,
      day_processed_format: null,
      comment: null,
      clear_datos: {
        date: moment().format("MM/DD/YYYY"),
        score: [{ score: "0" }, { score: "0" }, { score: "0" }],
        personal: [],
        sumary: [
          {
            t_account: 0,
            o_account: 0,
            c_account: 0,
            balance: 0,
            payments: 0,
          },
          {
            t_account: 0,
            o_account: 0,
            c_account: 0,
            balance: 0,
            payments: 0,
          },
          {
            t_account: 0,
            o_account: 0,
            c_account: 0,
            balance: 0,
            payments: 0,
          },
        ],
        inquires: [],
        account: [],
        public: [],
      },
      data_fake: [
        {
          account: [],
          inquires: [],
          personal: [],
          public: [],
        },
      ],
      listNav: [
        {
          name: "Personal Profile",
          count: 0,
          id: 1,
        },
        {
          name: "Summary",
          count: 0,
          id: 2,
        },
        {
          name: "Inquiries",
          count: 0,
          id: 3,
        },
        {
          name: "Public Information",
          count: 0,
          id: 4,
        },
        {
          name: "Accounts",
          count: 0,
          id: 5,
        },
      ],
      selectedTab: 5,
      isBusy: true,
    };
  },
  async beforeDestroy() {
    await this.A_CLEAR_DISPUTE_DATA([]);
    await this.A_ID_REQUEST_WORKPLAN(null);
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      G_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/G_ID_REQUEST_WORKPLAN",
    }),

    isForRound() {
      return (
        this.process == 1 &&
        this.moduleId == 28 &&
        this.day_processed > "2021-09-27"
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.generateReport();
    await this.getClientWpReport();
    await this.getDataOfScore();
  },
  methods: {
    ...mapActions({
      A_CLEAR_DISPUTE_DATA: "RequestWorkPlanStore/A_CLEAR_DISPUTE_DATA",
      A_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/A_ID_REQUEST_WORKPLAN",
      A_IS_SUCCESSFUL: "RequestWorkPlanStore/A_IS_SUCCESSFUL",
      A_UPDATE_STEP_WP: "RequestWorkPlanStore/A_UPDATE_STEP_WP",
      A_UPDATE_STEP_UR: "DebtSolutionDisputeResult/A_UPDATE_STEP_UR",
    }),

    updateStep(step) {
      const updatedData = {
        id: this.rowData.id,
        step: step,
      };
      if (this.section == "workplan") {
        this.A_UPDATE_STEP_WP(updatedData);
      } else if (this.section == "update") {
        this.A_UPDATE_STEP_UR(updatedData);
      }
    },

    async getDataOfScore() {
      try {
        this.addPreloader();
        const { data } = await ReportLeadService.getCreditReportData({
          score_id: this.rowData.score_id,
          account_id: this.rowData.client_account_id,
          program_id: this.rowData.program_id,
          lead_id: this.rowData.lead_id,
        });
        this.accounts = data.accounts;
        this.accounts.forEach((account) => {
          account.items.forEach((item) => {
            item.to_select = false;
          });
        });
        this.inquires = data.inquires;
        this.personal_info = data.personal;
        this.public_info = data.public;
        this.round_id = data.round_id;
        this.isBusy = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },

    async generateReport() {
      try {
        this.addPreloader();
        const response = await ReportLeadService.getReports({
          id: this.rowData.score_id,
        });

        this.clear_datos.score[0].score = response.data[0].transunion;
        this.clear_datos.score[1].score = response.data[0].experian;
        this.clear_datos.score[2].score = response.data[0].equifax;

        if (response.status == 200) {
          this.datos =
            response.data.length > 0
              ? response.data[0].type_ncr == "AUTOMATIC"
                ? response.data[0].cr
                : this.clear_datos
              : this.clear_datos;
          this.data =
            response.data.length > 0
              ? response.data[0].cra || this.data_fake
              : this.data_fake;

          this.plataform_id = response.data[0].plataform_id;
          this.process = response.data[0].process ?? 0;
          this.day_processed = response.data[0].day_processed;
          this.day_processed_format = this.day_processed_format
            ? moment(this.day_processed).format("MM/DD/YYYY")
            : moment().format("MM/DD/YYYY");
          this.A_ID_REQUEST_WORKPLAN(response.data[0].id);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },

    async toRevision() {
      try {
        if (this.G_DISPUTE_DATA.length == 0) {
          const validateObservation = await this.$swal({
            title: "Your Observation",
            input: "textarea",
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonText: "Cancel",
            inputValidator: (observation) => {
              return !observation ? "Please enter your observation" : undefined;
            },
          });

          if (validateObservation.value) {
            this.addPreloader();
            const params = {
              save_by: this.currentUser.user_id,
              letters_id: this.round_id,
              id: this.rowData.id,
              comment: validateObservation.value,
            };
            if (this.section == "workplan") {
              await RequestWorkPlanService.addCommentWithoutDispute(params);
            } else {
              await DisputeResultsService.addCommentWithoutDispute(params);
            }

            this.isRecommendable();
          }
        } else {
          this.showModalRound = true;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    isRecommendable() {
      this.showQuestionSwal(
        "Would you like to recommend a service?",
        null,
        "Yes",
        "Cancel",
        "question",
        {
          allowEscapeKey: false,
          allowOutsideClick: false,
          showDenyButton: true,
          showCancelButton: true,
          denyButtonText: "No",
          customClass: {
            confirmButton: "btn btn-outline-success mr-1",
            cancelButton: "btn btn-outline-warning mr-1",
            denyButton: "btn btn-outline-danger  mr-1",
          },
          focusConfirm: false,
        }
      ).then(async (result) => {
        if (result.isConfirmed) {
          this.addPreloader();
          this.section == "workplan" ? this.updateStep(4) : this.updateStep(3);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Dispute completed"
          );
        } else if (result.isDenied) {
          this.addPreloader();
          const params = {
            save_by: this.currentUser.user_id,
            letters_id: this.round_id,
            id: this.rowData.id,
          };
          let res;
          if (this.section == "workplan") {
            res = await ReportService.toRevision(params);
            this.updateStep(5);
          } else if (this.section == "update") {
            res = await DisputeResultsService.toRevisionUpdateRequest(params);
            this.updateStep(4);
          }
          if (res.status == 200) {
            this.showToast(
              "success",
              "top-right",
              "Congratulations",
              "CheckIcon",
              "Dispute completed"
            );
            this.redirectTabToRevision();
          } else {
            this.showErrorSwal();
          }
        }
        this.removePreloader();
      });
    },

    redirectTabToRevision() {
      this.section == "workplan"
        ? this.$router.push({ name: "sp-wp-to-revision" })
        : this.$router.push({
            name: "sp-update-request-to-revision",
          });
    },

    async getClientWpReport() {
      try {
        const { data } = await ReportService.getClientWpReport({
          id: this.rowData.id,
          section: this.section,
        });
        this.requestWpdataClient = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    selectTab(item) {
      this.selectedTab = item.id;
    },
    async returnActionStep2(dataWorkPlan) {
      let status;
      try {
        const { id, client_account_id } = dataWorkPlan;
        await NcrRequestService.returnActionToSyncronizeItems({
          id,
          client_account_id,
        });
        status = true;
      } catch (ex) {
        status = false;
      }
      return status;
    },
  },
};
</script>

<style scoped>
.table::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.table::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}
.table::-webkit-scrollbar-thumb:hover {
  background: #c5c2c2 !important;
}
</style>
