var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav',{staticClass:"m-0 custom-tab-amg",attrs:{"pills":"","card-header":""},model:{value:(_vm.tabUpdate),callback:function ($$v) {_vm.tabUpdate=$$v},expression:"tabUpdate"}},_vm._l((_vm.updateTabs),function(tab,index){return _c('b-nav-item',{key:index,attrs:{"link-classes":['px-3', _vm.bgTabsNavs],"active":tab.id == _vm.tabUpdate},on:{"click":function($event){_vm.tabUpdate = tab.id}}},[_vm._v(" "+_vm._s(tab.label)+" "),_c('b-badge',{staticClass:"ml-1",attrs:{"variant":index === 1 ? 'info' : 'danger',"pill":""}},[_vm._v(" "+_vm._s(index === 0 ? _vm.counters.pending : index === 1 ? _vm.counters.to_revision : _vm.counters.completed)+" ")])],1)}),1),_c('b-table',{staticClass:"table-new-customization custom-table",attrs:{"responsive":"","items":_vm.filteredItems,"fields":_vm.visibleFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(request)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.requested_by))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.requested_at)))])]}},{key:"cell(disputed_by)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.created_by))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.created_at)))])]}},{key:"cell(completed_by)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.completed_by))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.completed_at)))])]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [(item.program_id)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(item.program_name),expression:"item.program_name",modifiers:{"bottom":true}}],staticClass:"d-flex justify-content-center m-0"},[_c('img',{attrs:{"src":_vm.getProgramImageRoute(item.program_id),"alt":"img_services"}})]):_vm._e()]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-primary cursor-pointer font-weight-bolder",on:{"click":function($event){return _vm.editFirstNote(item)}}},[_vm._v(" "+_vm._s([1, 2].includes(item.type_round) ? "FIRST ROUND" : "-")+" ")])]}},{key:"cell(tracking)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ListIcon","size":"18"},on:{"click":function($event){return _vm.openModalTracking(item)}}})]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [([1, 2].includes(item.type_round))?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" Letter ")]):_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" Direct ")])]}},{key:"cell(from)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.ncr_id ? 'light-primary' : 'light-warning'}},[_vm._v(" "+_vm._s(item.ncr_id ? "NCR" : "Individual")+" ")])]}},{key:"cell(items)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.editFirstNote(item)}}},[_vm._v(" "+_vm._s(item.sumeq + item.sumtr + item.sumex)+" ")])]}},{key:"cell(disputes)",fn:function(ref){
var item = ref.item;
return [(
          item.state == 7 &&
          item.status_dispute == 0 &&
          item.date_diff > _vm.dateDiff
        )?_c('feather-icon',{staticClass:"cursor-pointer text-primary",staticStyle:{"font-size":"20px","margin-top":"5px"},attrs:{"icon":"PlusIcon","size":"22"},on:{"click":function($event){return _vm.openModalDispute(item)}}}):_vm._e(),(
          item.state == 7 &&
          ((item.status_dispute == 1 &&
            item.status_dispute_grilla == 'PENDING') ||
            item.status_dispute_grilla == 'COMPLETED' ||
            item.status_dispute_grilla == 'TO REVISION') &&
          item.date_diff > _vm.dateDiff
        )?_c('b-badge',{staticStyle:{"font-weight":"bold","text-transform":"uppercase"},attrs:{"variant":item.status_dispute_grilla === 'PENDING'
            ? 'light-warning'
            : item.status_dispute_grilla === 'TO REVISION'
            ? 'light-info'
            : 'light-success'},on:{"click":function($event){return _vm.openModalViewDispute(item)}}},[_vm._v(" "+_vm._s(item.status_dispute_grilla)+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
            var item = ref.item;
return [_c('feather-icon',{staticClass:"cursor-pointer mr-1",class:item.view == 1 ? 'oscillate text-warning' : 'text-primary',attrs:{"icon":"EyeIcon","size":"22"},on:{"click":function($event){return _vm.openUpdate(item)}}})]}}])}),(_vm.showModalAction)?_c('ActionDoneReturnModal',{attrs:{"dataClient":_vm.dataUpdate,"revision":false,"section":'UPDATE'},on:{"close":function($event){_vm.showModalAction = false}}}):_vm._e(),(_vm.showTracking)?_c('ModalTrackingRo',{attrs:{"data-tracking":_vm.trackingData},on:{"close":_vm.closeModalTracking}}):_vm._e(),(_vm.showDetailFN)?_c('DetailsModal',{attrs:{"details-round-data":_vm.roundData,"validate-modal":1},on:{"close":_vm.closeEditFirstNote}}):_vm._e(),_c('b-modal',{attrs:{"size":"xmd","title":"UPDATES","scrollable":"","header-bg-variant":"transparent","modal-class":"custom-modal-amg","hide-footer":"","header-class":"p-0 custom-header"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal d-flex justify-content-between"},[_c('h3',{staticClass:"text-white m-0 font-weight-bold"},[_vm._v(" New Update Request for "+_vm._s(((_vm.client.client_name) + " (" + (_vm.client.account) + ")"))+" ")]),_c('div',[_c('feather-icon',{staticClass:"close-button",attrs:{"icon":"XIcon","size":"22"},on:{"click":function($event){_vm.modalDisputeOn = false}}})],1)])]},proxy:true}]),model:{value:(_vm.modalDisputeOn),callback:function ($$v) {_vm.modalDisputeOn=$$v},expression:"modalDisputeOn"}},[(_vm.modalDisputeOn)?_c('CreateDispute',{attrs:{"isModal":true,"data":_vm.createDisputeData},on:{"refresh":function($event){return _vm.getDisputes()},"close":function($event){_vm.modalDisputeOn = false}}}):_vm._e()],1),(_vm.showModalAction)?_c('ActionDoneReturnModal',{attrs:{"dataClient":_vm.dataUpdate,"revision":false,"section":'UPDATE'},on:{"close":function($event){_vm.showModalAction = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }