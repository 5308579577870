<template>
  <div v-if="ownControl">
    <div class="col-lg-12 col-md-12">
      <div class="mb-1">
        <b-button variant="info" @click="closeModal">
          <feather-icon icon="ChevronLeftIcon" /> RETURN
        </b-button>
      </div>
      <div class="form-group">
        <p>
          FORMAT :<span class="ml-1" style="font-weight: bold; font-size: 15px"
            >{{ this.dataRound.format }}
          </span>
        </p>
      </div>
      <div class="form-group">
        <div class="row">
          <b-tabs content-class="mt-3">
            <b-tab v-if="bureaus.includes(1)" :active="this.bureau == 1" @click="search(1)">
              <template #title
                ><span>TRANS</span><span style="color: #4d917a">UNION</span>
              </template>
            </b-tab>
            <b-tab v-if="bureaus.includes(2)" :active="this.bureau == 2" @click="search(2)">
              <template #title
                ><span style="color: #0566b7; font-weight: bold"
                  >EXPERIAN</span
                ></template
              >
            </b-tab>
            <b-tab v-if="bureaus.includes(3)" :active="this.bureau == 3" @click="search(3)"
              ><template #title
                ><span
                  style="color: #822a3a; font-weight: bold; font-style: italic"
                  >EQUIFAX</span
                ></template
              ></b-tab
            >
          </b-tabs>

          <div v-if="viewp == false" class="col-lg-12">
            <div class="form-group">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </div>
          </div>
          <div v-if="viewp && formatid == 1" class="col-lg-12">
            <div
              style="
                margin: 2px 10px 10px 0px;
                padding: 10px;
                border: 1px solid #ccc;
                height: 340px;
                overflow-y: auto;
              "
            >
              <p style="margin-bottom: 0">
                {{ valors[0].name_client }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].street }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].city }}
              </p>
              <p style="margin-bottom: 0">
                {{ "Telephone: " + valors[0].mobile }}
              </p>
              <p style="margin-bottom: 0">
                {{ "Date of Birth: " + valors[0].dob }}
              </p>
              <p style="margin-bottom: 0">
                {{ "SS#: " + valors[0].ssn }}
              </p>
              <br />
              <p style="margin-bottom: 0">
                {{ valors[0].name_oficial }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].pob }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].address }}
              </p>
              <br />
              <p style="margin-bottom: 0">
                {{ valors[0].date_now }}
              </p>
              <br />
              <p>To Whom It May Concern,</p>
              <p>
                This letter is a formal complaint that you are reporting
                inaccurate and incomplete credit <br />
                information on my Credit Report.
              </p>
              <p>
                I understand that mistakes happen but your inaccurate
                information could cost me in higher interest rates and I have
                <br />
                enough expenses as it is. Please investigate the following
                information and either remove it or at least send me the <br />
                information that you used to add it to my report.
              </p>
              <ul style="list-style: decimal">
                <li
                  v-for="item in JSON.parse(valors[0].pi)"
                  v-if="valors[0].pi != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{
                      item.type == 1
                        ? "NAME: " + item.content
                        : item.type == 2
                        ? "DATE OF BIRTH:  " + item.content
                        : item.type == 3
                        ? "ADDRESS: " + item.content
                        : "SSN: " + item.content
                    }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].inq)"
                  v-if="valors[0].inq != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.company }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ "Date of inquiry: " + item.date }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].pr)"
                  v-if="valors[0].pr != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.public }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ "Court Case: " + item.court }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].ac)"
                  v-if="valors[0].ac != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.account_name }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.account_number }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
              </ul>
              <p>Thank You</p>
              <p>Sincerely yours,</p>
              <br /><span
                style="
                  margin-top: 10px;
                  border-top: 1px solid black;
                  padding: 10px 60px;
                "
                >{{ valors[0].name_client }}</span
              >
            </div>
          </div>
          <div v-if="viewp && formatid == 2" class="col-lg-12">
            <div
              style="
                margin: 2px 10px 10px 0px;
                padding: 10px;
                border: 1px solid #ccc;
                height: 340px;
                overflow-y: auto;
              "
            >
              <p style="margin-bottom: 0">
                {{ valors[0].name_client }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].street }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].city }}
              </p>
              <p style="margin-bottom: 0">
                {{ "Telephone: " + valors[0].mobile }}
              </p>
              <p style="margin-bottom: 0">
                {{ "Date of Birth: " + valors[0].dob }}
              </p>
              <p style="margin-bottom: 0">
                {{ "SS#: " + valors[0].ssn }}
              </p>
              <br />
              <p style="margin-bottom: 0">
                {{ valors[0].name_oficial }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].pob }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].address }}
              </p>
              <br />
              <p style="margin-bottom: 0">
                {{ valors[0].date_now }}
              </p>
              <br />
              <p>To Whom It May Concern,</p>
              <p>
                This letter is a formal complaint that you are reporting
                inaccurate and incomplete credit information. I <br />
                am distressed that you have included the information below in my
                credit profile and that you have <br />
                failed to maintain reasonable procedures in your operations to
                assure maximum possible accuracy <br />
                in the credit reports you publish. Credit reporting laws ensure
                that bureaus report only 100% <br />
                accurate credit information. Every step must be taken to assure
                the information reported is <br />
                completely accurate and correct. The following information
                therefore needs to be re-investigated.
              </p>
              <ul style="list-style: decimal">
                <li
                  v-for="item in JSON.parse(valors[0].pi)"
                  v-if="valors[0].pi != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{
                      item.type == 1
                        ? "NAME: " + item.content
                        : item.type == 2
                        ? "DATE OF BIRTH:  " + item.content
                        : item.type == 3
                        ? "ADDRESS: " + item.content
                        : "SSN: " + item.content
                    }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].inq)"
                  v-if="valors[0].inq != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.company }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ "Date of inquiry: " + item.date }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].pr)"
                  v-if="valors[0].pr != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.public }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ "Court Case: " + item.court }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].ac)"
                  v-if="valors[0].ac != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.account_name }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.account_number }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
              </ul>
              <p>
                I respectfully request to be provided proof of this alleged
                item, specifically the contract, note or other <br />
                instrument bearing my signature.
              </p>
              <p>
                Failing that, the item must be deleted from the report as soon
                as possible. This information is entirely <br />
                inaccurate and incomplete, and as such represents a very serious
                error in your reporting. Please <br />
                delete this misleading information and supply a corrected credit
                profile to all creditors who have <br />
                received a copy within the last six months, or the last two
                years for employment purposes.
              </p>
              <p>
                Additionally, please provide the name, address, and Telephonee
                number of each credit grantor or <br />
                other subscriber.
              </p>
              <p>
                Under federal law, you have thirty (30) days to complete your
                re-investigation. Be advised that the <br />
                description of the procedure used to determine the accuracy and
                completeness of the information is <br />
                hereby requested as well, to be provided within fifteen (15)
                days of the completion of your re- <br />
                investigation.
              </p>
              <p>Sincerely yours,</p>
              <br /><span
                style="
                  margin-top: 10px;
                  border-top: 1px solid black;
                  padding: 10px 60px;
                "
                >{{ valors[0].name_client }}</span
              >
            </div>
          </div>
          <div v-if="viewp && formatid == 3" class="col-lg-12">
            <div
              style="
                margin: 2px 10px 10px 0px;
                padding: 10px;
                border: 1px solid #ccc;
                height: 340px;
                overflow-y: auto;
              "
            >
              <p style="margin-bottom: 0">
                {{ valors[0].name_client }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].street }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].city }}
              </p>
              <p style="margin-bottom: 0">
                {{ "Telephone: " + valors[0].mobile }}
              </p>
              <p style="margin-bottom: 0">
                {{ "Date of Birth: " + valors[0].dob }}
              </p>
              <p style="margin-bottom: 0">
                {{ "SS#: " + valors[0].ssn }}
              </p>
              <br />
              <p style="margin-bottom: 0">
                {{ valors[0].name_oficial }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].pob }}
              </p>
              <p style="margin-bottom: 0">
                {{ valors[0].address }}
              </p>
              <br />
              <p style="margin-bottom: 0">
                {{ valors[0].date_now }}
              </p>
              <br />
              <p>To Whom It May Concern,</p>
              <p>
                I am in disagreement with the items listed below which still
                appear on my credit report, even after your Investigation. I
                <br />
                would like these items immediately re-investigated. These
                inaccuracies are highly injurious to my credit rating.
              </p>
              <ul style="list-style: decimal">
                <li
                  v-for="item in JSON.parse(valors[0].pi)"
                  v-if="valors[0].pi != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{
                      item.type == 1
                        ? "NAME: " + item.content
                        : item.type == 2
                        ? "DATE OF BIRTH:  " + item.content
                        : item.type == 3
                        ? "ADDRESS: " + item.content
                        : "SSN: " + item.content
                    }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].inq)"
                  v-if="valors[0].inq != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.company }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ "Date of inquiry: " + item.date }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].pr)"
                  v-if="valors[0].pr != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.public }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ "Court Case: " + item.court }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
                <li
                  v-for="item in JSON.parse(valors[0].ac)"
                  v-if="valors[0].ac != null"
                  :key="item.id"
                  style="margin-bottom: 10px"
                >
                  <p style="margin-bottom: 0">
                    {{ item.reason }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.account_name }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.account_number }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.instruction }}
                  </p>
                </li>
              </ul>
              <p>
                Furthermore, In accordance with The Fair Credit Reporting Act,
                Public law 91-506, Title VI, Section 611, Subsection <br />
                A-D, please provide the names and business addresses of each
                individual with whom you verified the above, so that I <br />
                may follow up.
              </p>
              <p>
                Please forward me an updated credit report after you have
                completed your Investigation and corrections.
              </p>
              <p>
                Your cooperation and prompt attention are greatly appreciated.
              </p>
              <p>Thank You</p>
              <p>Sincerely yours,</p>
              <br /><span
                style="
                  margin-top: 10px;
                  border-top: 1px solid black;
                  padding: 10px 60px;
                "
                >{{ valors[0].name_client }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

export default {
  props: {
    dataRound: {
      type: Object,
      default: null,
    },
    bureaus: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      ownControl: false,
      valors: "",
      bureau: null,
      viewp: false,
      formatid: "",
    };
  },

  mounted() {
    this.ownControl = true;
  },
  async created() {
    await this.search(this.bureaus[0]);
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
    async search(valor) {
      try {
        this.viewp = false;

        const params = {
          idround: this.dataRound.id,
          bureau: valor,
        };
        const data = await ClientsOptionsServices.searchPdfRounds(params);

        this.bureau = valor;
        this.formatid = data.data[0].format_id;
        this.viewp = true;
        this.valors = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.direct;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>
