<template>
  <div>
    <b-modal
      v-model="modalUp"
      title-class="h4 text-white"
      centered
      size="md"
      :title="getTitle"
      modal-class="modal-primary"
      hide-footer
      @hidden="hideModal(false)"
    >
      <label for="title" size="lg" class="pt-1">Title</label>
      <b-form-input label-for="title" type="text" v-model="motive.title" />

      <b-row class="d-flex justify-content-end pt-1 pr-1">
        <b-button
          v-if="stateModal == 2"
          variant="warning"
          @click="submit"
          class="mr-1"
        >
          <feather-icon icon="Edit3Icon"> </feather-icon>
          UPDATE</b-button
        >
        <b-button v-if="stateModal == 2" @click="hideModal(false)">
          CANCEL</b-button
        >
        <b-button
          v-else
          variant="primary"
          :disabled="!motive.title"
          @click="submit"
        >
          <feather-icon icon="SaveIcon"> </feather-icon>
          SAVE</b-button
        >
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import MotivesService from "@/views/ce-digital/sub-modules/settings/views/motives/service/motives.service.js";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    stateModal: Number,
    motiveData: Object,
  },
  data() {
    return {
      motive: {},
      modalUp: false,
    };
  },
  methods: {
    async submit() {
      this.motive.user_id = this.currentUser.user_id;
      this.motive.state = 0;
      const res = await MotivesService.setMotives(this.motive);
      if (res.status == 200 && res.data[0].status == 0) {
        this.showWarningSwal("IMPORTANT!", res.data[0].message);
      } else {
        try {
          this.motive.state = this.stateModal;
          this.motive.id = this.stateModal == 1 ? 0 : this.motive.id;
          const resultSave = await MotivesService.setMotives(this.motive);
          this.addPreloader();
          if (resultSave.status == 200) {
            const tip = resultSave.data[0].status == 1 ? "added" : "updated";
            this.showToast(
              "success",
              "top-right",
              "Congratulation!",
              "CheckIcon",
              "Motive has been " + tip + " successfully"
            );
            this.$emit("reload");
          }
        } catch (error) {
          throw error;
        } finally {
          this.removePreloader();
        }
      }
    },
    hideModal(status) {
      this.modalUp = false;
      this.$emit("hide", status);
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getTitle() {
      switch (this.stateModal) {
        case 1:
          return "NEW MOTIVE";
          break;
        case 2:
          return "EDIT MOTIVE";
          break;
      }
    },
  },
  created() {
    this.motive = { ...this.motiveData };
    // if(this.stateModal == 2){
    //   this.motive = this.motiveData;
    // }else{
    //   this.motive = {}
    // }
  },
  mounted() {
    this.modalUp = true;
  },
};
</script>