<template>
  <b-card class="mb-1">
    <div class="mb-1 d-flex align-items-center">
      <feather-icon icon="CircleIcon" size="15" class="color-summary" />
      <span class="title-table d-inline-block lh-0 mr-1">Summary</span>
      <b-badge
        class="cursor-pointer"
        variant="light-primary"
        :class="showSummary ? null : 'collapsed'"
        @click="showSummary = !showSummary"
        aria-controls="collapse-summary"
        :aria-expanded="showSummary"
      >
        {{ showSummary ? "Hide" : "Show" }}
      </b-badge>
    </div>
    <b-collapse v-model="showSummary" id="collapse-summary">
      <b-table-simple
        caption-top
        responsive
        class="position-relative table-new-customization"
        :sticky-header="'65vh'"
      >
        <b-thead>
          <b-tr>
            <b-th class="td-left text-right header-width"></b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">TransUnion</span>
              <b-img :src="require('@/assets/images/icons/transunion.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Experian</span>
              <b-img :src="require('@/assets/images/icons/experian.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Equifax</span>
              <b-img :src="require('@/assets/images/icons/equifax.png')" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-if="datos.sumary">
            <b-td class="td-left text-right col-2"
              ><span class="font-weight-bolder">Total Accounts:</span></b-td
            >
            <b-td
              class="td-center text-center"
              v-for="(item, index) in datos.sumary"
              :key="index"
            >
              {{ item.t_account }}
            </b-td>
          </b-tr>
          <b-tr v-if="datos.sumary">
            <b-td class="td-left text-right">
              <span class="font-weight-bolder">Open Accounts:</span>
            </b-td>
            <b-td
              class="td-center text-center"
              v-for="(item, index) in datos.sumary"
              :key="index"
            >
              {{ item.o_account }}
            </b-td>
          </b-tr>
          <b-tr v-if="datos.sumary">
            <b-td class="td-left text-right">
              <span class="font-weight-bolder">Closed Accounts:</span>
            </b-td>
            <b-td
              class="td-center text-center"
              v-for="(item, index) in datos.sumary"
              :key="index"
            >
              {{ item.c_account }}
            </b-td>
          </b-tr>

          <b-tr v-if="datos.sumary">
            <b-td class="td-left text-right">
              <span class="font-weight-bolder">Balances:</span>
            </b-td>
            <b-td
              class="td-center text-center"
              v-for="(item, index) in datos.sumary"
              :key="index"
            >
              $ {{ item.balance || "-" }}
            </b-td>
          </b-tr>

          <b-tr v-if="datos.sumary">
            <b-td class="td-left text-right">
              <span class="font-weight-bolder">Balances:</span>
            </b-td>
            <b-td
              class="td-center text-center"
              v-for="(item, index) in datos.sumary"
              :key="index"
            >
              $ {{ item.payments || "-" }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    datos: {
      required: true,
    },
    val: {
      required: true,
    },
  },
  data() {
    return {
      showSummary: true,
    };
  },
  methods: {},
};
</script>

<style scoped>
.title-table {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.color-summary {
  color: #00ff88;
  fill: #00ff88;
  margin-right: 10px;
}
</style>
