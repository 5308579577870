<template>
  <div class="syncronization-items">
    <h2
      class="text-center text-uppercase"
      :class="lastReport ? 'text-primary' : 'text-warning'"
    >
      {{ title }}
    </h2>
    <div
      class="container-bureaus"
      :style="{ width: isVerticalScrollActive ? '98%' : '100%' }"
    >
      <div class="bureau">
        <b-img
          :src="require('@/assets/images/icons/transunion.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'TRANSUNION'"
        />
      </div>
      <div class="bureau">
        <b-img
          :src="require('@/assets/images/icons/experian.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'TRANSUNION'"
        />
      </div>
      <div class="bureau">
        <b-img
          :src="require('@/assets/images/icons/equifax.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'TRANSUNION'"
        />
      </div>
    </div>
    <div class="main-container" ref="mainContainer" :id="type">
      <template v-if="dataAccounts.length > 0">
        <div
          :ref="`account${index + 1}`"
          :id="foundMatch(account) ? 'found' : ''"
          class="container-items"
          v-for="(account, index) in dataAccounts"
          :key="index"
        >
          <div
            v-for="(item, index1) in account.items"
            :key="index1"
            v-bind="borderItem(item)"
          >
            <template v-if="hasItem(item)">
              <template v-for="(field, index2) in fieldsItems">
                <div
                  v-if="field.visible"
                  :key="index2"
                  class="d-flex justify-content-start pr-1 field-items"
                >
                  <strong class="title d-flex justify-content-between pr-1">
                    <span>{{ field.label }}</span>
                    <span>:</span>
                  </strong>
                  <span class="text text-truncate">
                    <template v-if="field.type == 'money'">
                      {{ Number(item[field.key]) | formatMoney }}
                    </template>
                    <template v-else>
                      {{ item[field.key] }}
                    </template>
                  </span>
                </div>
              </template>
              <template v-if="!showDetails">
                <div class="container-btn-syncronize" v-if="lastReport">
                  <b-button
                    v-if="disabledSincronized"
                    :variant="disabledSyncronizedItem(item).variant"
                    size="sm"
                    @click="syncronize(account, item)"
                    class="mr-1"
                    :disabled="disabledSyncronizedItem(item).disabled"
                  >
                    <span v-if="!item.match">Synchronize</span>
                    <span v-else>
                      <template v-if="item.match">
                        {{
                          item.current_match
                            ? "Looking for sync"
                            : "Synchronized"
                        }}
                      </template>
                    </span>
                  </b-button>
                  <b-button
                    v-if="item.match"
                    :disabled="!item.match"
                    size="sm"
                    variant="outline-danger"
                    @click="clear(account, item)"
                  >
                    <feather-icon icon="RefreshCwIcon" />
                  </b-button>
                </div>
                <div
                  v-else
                  style="height: 38px"
                  class="d-flex align-items-center px-1 justify-content-between"
                >
                  <b-form-checkbox
                    :id="`checkbox-${index1}-${index}`"
                    :name="`checkbox-${index1}-${index}`"
                    v-model="item.selected"
                    :disabled="item.match ? true : disabledCheckbox(item)"
                    @change="
                      SelectedItemManual(account, item, disabledCheckbox(item))
                    "
                  >
                    <span v-if="item.selected" class="text-uppercase">
                      UnSelect
                    </span>
                    <span v-else class="text-uppercase"> Select </span>
                  </b-form-checkbox>
                  <b-button
                    size="sm"
                    :variant="item.selected ? 'secondary' : 'outline-danger'"
                    @click="deleteItem(item)"
                    :disabled="item.selected"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
              <div
                v-if="!lastReport && item.total_found > 0"
                class="item--total--found"
              >
                <div>
                  {{ item.total_found }}
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="w-100 d-flex justify-content-center align-items-center py-5 position-relative rounded-lg"
                :class="isDarkSkin ? 'bg-dark' : null"
              >
                <img
                  :src="require('@/assets/images/icons/empty-notes.svg')"
                  class="my-0 my-md-2"
                  style="opacity: 0.2"
                  width="150px"
                  height="150px"
                  alt="Empty"
                />
                <span class="custom-span">Empty</span>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="w-100 d-flex justify-content-center align-items-center py-5 position-relative rounded-lg mt-1"
          :class="isDarkSkin ? 'bg-dark' : null"
        >
          <img
            :src="require('@/assets/images/icons/empty-notes.svg')"
            class="my-0 my-md-2"
            style="opacity: 0.2"
            width="150px"
            height="150px"
            alt="Empty"
          />
          <span v-if="!loading" class="custom-span">{{ textNoData }}</span>
          <b-spinner
            class="custom-spinner"
            v-else
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReportCredit",
  props: {
    dataAccounts: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    lastReport: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      required: true,
    },
    fieldsItems: {
      type: Array,
      required: true,
    },
    enabledCheckbox: {
      type: Object,
      default: () => false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    textNoData: {
      type: String,
      required: true,
    },
    disabledSincronized: {
      type: Boolean,
    },
    showDetails: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      scrollHeight: 0,
      clientHeight: 0,
    };
  },
  computed: {
    isVerticalScrollActive() {
      return this.scrollHeight > this.clientHeight;
    },
  },
  watch: {
    dataAccounts: {
      handler(value) {
        this.$nextTick(() => {
          let container = this.$refs.mainContainer;
          const containerAccount = this.$refs.account1;
          if (container) {
            const { scrollHeight, clientHeight } = container;
            this.scrollHeight = scrollHeight;
            this.clientHeight = clientHeight;
          }
          /** reduce el tamanio del div.container siempre y cuando tenga 1 cuenta */
          if (value.length === 1 && containerAccount) {
            const { clientHeight } = containerAccount[0];
            this.$refs.mainContainer.style.height = `${clientHeight + 20}px`;
          } else if (value.length === 0) {
            this.$refs.mainContainer.style.height = `321px`;
          } else {
            this.$refs.mainContainer.style.height = null;
          }

          /** se agrega el scroll automatico */
          if (!this.lastReport) {
            let total = this.dataAccounts
              .flatMap((item) => item.items)
              .filter((item) => item.found).length;

            const containerCurrentItems = document.querySelector("#remaining");
            const foundAcc = document.querySelector("#found");
            if (foundAcc && total > 0) {
              const targetPosition = foundAcc.offsetTop - container.offsetTop;
              const startPosition = containerCurrentItems.scrollTop;
              const distance = targetPosition - startPosition;
              const duration = 1000;
              let startTime = null;
              const ease = (t, b, c, d) => {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
              };
              const smoothScroll = (currentTime) => {
                if (startTime == null) startTime = currentTime;
                const timeElapsed = currentTime - startTime;
                const run = ease(
                  timeElapsed,
                  startPosition,
                  distance,
                  duration
                );
                containerCurrentItems.scrollTop = run;
                if (timeElapsed < duration) requestAnimationFrame(smoothScroll);
              };
              requestAnimationFrame(smoothScroll);
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    foundMatch(account) {
      return (
        account.items.filter((item) => item.found).length > 0 &&
        !this.lastReport
      );
    },
    hasItem(item) {
      return !(item.a_name == "-" || item.a_number == "-");
    },
    syncronize(account, element) {
      if(this.disabledSyncronizedItem(element).disabled) return;
      this.$emit("synchronize", account, element);
    },
    hexToRgba(hex, alpha = 1) {
      if (!hex) return null;
      // Remueve el '#' si está presente
      hex = hex.replace(/^#/, "");

      // Parsear los valores de las componentes de color
      let bigint = parseInt(hex, 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;

      // Retornar el valor en formato rgba
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
    borderItem(item) {
      let _class = `items ${this.isDarkSkin ? "dark" : ""}`;
      if (!item.borderColor || item.borderColor == "-") {
        return {
          class: _class,
        };
      } else {
        this.pulseBorder(item.borderColor);
        return {
          style: {
            border: "2px solid transparent",
            "border-color": `${item.borderColor}`,
            "box-shadow": `0 0 0 0.2rem ${this.hexToRgba(
              item.borderColor,
              0.25
            )}`,
          },
          class: `${_class}`,
        };
      }
    },
    clear(account, item) {
      this.$emit("clear", { account, item, type: this.type });
    },
    SelectedItemManual(account, item, validDisabled) {
      try {
        if (validDisabled) return;
        if (item.selected) {
          this.$emit("selected", {
            account,
            item,
            checked: item.selected_change,
          });
        }
      } catch (ex) {
        item.selected = false;
        this.showGenericToast({
          variant: "warning",
          text: ex.message,
          icon: "AlertTriangleIcon",
        });
      }
    },
    validItem(item) {
      return item.match;
    },
    async deleteItem(item) {
      try {
        if (item.selected) return;
        const confirm = await this.showConfirmSwal(
          "Are you sure you want to update the status of this report?"
        );
        if (!confirm.value) return;
        this.$emit("deleteItem", item);
      } catch (ex) {}
    },
    disabledSyncronizedItem(item) {
      const variant = !this.enabledCheckbox.checked ? "secondary" : "primary";
      return {
        disabled: item.match ? true : !this.enabledCheckbox.checked,
        variant: item.match ? "success" : variant,
      };
    },
    disabledCheckbox(item) {
      if (!this.enabledCheckbox.checked) {
        return this.enabledCheckbox.bureau_id !== item.bureau_id;
      }
      return this.enabledCheckbox.checked;
    },
    pulseBorder(color) {
      const keyframes = `
        @keyframes pulseBorder {
            0% {
                box-shadow: 0 0 0 0 ${color};
            }
            70% {
                box-shadow: 0px 0px 4px 2px ${color};
            }
            100% {
                box-shadow: 0 0 0 0 ${color};
            }
        }
    `;
      // Añade los keyframes al documento
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = keyframes;
      document.head.appendChild(styleSheet);
    },
  },
};
</script>

<style lang="scss">
.custom-span {
  position: absolute !important;
  font-size: 25px !important;
  font-weight: bolder !important;
  color: dark !important;
}
.syncronization-items {
  position: relative;
  .container-items:first-child {
    margin-top: 10px;
  }
  .container-items {
    font-size: 0.85rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .items {
      position: relative;
      width: calc(33.333333333% - 5px);
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      .field-items {
        padding: 5px 8px 2px 8px;
        .title {
          width: 45% !important;
        }
        .text {
          width: 55% !important;
        }
      }
      .item--total--found {
        position: absolute;
        top: 10%;
        left: 89%;
        background: red;
        color: white;
        width: 30px;
        text-align: center;
        font-weight: 900;
        font-size: 16px;
        z-index: 1000;
        border-radius: 5px 0px 0px 5px;
        transition: background-color 0.5s ease-in-out;
      }
    }
    .items.dark {
      border-color: #546e7a;
    }
    margin-bottom: 10px;
  }
  .main-container {
    height: 600px;
    overflow-y: auto;
  }
  .container-bureaus {
    display: flex;
    justify-content: space-between;
    .bureau {
      width: calc(33.333333333% - 5px);
      display: flex;
      justify-content: center;
    }
    margin-bottom: 5px;
  }
  .container-btn-syncronize {
    text-align: center;
    padding: 5px;
  }
  .custom-span {
    position: absolute !important;
    font-size: 25px !important;
    font-weight: bolder !important;
    color: dark !important;
  }
}
.oscillate {
  animation: pulseBorder 2s linear infinite;
}
</style>
