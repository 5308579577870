<template>
  <b-modal
    v-model="showModal"
    title-class="h3 text-white"
    title="Assign Specific"
    size="md"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="container-keywords">
      <div class="mb-2">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <span class="title-tag">CLIENT NAME</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ item.client_name || item.lead_name }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="pt-md-0 pt-1">
            <span class="title-tag">CLIENT TYPE</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ item.type_client == 1? "NEW CLIENT" : "REGULAR" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table striped hover small :fields="fields" :items="data">
        <!-- Selected -->
        <template #cell(selected)="{ item }">
          <div class="text-center ml-1">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="selectSpecialist"
                name="some-radios"
                :disabled="item.status_session == 0 || item.status_session == 4"
                :value="item"
                :class="ariaDescribedby"
              ></b-form-radio>
            </b-form-group>
          </div>
        </template>

        <template #cell(task)="{ item }">
          <b-badge variant="primary">
            {{ item.task ? item.task : 0 }}
          </b-badge>
        </template>

        <template #cell(full_name)="{ item }">
          <div v-if="[1, 2, 3].includes(item.status_session)">
            <feather-icon
              size="16"
              icon="UserCheckIcon"
              class="mr-50 text-success"
            />
            {{ item.full_name }}
          </div>
          <div v-else>
            <feather-icon
              size="16"
              icon="UserXIcon"
              class="mr-50 text-danger"
            />
            {{ item.full_name }}
          </div>
        </template>

        <template #cell(updated_at)="{ item }">
          <div>
            {{ (item.updated_at || item.created_at) | myGlobalDay }}
          </div>
        </template>
      </b-table>
    </div>

    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        @click="assignWorkplan"
        :disabled="verifyUserOnline()"
      >
        ASSIGN
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

export default {
  props: {
    item: Object,
    section: String,
  },
  data() {
    return {
      showModal: false,

      fields: [
        {
          key: "selected",
          tdClass: "text-center",
        },
        {
          key: "full_name",
        },
        {
          key: "task",
          tdClass: "text-center",
          thClass: "text-center",
          label: "Assigned",
        },
        {
          key: "updated_at",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      data: [],
      selectSpecialist: null,
    };
  },
  created() {
    this.openModal();
    this.getUsers();
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$emit("close");
    },

    async getUsers() {
      const params = {
        section: this.section,
        column: this.section == "REQUEST_WORK_PLAN" ? 'a_workplan' : 'a_update'
      }
      const { data } = await RequestWorkPlanService.getSpecialist(params);
      this.data = data;
    },
    async assignWorkplan() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const params = {
            idWorkplan: this.item.id,
            specialistId: this.selectSpecialist.id,
            taskCount: this.selectSpecialist.task,
            section: this.section,
          };
          const data = await RequestWorkPlanService.assignSpecificManual(
            params
          );

          if (data.status == 200) {
            this.closeModal();
            this.showSuccessSwal();
            this.$emit("refresh");
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    verifyUserOnline() {
      return (
        this.data.every(
          (item) => item.status_session == 0 || item.status_session == 4
        ) || this.selectSpecialist == null
      );
    },
  },
};
</script>



