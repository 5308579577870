<template>
  <div>
    <div class="mb-2 px-5 py-1" :class="isDarkSkin ? 'dark-bg-card' : 'light-bg-card'">
      <div class="d-flex justify-content-between" @click="iconIsDown = !iconIsDown">
        <div class="d-flex">
          <h4 class="m-0 p-0 font-weight-bolder">CREDIT REPORT</h4>
        </div>
        <span class="cursor-pointer text-dark" style="margin-top: 2px">
          <tabler-icon
            :icon="iconIsDown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="20"
          />
        </span>
      </div>
      <hr v-if="iconIsDown" class="text-dark" style="border-top: 3px groove" />
      <div v-if="iconIsDown">
        <b-collapse
          id="collapse-pi-report"
          v-model="iconIsDown"
          class="rounded"
        >
          <!-- CREDIT REPORT CLIENT -->
          <card-lead-credit-report
            v-if="showComponent"
            :lead="clientData"
            :isClient="true"
            :openBySeller="false"
            :isClientDashboard="true"
          />

          <div class="container-report pt-1 px-1" ref="container-report">
            <div
              class="container-grid mb-1"
              v-for="(items, index) in data"
              :key="index"
            >
              <div
                class="shadow w-auto p-2 rounded position-relative"
                :style="
                  isDarkSkin
                    ? 'background: #17171A; color:white'
                    : 'background: #fff'
                "
                v-for="(account, index) in items"
                :key="index"
              >
                <div
                  class="d-flex justify-content-center align-items-center h-100 text-muted"
                  v-if="account.empty"
                >
                  <feather-icon
                    icon="SlashIcon"
                    size="16"
                    class="cursor-pointer text-end text-info text-muted"
                    style="margin-right: 3px"
                  />
                  <p class="m-0">NO CONTENT</p>
                </div>
                <div
                  class="d-flex justify-content-start pr-3"
                  v-for="(infoAc, index) in account.content"
                  :key="index"
                >
                  <span class="mr-1 font-weight-bolder">{{ infoAc.key }}: </span>
                  <span
                    class="text-truncate"
                    v-b-tooltip.hover.top="infoAc.value"
                  >
                    {{ infoAc.value }}
                  </span>

                  <div
                    class="action-data"
                    v-if="
                      isEnabledForEditAndDelete &&
                      !isFromModalFinancial &&
                      !account.empty
                    "
                  >
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          v-b-tooltip.hover.right="'Actions'"
                          class="align-middle text-info"
                        />
                      </template>
                      <b-dropdown-item
                        v-b-modal.modal-primary
                        @click="openModalTracking(account.id)"
                      >
                        <feather-icon
                          icon="ListIcon"
                          size="16"
                          class="mr-1 cursor-pointer text-end text-info"
                        />
                        <span class="align-middle">View Tracking</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="isSpecialist"
                        v-b-modal.modal-primary
                        @click="editPersonalInfo(infoAc, account)"
                      >
                        <feather-icon
                          size="16"
                          class="mr-1 cursor-pointer text-end text-warning"
                          icon="EditIcon"
                        />
                        <span class="align-middle">Edit Account</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="isSpecialist"
                        v-b-modal.modal-primary
                        @click="deletePersonalInfo(account.id)"
                      >
                        <feather-icon
                          size="16"
                          class="mr-1 cursor-pointer text-danger"
                          icon="TrashIcon"
                        />
                        <span class="align-middle">Delete Account</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <b-badge
                  class="float-right mt-1"
                  v-if="!account.empty"
                  :variant="getColorStatus(account.status)"
                >
                  {{ account.status_name }}
                </b-badge>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import CardLeadCreditReport from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReport.vue";
import ClientOptionServices from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      clientData: {},
      showComponent: false,
      iconIsDown: false,
    };
  },
  components: {
    CardLeadCreditReport,
  },
  computed: {
    ...mapState({
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
    }),
    clientAccountId() {
      return this.$route.params.idClient;
    },
  },
  async created() {
    await this.getClientData();
  },
  methods: {
    ...mapActions({
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
    }),
    async getClientData() {
      try {
        const { data } = await ClientOptionServices.getClientData({
          id: this.clientAccountId,
        });
        await this.getLead(data[0].lead_id);
        this.clientData = {
          client_account_id: this.clientAccountId,
          account: data[0].account,
          client_id: data[0].client_id,
          lead_id: data[0].lead_id,
          ...this.S_LEAD,
        };
        this.showComponent = true;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getLead(lead_id) {
      try {
        await this.A_GET_LEAD({ id: lead_id });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped>
*{
  --light-bg-color: #f1f1f1;
  --light-bg-color2: #fafafa;
  --dark-bg-color1: #232328;
  --dark-bg-color2: #17171A;
}
.dark-bg-card{
  background-color: var(--dark-bg-color2) !important;
  border-radius: 10px;
}
.light-bg-card{
  background-color: var(--light-bg-color2) !important;
  border-radius: 10px;
}
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 1em;
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.container-report {
  overflow-y: auto;
}

.action-data {
  position: absolute;
  right: -10px;
  top: 0;
}

.icon-collapse {
  position: absolute;
  right: 15px;
  top: 25%;
}

.btn-delete-all {
  position: absolute;
  left: 15px;
  top: 25%;
}
</style>
