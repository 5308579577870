<template>
  <div>
    <div class="d-flex justify-content-center">
      <span
        v-if="!statusPending.value"
        :class="[
          data.item.status == 'PENDING' ? 'cursor-pointer' : '',
          StatusColorText(data.item.status),
        ]"
      >
        {{ data.item.status }}
      </span>
      <feather-icon
        v-if="!statusPending.value && data.item.status == 'PENDING'"
        icon="EditIcon"
        class="text-warning cursor pointer oscillate"
        v-b-tooltip.hover.right="'Edit status'"
        style="margin-left: 5px"
        size="15"
        @click="changeStatusPending(data.index)"
      />
    </div>
    <div
      style="display: inline-flex"
      v-if="data.item.status == 'PENDING' && statusPending.value"
    >
      <b-input-group size="sm">
        <b-form-select  
          placeholder="--select--"
          :options="options"
          v-model="actionSelected"
          @change="openModal"
        />
        <b-input-group-append is-text @click="hideSelector()">
          <feather-icon
            icon="XIcon"            
            class="text-danger cursor-pointer"
            size="20"
          />
        </b-input-group-append>
      </b-input-group>
    </div>

    <modal-action
      v-if="activeModal"
      :action="actionSelected"
      :data="data.item"
      :otherServices="true"
      @close="closeModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import ModalAction from "./ModalAction.vue";
export default {
  components: { ModalAction },
  props: {
    data: { type: Object, required: true },
    options: { type: Array, required: true },
    service: { type: String, default: null }
  },
  data() {
    return {
      activeModal: false,
      statusPending: {
        id: 0,
        value: false,
      },
      actionSelected: 0,
    };
  },
  methods: {
    refreshTable() {
      this.statusPending.value = false;
      this.$emit("refreshTable");
    },
    closeModal() {
      this.activeModal = false;
      this.actionSelected = 0;

    },
    openModal() {
      if(this.service = "appointments"){
        if(this.actionSelected == 1){
          this.$emit("addCharge")
        }else{
          this.activeModal = true;
        }
      }else{
        this.actionSelected
          ? (this.activeModal = true)
          : (this.activeModal = false);
      }
    },
    changeStatusPending(id) {
      if (this.data.item.status == 0 || "PENDING") {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = id;
      }
    },
    hideSelector(){
      this.actionSelected = null;
      this.statusPending.value = false
    },
    StatusColorText(status) {
      switch (status) {
        case "PENDING":
          return "text-warning";
          break;
        case "ACCEPTED":
          return "text-success";
          break;
        case "REJECTED":
          return "text-danger";
          break;
        default:
          return "bg-secondary text-light";
          break;
      }
    },
  },
};
</script>

