<template>
  <b-modal
    ref="modalFiles"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="UPLOAD FILES"
    centered
    hide-footer
    no-close-on-backdrop
    @hidden="closeModal()"
  >
    <drag-and-drop v-model="files" :filesArray="files"></drag-and-drop>
    <div class="text-center">
      <b-button variant="success" @click="sendFiles()"
        ><feather-icon icon="SaveIcon" size="16" /> SAVE</b-button
      >
    </div>
  </b-modal>
</template>
<script>
import dragAndDrop from "@/views/commons/utilities/DragAndDrop";
export default {
  name: "ModalUploadFiles",
  components: {
    dragAndDrop,
  },
  data() {
    return {
      files: [],
    };
  },
  mounted() {
    this.toggleModal("modalFiles");
  },
  methods: {
    sendFiles() {
      this.$emit("sendFiles", this.files);
      this.files = [];
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>