<template>
  <div>
    <b-nav pills card-header class="m-0 custom-tab-amg" v-model="tabUpdate">
      <b-nav-item
        v-for="(tab, index) in updateTabs"
        :key="index"
        :link-classes="['px-3', bgTabsNavs]"
        :active="tab.id == tabUpdate"
        @click="tabUpdate = tab.id"
      >
        {{ tab.label }}
        <b-badge :variant="index === 1 ? 'info' : 'danger'" pill class="ml-1">
          {{
            index === 0
              ? counters.pending
              : index === 1
              ? counters.to_revision
              : counters.completed
          }}
        </b-badge>
      </b-nav-item>
    </b-nav>
    <b-table
      responsive
      :items="filteredItems"
      :fields="visibleFields"
      class="table-new-customization custom-table"
      show-empty
    >
      <template v-slot:cell(request)="{ item }">
        <div>{{ item.requested_by }}</div>
        <div>{{ item.requested_at | myGlobalDay }}</div>
      </template>
      <template v-slot:cell(disputed_by)="{ item }">
        <div>{{ item.created_by }}</div>
        <div>{{ item.created_at | myGlobalDay }}</div>
      </template>
      <template v-slot:cell(completed_by)="{ item }">
        <div>{{ item.completed_by }}</div>
        <div>{{ item.completed_at | myGlobalDay }}</div>
      </template>
      <template #cell(service)="{ item }">
        <div
          v-if="item.program_id"
          v-b-tooltip.bottom="item.program_name"
          class="d-flex justify-content-center m-0"
        >
          <img
            :src="getProgramImageRoute(item.program_id)"
            alt="img_services"
          />
        </div>
      </template>
      <template v-slot:cell(title)="{ item }">
        <div
          class="text-primary cursor-pointer font-weight-bolder"
          @click="editFirstNote(item)"
        >
          {{ [1, 2].includes(item.type_round) ? "FIRST ROUND" : "-" }}
        </div>
      </template>
      <template v-slot:cell(tracking)="{ item }">
        <feather-icon
          icon="ListIcon"
          class="cursor-pointer"
          size="18"
          @click="openModalTracking(item)"
        />
      </template>
      <template v-slot:cell(type)="{ item }">
        <b-badge v-if="[1, 2].includes(item.type_round)" variant="primary">
          Letter
        </b-badge>
        <b-badge v-else variant="warning"> Direct </b-badge>
      </template>
      <template v-slot:cell(from)="{ item }">
        <b-badge :variant="item.ncr_id ? 'light-primary' : 'light-warning'">
          {{ item.ncr_id ? "NCR" : "Individual" }}
        </b-badge>
      </template>
      <template v-slot:cell(items)="{ item }">
        <b-badge
          variant="primary"
          pill
          class="cursor-pointer"
          @click="editFirstNote(item)"
        >
          {{ item.sumeq + item.sumtr + item.sumex }}
        </b-badge>
      </template>
      <template v-slot:cell(disputes)="{ item }">
        <feather-icon
          v-if="
            item.state == 7 &&
            item.status_dispute == 0 &&
            item.date_diff > dateDiff
          "
          icon="PlusIcon"
          size="22"
          class="cursor-pointer text-primary"
          style="font-size: 20px; margin-top: 5px"
          @click="openModalDispute(item)"
        />
        <b-badge
          v-if="
            item.state == 7 &&
            ((item.status_dispute == 1 &&
              item.status_dispute_grilla == 'PENDING') ||
              item.status_dispute_grilla == 'COMPLETED' ||
              item.status_dispute_grilla == 'TO REVISION') &&
            item.date_diff > dateDiff
          "
          style="font-weight: bold; text-transform: uppercase"
          :variant="
            item.status_dispute_grilla === 'PENDING'
              ? 'light-warning'
              : item.status_dispute_grilla === 'TO REVISION'
              ? 'light-info'
              : 'light-success'
          "
          @click="openModalViewDispute(item)"
        >
          {{ item.status_dispute_grilla }}
        </b-badge>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <feather-icon
          icon="EyeIcon"
          size="22"
          class="cursor-pointer mr-1"
          @click="openUpdate(item)"
          :class="item.view == 1 ? 'oscillate text-warning' : 'text-primary'"
        />
      </template>
    </b-table>
    <ActionDoneReturnModal
      v-if="showModalAction"
      :dataClient="dataUpdate"
      :revision="false"
      :section="'UPDATE'"
      @close="showModalAction = false"
    />
    <ModalTrackingRo
      v-if="showTracking"
      :data-tracking="trackingData"
      @close="closeModalTracking"
    />
    <DetailsModal
      v-if="showDetailFN"
      :details-round-data="roundData"
      :validate-modal="1"
      @close="closeEditFirstNote"
    />
    <b-modal
      v-model="modalDisputeOn"
      size="xmd"
      title="UPDATES"
      scrollable
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
      hide-footer
      header-class="p-0 custom-header"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            New Update Request for
            {{ `${client.client_name} (${client.account})` }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="modalDisputeOn = false"
            />
          </div>
        </div>
      </template>
      <CreateDispute
        :isModal="true"
        v-if="modalDisputeOn"
        :data="createDisputeData"
        @refresh="getDisputes()"
        @close="modalDisputeOn = false"
      />
    </b-modal>
    <ActionDoneReturnModal
      v-if="showModalAction"
      :dataClient="dataUpdate"
      :revision="false"
      :section="'UPDATE'"
      @close="showModalAction = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalTrackingRo from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/tracking-modal-ro/ModalTrackingRo.vue";
import DetailsModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsModal.vue";
import CreateDispute from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/CreateDispute.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
import { modifyVisibility } from "@/helpers/fields-table";
export default {
  props: {
    type: {
      type: String,
      default: "letters",
    },
  },
  components: {
    ModalTrackingRo,
    DetailsModal,
    CreateDispute,
    ActionDoneReturnModal,
  },
  data() {
    return {
      tabUpdate: 1,
      updateTabs: [
        {
          id: 1,
          label: "PENDING",
        },
        {
          id: 2,
          label: "TO REVISION",
        },
        {
          id: 3,
          label: "COMPLETED",
        },
      ],
      ncrs: [],
      fields: [
        {
          key: "disputed_by",
          label: "Disputed by",
          visible: true,
        },
        {
          key: "request",
          label: "Requested by",
          visible: true,
        },
        {
          key: "completed_by",
          label: "Completed by",
          visible: false,
        },
        {
          key: "service",
          label: "Service",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "type",
          label: "Type",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "title",
          label: "Title",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "from",
          label: "From",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "tracking",
          label: "Tracking",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "items",
          label: "Items",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "disputes",
          label: "Status",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
      ],
      showTracking: false,
      trackingData: {},
      roundData: {},
      showDetailFN: false,
      modalDisputeOn: false,
      showModalAction: false,
      dataUpdate: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    visibleFields() {
      if (this.type == "directs") {
        return this.fields.filter(
          (field) => field.visible && field.key !== "title"
        );
      }
      modifyVisibility(this.fields, "actions", this.tabUpdate == 3);
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
    dateDiff() {
      return this.type == "letters" ? 44 : 34;
    },
    filteredItems() {
      if (this.tabUpdate == 1) {
        // if necessary, order by created_at
        return this.ncrs.filter(
          (item) =>
            item.status_dispute_grilla == "PENDING" ||
            item.status_dispute_grilla == null
        );
      } else if (this.tabUpdate == 2) {
        // order by created_at
        return this.ncrs.filter(
          (item) => item.status_dispute_grilla == "TO REVISION"
        );
      } else {
        // order by completed_at
        return this.ncrs.filter(
          (item) => item.status_dispute_grilla == "COMPLETED"
        );
      }
    },
    counters() {
      return this.ncrs.reduce(
        (acc, item) => {
          if (
            item.state == 7 &&
            item.status_dispute == 0 &&
            item.date_diff > this.dateDiff &&
            item.status_dispute_grilla == null
          ) {
            acc.pending += 1;
          } else if (item.status_dispute_grilla == "TO REVISION") {
            acc.to_revision += 1;
          } else if (item.status_dispute_grilla == "COMPLETED") {
            if (item.view == 1) acc.completed += 1;
          }
          return acc;
        },
        { pending: 0, to_revision: 0, completed: 0 }
      );
    },
  },
  methods: {
    async saveView(item) {
      try {
        const params = {
          id: item.dispute_id,
          save_by: this.currentUser.user_id,
        };
        const res = await UpdateService.saveView(params);
        item.read = 0;
        // update counters here...
        this.getDisputes();
        return res;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openUpdate(item) {
      try {
        this.addPreloader();
        const { data } = await UpdateService.getCompletedUrInfo({
          id: item.dispute_id,
        });
        this.dataUpdate.agent = data.agent;
        this.dataUpdate.client_account = data.client_account;
        this.dataUpdate.client_name = data.client_name;
        this.dataUpdate.comment = data.comment;
        this.dataUpdate.creditUtilized = data.creditUtilized;
        this.dataUpdate.deletedAccounts = data.deletedAccounts;
        this.dataUpdate.rounds = data.rounds;
        this.dataUpdate.recommended_services = data.recommended_services;
        this.showModalAction = true;
        if (item.view == 1) {
          await this.saveView(item);
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getDisputes() {
      this.addPreloader();
      try {
        const { data } = await ClientsOptionsServices.getAllLetterUpdate({
          accountId: this.$route.params.idClient,
        });
        this.ncrs = data || [];
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.countUpdates();
        this.removePreloader();
      }
    },
    async countUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    openModalTracking(item) {
      this.trackingData = {
        id: item.id,
        account: item.account,
        clientName: this.client.client_name,
      };
      this.showTracking = true;
    },
    closeModalTracking() {
      this.showTracking = false;
      this.trackingData = {};
    },
    editFirstNote(item) {
      console.log(item);
      const { id, clientAccountId, account, title, format } = item;
      const createdAfterMigration = item.created_at > '2024-08-14 00:00:00';
      this.roundData = {
        id,
        clientAccountId,
        account,
        title,
        format,
        clientName: this.client.client_name,
        status_round: item.status,
        round_created_at: item.created_at,
        createdAfterMigration
      };
      this.showDetailFN = true;
    },
    closeEditFirstNote() {
      this.roundData = {};
      this.showDetailFN = false;
    },
    counterBureaus(item) {
      const { sumeq, sumtr, sumex } = item;
      let result =
        (sumtr != null ? "TU(" + sumtr + ")" : "") +
        (sumeq != null ? " | EQ(" + sumeq + ")" : "") +
        (sumex != null ? " | EX(" + sumex + ")" : "");
      return result;
    },
    openModalDispute(item) {
      this.createDisputeData = {
        id: item.id,
      };
      this.modalDisputeOn = true;
    },
    openModalViewDispute(item) {
      console.log(item);
    },
    filterColumn(key, tab) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = tab;
    },
  },
  mounted() {
    this.getDisputes();
  },
  watch: {
    tabUpdate(value) {
      this.filterColumn("completed_by", value == 3);
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.font-score {
  font-size: 1.2rem;
  font-weight: 600;
}
.custom-table >>> th {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child {
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
</style>
