<template>
  <div style="text-align: center">
    <div
      v-if="
        (statusResult == 0 || statusResult == 3 || statusResult == null) &&
        [11, 25, 28, 29, 30].includes(this.moduleId) &&
        roleId != 8
      "
    >
      <span
        v-if="!statusPending.value"
        :class="[
          [0, 3].includes(data.item.status_result) ? 'cursor-pointer' : '',
          StatusColorText(data.item.status_result),
        ]"
        @click="changeStatusPending(data.index)"
      >
        {{ status }}
      </span>

      <div
        v-if="statusPending.value || statusResult == null || statusResult == 3"
        style="display: inline-flex"
      >
        <div v-if="!showSelector">
          <div class="text-warning d-flex">
            <span>{{
              data.item.status_result == 3 ? "IN PROCESS" : "PENDING"
            }}</span>
            <feather-icon
              icon="EditIcon"
              size="15"
              class="cursor-pointer"
              style="margin-left: 5px"
              v-b-tooltip.hover.right="'Edit'"
              @click="showSelector = true"
            />
          </div>
        </div>
        <div v-else>
          <b-input-group size="sm">
            <b-form-select
              placeholder="--select--"
              :options="options"
              v-model="actionSelected"
              @change="openModal"
            />

            <b-input-group-append is-text>
              <feather-icon
                icon="XIcon"
                @click="changeStatusPending(data.index)"
                class="text-danger cursor-pointer"
                size="20"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>
    <span
      v-if="
        statusResult === 0 &&
        (![11, 25, 28, 29, 30].includes(this.moduleId) || roleId == 8)
      "
      :class="['cursor-default', StatusColorText(data.item.status_result)]"
    >
      {{ status }}
    </span>
    <span
      v-if="statusResult >= 1"
      :class="['cursor-default', StatusColorText(data.item.status_result)]"
    >
      {{ status }}
    </span>
    <modal-action
      v-if="activeModal"
      :action="option"
      :data="data.item"
      :num="2"
      @close="closeModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalAction from "../modals/ModalAction.vue";
import ApplicationsService from "@/views/commons/components/applications/services/applications.service.js";

export default {
  components: { ModalAction },
  props: {
    data: { type: Object, required: true },
    options: { type: Array, required: true },
  },
  data() {
    return {
      activeModal: false,
      statusPending: {
        id: 0,
        value: false,
      },
      actionSelected: null,
      action: null,
      showSelector: false,
    };
  },
  methods: {
    refreshTable() {
      this.statusPending.value = false;
      this.$emit("refreshTable");
    },
    closeModal() {
      this.activeModal = false;
    },
    async openModal() {
      console.log(this.data.item);
      this.option = this.actionSelected;
      this.actionSelected = null;
      if (this.option == 3) {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          try {
            this.addPreloader();
            await ApplicationsService.setAppInProgress({
              app_id: this.data.item.app_id,
              user_id: this.currentUser.user_id,
              client_account_id: this.data.item.ca_id,
            });
            this.refreshTable();
          } catch (error) {
            console.error(error);
          } finally {
            this.removePreloader();
            return;
          }
        }
      } else {
        this.activeModal = true;
      }
    },
    changeStatusPending(id) {
      if (!this.data.item.status_result) {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = id;
      }
    },
    StatusColorText(status) {
      switch (status) {
        case 0:
          return "text-warning";
          break;
        case 1:
          return "text-success";
          break;
        case 2:
          return "text-danger";
          break;
        default:
          return "bg-secondary text-light";
          break;
      }
    },
  },
  created() {
    // remove in process option if it's current status
    if(this.data.item.status_result == 3) {
      this.options = this.options.filter(option => option.value !== 3);
    }
  },
  computed: {
    status() {
      switch (this.statusResult) {
        case 0:
          return "PENDING";
          break;
        case 1:
          return "APPROVED";
          break;
        case 2:
          return "DENIED";
          break;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    doneForm() {
      return this.data.item.done_form;
    },
    statusResult() {
      return this.data.item.status_result;
    },
    roleId() {
      return this.currentUser.role_id;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
