<template>
  <div class="card-month">
    <div class="month-name text-uppercase" :class="nameClass">{{ month }}</div>
    <div
      class="month-counter border-month"
      :class="{ 'text-warning': number > 0 }"
    >
      {{ number }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    month: String,
    number: Number,
  },
  computed: {
    nameClass() {
      return this.isDarkSkin ? "name-dark" : "name-light"
    },
  },
}
</script>

<style scoped>
.card-month {
  border: 1px solid #3f3f3f;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.name-dark {
  background-color: #fff;
  color: #3b3b3b;
}

.name-light {
  background-color: #3b3b3b;
  color: #fff;
}

.month-name {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2px 0;
}

.month-counter {
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-month {
  border-top: 1px solid #3f3f3f;
}
</style>