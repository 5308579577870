<template>
  <div>
    <div class="float-right mb-1" v-if="section == 'workplan'">
      <span>Show CR:</span>
      <amg-icon
        icon="FilePdfIcon"
        class="text-danger ml-1"
        size="20"
        style="cursor: pointer"
        @click="showPdfNcrRequest"
      />
    </div>
    <b-table
      class="position-relative table-new-customization mt-2 w-100"
      hover
      :items="items"
      :fields="visibleFields"
      details-td-class="p-0"
      show-empty
      :sticky-header="'45vh'"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty="scope">
        <h4 class="text-center">{{ scope.emptyText }}</h4>
      </template>
      <template #head(transunion)>
        <b-img
          :src="require('@/assets/images/icons/transunion.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'TRANSUNION'"
        />
      </template>
      <template #head(experian)>
        <b-img
          :src="require('@/assets/images/icons/experian.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'EXPERIAN'"
        />
      </template>
      <template #head(equifax)>
        <b-img
          :src="require('@/assets/images/icons/equifax.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'EQUIFAX'"
        />
      </template>
      <template #cell(id)="{ index }">
        <div>
          {{ index + 1 }}
        </div>
      </template>
      <template #cell(transunion)="{ item, field }">
        <div
          class="action-data mx-auto text-center"
          v-if="validateItemBureau(item.items, field.key)"
          :style="`background-color: ${getStatusBackground(
            item.items[0].priority
          )};`"
        >
          <b-dropdown no-caret variant="none" class="wo-padding w-100">
            <template #button-content>
              <div class="d-flex align-items-center justify-content-center">
                <b-badge
                  class="text-capitalize custom-badge"
                  :variant="
                    item.items[0].id != '-'
                      ? variantBadge[item.items[0].status_letter]
                      : 'light-info'
                  "
                >
                  {{ item.items[0].status_letter }}
                </b-badge>
                <span class="status-name">{{
                  item.items[0].priority || "NOT QUALIFIED"
                }}</span>
                <feather-icon
                  v-if="!validateRestoreButton(item.items, field.key)"
                  class="ml-1 text-white"
                  icon="ChevronDownIcon"
                  size="22"
                />
              </div>
            </template>
            <b-dropdown-item
              v-for="(data, index) in getStatusOptions(item, 0)"
              :key="index"
              :disabled="[3, 4].includes(item.items[0].statusIdOld) && item.items[0].updated_from != sectionId"
              @click="changeStatus(item.items, field.key, data)"
            >
              {{ data.value }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            v-if="validateRestoreButton(item.items, field.key)"
            @click="restoreStatus(item.items, field.key)"
            class="ml-1 text-white"
            icon="XIcon"
            size="22"
          />
        </div>
        <div v-else class="d-flex justify-content-center">
          <img
            :src="require(`@/assets/images/icons/${field.key}.png`)"
            alt=""
            width="30px"
            height="30px"
            class="custom-bg-logo-effectivity"
          />
        </div>
      </template>
      <template #cell(experian)="{ item, field }">
        <div
          class="action-data mx-auto text-center"
          v-if="validateItemBureau(item.items, field.key)"
          :style="`background-color: ${getStatusBackground(
            item.items[1].priority
          )};`"
        >
          <b-dropdown no-caret variant="none" class="wo-padding w-100">
            <template #button-content>
              <div class="d-flex align-items-center justify-content-center">
                <b-badge
                  class="text-capitalize custom-badge"
                  :variant="
                    item.items[1].id != '-'
                      ? variantBadge[item.items[1].status_letter]
                      : 'light-info'
                  "
                >
                  {{ item.items[1].status_letter }}
                </b-badge>
                <span class="status-name">{{
                  item.items[1].priority || "NOT QUALIFIED"
                }}</span>
                <feather-icon
                  v-if="!validateRestoreButton(item.items, field.key)"
                  class="ml-1 text-white"
                  icon="ChevronDownIcon"
                  size="22"
                />
              </div>
            </template>
            <b-dropdown-item
              v-for="(data, index) in getStatusOptions(item, 1)"
              :key="index"
              :disabled="[3, 4].includes(item.items[1].statusIdOld) && item.items[1].updated_from != sectionId"
              @click="changeStatus(item.items, field.key, data)"
            >
              {{ data.value }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            v-if="validateRestoreButton(item.items, field.key)"
            @click="restoreStatus(item.items, field.key)"
            class="ml-1 text-white"
            icon="XIcon"
            size="22"
          />
        </div>
        <div v-else class="d-flex justify-content-center">
          <img
            :src="require(`@/assets/images/icons/${field.key}.png`)"
            alt=""
            width="30px"
            height="30px"
            class="custom-bg-logo-effectivity"
          />
        </div>
      </template>
      <template #cell(equifax)="{ item, field }">
        <div
          class="action-data mx-auto text-center"
          v-if="validateItemBureau(item.items, field.key)"
          :style="`background-color: ${getStatusBackground(
            item.items[2].priority
          )};`"
        >
          <b-dropdown no-caret variant="none" class="wo-padding w-100">
            <template #button-content>
              <div class="d-flex align-items-center justify-content-center">
                <b-badge
                  class="text-capitalize custom-badge"
                  :variant="
                    item.items[2].id != '-'
                      ? variantBadge[item.items[2].status_letter]
                      : 'light-info'
                  "
                >
                  {{ item.items[2].status_letter }}
                </b-badge>
                <span class="status-name">{{
                  item.items[2].priority || "NOT QUALIFIED"
                }}</span>
                <feather-icon
                  v-if="!validateRestoreButton(item.items, field.key)"
                  class="ml-1 text-white"
                  icon="ChevronDownIcon"
                  size="22"
                />
              </div>
            </template>
            <b-dropdown-item
              v-for="(data, index) in getStatusOptions(item, 2)"
              :key="index"
              :disabled="[3, 4].includes(item.items[2].statusIdOld) && item.items[2].updated_from != sectionId"
              @click="changeStatus(item.items, field.key, data)"
            >
              {{ data.value }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            v-if="validateRestoreButton(item.items, field.key)"
            @click="restoreStatus(item.items, field.key)"
            class="ml-1 text-white"
            icon="XIcon"
            size="22"
          />
        </div>
        <div v-else class="d-flex justify-content-center">
          <img
            :src="require(`@/assets/images/icons/${field.key}.png`)"
            alt=""
            width="30px"
            height="30px"
            class="custom-bg-logo-effectivity"
          />
        </div>
      </template>
      <template #cell(accounts)="{ item }">
        <div
          class="w-100 d-flex flex-column justify-content-start text-capitalize text-center"
        >
          <div
            class="d-flex flex-column text-center justify-content-center font-size-xmd font-weight-bolder text-truncate"
            style="width: 250px"
            v-b-tooltip:hover
            :title="getCreditorName(item.items)"
          >
            {{ getCreditorName(item.items) }}
          </div>
          <span
            class="w-100 mt-50 text-capitalize px-1"
            :class="isDarkSkin ? 'text-white' : 'text-secondary'"
            style="font-size: 12px"
          >
            Account #: {{ getCreditorAccount(item.items) }}
          </span>
        </div>
      </template>
      <template #cell(classification_ncr)="{ item }">
        <div class="d-flex justify-content-center align-items-center h-100">
          <h6
            class="text-uppercase font-weight-bolder text-center"
            :style="`color: ${textColor[item.classification_ncr_id]}`"
          >
            {{ item.classification_ncr || "NOT QUALIFIED" }}
          </h6>
        </div>
      </template>
      <template #cell(detail)="{ toggleDetails, detailsShowing }">
        <div @click="toggleDetails">
          <feather-icon
            icon="ChevronDownIcon"
            class="hover-me cursor-pointer"
            size="22"
            :class="{ 'rotate-180': detailsShowing }"
          />
        </div>
      </template>
      <template #row-details="data">
        <transition name="fade">
          <b-table
            thead-class="d-none"
            :items="transformData(data.item.items)"
            :fields="visibleFields"
            class="tableDetailsEffectivity"
            :class="isDarkSkin ? 'dark-bg' : ''"
          >
            <template #cell(experian)="{ value }">
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(label, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">
                      <template
                        v-if="
                          [
                            'balance',
                            'monthly_payment',
                            'credit_limit',
                            'h_credit',
                          ].includes(label.key)
                        "
                      >
                        ${{
                          isNaN(value[label.key])
                            ? 0
                            : value[label.key] | currency
                        }}
                      </template>
                      <template v-else>
                        {{
                          value.hasOwnProperty(label.key)
                            ? value[label.key]
                            : "-"
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(transunion)="{ value }">
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(label, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">
                      <template
                        v-if="
                          [
                            'balance',
                            'monthly_payment',
                            'credit_limit',
                            'h_credit',
                          ].includes(label.key)
                        "
                      >
                        ${{
                          isNaN(value[label.key])
                            ? 0
                            : value[label.key] | currency
                        }}
                      </template>
                      <template v-else>
                        {{
                          value.hasOwnProperty(label.key)
                            ? value[label.key]
                            : "-"
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(equifax)="{ value }">
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex justify-content-center align-items-center justify-content-center text-center text-capitalize"
                  v-for="(label, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important" class="text-center">
                    <span class="text-truncate w-100">
                      <template
                        v-if="
                          [
                            'balance',
                            'monthly_payment',
                            'credit_limit',
                            'h_credit',
                          ].includes(label.key)
                        "
                      >
                        ${{
                          isNaN(value[label.key])
                            ? 0
                            : value[label.key] | currency
                        }}
                      </template>
                      <template v-else>
                        {{
                          value.hasOwnProperty(label.key)
                            ? value[label.key]
                            : "-"
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(accounts)>
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(data, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">{{ data.text }}</span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(classification_ncr)>
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex justify-content-center text-center text-capitalize"
                  v-for="(data, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">&nbsp;</span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell()>
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(data, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">&nbsp;</span>
                  </div>
                </li>
              </ul>
            </template>
          </b-table>
        </transition>
      </template>
    </b-table>
  </div>
</template>
<script>
//Import Fields
import fieldsDataModalTracking from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/data/fields.data.ModalTrackingEfectivity.js";
import LabelsRemove from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/data/label-bureau";
//Import Service
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";

import { mapGetters } from "vuex";

export default {
  props: {
    statusOptions: {
      type: Array,
      default: () => [],
    },
    statusOptionsRevert: {
      type: Array,
      default: () => [],
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
    items: {
      type: Array,
      required: true,
      default: [],
    },
    client: {
      type: [Object, Array],
      required: true,
      default: [],
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
  },
  data() {
    return {
      labelsRemove: LabelsRemove,
      fields: fieldsDataModalTracking.creditExpertsAndBoostCredit,
      selectedStatus: 4,
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      variantBadge: {
        UPDATED: "info",
        DELETED: "success",
        "IN DISPUTE": "warning",
        NEGATIVE: "danger",
        VERIFIED: "danger",
        POSITIVE: "primary",
      },
      dataUpdated: [],
    };
  },
  methods: {
    getStatusOptions(item, idx) {
      if ([3, 4].includes(item.items[idx].statusIdOld)) {
        return this.statusOptionsRevert;
      }
      return this.statusOptions;
    },
    async showPdfNcrRequest() {
      try {
        const { url } = await NcrRequestService.getPdfNcrRequest({
          ncrRequestId: this.client.ncr_request_id,
        });
        window.open(url, "_blank");
      } catch (ex) {}
    },
    transformData(data) {
      const transformedData = {
        experian: {},
        equifax: {},
        transunion: {},
      };

      data.forEach((obj) => {
        const bureau = this.getBureau(obj.bureau_id);
        this.$set(transformedData, bureau, obj);
      });
      return [transformedData];
    },
    getBureau(bureauId) {
      switch (bureauId) {
        case 1:
          return "transunion";
        case 2:
          return "experian";
        case 3:
          return "equifax";
        default:
          return "";
      }
    },
    labelItem(count) {
      return count == 1 ? `${count} item` : `${count} items`;
    },
    typeClassification(item, key) {
      let filter = item.filter((item) => item.bureau_name == key.toUpperCase());
      let background = {
        MEDIUM: "rgb(255, 175, 0)",
        LOW: "rgb(254, 0, 0)",
        HIGH: "rgb(0, 243, 128)",
        null: "#82868b",
      };
      const imagePath = require(`@/assets/images/icons/${key}.png`);
      return filter.length > 0
        ? `<span class="custom-badge-effectivity" style="background:${
            background[filter[0].type_classification]
          }">${filter[0].type_classification || "NOT QUALIFIED"}</span>`
        : `<img src="${imagePath}" width="30px" height="30px" class="custom-bg-logo-effectivity"/>`;
    },
    getStatusLetter(item, key) {
      let filter = item.filter((item) => item.bureau_name == key.toUpperCase());
      return filter.length > 0
        ? filter[0].status_letter || "NOT QUALIFIED"
        : key;
    },
    getStatusBackground(priority) {
      if (!priority) return "rgb(130, 134, 139)";
      let background = {
        HIGH: "#00F380",
        MEDIUM: "#FFAF00",
        LOW: "#FE0000",
      };
      return background[priority];
    },
    validateItemBureau(item, key) {
      return item.filter((item) => item.bureau_name == key.toUpperCase())
        .length > 0
        ? true
        : false;
    },
    changeStatus(item, key, data) {
      let filter = item.filter((item) => item.bureau_name == key.toUpperCase());
      filter.map(
        (item) => ((item.status_letter = data.value), (item.status = data.id))
      );
      // this.dataUpdated.push({ id: filter[0].id, status_id: data.id });
    },
    restoreStatus(item, key) {
      let filter = item.filter((item) => item.bureau_name == key.toUpperCase());
      filter.map(
        (item) => (
          (item.status_letter = item.statusOld),
          (item.status = item.statusIdOld)
        )
      );
    },
    getCreditorName(items) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.creditor_name : null;
    },
    getCreditorAccount(items) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.account_number : null;
    },
    validateRestoreButton(item, key) {
      let filter = item.filter((item) => item.bureau_name == key.toUpperCase());
      return !filter.find((item) => item.status_letter == item.statusOld);
    },
    excludeItem(item, key) {
      let filter = item.filter((item) => item.bureau_name == key.toUpperCase());
      return !["DELETED", "UPDATED"].includes(filter[0].status_letter);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_ID_UPDATE_REQUEST: "RequestWorkPlanStore/G_ID_UPDATE_REQUEST",
      G_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/G_ID_REQUEST_WORKPLAN",
    }),
    sectionId() {
      return this.section == "workplan" ? this.G_ID_REQUEST_WORKPLAN : this.G_ID_UPDATE_REQUEST;
    },
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
  },
};
</script>
<style lang="scss" scoped>
.status-name {
  font-size: 13px;
  color: white !important;
  font-weight: bold !important;
}
.custom-img {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
}
li.padding {
  height: 35px;
  border-top: 1px solid #a7a9ad !important;
}
.action-data {
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 150px;
}
.dark-bg {
  background: #1b1b1e !important;
}
.custom-badge {
  position: absolute !important;
  top: -20px;
  right: -20px !important;
}
</style>
