<template>
  <b-card class="mb-1">
    <div class="mb-1 d-flex align-items-center">
      <feather-icon icon="CircleIcon" size="15" class="color-profile mr-1" />
      <span class="title-table d-inline-block lh-0 mr-1">Personal Profile</span>
      <b-badge
        class="cursor-pointer"
        variant="light-primary"
        :class="showProfile ? null : 'collapsed'"
        @click="showProfile = !showProfile"
        aria-controls="collapse-profile"
        :aria-expanded="showProfile"
      >
        {{ showProfile ? "Hide" : "Show" }}
      </b-badge>
    </div>
    <b-collapse v-model="showProfile" id="collapse-profile">
      <b-table-simple
        small
        caption-top
        responsive
        bordered
        class="position-relative custom-table-report"
        :sticky-header="'65vh'"
      >
        <b-thead>
          <b-tr>
            <b-th class="td-left text-right header-width"></b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">TransUnion</span>
              <b-img :src="require('@/assets/images/icons/transunion.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Experian</span>
              <b-img :src="require('@/assets/images/icons/experian.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Equifax</span>
              <b-img :src="require('@/assets/images/icons/equifax.png')" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <!-- Scores -->
          <b-tr>
            <b-td class="td-left col-2 text-right">
              <span class="mr-1 font-weight-bolder">Score</span>
              <b-badge
                :title="`${!editScore} ? 'Edit' : 'Save'`"
                :variant="`light-${!editScore ? 'primary' : 'success'}`"
                v-if="isForSpecialist"
                size="sm"
                class="cursor-pointer d-inline-flex align-items-center"
                style="gap: 5px"
                @click="editScore = !editScore"
              >
                <feather-icon
                  :icon="!editScore ? 'EditIcon' : 'CheckIcon'"
                  size="13"
                />
                <span class="lh-0">
                  {{ !editScore ? "Edit" : "Save" }}
                </span>
              </b-badge>
            </b-td>

            <template v-for="score in score">
              <b-td class="td-center td-bold text-center" :key="score.id">
                <span class="h3 font-weight-bolder" v-if="!editScore">
                  {{ score.score }}
                </span>
                <b-input
                  v-else
                  class="form-control"
                  type="text"
                  v-model="score.score"
                  v-mask="'###'"
                ></b-input>
              </b-td>
            </template>
          </b-tr>

          <!-- Credit Report -->
          <b-tr>
            <b-td class="td-left text-right">
              <span class="font-weight-bolder">Credit Report Date</span>
            </b-td>

            <b-td class="td-center text-center" v-for="i in 3" :key="i">
              <feather-icon
                class="cursor-pointer"
                icon="CalendarIcon"
                size="13"
                v-b-tooltip.hover="'Delete'"
              />
              <span> {{ day_processed_format || datos.date }}</span>
            </b-td>
          </b-tr>

          <!-- More Info -->
          <b-tr v-for="LineIndex in 4" :key="LineIndex">
            <b-td class="td-left text-right">
              <span class="font-weight-bolder d-block">
                {{
                  LineIndex == 1
                    ? "Name:"
                    : LineIndex == 2
                    ? "Date of Birth:"
                    : LineIndex == 3
                    ? "Address(es):"
                    : "Employment:"
                }}
              </span>

              <b-badge
                variant="secondary"
                class="cursor-pointer"
                size="sm"
                @click="modalPersonalInfo(LineIndex)"
                v-if="isForSpecialist"
              >
                <feather-icon icon="PlusIcon" />
              </b-badge>
              <b-button
                size="sm"
                :variant="disableButton(LineIndex) ? 'primary' : 'info'"
                v-if="showDisputeButton(LineIndex)"
                @click="openDisputeDetailsModal"
                :disabled="disableButton(LineIndex)"
              >
                {{ disableButton(LineIndex) ? "IN ROUND" : "ADD NEW ROUND" }}
              </b-button>
            </b-td>

            <b-td
              class="td-center text-center"
              v-for="rowsIndex in 3"
              :key="rowsIndex"
            >
              <ul class="list-unstyled">
                <template v-for="(name, index) in personal_info">
                  <li
                    :key="index"
                    v-if="name.type == LineIndex && name.bureau_id == rowsIndex"
                    class="text-center"
                  >
                    <div
                      class="row justify-content-between align-items-center p-1"
                    >
                      <b-form-checkbox
                        v-model="name.to_select"
                        v-if="isForRound && name.status != 1"
                        @change="toSelected(name, LineIndex)"
                        :disabled="inRound(name.id)"
                        class="col-8"
                      >
                        <p>
                          {{ name.content }}

                          <b-badge
                            class="ml-1"
                            v-if="inRound(name.id, false)"
                            variant="info"
                            >IN ROUND</b-badge
                          >

                          <feather-icon
                            v-if="inRound(name.id, false)"
                            icon="XIcon"
                            class="text-danger pointer"
                            size="18"
                            v-b-tooltip.hover.top="'Remove from round'"
                            @click="removeFromRound(name)"
                          />
                        </p>
                      </b-form-checkbox>

                      <div v-else class="mx-auto">
                        <div v-if="!name.for_edit" style="margin-bottom: 5px">
                          <feather-icon
                            size="17"
                            icon="CheckIcon"
                            v-if="name.check"
                          ></feather-icon>

                          <span
                            class="text-primary"
                            :class="isDarkSkin ? 'text-orange-wounder' : ''"
                            @click="displayCheckArray(name)"
                            v-if="!name.for_edit"
                          >
                            {{ name.content }}
                          </span>
                          <b-button
                            v-if="isForSpecialist"
                            class="btn-circle ml-1"
                            variant="primary"
                            @click="
                              EditInfo(index, !name.for_edit, name.content)
                            "
                          >
                            <feather-icon icon="EditIcon"></feather-icon>
                          </b-button>
                          <b-button
                            class="btn-circle"
                            style="margin-left: 5px"
                            variant="danger"
                            @click="removePersonal(index)"
                          >
                            <feather-icon icon="TrashIcon"></feather-icon>
                          </b-button>
                          <template v-if="name.title_id">
                            <b-badge
                              :variant="
                                name.type_send == 'CERTIFIED'
                                  ? 'primary'
                                  : 'info'
                              "
                              class="ml-1"
                            >
                              <template v-if="name.type_send == 'CERTIFIED'">
                                <feather-icon
                                  icon="PhoneIcon"
                                  size="20"
                                  class="clickable"
                                  v-b-tooltip.hover.top="'Direct'"
                                />
                                Direct
                              </template>

                              <template v-else>
                                <amg-icon
                                  class="cursor-deafult"
                                  icon="LetterIcon"
                                  size="17"
                                  v-b-tooltip.hover.top="'Letter'"
                                />
                                {{ getRoundNumber(name.title_id) }}
                              </template>
                            </b-badge>
                          </template>
                        </div>

                        <div
                          v-if="name.for_edit && isForSpecialist"
                          class="d-flex align-items-center justify-content-between mx-5 mb-1"
                          style="gap: 5px"
                        >
                          <b-form-input
                            class="w-80"
                            v-model="name.content"
                          ></b-form-input>
                          <b-button
                            class="btn-circle"
                            variant="success"
                            @click="saveInput(index, name.content)"
                          >
                            <feather-icon icon="CheckCircleIcon"></feather-icon>
                          </b-button>

                          <b-button
                            class="btn-circle"
                            variant="danger"
                            @click="removePersonalInfo(index)"
                          >
                            <feather-icon icon="XCircleIcon"></feather-icon>
                          </b-button>
                        </div>
                      </div>
                      <div
                        class="col-4"
                        v-if="section == 'update' && isForRound"
                      >
                        <b-dropdown variant="link" no-caret>
                          <template #button-content>
                            <b-badge :variant="getColorStatus(name.status)"
                              >{{ statusName(name.status)
                              }}<feather-icon
                                icon="ChevronDownIcon"
                                size="16"
                                v-b-tooltip.hover.right="'Actions'"
                              />
                            </b-badge>
                          </template>
                          <b-dropdown-item
                            v-for="(subItem, index) in statuses"
                            :key="index"
                            v-b-modal.modal-primary
                            @click="updateStatus(name, subItem)"
                          >
                            <span class="align-middle">{{
                              subItem.value
                            }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="td-left text-right">
              <span class="font-weight-bolder">Employers:</span>
            </b-td>
            <b-td
              class="td-center text-center"
              v-for="rowsIndex in 3"
              :key="rowsIndex"
            >
              <ul class="list-unstyled">
                <template v-for="(name, index) in personal_info">
                  <li
                    :key="index"
                    v-if="name.type == 4 && name.bureau_id == rowsIndex"
                  >
                    <span>{{ name.content }}</span>
                  </li>
                </template>
              </ul>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-collapse>

    <!-- Modal Info -->
    <b-modal
      modal
      v-model="modalInfo"
      title="SELECT BUREAU"
      title-class="h3 text-white font-weight-bolder"
      size="sm"
      header-class="b-vue-modal-header"
      modal-class="top-modal"
    >
      <div class="text-center mt-1">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="bureausSelected"
            :options="bureaus"
            :aria-describedby="ariaDescribedby"
            name="buttons-1"
            class="customCheckBureau w-full"
            :class="{ dark: isDarkSkin }"
            ref="checkboxes"
            size="lg"
            buttons
          />
        </b-form-group>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="addItems()"> Add Inputs</b-button>
        </div>
      </template>
    </b-modal>

    <dispute-details-modal
      v-if="showDisputeDetailsModal"
      :selectedElements="selectedElements"
      @resetSelectedElem="resetSelectedElements"
      @close="showDisputeDetailsModal = false"
    />
    <edit-personal-information-modal
      v-if="editPIModalController"
      :user-id="currentUser.user_id"
      :pi-item="itemPi"
      @reload="updatePersonal"
      @close="editPIModalController = false"
      :fromComponent="'report'"
    />
  </b-card>
</template>

<script>
// COMPONENTS
import DisputeDetailsModal from "@/views/commons/components/request-workplan/views/components/modal/DisputeDetailsModal.vue";
import EditPersonalInformationModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditPersonalInformationModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

// STORE
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    DisputeDetailsModal,
    EditPersonalInformationModal,
  },
  props: {
    score: {
      required: true,
    },
    day_processed_format: {
      required: true,
    },
    datos: {
      required: true,
    },
    personal_info: {
      required: true,
    },
    process: {
      required: true,
      type: [Number, String],
    },
    day_processed: {
      required: true,
    },
    round_id: {
      required: true,
    },
    idSection: {
      required: false,
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
  },
  async created() {
    await this.getStatuses();
  },
  data() {
    return {
      showProfile: true,
      modalInfo: false,
      editScore: false,
      setPersonal: [
        {
          reason: null,
          instruction: null,
        },
        {
          reason: null,
          instruction: null,
        },
        {
          reason: null,
          instruction: null,
        },
      ],
      disputeProfile: {
        name: false,
        dob: false,
        address: false,
        employment: false,
      },
      selectedElements: [],
      showDisputeDetailsModal: false,
      bureaus: [
        { text: "Transunion", value: 1 },
        { text: "Experian", value: 2 },
        { text: "Equifax", value: 3 },
      ],
      bureausSelected: [],
      editPIModalController: false,
      statuses: [],
      valueTemporal: null,
      statusAddInputs: false,
      arrayPersonalInfo: [],
    };
  },
  methods: {
    ...mapActions({
      A_REMOVE_ACCOUNT: "RequestWorkPlanStore/A_REMOVE_ACCOUNT",
    }),
    addItems() {
      this.bureausSelected.forEach((element) => {
        this.addPersonalInfo(element);
      });
      this.bureausSelected = [];
      this.modalInfo = false;
      this.personal_info = JSON.parse(JSON.stringify(this.personal_info));
    },
    async removePersonal(index) {
      const swal = await this.showConfirmSwal();
      if (swal.isConfirmed) {
        this.personal_info.splice(index, 1);
      }
    },
    displayCheckArray(name) {
      if (!this.isForRound) {
        name.check = !name.check;
        name.status = !name.check ? 5 : 6;
      }
    },
    async modalPersonalInfo(type) {
      this.statusAddInputs = true;
      this.type_selected = type;
      this.modalInfo = true;
      await this.$nextTick();
      this.changeCheckboxImg();
    },
    async changeCheckboxImg() {
      const images = {
        0: "/assets/images/icons/transunion.png",
        1: "/assets/images/icons/experian.png",
        2: "/assets/images/icons/equifax.png",
      };
      const checkboxes = this.$refs.checkboxes;
      const childrens = checkboxes.$el;
      const labels = childrens.querySelectorAll("label");
      labels.forEach((label, index) => {
        // add image before label
        const img = document.createElement("img");
        img.src = images[index];
        img.style.width = "30px";
        img.style.height = "30px";
        img.style.marginRight = "10px";
        label.prepend(img);
      });
    },
    addPersonalInfo(bureau_id) {
      this.personal_info.push({
        type: this.type_selected,
        content: null,
        status: 6,
        bureau_id: bureau_id,
        check: true,
        for_edit: true,
      });
      // this.personal_info = JSON.parse(JSON.stringify(this.personal_info));
    },

    saveInput(index, content) {
      this.personal_info[index].for_edit = !this.personal_info[index].for_edit;
      const currentObject = this.arrayPersonalInfo.find(
        (objeto) => objeto.index === index
      );
      currentObject && (currentObject.content = content);
    },

    EditInfo(index, value, content) {
      const data = { index, content };
      this.arrayPersonalInfo.push(data);
      this.personal_info[index].for_edit = value;
    },

    removePersonalInfo(index) {
      if (this.statusAddInputs) {
        this.personal_info.splice(index, 1);
        this.statusAddInputs = false;
        return;
      } else {
        let { content } = this.arrayPersonalInfo.find(
          (objeto) => objeto.index === index
        );
        this.personal_info[index].content = content;
        this.personal_info[index].for_edit =
          !this.personal_info[index].for_edit;
      }
      this.arrayPersonalInfo = this.arrayPersonalInfo.filter(
        (objeto) => objeto.index !== index
      );
    },

    toSelected(item, line) {
      if (item.to_select) {
        item.round_id = this.round_id;
        item.type_element = 1;
        this.selectedElements.push(item);
      } else {
        this.A_REMOVE_ACCOUNT(item.id);
        const idxToRemove = this.selectedElements.findIndex(
          (x) => x.id == item.id
        );
        if (idxToRemove >= 0) this.selectedElements.splice(idxToRemove, 1);
      }

      let selected = false;
      this.personal_info
        .filter((element) => element.type == item.type)
        .forEach((element) => {
          if (element.to_select) {
            selected = true;
          }
        });

      switch (line) {
        case 1:
          this.disputeProfile.name = selected;
          break;
        case 2:
          this.disputeProfile.dob = selected;
          break;
        case 3:
          this.disputeProfile.address = selected;
          break;
        default:
          this.disputeProfile.employment = selected;
          break;
      }
    },

    removeFromRound(item) {
      this.A_REMOVE_ACCOUNT(item.id);
    },

    getRoundNumber(action) {
      switch (action) {
        case 1:
          return "FIRST ROUND";
        case 2:
          return "SECOND ROUND";
        case 3:
          return "THIRD ROUND";
        case 4:
          return "FOURTH ROUND";
        default:
          return "FIFTH ROUND";
      }
    },

    showDisputeButton(line) {
      return line == 1
        ? this.disputeProfile.name
        : line == 2
        ? this.disputeProfile.dob
        : line == 3
        ? this.disputeProfile.address
        : this.disputeProfile.employment;
    },
    openDisputeDetailsModal() {
      this.showDisputeDetailsModal = true;
    },
    inRound(id) {
      let bool = false;
      this.G_DISPUTE_DATA.forEach((el) => {
        if (el.id == id) {
          bool = true;
        }
      });
      return bool;
    },
    disableButton(line) {
      return this.personal_info
        .filter((info) => info.type == line && info.to_select)
        .every((info) =>
          this.G_DISPUTE_DATA.some((data) => info.id === data.id)
        );
    },
    resetSelectedElements() {
      this.selectedElements = [];

      this.personal_info
        .filter((element) => element.to_select)
        .forEach((element) => {
          element.to_select = false;
        });
    },
    async editPersonalInfo(info, account) {
      let data = {
        id: account.id,
        status: account.status,
        content: account.content,
        bureau_id: 1,
        status_name: account.status_name,
      };

      this.itemPi = data;
      this.editPIModalController = true;
    },
    statusName(status) {
      let obj = {
        1: "IN DISPUTE",
        2: "VERIFIED",
        3: "UPDATED",
        4: "DELETED",
        5: "POSITIVE",
        6: "NEGATIVE",
      };
      return obj[status] || "light-success";
    },
    getColorStatus(number) {
      let setColorStatus = {
        1: "light-warning",
        2: "light-danger",
        5: "light-primary",
        6: "light-danger",
      };

      return setColorStatus[number] || "light-success";
    },
    updatePersonal() {
      this.$emit("reloadGetPersonal");
    },
    async getStatuses() {
      try {
        const { data } = await ClientsOptionsServices.getStatusLetters();

        this.statuses = data.filter((item) => item.id != 1);
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async updateStatus(item, subItem) {
      try {
        await ClientsOptionsServices.editPersonalInformation({
          id: item.id,
          status: subItem.id,
          content: item.content,
          iduser: this.currentUser.user_id,
          instruction: null,
          reason: null,
          update_id: this.idSection,
        });
        item.status = subItem.id;
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          error
        );
      }
    },
  },
  computed: {
    isForSpecialist() {
      return this.process != 1 && (this.moduleId == 28 || this.moduleId == 25);
    },
    isForRound() {
      return (
        this.process == 1 &&
        (this.moduleId == 28 || this.moduleId == 25) &&
        this.day_processed > "2021-09-27"
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      skin: "appConfig/skin",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      currentUser: "auth/currentUser",
    }),
    classAdd() {
      return this.skin == "dark" ? "dark" : "light";
    },
    statusValue() {
      return this.skin == "dark" ? "text-primary" : "";
    },
    idBureau() {
      return this.$route.params.idBureau;
    },
    // section() {
    //   return this.$route.params.section;
    // },
  },

  watch: {
    isForRound(value) {
      if (value) {
        this.displayCheckArray();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-table {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.color-profile {
  color: #ff9600;
  fill: #ff9600;
  margin-right: 10px;
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 8px;
  line-height: 1.42857;
}
.w-80 {
  width: 80% !important;
}

.custom-table-report::v-deep thead tr th {
  background-color: #3a72ea !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-table-report::v-deep thead tr th:first-child {
  border-radius: 15px 0 0 0 !important;
}

.custom-table-report::v-deep thead tr th:last-child {
  border-radius: 0 15px 0 0 !important;
}
</style>

<style lang="scss">
.customCheckBureau {
  justify-content: space-around;
  .btn {
    border: 1px solid #eeeeef !important;
    background-color: #eeeeef !important;
    color: #000 !important;
  }
  .btn.active,
  .btn:hover {
    border: 1px solid #eeeeef !important;
    background-color: #594e4e !important;
    color: #eeeeef !important;
  }
}

.customCheckBureau.dark {
  justify-content: space-around;
  .btn {
    border: 1px solid #2c2c2c !important;
    background-color: #2c2c2c !important;
    color: #eeeeef !important;
  }
  .btn.active,
  .btn:hover {
    border: 1px solid #3f3c3c !important;
    background-color: #3f3c3c !important;
    color: #eeeeef !important;
  }
}
</style>
