<template>
  <div>
    <b-row>
      <b-col cols="6">
        <report-credit
          :dataAccounts="currentItemsData"
          title="Current Items"
          type="remaining"
          @selected="selected"
          :fieldsItems="fieldsItems"
          :enabledCheckbox="enabledCheckbox"
          :loading="isLoading"
          textNoData="no data on current items"
          @deleteItem="deleteItem"
        />
      </b-col>
      <b-col cols="6">
        <report-credit
          :dataAccounts="newItemsData"
          title="New Items"
          :lastReport="true"
          @synchronize="synchronize"
          @clear="clear"
          type="last"
          :fieldsItems="fieldsItems"
          :enabledCheckbox="enabledCheckbox"
          :loading="isLoading"
          textNoData="no data on new items"
          :disabledSincronized="currentItemsData.length > 0"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12 py-2 text-right">
        <b-button class="mr-1" variant="danger" v-if="hasMatches" @click="save"
          >Save</b-button
        >
      </b-col>
    </b-row>
    <SynchronizationSummaryTable
      v-if="showModalDetails"
      :currentItems="currentItemsData"
      :newItems="newItemsData"
      :sendParams="sendParams"
      @close="() => (showModalDetails = false)"
      :requestWorkplan="requestWorkplan"
    />
  </div>
</template>
<script>
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import ReportCredit from "@/views/commons/components/request-workplan/views/components/modal/steps/syncronization/ReportCredit.vue";
import { mapGetters, mapActions } from "vuex";
import { fieldsSyncronization } from "@/views/commons/components/request-workplan/data/fields.syncronization";
import SynchronizationSummaryTable from "@/views/commons/components/request-workplan/views/components/modal/steps/syncronization/SynchronizationSummaryTable.vue";

export default {
  name: "SynchronizationOfItems",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  components: {
    ReportCredit,
    SynchronizationSummaryTable,
  },
  data() {
    return {
      currentItemsData: [],
      newItemsData: [],
      colors: [],
      isLoading: false,
      status: "",
      fieldsItems: fieldsSyncronization,
      enabledCheckbox: {
        checked:true,
        bureau_id:0
      },
      showModalDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    hasRemainingReport() {
      return this.currentItemsData.length > 0;
    },
    hasLastReport() {
      return this.newItemsData.length > 0;
    },
    hasMatches() {
      return (
        this.newItemsData
          .flatMap((account) => account.items)
          .filter((item) => item.match).length > 0
      );
    },
    lastReportItems() {
      return this.newItemsData
        .flatMap((account) => account.items)
        .filter((item) => item.id != "-")
        .map((item) => ({
          id: item.id,
          is_synced: item.match,
          current_match: item.current_match,
          is_remove: this.client.program_id === 3 ? item.match : 1,
          status: this.getStatus(item, "new_items"),
        }));
    },
    remainingReportDataItems() {
      return this.currentItemsData
        .flatMap((element) => element.items)
        .filter((item) => item.id != "-")
        .map((item) => ({
          selected: item.selected,
          bureau_id: item.bureau_id,
          new_item_id: item.new_item_id,
          current_item_id: item.id,
          request_work_plan_id: this.requestWorkplan.id,
          user_id: this.currentUser.user_id,
          border_color: item.borderColor,
          ...this.getStatus(item, "current_items"),
        }));
    },
    requestWorkplan() {
      return this.client;
    },
    sendParams() {
      return {
        user_id: this.currentUser.user_id,
        remainingReport: this.remainingReportDataItems.filter(
          (item) => item.selected
        ),
        newItemsData: this.lastReportItems,
        workplan_id: this.requestWorkplan.id,
        status: this.status,
        client_account_id: this.client.client_account_id,
      };
    },
    searchByProperties() {
      return this.fieldsItems
        .filter((item) => item.match)
        .flatMap((item) => item.key);
    },
    validateMissing() {
      const total2 = this.lastReportItems.filter(
        (item) => item.is_synced && item.current_match
      ).length;
      return total2 == 0;
    },
  },
  methods: {
    ...mapActions("RequestWorkPlanStore", ["A_UPDATE_STEP_WP"]),
    getStatus(element, typeReport) {
      const { id, cod_status } = element;
      if (typeReport === "new_items") {
        let dataReports = this.currentItemsData.flatMap((item) => item.items);
        const search = dataReports.find((item) => item.new_item_id === id);
        if (search) {
          return search.cod_status;
        } else {
          return cod_status;
        }
      }
      if (typeReport === "current_items") {
        return {
          status_current_item: cod_status,
          status_new_item: 6,
        };
      }
    },
    updateStepWorkPlan() {
      const updatedData = {
        id: this.requestWorkplan.id,
        step: 3,
      };
      this.A_UPDATE_STEP_WP(updatedData);
    },
    async getData() {
      try {
        this.newItemsData = [];
        this.currentItemsData = [];
        this.enabledCheckbox.checked = true;
        this.isLoading = true;
        const data = await NcrRequestService.getDataBySyncronizeItems({
          client_account_id: this.client.client_account_id,
          workplan_id: this.requestWorkplan.id,
        });
        const { last_credit_report, remaining_credit_reports } = data;
        this.currentItemsData = remaining_credit_reports;
        this.newItemsData = last_credit_report;
        this.isLoading = false;
      } catch (ex) {
        this.isLoading = false;
        console.log(ex);
      }
    },
    changeValueANumber(data) {
      data.forEach((element) => {
        element.items.forEach((item) => {
          item.a_number = this.clearChain(item.a_number);
        });
      });
      return data;
    },
    async synchronize(account, element, save = false) {
      element.current_item_id = [];
      let matchs = [];
      const dataRemaining = this.currentItemsData.flatMap((item) => item.items);
      let fieldBySearch = this.searchByProperties;
      let _color = this.generateRandomColor();
      /** validar si existe INSTALLAMENT en a_type */
      if (element.a_type.toUpperCase() != "INSTALLMENT") {
        fieldBySearch = fieldBySearch.filter(
          (item) => item != "c_limit" && item != "h_credit"
        );
      }

      /** seteamos las propiedades del new item a sincronizar */
      element = await this.changeNewItemSincronize(element, _color);

      dataRemaining.forEach((item, index, arr) => {
        let obj = this.setObj(item, fieldBySearch);
        let match = this.isMatch(obj, element, fieldBySearch);
        if (match && item.bureau_id === element.bureau_id) {
          const { borderColorOrigin } = element;
          matchs.push(match);
          _color = borderColorOrigin || _color;
          item.found = false;
          if (!item.match) {
            item.selected_change = true;
            item.found = true;
            item.borderColor = _color;
            item.new_item_id = element.id;
            element.current_item_id.push(item.id);
          }
        }
      });
      this.enabledCheckbox.checked = false;
      this.enabledCheckbox.bureau_id = element.bureau_id;
    },
    async changeNewItemSincronize(element, _color) {
      this.newItemsData.forEach((acc) => {
        acc.items.forEach((item) => {
          if (item.id === element.id) {
            item.current_match = true;
            item.match = true;
            if (!item.borderColorOrigin) {
              /** buscar el color repetido en el newItems*/
              while (this.colorAlreadyUsed(_color)) {
                _color = this.generateRandomColor();
              }
              item.borderColor = _color;
              item.borderColorOrigin = _color;
            } else {
              item.borderColor = element.borderColorOrigin;
            }
          }
        });
      });
      return this.newItemsData
        .flatMap((acc) => acc.items)
        .find((item) => item.id === element.id);
    },
    colorAlreadyUsed(color) {
      return (
        this.newItemsData
          .flatMap((item) => item.items)
          .filter((obj) => obj.borderColorOrigin == color).length > 0
      );
    },
    setObj(element, fieldBySearch) {
      let obj = {};
      let keys = Object.keys(element);
      for (let key in keys) {
        if (fieldBySearch.includes(keys[key])) {
          obj[keys[key]] = element[keys[key]];
        }
      }
      return obj;
    },
    isMatch(base, search, fieldBySearch) {
      let matchs = [];
      for (let key in base) {
        if (key == "a_number") {
          this.validateAccountNumber(key, base, search, matchs);
        } else {
          if (base[key].toLowerCase() !== search[key].toLowerCase()) {
            matchs.push(false);
          }
          if (base[key].toLowerCase() === search[key].toLowerCase()) {
            matchs.push(true);
          }
        }
      }
      return matchs.filter((item) => item).length === fieldBySearch.length;
    },
    validateAccountNumber(key, base, search, matchs) {
      const baseValue = base[key];
      const searchValue = search[key];
      if (baseValue.length === searchValue.length) {
        if (baseValue.length === 4 && searchValue.length === 4) {
          matchs.push(true);
        } else {
          this.checkSubstringMatch(baseValue, searchValue, matchs);
        }
      } else {
        matchs.push(false);
      }
    },
    clearString(value) {
      return value.replace(/[X*]+$/, "");
    },
    checkSubstringMatch(baseValue, searchValue, matchs) {
      const baseAccNumber = this.clearString(baseValue);
      const searchAccNumber = this.clearString(searchValue);
      if (baseAccNumber.length <= searchAccNumber) {
        matchs.push(searchAccNumber.startsWith(baseAccNumber));
      } else {
        matchs.push(baseAccNumber.startsWith(searchAccNumber));
      }
    },
    clearChain(value) {
      const chain = /\d/.test(value);
      if (chain) {
        return value.replace(/\D/g, "").trim();
      } else {
        return value.trim();
      }
    },
    generateRandomColor() {
      let color = "#";
      const strongColors = ["00", "33", "66", "99", "CC", "FF"];
      for (let j = 0; j < 3; j++) {
        color += strongColors[Math.floor(Math.random() * strongColors.length)];
      }
      return color;
    },
    async save() {
      try {
        if (!this.validateMissing)
          throw new Error(
            "You have items marked as match, but they are not yet synchronized."
          );
        const confirm = await this.showConfirmSwal(
          "Are you sure you want to update the status of this report?"
        );
        if (!confirm.value) return false;
        this.addPreloader();
        this.status = "SAVE";
        await NcrRequestService.storeSyncronizeItems(this.sendParams);
        this.removePreloader();
      } catch (ex) {
        this.showGenericToast({
          variant: "warning",
          text: ex.message,
          title: "Synchronization",
          icon: "AlertTriangleIcon",
        });
        this.removePreloader();
      }
    },
    async done() {
      try {
        if (!this.validateMissing)
          throw new Error(
            "You have items marked as match, but they are not yet synchronized."
          );
        if (this.newItemsData.length > 0 || this.currentItemsData.length > 0) {
          this.status = "DONE";
          this.showModalDetails = true;
        } else {
          this.updateStepWorkPlan();
        }
      } catch (ex) {
        this.showGenericToast({
          variant: "warning",
          text: ex.message,
          title: "Synchronization",
          icon: "AlertTriangleIcon",
        });
        this.showModalDetails = false;
      }
    },
    clear({ account, item, type }) {
      try {
        this.currentItemsData.forEach((element) => {
          element.items.forEach((_item) => {
            if (_item.new_item_id === item.id) {
              this.clearAttributes(_item);
            }
          });
        });
        this.newItemsData.forEach((acc) => {
          acc.items.forEach((_item) => {
            if (_item.id === item.id) {
              _item.match = false;
              _item.borderColor = null;
              _item.current_item_id = [];
              _item.new_cod_status = null;
            }
          });
        });
        this.enabledCheckbox.checked = true;
      } catch (ex) {
        console.log(ex);
      }
    },
    clearAttributes(element) {
      element.found = false;
      element.match = false;
      element.new_item_id = null;
      element.borderColor = null;
      element.total_found = element.total_found_origin;
      element.selected = false;
      element.selected_change = false;
    },
    async selected({ account, item, checked }) {
      try {
        let elementCurrent = this.newItemsData
          .flatMap((acc) => acc.items)
          .find((_acc) => _acc.current_match && _acc.match);
        /** reseteamos a todos */
        await this.resetFoundCurrentItems();
        await this.selectedItemCurrent(elementCurrent, item);
      } catch (ex) {
        console.error("Error en selected", ex);
      }
    },
    async selectedItemCurrent(elementCurrent, item) {
      if (elementCurrent) {
        elementCurrent.current_match = false;
        elementCurrent.new_cod_status = item.cod_status;
        item.new_item_id = elementCurrent.id;
        item.found = false;
        item.borderColor = elementCurrent.borderColor;
        item.total_found = item.total_found_origin + 1;
        item.match = true;
        item.status_new_item = elementCurrent.cod_status;
        this.enabledCheckbox.checked = true;
      }
    },
    async resetFoundCurrentItems() {
      this.currentItemsData.forEach((_item) => {
        _item.items.forEach((it) => {
          if (it.found) {
            it.found = false;
            it.borderColor = null;
            it.total_found = it.total_found_origin;
            it.new_item_id = null;
          }
        });
      });
    },
    async deleteItem(item) {
      try {
        await NcrRequestService.deleteItem({
          id_item: item.id,
          user_id: this.currentUser.user_id,
        });
        this.getData();
      } catch (ex) {}
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
</style>
