<template>
  <div>
    <b-table
      striped
      small
      :items="ncrs"
      :key="ncrs.ncr_id"
      :fields="visibleFields"
      :tbody-tr-class="isDarkSkin ? 'table-round dark' : 'table-round'"
      class="table-new-customization custom-table"
    >
      <template v-slot:cell(request)="{ item }">
        <div>{{ item.request_by }}</div>
        <div>{{ item.request_at | myGlobalDay }}</div>
      </template>
      <template #cell(service)="{ item }">
        <div
          v-if="item.program_id"
          v-b-tooltip.bottom="item.program_name"
          class="d-flex justify-content-center m-0"
        >
          <img
            :src="getProgramImageRoute(item.program_id)"
            alt="img_services"
          />
        </div>
      </template>
      <template v-slot:cell(platform)="{ item }">
        <b-img-lazy
          v-b-tooltip.hover.top="item.platform_name"
          :src="`/assets${item.platform_icon}`"
          :alt="item.platform_name"
          width="20"
          height="20"
        />
      </template>
      <template v-slot:cell(file)="{ item }">
        <a
          v-if="item.route_file"
          :href="item.route_file"
          target="_blank"
          class="text-primary"
        >
          <amg-icon class="text-danger" icon="FilePdfIcon" size="18" />
        </a>
      </template>
      <template v-slot:cell(cr)="{ item }">
        <a :href="routeCR(item)" target="_blank" class="text-primary">
          <amg-icon icon="FileChartIcon" size="18" />
        </a>
      </template>
      <template v-slot:cell(tracking)="{ item }">
        <feather-icon
          icon="ListIcon"
          class="cursor-pointer"
          size="18"
          @click="openModalTracking(item)"
        />
      </template>
      <template v-slot:cell(items)="data">
        <b-badge
          variant="primary"
          pill
          class="cursor-pointer"
          @click="
            jsonParse(data.item.rounds).length > 0 && showRounds(data.index)
          "
        >
          {{ jsonParse(data.item.rounds).length }}
        </b-badge>
      </template>
      <!-- <template v-slot:cell(rounds)="data">
        <b-badge
          variant="primary"
          pill
          class="cursor-pointer"
          @click="
            jsonParse(data.item.rounds).length > 0 && showRounds(data.index)
          "
        >
          {{ jsonParse(data.item.rounds).length }}
        </b-badge>
      </template> -->
      <template v-slot:cell(directs)="data">
        <b-badge
          style="width: 40px"
          variant="primary"
          pill
          :class="countRounds(data.item, 'directs') > 0 ? 'cursor-pointer' : ''"
          @click="
            countRounds(data.item, 'directs') && showRounds(data.index, 1)
          "
        >
          {{ countRounds(data.item, "directs") }}
          <feather-icon
            v-if="countRounds(data.item, 'directs') > 0"
            icon="ChevronDownIcon"
            :class="{
              'rotate-180': data.item.detailsShowing && typeDispute == 1,
            }"
          />
        </b-badge>
      </template>
      <template v-slot:cell(letters)="data">
        <b-badge
          style="width: 40px"
          variant="primary"
          pill
          :class="countRounds(data.item, 'letters') > 0 ? 'cursor-pointer' : ''"
          @click="
            countRounds(data.item, 'letters') > 0 && showRounds(data.index, 2)
          "
        >
          {{ countRounds(data.item, "letters") }}
          <feather-icon
            v-if="countRounds(data.item, 'letters') > 0"
            icon="ChevronDownIcon"
            :class="{
              'rotate-180': data.item.detailsShowing && typeDispute == 2,
            }"
          />
        </b-badge>
      </template>
      <template v-slot:cell(documents)="{ item }">
        <div v-if="missingFiles && missingRoundFile(item)">
          <b-badge
            variant="danger"
            class="cursor-pointer"
            @click="toAddFiles()"
          >
            Add Files
          </b-badge>
          <b-badge
            v-b-tooltip.hover="'ID file is missing'"
            class="ml-1"
            variant="warning"
            v-if="countId == 0"
          >
            ID
          </b-badge>
          <b-badge
            v-b-tooltip.hover="'UB file is missing'"
            class="ml-1"
            variant="warning"
            v-if="countUb == 0"
          >
            UB
          </b-badge>
        </div>
        <b-badge variant="light-primary" v-else> Completed </b-badge>
      </template>
      <template #row-details>
        <transition name="fade">
          <RoundDetail
            :ncrId="ncrId"
            :typeDispute="typeDispute"
            :clientData="client"
            :key="keyDetail"
          />
        </transition>
      </template>
    </b-table>
    <TrackingModalDis
      v-if="showTracking"
      :data="trackingData"
      @close="closeModalTracking"
    />
    <AddRoundModal
      v-if="showRoundModal"
      :data-round="roundData"
      :validate-modal="1"
      @close="closeRoundModal"
      @reload="getAllNcr"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import TrackingModalDis from "@/views/commons/components/clients/dashboard/options/dis-module/modals/tracking-modal-dis/TrackingModalDis.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import RoundDetail from "@/views/commons/components/clients/dashboard/options/dis-module/round/RoundDetail.vue";
export default {
  components: {
    TrackingModalDis,
    AddRoundModal,
    RoundDetail,
  },
  data() {
    return {
      typeDispute: null,
      ncrs: [],
      fields: [
        {
          key: "request",
          label: "Processed by",
          visible: true,
        },
        {
          key: "service",
          label: "Service",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "platform",
          label: "platform",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "file",
          label: "File",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        // {
        //   key: "cr",
        //   label: "CR",
        //   thClass: "text-center",
        //   tdClass: "text-center",
        //   visible: true,
        // },
        {
          key: "transunion",
          label: "Transunion",
          thClass: "text-center",
          tdClass: "text-center font-score",
          visible: false,
        },
        {
          key: "experian",
          label: "Experian",
          thClass: "text-center",
          tdClass: "text-center font-score",
          visible: false,
        },
        {
          key: "equifax",
          label: "Equifax",
          thClass: "text-center",
          tdClass: "text-center font-score",
          visible: false,
        },
        {
          key: "tracking",
          label: "Tracking",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "documents",
          label: "Documents",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "rounds",
          label: "Disputes",
          thClass: "text-center",
          tdClass: "text-center",
          visible: false,
        },
        {
          key: "directs",
          label: "Directs",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "letters",
          label: "Letters",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
      ],
      showTracking: false,
      trackingData: {},
      showRoundModal: false,
      roundData: {},
      ncrId: null,
      keyDetail: 0,
      currentIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      missingFiles: "ReportStore/G_MISSING_FILES",
      countId: "ReportStore/G_COUNT_ID",
      countUb: "ReportStore/G_COUNT_UB",
    }),
    visibleFields() {
      if (!this.showCR) {
        return this.fields.filter(
          (field) => field.visible && field.key !== "cr"
        );
      }
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    showCR() {
      return [2, 3, 5, 28].includes(this.moduleId);
    },

    routeFiles() {
      let routes = {
        5: "client-files",
        6: "client-files-credit-experts",
        7: "client-files-boost-credit",
        8: "client-files-tax-research",
        10: "client-files-claim-department",
        12: "client-o-files",
        20: "connection-clients-account-files",
        22: "client-files-ce-customer-service",
        28: "client-files-specialist",
      };
      return routes[this.moduleId] || this.$route.name;
    },
  },
  methods: {
    countRounds(item, type) {
      const types = type == "directs" ? [3, 4, 5] : [1, 2];
      return this.jsonParse(item.rounds).filter((x) =>
        types.includes(x.type_send)
      ).length;
    },
    toAddFiles() {
      this.$router.push({ name: this.routeFiles });
    },
    async getAllNcr() {
      this.addPreloader();
      try {
        const { data } = await ClientsOptionsServices.getAllNcrs({
          accountId: this.$route.params.idClient,
        });
        this.ncrs = data || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
      this.typeDispute = this.countRounds(this.ncrs[0], "directs") > 0 ? 1 : 2;
      // this.showFirstElement();
    },
    openModalTracking(item) {
      this.showTracking = true;
      this.trackingData = {
        id: item.ncr_id,
        account: item.account,
        clientName: item.client_name,
      };
    },
    closeModalTracking() {
      this.showTracking = false;
      this.trackingData = {};
    },
    addRoundModal(item) {
      this.roundData = {
        id: item.ncr_id,
        account: item.account,
        clientName: item.client_name,
        clientData: {
          dob: this.client.dob,
          address: this.client.address,
          ssn: this.client.data_ssn,
          origin_country: this.client.origin_country,
        },
      };
      this.showRoundModal = true;
    },
    closeRoundModal() {
      this.showRoundModal = false;
    },
    showRounds(index, type) {
      const previousTypeDispute = this.typeDispute; // get previous type dispute to know if component should show the other type in a row or toggle the entire row
      this.ncrId = this.ncrs[index].ncr_id;
      this.ncrs.forEach((element, i) => {
        if (i != index) {
          element._showDetails = false;
          element.detailsShowing = false;
        } else {
          if (this.previousIndex == index) {
            // if we clicked inside the same row
            if (previousTypeDispute == type) {
              // if we clicked the same type in the same row, hide or show all its details
              element._showDetails = !element._showDetails;
              element.detailsShowing = element._showDetails;
            } else {
              // if we clicked the other type in the same row, keep showing details of current row but this time with the other type of dispute
              element._showDetails = true;
              element.detailsShowing = true;
            }
          } else {
            // if we click inside another row, toggle all its details
            this.showDetails = !this.showDetails;
            this.ncrs[index]._showDetails = !this.ncrs[index]._showDetails;
            this.ncrs[index].detailsShowing = !this.ncrs[index].detailsShowing;
          }
        }
      });
      this.typeDispute = type; // assign the typeDispute to RoundDetail params
      this.keyDetail++; // re-render RounDetail table
      this.previousIndex = index; // store the previous index
    },
    showFirstElement(index = 0) {
      this.ncrId = this.ncrs[index].ncr_id;
      let rounds = this.ncrs[index].rounds;
      if (rounds != null) {
        this.previousIndex = index;
        this.showDetails = !this.showDetails;
        this.ncrs[index]._showDetails = !this.ncrs[index]._showDetails;
        this.ncrs[index].detailsShowing = !this.ncrs[index].detailsShowing;
      } else {
        if (this.ncrs[index + 1]) {
          this.showFirstElement(index + 1);
          this.currentIndex = index + 1;
        }
      }
    },
    routeCR(item) {
      const routes = {
        2: "/boostcredit/leads/report",
        3: "/creditexperts/leads/report",
        5: "/debtsolution/leads/report",
        28: "/specialists/analyst-department/leads/report",
      };
      let depRoute = `${routes[this.moduleId]}/${this.client.lead_id}/${
        item.score_id
      }`;
      let specRoute = `${routes[this.moduleId]}/${this.client.lead_id}/${
        item.score_id
      }/${this.client.account}`;
      return this.moduleId === 28 ? specRoute : depRoute;
    },
    missingRoundFile(item) {
      let missing = false;
      let rounds = this.jsonParse(item.rounds);
      rounds.forEach((round) => {
        if (round.status != 7) {
          missing = true;
        }
      });
      return missing;
    },
  },
  mounted() {
    this.getAllNcr();
  },
};
</script>

<style scoped lang="scss">
.font-score {
  font-size: 1.2rem;
  font-weight: 600;
}
</style>

<style scoped>
.b-table-details.table-round {
  background: #ebebeb !important;
  border-top: 1px solid #e1dde3 !important;
}

.b-table-details.table-round.dark {
  background: #1e1e1e !important;
  border-top: 1px solid #2b2b2b !important;
}
.custom-table >>> th {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child {
  border-top-right-radius: 10px !important;
}

.rotate-180 {
  transform: rotate(180deg) scale(1.3);
}
</style>
