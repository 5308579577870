var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"d-flex flex-column gap-5"},[_c('div',[_c('b-button',{staticClass:"buttons-menu",class:_vm.tabs[0].id == _vm.actualTab
          ? 'button-active'
          : _vm.isDarkSkin
          ? 'button-inactive-dark'
          : 'button-inactive-light',on:{"click":function($event){_vm.actualTab = _vm.tabs[0].id}}},[_vm._v(" "+_vm._s(_vm.tabs[0].label)+" "),(_vm.accountCounters.toRemove > 0)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.accountCounters.toRemove > 99 ? '99+' : _vm.accountCounters.toRemove,"badge-classes":"badge-danger"}})],1):_vm._e()]),(_vm.personalInformation.length)?_c('b-button',{staticClass:"buttons-menu",class:_vm.tabs[1].id == _vm.actualTab
          ? 'button-active'
          : _vm.isDarkSkin
          ? 'button-inactive-dark'
          : 'button-inactive-light',on:{"click":function($event){_vm.actualTab = _vm.tabs[1].id}}},[_vm._v(" "+_vm._s(_vm.tabs[1].label)+" "),(_vm.personalInformation.length > 0)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.personalInformation.length > 99
              ? '99+'
              : _vm.personalInformation.length,"badge-classes":"badge-danger"}})],1):_vm._e()]):_vm._e(),(_vm.inquiries.length)?_c('b-button',{staticClass:"buttons-menu",class:_vm.tabs[2].id == _vm.actualTab
          ? 'button-active'
          : _vm.isDarkSkin
          ? 'button-inactive-dark'
          : 'button-inactive-light',on:{"click":function($event){_vm.actualTab = _vm.tabs[2].id}}},[_vm._v(" "+_vm._s(_vm.tabs[2].label)+" "),(_vm.inquiries.length > 0)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.inquiries.length > 99 ? '99+' : _vm.inquiries.length,"badge-classes":"badge-danger"}})],1):_vm._e()]):_vm._e(),(_vm.publicRecords.length)?_c('b-button',{staticClass:"buttons-menu",class:_vm.tabs[3].id == _vm.actualTab
          ? 'button-active'
          : _vm.isDarkSkin
          ? 'button-inactive-dark'
          : 'button-inactive-light',on:{"click":function($event){_vm.actualTab = _vm.tabs[3].id}}},[_vm._v(" "+_vm._s(_vm.tabs[3].label)+" "),(_vm.publicRecords.length > 0)?_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.publicRecords.length > 99 ? '99+' : _vm.publicRecords.length,"badge-classes":"badge-danger"}})],1):_vm._e()]):_vm._e()],1),_c('div',[(_vm.actualTab == 1)?_c('FirstStepTable',{attrs:{"isBusy":_vm.isBusy,"statusOptions":_vm.statusOptions,"statusOptionsRevert":_vm.statusOptionsRevert,"client":_vm.client,"items":_vm.items,"section":_vm.section}}):_vm._e(),(_vm.actualTab == 2)?_c('b-card',[_c('GridReport',{attrs:{"data":_vm.personalInformation,"statusOptions":_vm.statusOptionsGridReport,"showTitle":false,"title":'Personal Information',"showInfo":true}})],1):_vm._e(),(_vm.actualTab == 3)?_c('b-card',[_c('GridReport',{attrs:{"data":_vm.inquiries,"statusOptions":_vm.statusOptionsGridReport,"showTitle":false,"title":'Inquiries',"showInfo":true}})],1):_vm._e(),(_vm.actualTab == 4)?_c('b-card',[_c('GridReport',{attrs:{"data":_vm.publicRecords,"statusOptions":_vm.statusOptionsGridReport,"showTitle":false,"title":'Public Records',"showInfo":true}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }