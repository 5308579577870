<template>
  <div v-if="ownControl">
    <div class="mb-1">
      <b-button v-if="!isModal" variant="info" @click="closeModal">
        <feather-icon icon="ChevronLeftIcon" /> RETURN
      </b-button>
      <b-row class="mt-1">
        <b-col lg="6">
          <validation-observer ref="form">
            <b-row>
              <b-col lg="4">
                <label>Select:</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Select"
                  rules="required"
                >
                  <b-select
                    v-model="disputeData.type"
                    :options="optionsType"
                    value-field="value"
                    text-field="name"
                  />
                  <label v-if="errors[0]" class="text-danger">
                    Select {{ errors[0] }}
                  </label>
                </validation-provider>
              </b-col>
              <b-col v-if="disputeData.type === 2" lg="4">
                <label>Observation message:</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="required"
                  rules="required"
                >
                  <b-select
                    v-model="observationSelected"
                    :options="optionsObservations"
                    value-field="value"
                    text-field="name"
                  />
                  <label v-if="errors[0]" class="text-danger">
                    Select Email {{ errors[0] }}
                  </label>
                </validation-provider>
              </b-col>
              <b-col v-if="disputeData.type === 2" lg="4">
                <label>Email of access credentials:</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-select
                    v-model="disputeData.email"
                    :options="optionsEmail"
                    value-field="id"
                    text-field="user"
                  />
                  <label v-if="errors[0]" class="text-danger">
                    Select Email {{ errors[0] }}
                  </label>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col>
                <label>Observation:</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Observation"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="insertObservationMessage"
                    style="min-height: 184px"
                    disabled
                  />
                  <label v-if="errors[0]" class="text-danger">
                    Observation {{ errors[0] }}
                  </label>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>

        <b-col lg="6">
          <div class="my-1" v-if="disputeData.type === 1">
            <span>Upload files:</span>
            <b-row
              v-for="(bureau, i) in bureausOptions"
              :key="i"
              size="lg"
              class="my-1 col-12 rounded border text-center"
            >
              <b-col class="mx-1 px-3 col-12"
                ><img :src="bureau.img" style="width: 30px" /><br />
                <div class="col-12">
                  <drag-and-drop
                    v-if="bureau.value == 'equifax'"
                    class="custom"
                    ref="filesEquifax"
                    v-model="filesEquifax"
                    source="equifax"
                    :files-array="files"
                    @delete-file="deleteFile"
                  />
                  <drag-and-drop
                    v-else-if="bureau.value == 'experian'"
                    class="custom"
                    ref="filesExperian"
                    v-model="filesExperian"
                    source="experian"
                    :files-array="files"
                    @delete-file="deleteFile"
                  />
                  <drag-and-drop
                    v-else
                    class="custom"
                    ref="filesTransunion"
                    v-model="filesTransunion"
                    source="transunion"
                    :files-array="files"
                    @delete-file="deleteFile"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="!disputeData.type">
            Awaiting for selection of the type of request.
          </div>
          <div v-if="!bureauSelected && disputeData.type === 1">
            Awaiting for selection of the bureau.
          </div>

          <b-row v-if="disputeData.type === 2">
            <b-col>
              <drag-and-drop
                v-model="files"
                source="files"
                :files-array="files"
                @delete-file="deleteFile"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-end">
      <b-button 
        variant="primary" 
        @click="insertDispute" 
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'">
        <b-spinner v-if="spinnerOn" small />
        SAVE
      </b-button>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
// eslint-disable-next-line import/extensions
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
import { amgApi } from "@/service/axios";
import { mapGetters, mapActions } from "vuex";

//logo bureaus
import equifax from "@/assets/images/icons/equifax.png";
import experian from "@/assets/images/icons/experian.png";
import transunion from "@/assets/images/icons/transunion.png";

export default {
  components: {
    DragAndDrop,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      spinnerOn: false,
      disputeData: {
        type: 1,
      },
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      optionsEmail: [],
      optionsType: [
        { name: "LETTER", value: 1 },
        { name: "RECOVERY", value: 2 },
      ],
      deletedFiles: [],
      idDrag: 1,
      observationSelected: null,
      observation: "",
      optionsObservations: [
        { value: null, name: "Select a Observation", disabled: true },
        { value: 1, name: "letter was not received" },
        { value: 2, name: "letter lost or stolen" },
        { value: 3, name: "letter not sent" },
      ],
      bureausOptions: [
        { value: "equifax", img: equifax },
        { value: "experian", img: experian },
        { value: "transunion", img: transunion },
      ],
      bureauSelected: "equifax",
      filesEquifax: [],
      filesExperian: [],
      filesTransunion: [],
      modalCreateApplications: false,
      modalCreateNcr: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    hasFiles() {
      return (
        this.filesEquifax.length > 0 ||
        this.filesExperian.length > 0 ||
        this.filesTransunion.length > 0
      );
    },

    insertObservationMessage() {
      this.observation = "";
      if (this.disputeData.type === 1) {
        this.observation = "PLEASE ANALIZE THE LETTERS";
      } else if (this.disputeData.type === 2) {
        switch (this.observationSelected) {
          case 1:
            this.observation = "LETTER WAS NOT RECEIVED";
            break;
          case 2:
            this.observation = "LETTER LOST OR STOLEN";
            break;
          case 3:
            this.observation = "LETTER N0T SENT";
            break;
        }
      }
      return this.observation;
    },
  },
  watch: {
    bureauSelected() {
      if (this.filesEquifax.length > 0) {
        this.filesEquifax = [];
        this.$refs["filesEquifax"].removeAllFiles();
      }
      if (this.filesExperian.length > 0) {
        this.filesExperian = [];
        this.$refs["filesExperian"].removeAllFiles();
      }
      if (this.filesTransunion.length > 0) {
        this.filesTransunion = [];
        this.$refs["filesTransunion"].removeAllFiles();
      }
    },
  },
  async created() {
    this.ownControl = true;
    await this.search();
  },
  methods: {
    ...mapActions({
      A_SET_UPDATED_REQUEST: "DebtSolutionClients/A_SET_UPDATED_REQUEST",
    }),
    async insertDispute() {
      if (!this.hasFiles && this.disputeData.type == 1) {
        this.showInfoSwal("Insert a file to continue");
        return;
      }
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const formData = new FormData();

          this.addPreloader();

          if (this.disputeData.type == 1) {
            this.filesEquifax.forEach((file) => {
              formData.append("files_eq[]", file, file.name);
            });
            this.filesExperian.forEach((file) => {
              formData.append("files_ex[]", file, file.name);
            });
            this.filesTransunion.forEach((file) => {
              formData.append("files_tr[]", file, file.name);
            });
          } else {
            this.files.forEach((file) => {
              formData.append("files[]", file, file.name);
            });
          }

          formData.append("module_id", this.currentUser.modul_id);
          formData.append("letter_id", this.data.id);
          formData.append("type", this.disputeData.type);
          formData.append(
            "email",
            this.disputeData.email ? this.disputeData.email : 0
          );
          formData.append("obs", this.insertObservationMessage);
          formData.append("user_id", this.currentUser.user_id);
          formData.append(
            "account_id",
            this.$route.params.idClient || this.data.client_account_id
          );
          formData.append("bureau", this.convertBureau(this.bureauSelected));
          const headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // eslint-disable-next-line func-names
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(this),
          };
          const response = await amgApi.post(
            "dispute/file-dispute-drag-drop",
            formData,
            headers
          );
          if (response.status === 200) {
            this.uploadFileModal = false;
            this.files = [];
            this.idDrag++;
            this.$emit("refresh");
            this.showSuccessSwal();
            this.A_SET_UPDATED_REQUEST(1);
            this.closeModal();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async search() {
      try {
        const params = {
          client_id: this.$route.params.idClient,
        };
        const data = await ClientsOptionsServices.getCredentialEmail(params);

        this.optionsEmail = data.data;

        return this.optionsEmail;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    closeModal() {
      this.$emit("close");
    },
    deleteFile(index, file) {
      this.customer.files.splice(index, 1);
      this.deletedFiles.push(file.id);
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
    },
    convertBureau(data) {
      let bureau = {
        transunion: 1,
        experian: 2,
        equifax: 3,
      };
      return bureau[data] || null;
    },
  },
};
</script>

<style scoped >
* {
  --primary-color: #3f7afa;
}
.custom >>> .uploader {
  border: none !important;
  padding: 0 !important;
}
.custom >>> img {
  padding: 0 !important;
  margin: 5px 0 !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
</style>
