<template>
  <b-modal
    v-model="onControl"
    title="Edit Public Records"
    modal-class="modal-primary"
    title-tag="h3"
    scrollable
    size="md"
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col cols="12">
          <validation-provider v-slot="{ errors }" name="pr" rules="required">
            <label>{{ itemPr.content[0].key }}:</label>
            <b-form-input v-model="publicRecord" />
            <span v-if="errors[0]" class="text-danger">
              {{ itemPr.content[0].key }} {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider v-slot="{ errors }" name="cs" rules="required">
            <label>{{ itemPr.content[1].key }}:</label>
            <b-form-input v-model="courtCase" />
            <span v-if="errors[0]" class="text-danger">
              {{ itemPr.content[0].key }} {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <label>Status:</label>
          <b-form-select
            v-model="itemPr.status"
            :options="statuses"
            text-field="value"
            value-field="id"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-button variant="success" @click="saveEdit"> Save </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapActions } from "vuex";
export default {
  props: {
    itemPr: null,
    userId: null,
  },

  data() {
    return {
      publicRecord: null,
      courtCase: null,
      status: null,
      statuses: null,
      onControl: false,
    };
  },
  created() {
    this.onControl = true;
    this.getStatuses();
    this.start();
  },

  methods: {
    start() {
      this.publicRecord = this.itemPr.content[0].value;
      this.courtCase = this.itemPr.content[1].value;
      this.status = this.itemPr.status;
    },

    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),
    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data;
        }
        this.statuses = this.statuses.filter((status) => status.id !== 1);
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
    async saveEdit() {
      this.addPreloader();
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          let params = {
            id: this.itemPr.cr_accounts_id,
            date: this.itemPr.date,
            bureau_id: this.itemPr.bureau,
            court: this.courtCase,
            publicr: this.publicRecord,
            status: this.itemPr.status,
            value: 1,
            iduser: this.userId,
            instruction: null,
            reason: null,
          };
          const data = await ClientsOptionsServices.editSingleAccountReportPr(
            params
          );
          if (data.status == 200) {
            this.updateRefreshReport(3);
            this.$emit("close");
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
