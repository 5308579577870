var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-1"},[_c('span',{staticClass:"sub-title-account",class:{ dark: _vm.isDarkSkin }},[_vm._v(" "+_vm._s(_vm.nameTitle)+" ")]),_c('b-table',{attrs:{"items":_vm.value,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center gap-5"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(item.name),expression:"item.name",modifiers:{"hover":true,"left":true}}]},[_vm._v(" "+_vm._s(item.name.length > 32 ? item.name.substring(0, 32) + "..." : item.name)+" ")]),(_vm.type == 'accounts')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Detail of the arguments of the account'),expression:"'Detail of the arguments of the account'",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary cursor-pointer",attrs:{"icon":"HelpCircleIcon","size":"16"},on:{"click":function($event){return _vm.openDetail(item)}}}):_vm._e()],1)]}},{key:"cell(type_classification)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticStyle:{"width":"100px"},attrs:{"variant":_vm.getVariant(item.type_classification)}},[_vm._v(" "+_vm._s(item.type_classification != null ? item.type_classification : "NOT QUALIFIED")+" ")])]}},{key:"cell(classification)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.classification !== null ? item.classification : "NOT QUALIFIED"))])]}},{key:"cell(bureau_id)",fn:function(ref){
var item = ref.item;
return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          item.bureau_id == 1
            ? 'Transunion'
            : item.bureau_id == 2
            ? 'Experian'
            : 'Equifax'
        ),expression:"\n          item.bureau_id == 1\n            ? 'Transunion'\n            : item.bureau_id == 2\n            ? 'Experian'\n            : 'Equifax'\n        ",modifiers:{"hover":true,"top":true}}],staticClass:"mr-1 ml-1",attrs:{"src":item.bureau_id == 1
            ? '/assets/images/icons/transunion.png'
            : item.bureau_id == 2
            ? '/assets/images/icons/experian.png'
            : '/assets/images/icons/equifax.png'}})]}}])}),_c('b-modal',{attrs:{"modal-class":"custom-modal-amg","title-class":"h3 text-white","size":"timeline","hide-footer":"","centered":"","header-bg-variant":"transparent","header-class":"p-0"},on:{"hidden":function($event){_vm.showDetail = false}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal d-flex justify-content-between"},[_c('h3',{staticClass:"text-white m-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.detailModal.title.length > 32 ? _vm.detailModal.title.substring(0, 32) + "..." : _vm.detailModal.title)+" ")]),_c('div',[_c('feather-icon',{staticClass:"close-button",attrs:{"icon":"XIcon","size":"22"},on:{"click":function($event){_vm.showDetail = false}}})],1)])]},proxy:true}]),model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"sub-title-account"},[_vm._v(" INSTRUCTION ")]),_c('span',{staticClass:"text-justify ml-1"},[_vm._v(" "+_vm._s(_vm.detailModal.instruction)+" ")]),_c('span',{staticClass:"sub-title-account mt-1"},[_vm._v(" REASON ")]),_c('span',{staticClass:"text-justify ml-1"},[_vm._v(" "+_vm._s(_vm.detailModal.reason)+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }