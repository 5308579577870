<template>
  <b-modal
    title="Scores"
    v-model="showModal"
    @hidden="closeModal"
    size="xlg"
    hide-footer
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">Scores</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal()"
          />
        </div>
      </div>
    </template>
    <AllScores :lead_id="lead_id" :stickySize="'60'" />
  </b-modal>
</template>
<script>
import AllScores from "@/views/commons/components/clients/dashboard/options/report-module/components/AllScores.vue";
export default {
  components: {
    AllScores,
  },
  props: {
    lead_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
