<template>
  <div>
    <div v-if="data.length" >
        <header-grid-report />

        <div class="container-report pt-1 px-1" ref="container-report">
          <div
            class="container-grid mb-1"
            v-for="(items, index) in data"
            :key="index"
          >
            <div
              class="shadow w-auto p-2 rounded position-relative"
              :style="
                isDarkSkin
                  ? 'background: #17171A; color:white'
                  : 'background: #fff'
              "
              v-for="(account, index) in items"
              :key="index"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100 text-muted"
                v-if="account.empty"
              >
                <feather-icon
                  icon="SlashIcon"
                  size="16"
                  class="cursor-pointer text-end text-info text-muted"
                  style="margin-right: 3px"
                />
                <p class="m-0">NO CONTENT</p>
              </div>
              <div
                class="d-flex justify-content-start pr-3"
                v-for="(infoAc, index) in account.content"
                :key="index"
              >
                <span class="mr-1 font-weight-bolder">{{ infoAc.key }}: </span>
                <span
                  class="text-truncate"
                  v-b-tooltip.hover.top="infoAc.value"
                >
                  {{ infoAc.value }}
                </span>

                <div
                  class="action-data"
                  v-if="
                    isEnabledForEditAndDelete &&
                    !isFromModalFinancial &&
                    !account.empty
                  "
                >
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        v-b-tooltip.hover.right="'Actions'"
                        class="align-middle text-info"
                      />
                    </template>
                    <b-dropdown-item
                      v-b-modal.modal-primary
                      @click="openModalTracking(account)"
                    >
                      <feather-icon
                        icon="ListIcon"
                        size="16"
                        class="mr-1 cursor-pointer text-end text-info"
                      />
                      <span class="align-middle">View Tracking</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="isSpecialist"
                      v-b-modal.modal-primary
                      @click="editPersonalInfo(infoAc, account)"
                    >
                      <feather-icon
                        size="16"
                        class="mr-1 cursor-pointer text-end text-warning"
                        icon="EditIcon"
                      />
                      <span class="align-middle">Edit Account</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="isSpecialist"
                      v-b-modal.modal-primary
                      @click="deletePersonalInfo(account.id)"
                    >
                      <feather-icon
                        size="16"
                        class="mr-1 cursor-pointer text-danger"
                        icon="TrashIcon"
                      />
                      <span class="align-middle">Delete Account</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <b-badge
                class="float-right mt-1"
                v-if="!account.empty"
                :variant="getColorStatus(account.status)"
              >
                {{ account.status_name }}
              </b-badge>
            </div>
          </div>
        </div>
      <edit-personal-information-modal
        v-if="editPIModalController"
        :user-id="currentUser.user_id"
        :pi-item="itemPi"
        @reload="getPersonalInformationReport"
        @close="editPIModalController = false"
      />
      <modal-tracking-letter
        v-if="modalTrackingController"
        :pItem="itemPi"
        :client-name="client.client_name"
        :account="client.account"
        @close="closeModalTracking"
      />
    </div>
    <div v-else class="text-center py-5">
      There are no records to show
    </div>
  </div>
</template>

<script>
// components
import EditPersonalInformationModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditPersonalInformationModal.vue";
import ModalTrackingLetter from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalTrackingLetter.vue";
import HeaderGridReport from "@/views/commons/components/clients/dashboard/options/report-module/components/HeaderGridReport.vue";

// Services
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ClientReportService from "@/views/commons/components/clients/dashboard/services/client.report.js";

import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    clientId: {
      type: String,
    },
    scoreId: {
      type: Number,
      required: true
    }
  },
  components: {
    EditPersonalInformationModal,
    ModalTrackingLetter,
    HeaderGridReport,
  },
  data() {
    return {
      data: [],
      itemPi: null,
      editPIModalController: false,
      iconIsDown: false,
      busy: true,
      modalTrackingController: false,
    };
  },

  async created() {
    await this.getPersonalInformationReport();
  },

  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),

    async getPersonalInformationReport() {
      try {
        this.addPreloader();
        let params = {
          idClientAccount: this.$route.params.idClient || this.clientId,
          idScore: this.scoreId
        };
        const data = await ClientReportService.getPersonalInformationByScore(params);
        if (data.status == 200) {
          this.$emit("uploadedData");
        }
        this.data = data.data;
        this.busy = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something has gone wrong with the report!"
        );
      } finally {
        this.removePreloader();
      }
    },

    async editPersonalInfo(info, account) {
      let data = {
        id: account.id,
        status: account.status,
        content: info.value,
        bureau_id: 1,
        status_name: account.status_name,
      };

      this.itemPi = data;
      this.editPIModalController = true;
    },
    async deletePersonalInfo(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteDelteAccountPi({
            iduser: this.currentUser.user_id,
            id,
          });
          if (data.status === 200) {
            this.showSuccessSwal("Public record has been deleted successfully");
            await this.getPersonalInformationReport();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    getColorStatus(number) {
      let setColorStatus = {
        1: "light-warning",
        2: "light-danger",
        5: "light-primary",
        6: "light-danger",
      };

      return setColorStatus[number] || "light-success";
    },

    async removeExceptDelete() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.deleteForException({
            account_id: this.$route.params.idClient || this.clientId,
            id_user: this.currentUser.user_id,
            type: 3,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            await this.getPersonalInformationReport();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    openModalTracking(account) {
      this.itemPi = account;
      this.modalTrackingController = true;
    },

    closeModalTracking() {
      this.modalTrackingController = false;
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      getRefreshReport: "ReportStore/G_REFRESH_REPORT",
    }),

    isFromModalFinancial() {
      return this.moduleId == 23;
    },

    isEnabledForEditAndDelete() {
      return (
        this.currentUser.role_id == 1 ||
        ((this.currentUser.role_id == 2 || this.currentUser.role_id == 3 || this.currentUser.role_id == 22))
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
  },

  watch: {
    async getRefreshReport(state) {
      if (state == 1) {
        await this.getPersonalInformationReport();
        this.updateRefreshReport(0);
      }
    },
  },
};
</script>

<style scoped>
*{
  --light-bg-color: #f1f1f1;
  --light-bg-color2: #fafafa;
  --dark-bg-color1: #232328;
  --dark-bg-color2: #17171A;
}
.dark-bg-card{
  background-color: var(--dark-bg-color2) !important;
  border-radius: 10px;
}
.light-bg-card{
  background-color: var(--light-bg-color2) !important;
  border-radius: 10px;
}
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 1em;
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.container-report {
  overflow-y: auto;
}

.action-data {
  position: absolute;
  right: -10px;
  top: 0;
}

.icon-collapse {
  position: absolute;
  right: 15px;
  top: 25%;
}

.btn-delete-all {
  position: absolute;
  left: 15px;
  top: 25%;
}
</style>
