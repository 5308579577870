<template>
  <b-modal
    v-model="visible"
    modal-class="modal-primary"
    no-close-on-backdrop
    centered
    size="md"
    :title="action == 1 ? 'Add Charge' : 'Paid: No'"
    title-tag="h3"
    :header-bg-variant="action == 1 ? 'success' : 'danger'"
    @hidden="close"
    no-close-on-esc
  >
    <template #default>
      <account-client-header :account="account" :client="client_name" />
      <b-row v-if="action == 1">
        <b-col :cols="8">
          <b-form-group label="Concept">
            <v-select
              v-model="chargeSelected"
              :options="optionsCharges"
              :reduce="(action) => action.id"
              :disabled="true"
              label="charge"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="4">
          <validation-observer ref="amount">
            <validation-provider
              rules="required|min_value:1"
              v-slot="{ errors, pass }"
            >
              <b-form-group label="Amount">
                <money
                  v-bind="vMoney"
                  v-model="suggest_charge"
                  class="form-control"
                  :class="errors[0] && shouldValidate ? 'border-danger' : ''"
                />
              </b-form-group>
              <span v-if="errors[0] && shouldValidate" class="text-danger"
                >The amount must be greater than zero</span
              >
              <pre>{{ pass }}</pre>
            </validation-provider>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row v-if="action == 2">
        <b-col>
          <validation-observer ref="form">
            <validation-provider
              v-slot="{ errors }"
              name="account_type"
              rules="required"
            >
              <b-form-group label="Comment:">
                <b-form-textarea
                  v-model="comment"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </validation-observer>
        </b-col>
      </b-row>
      <div
        v-if="action == 1"
        class="w-100 d-flex flex-column justify-content-center"
      >
        <b-row class="justify-content-start pl-3">
          <b-form-group label="Method Payment">
            <b-form-radio-group
              v-model="methodPaymentSelected"
              :options="optionsMethodsPayment"
            />
          </b-form-group>
        </b-row>
        <b-row
          v-if="methodPaymentSelected != 3 && methodPaymentSelected"
          class="justify-content-start pl-3"
        >
          <b-form-group label="Type Payment">
            <b-form-radio-group
              v-model="typePaymentSelected"
              :options="optionsTypePayment"
            />
          </b-form-group>
          <!-- <validation-observer > -->
          <validation-provider
            ref="amountPartialValidator"
            rules="money-1"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Amount Partial"
              v-if="methodPaymentSelected == 2"
            >
              <money
                :class="errors[0] && validAmount ? 'border-danger' : ''"
                v-bind="vMoney"
                v-model="amountPartial"
                class="form-control"
              />
            </b-form-group>
          </validation-provider>
        </b-row>
        <b-row
          v-if="methodPaymentSelected != 3 && typePaymentSelected"
          class="justify-content-center"
        >
          <div v-if="typePaymentSelected == 1">
            <b-row>
              <b-col>
                <validation-observer ref="cardValidator">
                  <b-table
                    :style="[
                      'fontSize:5px',
                      'fontWeight:thin',
                      'overflow-y: scroll',
                    ]"
                    small
                    :items="cardData"
                    :fields="fieldsCards"
                    sticky-header
                  >
                    <template #cell(select)="data">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <b-form-radio
                          v-model="cardSelected"
                          :value="data.item.id"
                          @change="assignIndexSelected(data.index)"
                          :class="errors[0] && validCard ? 'border-danger' : ''"
                        />
                      </validation-provider>
                    </template>
                    <template #cell(cardnumber)="data">
                      <div class="text-center">
                        xxxx-xxxx-xxxx-{{ data.item.cardnumber }}
                      </div>
                    </template>
                    <template #cell(cardsecuritycode)>
                      <div class="text-center">xxx</div>
                    </template>
                    <template #cell(created_by)="data">
                      <b-container>
                        <b-row>{{ data.item.created_by }}</b-row>
                        <b-row>{{
                          data.item.created_at | myGlobalWithHour
                        }}</b-row>
                      </b-container>
                    </template>
                    <template #cell(action)="data">
                      <div class="d-flex justify-content-center mt-1">
                        <feather-icon
                          icon="Trash2Icon"
                          class="text-danger cursor-pointer"
                          size="15"
                          @click="openModalDeleteCard(data.item.id)"
                        />
                      </div>
                    </template>
                  </b-table>
                  <span
                    v-if="!hasCardSelected && validCard"
                    class="text-danger"
                  >
                    Card is required
                  </span>
                </validation-observer>
              </b-col>
            </b-row>
            <div class="d-flex flex-row justify-content-end">
              <b-button variant="primary" @click="openModalCardCreate()">
                <feather-icon icon="PlusIcon" size="20" />
                Add Card
              </b-button>
            </div>
          </div>
          <div v-if="typePaymentSelected == 2" class="px-1">
            <other-payment-options
              ref="otherPaymentOptions"
              :amount="suggest_charge"
              :idClientOrLead="data.ca_id"
              :isClient="true"
              :paymentSelected="typePaymentSelected"
              :idProgram="data.program_id"
              :concept="chargeSelected"
              :idCurrentUser="currentUser.user_id"
              :idModule="moduleId"
              @close="close()"
            ></other-payment-options>
          </div>
        </b-row>
      </div>
      <modal-card-create
        v-if="isActiveModalCreateCard"
        @click="closeModalCardCreate"
        @new="refresCardTable"
        :ifModalCard="isActiveModalCreateCard"
        :idlead="accountData.lead_id"
        :session="currentUser.user_id"
      />
      <delete-card
        :card_id="cardSelected"
        :lead_id="accountData.lead_id"
        :ifModalCard="isActiveModalDeleteCard"
        :session="currentUser.user_id"
        @click="closeModalDeleteCard()"
        @new="refresCardTable"
      />
    </template>
    <template #modal-footer>
      <b-button
        :variant="action == 1 ? 'success' : 'danger'"
        @click="sendData"
        :disabled="isDisable"
      >
        {{ action == 1 ? "Pay" : "Paid: No" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ApplicationsService from "../../../services/applications.service";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import DeleteCard from "@/views/crm/views/sales-made/components/modals/DeleteCardModal.vue";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import AccountClientHeader from "../others/AccountClientHeader.vue";
import SpecialistsCommissionsService from "@/views/specialists/sub-modules/financial-approval/views/commissions/services/financial-commissions.service";
import { mapGetters } from "vuex";
export default {
  components: {
    OtherPaymentOptions,
    ModalCardCreate,
    DeleteCard,
    AccountClientHeader,
  },
  props: {
    action: {
      required: true,
      type: Number,
    },
    data: { required: true, type: Object },
  },
  data() {
    return {
      visible: false,
      methodPaymentSelected: null,
      typePaymentSelected: null,
      optionsCharges: [],
      suggest_charge: "",
      optionsTypePayment: [
        { text: "Credit Card", value: 1 },
        { text: "Others", value: 2 },
      ],
      comment: "",
      cardData: [],
      optionsMethodsPayment: [
        { text: "Direct Payment", value: 1 },
        { text: "Parcial", value: 2 },
        { text: "Fee Charge", value: 3 },
      ],
      shouldValidate: false,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      fieldsCards: [
        { key: "select", label: "" },
        { key: "cardholdername", label: "Card Holder Name" },
        { key: "cardnumber", label: "Card Number" },
        { key: "type_card", label: "Type" },
        { key: "card_expi_month", label: "MM" },
        { key: "card_expi_year", label: "YY" },
        { key: "cardsecuritycode", label: "CVC" },
        { key: "created_by", label: "Created by" },
        { key: "action", label: "Action" },
      ],
      validCard: false,
      validAmount: false,
      cardSelected: null,
      indexCardSelected: null,
      amountPartial: 0,
      isActiveModalCreateCard: false,
      isActiveModalDeleteCard: false,

      accountData: [],
      client_name: "",
      account: "",
    };
  },
  methods: {
    assignIndexSelected(value) {
      this.indexCardSelected = value;
    },
    assignCardData(arr) {
      this.cardData != [] ? (this.cardData = []) : "";
      arr.map((el) => {
        el.cardnumber ? this.cardData.push(el) : "";
      });
    },
    async start() {
      this.suggest_charge = this.data.suggets_charge;
      this.chargeSelected = this.data.type;

      let accountInfo = await ApplicationsService.searchAccount({
        id: this.data.ca_id,
      });
      this.accountData = { ...accountInfo.data[0] };
      let cardInfo = ApplicationsService.searchCardsClient({
        id: this.accountData.lead_id,
      });
      let typesChargesInfo = ApplicationsService.getTypesChargesByClient({
        idClient: this.data.ca_id,
        modul: this.moduleId,
      });
      let types = ApplicationsService.getTypesCharges();

      let result = await Promise.all([cardInfo, typesChargesInfo, types]);

      this.assignCardData(result[0].data);
      this.typesChargesData = [...result[1].data];
      result[2].data.map((el) => {
        this.optionsCharges.push({
          id: el.id,
          charge: el.description,
        });
      });
    },
    async sendData() {
      try {
        const parameters = this.buildParameters();
        this.validAmount = true;
        this.validCard = true;
        // PAID: YES
        if (this.action === 1) {
          const result = await this.showConfirmSwal();
          this.shouldValidate = true;
          const validation = await this.$refs.amount.validate();
          if (!validation) return false;

          // Other Payment
          if (
            this.methodPaymentSelected !== 3 &&
            this.typePaymentSelected === 2
          ) {
            this.sendingData = true;
            await this.saveOtherPayment();
            this.sendingData = false;
            return;
          }

          const isValidAmount = await this.validateAmount();
          const isValidCard =
            this.typePaymentSelected === 1 ? this.cardSelected : "";

          // Direct payment and credit card
          if (this.typePaymentSelected === 1 && isValidCard) {
            if (isValidAmount.valid || this.methodPaymentSelected !== 2) {
              if (result.isConfirmed) {
                await this.saveCharge(parameters);
              }
            }
          }
        }
        // PAID: NO
        else {
          const validate = await this.$refs.form.validate();
          if (validate) {
            const result = await this.showConfirmSwal();
            if (result.isConfirmed) {
              await ApplicationsService.updateStatusApp({
                ...parameters,
              });
            } else {
              return;
            }
          } else {
            return;
          }
        }

        await this.updateStatusApplication();

        // ********************
        if (this.data.commission_type != null && this.data.amount != "0.00") {
          const body = {
            commission_type: this.data.commission_type,
            specialist_id: this.data.specialist_id,
            client_account_id: this.data.ca_id,
            entry_amount: this.data.amount,
          };
          await SpecialistsCommissionsService.createCommissionSpecialists(body);
        }
        // ********************

        this.showSuccessSwal();
        this.$emit("refreshTable");
        this.close();
      } catch (error) {
        if (error.response) {
          if (error.response.status == 422) {
            this.showWarningSwal(error.response.data.errors.t_comment[0]);
          }
        }
      } finally {
        this.removePreloader();
      }
    },

    buildParameters() {
      if (this.action === 1) {
        return {
          id: this.data.ca_id,
          account: this.data.account,
          concept: this.chargeSelected,
          ammount: this.suggest_charge,
          ammount_partial:
            this.methodPaymentSelected === 2
              ? this.amountPartial
              : this.suggest_charge,
          method_payment: this.methodPaymentSelected,
          card_id: this.cardSelected,
          type_payment: this.typePaymentSelected,
          type: this.typeSelect !== 1 ? this.typeSelect : 0,
          specify: this.serialNumber,
          user_id: this.currentUser.user_id,
          notcharges: this.chargeSelected === 5 ? "" : this.data.full_charge,
          programid: this.program,
          type_process: 0,
          modul: this.moduleId,
          merchant:
            this.typePaymentSelected === 1
              ? this.cardData[this.indexCardSelected].type_card
              : null,
          idapp: this.data.app_id,
          nameCharge: this.data.name_charge,
          settlementcost: null,
          idcreditor: null,
          sent_from: "app_main",
        };
      } else {
        return {
          ca_id_client: this.data.ca_id,
          t_id: this.data.app_id,
          iduser: this.currentUser.user_id,
          num: 3,
          t_status: this.action,
          t_comment: this.comment,
        };
      }
    },

    async validateAmount() {
      return this.methodPaymentSelected === 2
        ? await this.$refs.amountPartialValidator.validate()
        : { valid: false };
    },

    async saveCharge(parameters) {
      this.addPreloader();
      try {
        return await ApplicationsService.saveCharge({ ...parameters });
      } finally {
        this.removePreloader();
      }
    },

    async updateStatusApplication() {
      const updateParams = {
        id_app: this.data.app_id,
        clientAccountId: this.data.ca_id,
        userId: this.currentUser.user_id,
      };
      return await ApplicationsService.updateStatusApplication(updateParams);
    },

    close(visible = false) {
      this.$emit("close");
      this.$emit("changePaidOption", null);
      this.visible = visible;
    },
    async saveOtherPayment() {
      this.addPreloader();
      try {
        await this.$refs.otherPaymentOptions.saveOtherPayment();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    refresCardTable(data) {
      this.assignCardData(data);
    },
    openModalCardCreate() {
      this.isActiveModalCreateCard = true;
    },
    closeModalCardCreate(visible = false) {
      this.isActiveModalCreateCard = visible;
    },
    openModalDeleteCard(cardId) {
      this.cardSelected = cardId;
      this.isActiveModalDeleteCard = true;
    },
    closeModalDeleteCard(visible = false) {
      this.isActiveModalDeleteCard = visible;
    },
  },
  computed: {
    hasCardSelected() {
      return this.cardSelected ? true : false;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      program: "DebtSolutionApplications/program",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isDisable() {
      if (this.action !== 1) {
        return this.comment === "";
      }
      if (!this.methodPaymentSelected) {
        return true;
      } else {
        if (this.typePaymentSelected == 2) {
          return false;
        }
      }

      if (this.methodPaymentSelected === 3) {
        return false;
      }

      if (this.typePaymentSelected === 1) {
        return false;
      }

      return (
        !(this.typeSelect && this.serialNumber) &&
        !(
          this.methodPaymentSelected === 2 &&
          this.amountPartial &&
          this.typePaymentSelected
        )
      );
    },
  },
  async created() {
    this.visible = true;
    await this.start();
  },

  mounted() {
    this.account = this.data.account
      ? this.data.account
      : this.data.client_account;
    this.client_name = this.data.name_client
      ? this.data.name_client
      : this.data.client_name;
  },
};
</script>
