export default [
  {
    key: "type_method",
    sortable: false,
    label: "Type Dispute",
    visible: true,
  },
  {
    key: "round",
    sortable: false,
    visible: true,
    label: "Round",
  },
  {
    key: "bureaus",
    sortable: false,
    label: "Bureaus",
    visible: true,
  },
  {
    key: "format",
    sortable: false,
    label: "Format",
    visible: true,
  },
  {
    key: "status",
    sortable: false,
    label: "Status",
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },

  {
    key: "tracking",
    sortable: false,
    label: "Tracking",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false,
  },
  {
    key: "created_by",
    sortable: false,
    label: "Created By",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "updated_by",
    sortable: false,
    label: "Updated By",
    visible: false,
  },
  {
    key: "actions",
    sortable: false,
    label: "Actions",
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "read",
    sortable: false,
    label: "Read",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "dispute_results",
    sortable: false,
    label: "Update Request",
    visible: false,
    thStyle: { textAlign: "center" },
  },
];
