<template>
  <b-row class="p-1">
    <b-col md="12">
      <div class="card m-0 p-1">
        <div class="accounts-round">
          <template v-for="(item, index) in dataRounds">
            <div
              class="sub-title d-flex gap-5"
              :class="{ dark: isDarkSkin }"
              :key="index"
            >
              <span>{{
                [1, 2].includes(item.type_send)
                  ? `${item.title}`
                  : `DIRECT`
              }}</span>
              <b-badge :variant="colorsState(item.state)">
                {{ nameState(item.state) }} | {{ item.date_send | myGlobal }}
              </b-badge>
              <b-badge variant="warning" class="ml-1" v-if="item.date_send">
                Expiration Date:
                {{ add45Days(item.date_send) | myGlobal }}
              </b-badge>
              <feather-icon
                icon="ListIcon"
                size="18"
                variant="info"
                class="ml-1 cursor-pointer"
                @click="openTrackingRound(item.id)"
              />
            </div>
            <template v-if="item.accounts.length > 0">
              <RoundParticle
                :value="item.accounts"
                :round="item"
                :key="index + 'accounts'"
                :type="'accounts'"
              />
            </template>
            <template v-if="item.inquiries.length > 0">
              <RoundParticle
                :value="item.inquiries"
                :round="item"
                :key="index + 'inquiries'"
                :type="'inquiries'"
              />
            </template>
            <template v-if="item.public_records.length > 0">
              <RoundParticle
                :value="item.public_records"
                :round="item"
                :key="index + 'public_records'"
                :type="'public_records'"
              />
            </template>
            <template v-if="item.personal_information.length > 0">
              <RoundParticle
                :value="item.personal_information"
                :round="item"
                :key="index + 'personal_information'"
                :type="'personal_information'"
              />
            </template>
          </template>
        </div>
      </div>
    </b-col>
    <!-- <b-col md="5">
      <div class="card m-0 p-1" style="height: 100%">
        <div class="sub-title" :class="{ dark: isDarkSkin }">SERVICES</div>
        <div
          v-if="services.length == 0"
          class="d-flex flex-column justify-content-center align-items-center h-100"
        >
          <span>No services </span>
          <b-button variant="primary" disabled class="mt-1">
            Add Service
          </b-button>
        </div>
      </div>
    </b-col> -->
    <modal-tracking-letter-round
      v-if="showTrackingRound"
      :dataClient="dataRound"
      @hideModal="showTrackingRound = false"
    />
  </b-row>
</template>

<script>
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import RoundParticle from "@/views/commons/components/clients/dashboard/options/dis-module/round/RoundParticle.vue";
import ModalTrackingLetterRound from "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/modals/ModalTrackingLetterRound.vue";

export default {
  components: {
    RoundParticle,
    ModalTrackingLetterRound,
  },
  props: {
    ncrId: {
      type: String,
    },
    round: {
      type: Object,
    },
    typeDispute: {
      type: Number,
    },
    clientData: {
      type: Object,
    }
  },
  data() {
    return {
      showTrackingRound: false,
      dataRound: {
        id: null,
        client_name: null,
        client_account: null,
      },
      dispute: {},
      dataRounds: [],
      services: [],
    };
  },
  async mounted() {
    if (this.round) {
      const data = await this.showRound(this.round);
      this.dataRounds.push(data);
      this.dataRounds.sort((a, b) => {
        return a.title - b.title;
      });
    } else {
      this.openRoundDetails();
    }
  },
  methods: {
    openTrackingRound(value) {
      this.dataRound.id = value;
      this.dataRound.client_account = this.clientData.account;
      this.dataRound.client_name = this.clientData.client_name;
      this.showTrackingRound = true;
    },
    async openRoundDetails() {
      const data = await RequestWorkPlanService.getRoundDetailDispute({
        id: this.ncrId,
      });
      for await (const element of data.filter((x) =>
        this.typeDispute == 1
          ? [3, 4, 5].includes(x.type_send)
          : [1, 2].includes(x.type_send)
      )) {
        let round = await this.showRound(element);
        this.dataRounds.push(round);
      }
    },

    async showRound(round) {
      const data = await RequestWorkPlanService.getRoundDetails({
        id: round.id,
      });
      return {
        type_send: round.type_send,
        state: round.state,
        title: round.title,
        date_send: round.date_send,
        id: round.id,
        personal_information: this.processData(data.personal_information, 1),
        inquiries: this.processData(data.inquiries, 2),
        public_records: this.processData(data.public_records, 3),
        accounts: this.processData(data.accounts, 4),
      };
    },
    processData(arr, num) {
      const keys = {
        1: "pi",
        2: "in",
        3: "pr",
        4: "ac",
      };
      arr.forEach((element) => {
        element.key = keys[num];
      });
      return arr;
    },
    nameState(state) {
      const names = {
        1: "Pending",
        2: "In Progress",
        5: "In Correspondence",
        7: "Sent",
        11: "Expired",
      };
      return names[state] || "Pending";
    },
    colorsState(state) {
      const colors = {
        1: "light-warning",
        2: "light-primary",
        5: "light-info",
        7: "light-success",
        11: "light-danger",
      };
      return colors[state] || "light-warning";
    },
    add45Days(date) {
      let newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 45);
      return newDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-title {
  font-size: 1rem;
  font-weight: bold;
  color: #0077e6;
  margin: 5px 0;
  &.dark {
    color: #fff;
  }
}
.border-right {
  border-right: 1px solid #d5d1d1;
}
.accounts-round {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
</style>
