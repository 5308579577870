<template>
  <div style="text-align: center">
    <div v-if="validatePaid && validateRole">
      <span
        v-if="!statusPending.value"
        :class="['cursor-pointer', StatusColorText(data.item.status_paid)]"
        @click="changeStatusPending(data.index)"
      >
        {{ status }}
      </span>
      <div v-if="statusPending.value" style="display: inline-flex">
        <b-form-select
          v-model="actionSelected"
          :options="options"
          @change="openModal"
        />
        <feather-icon
          icon="XIcon"
          class="text-danger cursor-pointer"
          size="20"
          @click="changeStatusPending(data.index)"
        />
      </div>
    </div>
    <span
      v-if="validatePaid && !validateRole"
      :class="['cursor-default', StatusColorText(data.item.status_paid)]"
    >
      {{ status }}
    </span>
    <span
      v-if="statusPaid >= 1"
      :class="['cursor-default', StatusColorText(data.item.status_paid)]"
    >
      {{ status }}
    </span>
    <b-row v-if="action == 2">
      <b-col>
        <validation-observer ref="form">
          <validation-provider
            v-slot="{ errors }"
            name="account_type"
            rules="required"
          >
            <b-form-group label="Comment:">
              <b-form-textarea
                v-model="comment"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>

    <add-charge-others-modal
      v-if="activeModal"
      :action="action"
      @close="closeModal"
      :data="dataClient"
      @refreshTable="refreshTable"
      :allPaymentMethods="false"
      :sendFrom="sendFrom"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalAction from "../modals/ModalAction.vue";
import ModalPaid from "@/views/commons/components/applications/views/components/modals/ModalPaid.vue";
import AddChargeOthersModal from "@/views/commons/components/other-services/view/component/modal/AddChargeModal.vue";

export default {
  components: { ModalAction, ModalPaid, AddChargeOthersModal },
  props: {
    data: { type: Object, required: true },
    options: { type: Array, required: true },
  },
  created() {
    this.dataClient.account_id = this.data.item.ca_id;
    this.dataClient.client_account = this.data.item.account;
    this.dataClient.client_name = this.data.item.name_client;
    this.dataClient.type_description = this.data.item.full_charge;
    this.dataClient.id = this.data.item.app_id;
    this.dataClient.lead_id = this.data.item.lead_id;
    this.dataClient.charge_description = this.data.item.name_charge;
    this.dataClient.account = this.data.item.account;
    this.dataClient.type_id = this.data.item.type;
  },
  data() {
    return {
      activeModal: false,
      statusPending: {
        id: 0,
        value: false,
      },
      actionSelected: 0,
      action: null,
      sendFrom: "app_main",
      dataClient: {},
    };
  },
  methods: {
    turnActionSelection(value) {
      this.actionSelected = value;
    },
    refreshTable() {
      this.statusPending.value = false;
      this.$emit("refreshTable");
    },
    closeModal() {
      this.activeModal = false;
    },
    openModal() {
      this.action = this.actionSelected;
      this.actionSelected = null;
      this.activeModal = true;
    },
    changeStatusPending(id) {
      if (this.data.item.status_paid == 0) {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = id;
      }
      this.turnActionSelection(null);
    },
    StatusColorText(status) {
      switch (status) {
        case 0:
          return "text-warning";
          break;
        case 1:
          return "text-success";
          break;
        case 2:
          return "text-danger";
          break;
        default:
          return "bg-secondary text-light";
          break;
      }
    },
  },
  computed: {
    status() {
      switch (this.statusPaid) {
        case 0:
          return "PENDING";
          break;
        case 1:
          return "YES";
          break;
        case 2:
          return "NO";
          break;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    doneBank() {
      return this.data.item.done_bank;
    },
    statusResult() {
      return this.data.item.status_result;
    },
    statusPaid() {
      return this.data.item.status_paid;
    },
    roleId() {
      return this.currentUser.role_id;
    },
    validatePaid() {
      return (
        this.statusResult === 1 && this.statusPaid === 0 && this.doneBank === 1
      );
    },
    validateRole() {
      return !this.inSpecialist || this.roleId === 8;
    },
    moduleForSpecialists() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    inSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
  },
};
</script>
