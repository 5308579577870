<template>
  <b-card class="mb-1">
    <div class="float-right">
      <b-button
        v-if="section == 'update'"
        class="mr-1 text-uppercase"
        variant="primary"
        @click="openModalAddAccounts"
      >
        <feather-icon icon="PlusIcon" />
        ADD ACCOUNTS
      </b-button>
      <b-button
        v-if="section == 'update' && rowData.client_program == 3"
        @click="openAddedAccountsModal"
        variant="primary"
        title="New items"
      >
        <feather-icon 
          icon="EyeIcon" 
          :badge="addAccountCounter == 0 ? '' : addAccountCounter"
          badge-classes="badge-danger"
        />
      </b-button>
    </div>
    <b-table
      class="position-relative table-new-customization tableDisputeReport mt-2 w-100"
      hover
      :items="accounts"
      :fields="visibleFields"
      details-td-class="p-0"
      show-empty
      :sticky-header="'65vh'"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #empty="scope">
        <h4 class="text-center">{{ scope.emptyText }}</h4>
      </template>
      <template #head(transunion)>
        <b-img
          :src="require('@/assets/images/icons/transunion.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'TRANSUNION'"
        />
      </template>
      <template #head(experian)>
        <b-img
          :src="require('@/assets/images/icons/experian.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'EXPERIAN'"
        />
      </template>
      <template #head(equifax)>
        <b-img
          :src="require('@/assets/images/icons/equifax.png')"
          class="custom-img"
          v-b-tooltip.hover.top="'EQUIFAX'"
        />
      </template>
      <template #cell(id)="{ index }">
        <div>
          {{ index + 1 }}
        </div>
      </template>
      <template #cell(transunion)="{ item, field }">
        <DataBureau
          :section="section"
          :item="filterObjectBureau(item.items, field.key)"
          :bureau="field.key"
          :selectedElements="selectedElements"
          :isForSpecialist="isForSpecialist"
          @changeStatus="changeStatus"
          @toSelected="toSelected"
          @openDisputeDetailsModal="openDisputeDetailsModal"
          @openModalTracking="openModalTracking"
          @editAccount="editAccount"
          @deleteAccount="deleteAccount"
        />
      </template>
      <template #cell(experian)="{ item, field }">
        <DataBureau
          :section="section"
          :item="filterObjectBureau(item.items, field.key)"
          :bureau="field.key"
          :selectedElements="selectedElements"
          :isForSpecialist="isForSpecialist"
          @changeStatus="changeStatus"
          @toSelected="toSelected"
          @openDisputeDetailsModal="openDisputeDetailsModal"
          @openModalTracking="openModalTracking"
          @editAccount="editAccount"
          @deleteAccount="deleteAccount"
        />
      </template>
      <template #cell(equifax)="{ item, field }">
        <DataBureau
          :section="section"
          :item="filterObjectBureau(item.items, field.key)"
          :bureau="field.key"
          :selectedElements="selectedElements"
          :isForSpecialist="isForSpecialist"
          @changeStatus="changeStatus"
          @toSelected="toSelected"
          @openDisputeDetailsModal="openDisputeDetailsModal"
          @openModalTracking="openModalTracking"
          @editAccount="editAccount"
          @deleteAccount="deleteAccount"
        />
      </template>
      <template #cell(accounts)="{ item }">
        <div class="row d-flex" style="width: 250px">
          <div
            class="d-flex col-10 flex-column justify-content-start text-capitalize text-center"
          >
            <div
              class="d-flex flex-column text-center justify-content-center font-size-xmd font-weight-bolder text-truncate"
              v-b-tooltip:hover
              :title="item.creditor_name"
            >
              {{ item.creditor_name }}
            </div>
            <span
              class="w-100 mt-50 text-capitalize px-1 text-truncate"
              :class="isDarkSkin ? 'text-white' : 'text-secondary'"
              style="font-size: 12px"
              v-b-tooltip:hover
              :title="getCreditorAccount(item.items)"
            >
              Account #: {{ getCreditorAccount(item.items) }}
            </span>
          </div>
          <div
            class="d-flex col-2 align-items-center"
            v-if="section == 'update'"
          >
            <b-badge
              variant="light-danger"
              class="hover-me cursor-pointer ml-1"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.hover.right="'REMOVE ACCOUNT'"
            >
              <feather-icon
                icon="TrashIcon"
                style="height: 15px; width: 15px"
                @click="deleteAccountAll(item.account_id, item.creditor_name)"
              />
            </b-badge>
          </div>
        </div>
      </template>
      <template #cell(classification_ncr)="{ item }">
        <div class="d-flex justify-content-center align-items-center h-100">
          <h6
            class="text-uppercase font-weight-bolder text-center"
            :style="`color: ${textColor[item.classification_ncr]}`"
          >
            {{ item.classification_ncr || "NOT QUALIFIED" }}
          </h6>
        </div>
      </template>
      <template #cell(detail)="{ toggleDetails, detailsShowing }">
        <div @click="toggleDetails">
          <feather-icon
            icon="ChevronDownIcon"
            class="hover-me cursor-pointer"
            size="22"
            :class="{ 'rotate-180': detailsShowing }"
          />
        </div>
      </template>
      <template #row-details="data">
        <transition name="fade">
          <b-table
            thead-class="d-none"
            :items="transformData(data.item.items)"
            :fields="visibleFields"
            class="tableDetailsEffectivity"
            :class="isDarkSkin ? 'dark-bg' : ''"
          >
            <template #cell(experian)="{ value }">
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(label, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">
                      <template
                        v-if="
                          [
                            'balance',
                            'monthly_payment',
                            'credit_limit',
                            'h_credit',
                          ].includes(label.key)
                        "
                      >
                        ${{
                          isNaN(value[label.key])
                            ? 0
                            : value[label.key] | currency
                        }}
                      </template>
                      <template v-else>
                        {{
                          value.hasOwnProperty(label.key)
                            ? value[label.key]
                            : "-"
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(transunion)="{ value }">
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(label, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">
                      <template
                        v-if="
                          [
                            'balance',
                            'monthly_payment',
                            'credit_limit',
                            'h_credit',
                          ].includes(label.key)
                        "
                      >
                        ${{
                          isNaN(value[label.key])
                            ? 0
                            : value[label.key] | currency
                        }}
                      </template>
                      <template v-else>
                        {{
                          value.hasOwnProperty(label.key)
                            ? value[label.key]
                            : "-"
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(equifax)="{ value }">
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex justify-content-center align-items-center justify-content-center text-center text-capitalize"
                  v-for="(label, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important" class="text-center">
                    <span class="text-truncate w-100">
                      <template
                        v-if="
                          [
                            'balance',
                            'monthly_payment',
                            'credit_limit',
                            'h_credit',
                          ].includes(label.key)
                        "
                      >
                        ${{
                          isNaN(value[label.key])
                            ? 0
                            : value[label.key] | currency
                        }}
                      </template>
                      <template v-else>
                        {{
                          value.hasOwnProperty(label.key)
                            ? value[label.key]
                            : "-"
                        }}
                      </template>
                    </span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(accounts)>
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(data, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">{{ data.text }}</span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell(classification_ncr)>
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex justify-content-center text-center text-capitalize"
                  v-for="(data, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">&nbsp;</span>
                  </div>
                </li>
              </ul>
            </template>
            <template #cell()>
              <ul class="list-unstyled overflow-hidden">
                <li
                  class="padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"
                  v-for="(data, index) in labelsRemove"
                  :key="index"
                >
                  <div style="width: 50px !important">
                    <span class="text-truncate w-100">&nbsp;</span>
                  </div>
                </li>
              </ul>
            </template>
          </b-table>
        </transition>
      </template>
    </b-table>
    <dispute-details-modal
      v-if="showDisputeDetailsModal"
      :selectedElements="selectedElements"
      :isAccount="true"
      @resetSelectedElem="resetSelectedElements"
      @close="closeDisputeDetailsModal()"
    />
    <modal-tracking-letter
      v-if="modalTrackingController"
      :pItem="selectedItem"
      :client-name="rowData.client_name"
      :account="rowData.client_account"
      @close="closeModalTracking"
    />
    <edit-account-modal
      v-if="editAcModalController"
      :user-id="currentUser.user_id"
      :item-id="itemIdToSend"
      :dataClient="rowData"
      @reload="myProvider"
      @close="closeEditAccount()"
    />
    <ModalAddAccounts
      v-if="modalAddAccounts.show"
      v-bind="modalAddAccountScope.attrs"
      v-on="modalAddAccountScope.events"
    />
    <added-accounts-modal
      v-if="showAddedAccountsModal"
      :rowData="rowData"
      @closeModal="showAddedAccountsModal = false"
    />
  </b-card>
</template>

<script>
import DisputeDetailsModal from "@/views/commons/components/request-workplan/views/components/modal/DisputeDetailsModal.vue";
import ModalTrackingLetter from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalTrackingLetter.vue";
import EditAccountModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditAccountModal.vue";

import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";
import { mapGetters, mapActions } from "vuex";
import LabelsRemove from "@/views/commons/components/ncr/components/report/data/label-bureau";
import fields from "@/views/commons/components/ncr/components/report/data/fields";

import DataBureau from "@/views/commons/components/ncr/components/report/components/DataBureau.vue";
import ModalAddAccounts from "@/views/commons/components/ncr/components/report/modal/ModalAddAccounts.vue";
import AddedAccountsModal from "@/views/commons/components/dispute-results/views/components/modal/AddedAccountsModal.vue";
import DisputeResultsServices from "@/views/commons/components/dispute-results/services/dispute-results.service.js";
import { th } from "date-fns/locale";
export default {
  components: {
    DisputeDetailsModal,
    ModalTrackingLetter,
    EditAccountModal,
    DataBureau,
    ModalAddAccounts,
    AddedAccountsModal,
  },
  props: {
    accounts: {
      required: true,
    },
    day_processed: {
      required: true,
    },
    process: {
      required: true,
    },
    round_id: {
      required: true,
    },
    idSection: {
      required: false,
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
    rowData: {
      required: true,
      type: Object,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  async mounted() {
    this.addPreloader();
    await this.getAddAccountsCounter();
    this.removePreloader();
  },
  data() {
    return {
      labelsRemove: LabelsRemove,
      fields,
      showDisputeDetailsModal: false,
      textColor: {
        "LATE PAYMENTS": "#3498DB",
        "CHARGE OFF": "#1ABC9C",
        COLLECTION: "#8E44AD",
        REPOSSESSION: "#E74C3C",
        FORECLOSURE: "#F1C40F",
        "SHORT SALE": "#F39C12",
        "CHILD SUPPORT": "#95A5A6",
        "STUDENT LOAN": "#34495E",
        "MEDICAL BILLS": "#BA4A00",
        "OPEN ACCOUNTS": "#7F8C8D",
      },
      selectedElements: [],
      selectedElementIndex: null,
      modalTrackingController: false,
      editAcModalController: false,
      modalAddAccounts: {
        show: false,
        report: null,
      },
      showAddedAccountsModal: false,
      addAccountCounter: 0,
      selectedItem: null,
      itemIdToSend: null,
    };
  },
  methods: {
    ...mapActions({
      A_REMOVE_ACCOUNT: "RequestWorkPlanStore/A_REMOVE_ACCOUNT",
    }),
    transformData(data) {
      const transformedData = {
        experian: {},
        equifax: {},
        transunion: {},
      };

      data.forEach((obj) => {
        const bureau = this.getBureau(obj.bureau_id);
        this.$set(transformedData, bureau, obj);
      });
      return [transformedData];
    },
    getBureau(bureauId) {
      switch (bureauId) {
        case 1:
          return "transunion";
        case 2:
          return "experian";
        case 3:
          return "equifax";
        default:
          return "";
      }
    },
    openAddedAccountsModal() {
      this.showAddedAccountsModal = true;
    },
    async getAddAccountsCounter() {
      try {
        const data = await DisputeResultsServices.getAddAccountsCounter({
          updateRequestId: this.rowData.letter_id,
        });
        this.addAccountCounter = data;
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader();
      }
    },
    async changeStatus({ item, status }) {
      try {
        const confirm = await this.showConfirmSwal(
          `${item.creditor_name} - ${item.bureau_name}`,
          `Are you sure to change the status to ${status.value}?`
        );
        if (!confirm.isConfirmed) return;
        const params = {
          id: item.id,
          status: status.id,
          user_id: this.currentUser.user_id,
          update_id: this.idSection,
          client_account_id: this.rowData.client_account_id,
        };
        if (status == this.originalStatus) {
          return;
        }
        const result = await ReportLeadService.updateAccountIndividual(params);
        if (result.status == 200) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
          this.myProvider();
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    getCreditorAccount(items) {
      const creditorObj = items.find((item) => item.account_number !== "-");
      return creditorObj ? creditorObj.account_number : null;
    },
    toSelected(item) {
      if (item.to_select) {
        item.round_id = this.round_id;
        item.type_element = 4;
        this.selectedElements.push(item);
      } else {
        this.A_REMOVE_ACCOUNT(item.id);
        this.selectedElementIndex = null;
        this.selectedElements = this.selectedElements.filter(
          (element) => element.id != item.id
        );
        if (this.selectedElements.length == 0) this.arrAcc = [];
      }
    },
    openDisputeDetailsModal() {
      this.showDisputeDetailsModal = true;
    },
    closeDisputeDetailsModal() {
      this.showDisputeDetailsModal = false;
    },
    filterObjectBureau(item, key) {
      return item.find((obj) => obj.bureau_name === key);
    },
    resetSelectedElements() {
      this.disputeCounter++;
      this.selectedElementIndex = null;
      this.selectedElements = [];
      this.arrAcc = [];
    },
    openModalTracking(item) {
      this.selectedItem = item;
      this.modalTrackingController = true;
    },
    closeModalTracking() {
      this.modalTrackingController = false;
    },
    editAccount(id) {
      this.editAcModalController = true;
      this.itemIdToSend = id;
    },
    async closeEditAccount() {
      this.editAcModalController = false;
    },
    myProvider() {
      this.$emit("reload");
    },
    async deleteAccount(item) {
      try {
        const response = await this.showConfirmSwal(
          `${item.creditor_name} - ${item.bureau_name}`,
          `Are you sure to delete this item?`
        );
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteDelteAccountAc({
            iduser: this.currentUser.user_id,
            id: item.id,
            client_account_id: this.rowData.client_account_id,
          });
          if (data.status === 200) {
            this.showSuccessSwal("Account has been deleted successfully");
            await this.myProvider();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
    async deleteAccountAll(acc_id, creditor) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to delete the account: " + creditor + "?"
      );
      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const params = {
          id: acc_id,
          user_id: this.currentUser.user_id,
        };
        await ReportLeadService.deleteAccounts(params);
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Succesfully process"
        );
        this.myProvider();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalAddAccounts() {
      this.modalAddAccounts.show = true;
      this.modalAddAccounts.report = this.rowData;
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
    isForSpecialist() {
      return this.isSpecialist;
    },
    modalAddAccountScope() {
      const { show, ...attrs } = this.modalAddAccounts;
      return {
        attrs,
        events: {
          closeModal: () => {
            this.modalAddAccounts.show = false;
          },
          refresh: () => {
            this.myProvider();
            this.getAddAccountsCounter();
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tableDisputeReport::v-deep tbody tr td {
  vertical-align: top !important;
}
.custom-img {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
}
li.padding {
  height: 35px;
  border-top: 1px solid #a7a9ad !important;
}
.status-name {
  font-size: 13px;
  color: white !important;
  font-weight: bold !important;
}
li.padding {
  height: 35px;
  border-top: 1px solid #a7a9ad !important;
}
.action-data {
  border-radius: 5px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  width: 170px;
  gap: 20px;
}
.dark-bg {
  background: #1b1b1e !important;
}
.dropdown::v-deep button {
  padding: 0 !important;
}
.custom-icon-size {
  width: 20px;
  height: 20px;
}
</style>
