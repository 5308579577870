<template>
  <div
    class="rounded card-item p-1 position-relative"
    :class="{ dark: isDarkSkin }"
  >
    <div class="all-data">
      <div class="mb-2">
        <div class="d-flex flex-column gap-5">
          <div
            class="d-flex justify-content-between"
            v-for="(item, index) in data.content"
            :key="index"
          >
            <span class="w-35 text-truncate font-weight-bolder">
              {{ item.key }}:
            </span>
            <span class="text-truncate w-65">
              {{ item.value }}
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between" style="margin-top: 0.5rem">
          <span class="w-35 text-truncate font-weight-bolder"> Status: </span>
          <span class="text-truncate w-65"> {{ statusOld.name }} </span>
        </div>
      </div>
      <div class="action-data" :style="`background: ${backgroundColor}`">
        <b-dropdown no-caret variant="none" class="wo-padding">
          <template #button-content>
            <span class="status-name">
              {{ data.status_name }}
            </span>
          </template>
          <b-dropdown-item
            v-for="(item, index) in statusOptions"
            :key="index"
            @click="changeStatus(item.id)"
          >
            {{ item.value }}
          </b-dropdown-item>
        </b-dropdown>
        <feather-icon
          icon="XCircleIcon"
          class="cursor-pointer text-danger"
          style="margin-left: 5px"
          size="16"
          @click="restoreStatus()"
          v-if="changed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusOld: {
        id: null,
        name: null,
      },
      changed: false,
      status_id: null,
    };
  },
  computed: {
    backgroundColor() {
      const colors = {
        1: "#fbb64a",
        2: "#ff682e",
        3: "#6bff20",
        4: "#6bff20",
        5: "#2e82ff",
        6: "#ff682e",
      };
      return colors[this.status_id];
    },
  },
  created() {
    this.status_id = this.data.status;
    this.statusOld.id = this.data.status;
    this.statusOld.name = this.data.status_name;
  },
  methods: {
    ...mapMutations({
      M_CHANGED_REPORT_ITEMS: "RequestWorkPlanStore/M_CHANGED_REPORT_ITEMS",
    }),
    changeStatus(statusId) {
      this.M_CHANGED_REPORT_ITEMS(1);
      this.status_id = statusId;
      this.data.status= statusId;
      let nameStatus = this.statusOptions.find(
        (item) => item.id == statusId
      ).value;
      this.data.status_name = nameStatus;
    },
    restoreStatus() {
      this.M_CHANGED_REPORT_ITEMS(-1);
      this.status_id = this.statusOld.id;
      this.data.status = this.statusOld.id;
      this.data.status_name = this.statusOld.name;
    },
  },
  watch: {
    data: {
      handler: function (val) {
        if (val.status != this.statusOld.id) {
          this.changed = true;
        } else {
          this.changed = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 100%;
  background-color: #c8eaff;
  &.dark {
    background-color: #1e1d21;
  }
}
.all-data {
  margin: 5px 5px 10px 5px;
}

.action-data {
  position: absolute;
  right: -1px;
  bottom: -1px;
  border-bottom-right-radius: 5px !important;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.status-name {
  font-size: 12px;
  color: white !important;
  font-weight: bold;
}

.w-35 {
  width: 35% !important;
}
.w-65 {
  width: 65% !important;
}
</style>
