export default {
  creditExpertsAndBoostCredit: [
    {
      key: "id",
      label: "#",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center position-relative p-0",
    },
    {
      key: "accounts",
      label: "accounts",
      visible: true,
      thClass: "text-center",
      tdClass: "px-0 py-1 col-2 text-center",
    },
    {
      key: "classification_ncr",
      label: "type",
      visible: true,
      thClass: "text-center",
      tdClass: "px-0 py-1 text-center col-2 position-relative",
    },
    {
      key: "transunion",
      label: "TU",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
      tdClass: "px-0 py-1 col-3",
    },
    {
      key: "experian",
      label: "EX",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
      tdClass: "px-0 py-1 col-3",
    },
    {
      key: "equifax",
      label: "EQ",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
      tdClass: "px-0 py-1 col-3",
    },
    {
      key: "balance",
      label: "balance",
      visible: false,
      thClass: "text-center",
      tdClass: "px-0 py-1 text-center",
    },
    {
      key: "high_credit",
      label: "high credit",
      visible: false,
      thClass: "text-center",
      tdClass: "px-0 py-1 text-center",
    },
    {
      key: "average",
      label: "average %",
      visible: false,
      thClass: "text-center",
      tdClass: "text-center",
      tdClass: "px-0 py-1",
    },
    {
      key: "detail",
      label: "",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center position-relative p-0 position-relative",
    },
  ],
  otherPrograms: [
    {
      key: "first_name",
      label: "User",
      sortable: false,
      visible: true,
    },
    {
      key: "efectivity_percentage",
      label: "Efectivity",
      thStyle: { width: "30%" },
      sortable: false,
      visible: true,
    },
    {
      key: "created_at",
      label: "CREATION DATE",
      sortable: false,
      visible: true,
    },
  ],
};
