<template>
  <div>
    <b-modal
      size="xlg"
      @hidden="closeModal"
      no-close-on-backdrop
      ref="modalAccountManual"
      title="ADD MANUAL ACCOUNTS"
      hide-footer
    >
      <template #modal-header>
        <h5 :class="isDarkSkin ? '' : 'text-light'" class="m-0">
          ADD NEW ACCOUNTS
        </h5>
        <!--BUTTON CLOSE MODAL-->

        <b-button
          variant="white"
          class="bg-white position-absolute"
          style="right: -10px; top: -10px; z-index: 20"
          size="sm"
          @click="closeModal"
        >
          <feather-icon icon="XIcon" size="20" />
        </b-button>
      </template>
      <b-container>
        <AddAccountManual
          :crAccountsStore="crAccountsStore"
          :statusDone="statusDone"
          @doneAccounts="doneAccounts"
          @deleteAccounts="deleteAccounts"
          @loadAccounts="loadAccounts"
          :canSave="false"
        >
          <template #others>
            <div class="mt-3">
              <b-button
                @click="openModalFiles"
                class="text-uppercase"
                variant="info"
                size="sm"
              >
                <feather-icon icon="UploadIcon" size="18" />
                UpLoad Files
                <b-badge variant="danger">{{ newFiles.length }}</b-badge>
              </b-button>
            </div>
          </template>
        </AddAccountManual>
      </b-container>
    </b-modal>
    <!-- LOAD FILES EVIDENCE -->
    <b-modal
      no-close-on-backdrop
      title="UPLOAD FILES"
      v-model="showLoadFilesModal"
      centered
    >
      <b-container>
        <DragAndDrop v-model="newFiles" :files-array="newFiles" />
      </b-container>
      <template #modal-footer>
        <b-button @click="showLoadFilesModal=false" variant="primary">
          Upload
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import AddAccountManual from "@/views/commons/components/request-ncr/views/modals/AddAccountsManual/AddAccountManual.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ModalAddAccounts",
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  components: {
    DragAndDrop,
    AddAccountManual,
  },
  data() {
    return {
      crAccountsStore: [],
      statusDone: false,
      showLoadFilesModal: false,
      newFiles: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    idReport() {
      const { id, client_account } = this.report;
      return `${client_account}-${id}`;
    },
  },
  watch: {
    report: {
      handler(value) {
        if (value) {
          const dataAccounts = this.getAccountsLS();
          const exist = dataAccounts.find(
            (element) => element.id === this.idReport
          );
          if (exist) {
            this.crAccountsStore = exist.accounts;
          } else {
            this.crAccountsStore = [];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      A_ACCOUNTS_ADD: "LeadNcrManualAccountStore/A_ACCOUNTS_ADD",
    }),
    openModalFiles() {
      this.showLoadFilesModal = true;
    },
    async closeModal() {
      try {
        this.$emit("closeModal");
      } catch (ex) {}
    },
    async deleteAccounts(cr) {
      try {
        let dataAccounts = this.getAccountsLS();
        dataAccounts.forEach((element) => {
          if (element.id === this.idReport) {
            element.accounts = cr.account;
          }
        });
        this.saveAccountsLS(dataAccounts);
        this.showGenericToast({
          text: "Deleted accounts",
          title: "Delete",
        });
      } catch (ex) {}
    },
    async doneAccounts(cr) {
      try {
        cr.account.forEach((element) => {
          element.forEach((item) => {
            item.saved_new_item_at = this.report.program_id === 3 ? 1 : null;
          });
        });
        let formData = new FormData();
        for (let i = 0; i < this.newFiles.length; i++) {
          formData.append(
            `files-${i + 1}`,
            this.newFiles[i],
            this.newFiles[i].name
          );
        }
        formData.append("total_files", this.newFiles.length);
        formData.append("created_by", this.currentUser.user_id);
        formData.append("client_account", this.report.client_account);
        formData.append("client_account_id", this.report.client_account_id);
        formData.append("module_id", this.report.module_id);
        formData.append("cr", JSON.stringify(cr));
        formData.append("update_request_id", this.report.letter_id);
        await ReportLeadService.analyzeNewAccounts(formData);
        this.removePreloader();
        this.closeModal();
        this.deleteAccountLS();
        this.$emit("refresh");
      } catch (ex) {
        console.log(ex);
        this.removePreloader();
      }
    },
    async loadAccounts(accounts) {
      try {
        let dataAccounts = this.getAccountsLS();
        const hasItems = dataAccounts.find((x) => x.id === this.idReport);
        if (hasItems) {
          dataAccounts.forEach((element) => {
            if (element.id === this.idReport) {
              element.accounts = accounts;
            }
          });
        } else {
          dataAccounts.push({
            id: this.idReport,
            accounts: accounts,
          });
        }
        this.saveAccountsLS(dataAccounts);
        this.removePreloader();
      } catch (ex) {
        console.log(ex);
      }
    },
    saveAccountsLS(data) {
      localStorage.setItem("accountsReport", JSON.stringify(data));
    },
    getAccountsLS() {
      const data = localStorage.getItem("accountsReport");
      return data ? JSON.parse(data) : [];
    },
    deleteAccountLS() {
      let dataAccounts = this.getAccountsLS();
      dataAccounts = dataAccounts.filter((x) => x.id !== this.idReport);
      console.table(dataAccounts);
      this.saveAccountsLS(dataAccounts);
    },
  },
  mounted() {
    this.toggleModal("modalAccountManual");
  },
};
</script>
