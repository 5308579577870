<template>
  <div v-if="validateItemBureau(item)">
    <b-badge
      class="d-inline-flex row text-center justify-content-around align-items-center py-50 px-1 position-relative"
      :variant="variantBadge[item.status_letter]"
    >
      <div class="d-flex align-items-center" style="gap: 5px">
        <!-- checkbox to dispute -->
        <b-form-checkbox
          v-model="item.to_select"
          @change="$emit('toSelected', item)"
          class="custom-checkbox"
          v-if="isForSpecialist && item.status_letter != 'IN DISPUTE'"
        />
        <span class="status-name">
          {{ item.status_letter || "NOT QUALIFIED" }}</span
        >

        <!-- Change status -->
        <b-dropdown
          no-caret
          variant="none"
          class="wo-padding w-100"
          dropright
          v-if="isForSpecialist && section == 'update'"
          @shown="dropDeployed = true"
          @hidden="dropDeployed = false"
        >
          <template #button-content>
            <feather-icon
              class="text-white custom-icon-size"
              :icon="dropDeployed ? 'ChevronRightIcon' : 'ChevronDownIcon'"
              size="22"
            />
          </template>
          <b-dropdown-item
            v-for="(data, index) in filterStatusOptions(
              statusOptions,
              item.status_letter
            )"
            :key="index"
            @click="$emit('changeStatus', { status: data, item: item })"
          >
            {{ data.value }}
          </b-dropdown-item>
        </b-dropdown>

        <!-- Icons when the item is disputed  -->
        <template v-if="item.status_letter == 'IN DISPUTE'">
          <b-badge
            v-if="item.type_send"
            variant="info"
            pill
            v-b-tooltip.hover.top
            :title="typeSend"
          >
            <feather-icon :icon="iconTypeSend" size="30" />
          </b-badge>
          <b-badge
            v-if="item.status_dispute_round"
            pill
            :variant="variantStatusDispute"
            v-b-tooltip.hover.top
            :title="getTitleStatusDisputeRound"
          >
            <feather-icon :icon="iconsStatusDispute" />
          </b-badge>
        </template>

        <!-- Options to update, view tracking and delete -->
        <b-dropdown
          right
          no-caret
          variant="link"
          toggle-class="text-decoration-none"
          v-if="isForSpecialist && section == 'update'"
        >
          <template #button-content>
            <feather-icon
              class="text-white custom-icon-size"
              size="32"
              icon="SettingsIcon"
              v-b-tooltip.hover.top
              title="ACTIONS"
            />
          </template>
          <b-dropdown-item
            v-b-modal.modal-primary
            @click="$emit('openModalTracking', item.id)"
          >
            <feather-icon
              icon="ListIcon"
              size="16"
              class="mr-1 cursor-pointer text-end text-info"
            />
            <span class="align-middle">View Tracking</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-primary
            @click="$emit('editAccount', item.id)"
          >
            <feather-icon
              size="16"
              class="mr-1 cursor-pointer text-end text-warning"
              icon="EditIcon"
            />
            <span class="align-middle">Edit Account</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-primary
            @click="
              $emit('deleteAccount', {
                id: item.id,
                creditor_name: item.creditor_name,
                bureau_name: item.bureau_name,
              })
            "
          >
            <feather-icon
              size="16"
              class="mr-1 cursor-pointer text-danger"
              icon="TrashIcon"
            />
            <span class="align-middle">Delete item</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- classification at the top right -->
      <div
        class="text-capitalize custom-badge rounded"
        :style="`background-color: ${getStatusBackground(
          item.classification_status
        )};`"
      >
        {{ item.classification_status || "NOT QUALIFIED" }}
      </div>
    </b-badge>
    <div v-if="item.to_select" class="d-flex justify-content-center">
      <b-button
        v-if="completeDispute(item)"
        @click="$emit('openDisputeDetailsModal', item)"
        :disabled="shouldDisableDisputeButton(item)"
        class="my-1"
        size="sm"
        variant="info"
      >
        <feather-icon icon="PlusIcon" size="13" /> ROUND
      </b-button>
      <b-button
        v-else
        class="my-2 cursor-not-allowed"
        variant="primary"
        :disabled="true"
        size="sm"
      >
        IN ROUND
      </b-button>
    </div>
  </div>
  <div v-else class="d-flex justify-content-center">
    <img
      :src="require(`@/assets/images/icons/${bureau}.png`)"
      alt=""
      width="30px"
      height="30px"
      class="custom-bg-logo-effectivity"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value === null || typeof value === "object";
      },
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
    bureau: {
      type: String,
      required: true,
    },
    selectedElements: {
      required: false,
      type: Array,
      default: [],
    },
    isForSpecialist: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      variantBadge: {
        UPDATED: "info",
        DELETED: "success",
        "IN DISPUTE": "warning",
        NEGATIVE: "danger",
        VERIFIED: "danger",
        POSITIVE: "primary",
      },
      selectedElementIndex: null,
      statusOptions: [
        {
          value: "DELETED",
          id: 4,
        },
        {
          value: "NEGATIVE",
          id: 6,
        },
        {
          value: "POSITIVE",
          id: 5,
        },
        {
          value: "UPDATED",
          id: 3,
        },
        {
          value: "VERIFIED",
          id: 2,
        },
      ],
      dropDeployed: false,
    };
  },
  methods: {
    ...mapActions({
      A_REMOVE_ACCOUNT: "RequestWorkPlanStore/A_REMOVE_ACCOUNT",
    }),
    validateItemBureau(item) {
      return item;
    },
    getStatusBackground(priority) {
      if (!priority) return "rgb(130, 134, 139)";
      let background = {
        HIGH: "#00F380",
        MEDIUM: "#FFAF00",
        LOW: "#FE0000",
      };
      return background[priority];
    },
    shouldDisableDisputeButton({ id }) {
      if (this.selectedElements.length === 0) {
        return false;
      }
      const lastSelected =
        this.selectedElements[this.selectedElements.length - 1];
      return lastSelected.id !== id;
    },
    filterStatusOptions(options, status) {
      return options.filter((item) => item.value !== status);
    },
    completeDispute(item_id) {
      let bool = true;
      this.G_DISPUTE_DATA.forEach((el) => {
        if (el.id == item_id.id) {
          bool = false;
        }
      });
      return bool;
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      currentUser: "auth/currentUser",
    }),
    typeSend() {
      const { type_send } = this.item;

      const typeSendMap = {
        REGULAR: "LETTER",
        CERTIFIED: "LETTER",
        DIRECT: "DIRECT",
        PHONE: "DIRECT",
        ONLINE: "DIRECT",
      };

      return typeSendMap[type_send] || "UNKNOWN";
    },
    iconTypeSend() {
      const { type_send } = this.item;

      const typeSendMap = {
        PHONE: "PhoneIcon",
        REGULAR: "MailIcon",
        LETTER: "MailIcon",
        CERTIFIED: "MailIcon",
        ONLINE: "PhoneIcon",
      };

      return typeSendMap[type_send] || "UNKNOWN";
    },
    iconsStatusDispute() {
      const { status_dispute_round } = this.item;

      const statusDispute = {
        1: "ClockIcon",
        2: "ClockIcon",
        5: "ClockIcon",
        7: "CheckCircleIcon",
        11: "SlashIcon",
      };

      return statusDispute[status_dispute_round] || "UNKNOWN";
    },
    variantStatusDispute() {
      const { status_dispute_round } = this.item;

      const statusDispute = {
        1: "primary",
        2: "primary",
        5: "primary",
        7: "success",
        11: "danger",
      };

      return statusDispute[status_dispute_round] || "UNKNOWN";
    },
    getTitleStatusDisputeRound() {
      const titles = {
        1: "PENDING",
        2: "TO SEND",
        5: "IN CORRESPONDENCE",
        7: "COMPLETED",
        11: "EXPIRED",
      };

      return titles[this.item.status_dispute_round];
    },
  },
};
</script>

<style lang="scss"  scoped>
.custom-icon-size {
  width: 20px;
  height: 20px;
}
.custom-badge {
  position: absolute !important;
  top: 0;
  right: 0;
  transform: translate(50%, -80%);
  padding: 5px 10px;
}
.custom-checkbox::v-deep .custom-control-input ~ .custom-control-label:before {
  border-radius: 50px !important;
  border: none !important;
}
.dropdown::v-deep button {
  padding: 0 !important;
}
</style>>
<style>
/* Asegura que el dropdown se muestre completamente */
.dropdown-container {
  position: relative;
  overflow: visible;
}
</style>

