<template>
  <b-card class="mb-1">
    <div class="mb-1 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-between">
        <feather-icon icon="CircleIcon" size="15" class="color-inquires" />
        <span class="title-table d-inline-block lh-0 mr-1">Inquiries</span>
        <b-badge
          class="cursor-pointer"
          variant="light-primary"
          :class="showInquires ? null : 'collapsed'"
          @click="showInquires = !showInquires"
          aria-controls="collapse-inquires"
          :aria-expanded="showInquires"
        >
          {{ showInquires ? "Hide" : "Show" }}
        </b-badge>
      </div>
      <div>
        <b-button
          v-if="isForSpecialist"
          variant="success text-black"
          @click="addInquires()"
        >
          ADD INQUIRIES
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="editInquire = !editInquire"
          v-if="isForSpecialist"
        >
          {{ editInquire ? "SAVE" : "EDIT" }} INQUIRIES
        </b-button>
      </div>
    </div>
    <b-collapse v-model="showInquires" id="collapse-inquires">
      <b-table-simple
        small
        caption-top
        responsive
        bordered
        class="position-relative custom-table-report"
        :sticky-header="'65vh'"
      >
        <b-thead>
          <b-tr>
            <b-th class="td-left text-right header-width"></b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">TransUnion</span>
              <b-img :src="require('@/assets/images/icons/transunion.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Experian</span>
              <b-img :src="require('@/assets/images/icons/experian.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Equifax</span>
              <b-img :src="require('@/assets/images/icons/equifax.png')" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, inquireIndex) in inquires" :key="inquireIndex">
            <b-td class="td-left text-right">
              <div
                v-if="!item.for_edit"
                class="d-flex flex-column"
                style="gap: 2px"
              >
                <div class="font-weight-bolder">{{ item.content }}</div>
                <div class="font-weight-bolder">{{ item.date_show }}</div>
                <div class="font-weight-bolder">{{ item.sub_content }}</div>
                <div>
                  <b-button
                    @click="openDisputeDetailsModal(item)"
                    :variant="inRound(item.id) ? 'primary' : 'info'"
                    :disabled="inRound(item.id)"
                    v-if="item.to_select"
                    size="sm"
                  >
                    {{ inRound(item.id) ? "IN ROUND" : "ADD NEW ROUND" }}
                  </b-button>
                </div>
                <div class="font-weight-bolder">
                  <b-button
                    variant="info"
                    size="sm"
                    @click="item.for_edit = !item.for_edit"
                    v-if="isForSpecialist"
                  >
                    <feather-icon icon="Edit2Icon" size="13" />
                    Edit
                  </b-button>
                </div>
              </div>
              <div v-else>
                <div
                  class="d-flex flex-column align-items-end"
                  style="gap: 2px; margin-bottom: 2px"
                >
                  <b-form-input v-model="item.content"></b-form-input>
                  <b-form-input
                    type="date"
                    v-model="item.date_show"
                  ></b-form-input>
                  <b-form-input v-model="item.sub_content"></b-form-input>
                </div>
                <b-button
                  variant="danger"
                  class="mr-1"
                  size="sm"
                  @click="cancelCloseInquire(item)"
                >
                  <feather-icon icon="XIcon" size="13" />
                  {{ item.content == null ? "Cancel" : "Close" }}
                </b-button>
                <b-button
                  variant="success"
                  size="sm"
                  @click="item.for_edit = !item.for_edit"
                >
                  <feather-icon icon="SaveIcon" size="13" />
                  Save
                </b-button>
              </div>
            </b-td>

            <template>
              <b-td
                v-for="rowsIndex in 3"
                :key="rowsIndex"
                class="td-left text-right"
              >
                <div class="d-flex justify-content-center">
                  <tr>
                    <td class="border-0">
                      <div class="text-center">
                        <b-form-checkbox
                          :id="'checkbox-' + inquireIndex + rowsIndex"
                          v-model="item.bureau_id"
                          :value="rowsIndex"
                          size="lg"
                          class="align_checkbox"
                          v-if="editInquire"
                        ></b-form-checkbox>

                        <b-form-checkbox
                          :id="'checkbox-' + inquireIndex + rowsIndex"
                          v-model="item.to_select"
                          :value="rowsIndex"
                          size="lg"
                          class="align_checkbox"
                          :disabled="isSelected(item.id)"
                          @change="toSelected(item)"
                          v-if="
                            isForRound &&
                            item.status != 1 &&
                            item.bureau_id == rowsIndex
                          "
                        ></b-form-checkbox>
                      </div>
                    </td>
                    <td class="border-0">
                      <div class="text-center">
                        <feather-icon
                          class="text-center"
                          :class="
                            item.status == 1
                              ? 'text-check-orange'
                              : 'text-check-red'
                          "
                          size="26"
                          v-if="item.bureau_id == rowsIndex"
                          icon="CheckIcon"
                        ></feather-icon>
                      </div>
                    </td>
                    <td class="border-0">
                      <div
                        v-if="
                          item.bureau_id == rowsIndex && section == 'update'
                        "
                        class="align_checkbox"
                      >
                        <b-dropdown variant="link" no-caret>
                          <template #button-content>
                            <b-badge :variant="getColorStatus(item.status)"
                              >{{ statusName(item.status)
                              }}<feather-icon
                                icon="ChevronDownIcon"
                                size="16"
                                v-b-tooltip.hover.right="'Actions'"
                              />
                            </b-badge>
                          </template>
                          <b-dropdown-item
                            v-for="(subItem, index) in statuses"
                            :key="index"
                            v-b-modal.modal-primary
                            @click="updateStatus(item, subItem)"
                          >
                            <span class="align-middle">{{
                              subItem.value
                            }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                </div>
              </b-td>
            </template>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-collapse>

    <dispute-details-modal
      v-if="showDisputeDetailsModal"
      :selectedElements="selectedElements"
      @resetSelectedElem="resetSelectedElements"
      @close="closeDisputeDetailsModal()"
    />
  </b-card>
</template>

<script>
import DisputeDetailsModal from "@/views/commons/components/request-workplan/views/components/modal/DisputeDetailsModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DisputeDetailsModal,
  },
  props: {
    process: {
      required: true,
    },
    inquires: {
      required: true,
    },
    day_processed: {
      required: true,
    },
    round_id: {
      required: true,
    },
    idSection: {
      required: false,
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
  },
  async created() {
    await this.getStatuses();
  },
  data() {
    return {
      showInquires: true,
      editInquire: false,
      setDisputeInquire: [
        {
          type_of_disput: null,
        },
        {
          type_of_disput: null,
        },
        {
          type_of_disput: null,
        },
      ],
      setInquires: {
        reason: null,
        instruction: null,
      },
      showDisputeDetailsModal: false,
      selectedElements: [],
      statuses: [],
      status: null,
    };
  },
  methods: {
    ...mapActions({
      A_REMOVE_ACCOUNT: "RequestWorkPlanStore/A_REMOVE_ACCOUNT",
    }),
    cancelCloseInquire(item) {
      if (item.content == null) {
        this.inquires.splice(this.inquires.indexOf(item), 1);
      } else {
        item.for_edit = !item.for_edit;
      }
    },

    resetSelectedElements() {
      this.selectedElements = [];
    },

    toSelected(item) {
      if (item.to_select) {
        item.type_element = 2;
        item.round_id = this.round_id;
        this.selectedElements.push(item);
      }
      if (!item.to_select) {
        this.A_REMOVE_ACCOUNT(item.id);
        this.selectedElements = this.selectedElements.filter(
          (el) => el.id != item.id
        );
      }
    },

    openDisputeDetailsModal(item) {
      if (this.inRound(item.id)) return;
      this.showDisputeDetailsModal = true;
    },

    closeDisputeDetailsModal() {
      this.showDisputeDetailsModal = false;
    },

    inRound(item_id) {
      let bool = false;
      this.G_DISPUTE_DATA.forEach((el) => {
        if (el.id == item_id) {
          bool = true;
        }
      });
      return bool;
    },

    isSelected(item_id) {
      let bool = false;
      this.selectedElements.forEach((el) => {
        if (el.id == item_id) {
          bool = true;
        }
      });
      return bool;
    },
    statusName(status) {
      let obj = {
        1: "IN DISPUTE",
        2: "VERIFIED",
        3: "UPDATED",
        4: "DELETED",
        5: "POSITIVE",
        6: "NEGATIVE",
      };
      return obj[status] || "light-success";
    },
    getColorStatus(number) {
      let setColorStatus = {
        1: "light-warning",
        2: "light-danger",
        5: "light-primary",
        6: "light-danger",
      };

      return setColorStatus[number] || "light-success";
    },
    async updateStatus(item, subItem) {
      try {
        await ClientsOptionsServices.editSingleAccountReportIn({
          id: item.sub_id,
          status: subItem.id,
          content: item.content,
          iduser: this.currentUser.user_id,
          instruction: null,
          reason: null,
          value: 1,
          bureau_id: item.idSection,
          update_id: this.idSection,
        });
        item.status = subItem.id;
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          error
        );
      }
    },
    async getStatuses() {
      try {
        const { data } = await ClientsOptionsServices.getStatusLetters();

        this.statuses = data.filter((item) => item.id != 1);
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
  computed: {
    ...mapGetters({
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      currentUser: "auth/currentUser",
    }),
    isForSpecialist() {
      return this.process != 1 && (this.moduleId == 28 || this.moduleId == 25);
    },
    isForRound() {
      return (
        this.process == 1 &&
        (this.moduleId == 28 || this.moduleId == 25) &&
        this.day_processed > "2021-09-27"
      );
    },

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    addInquires() {
      this.inquires.unshift({
        content: null,
        sub_content: null,
        status: 6,
        bureau_id: 1,
        date: null,
        date_show: null,
        for_edit: true,
      });
    },
    idBureau() {
      return this.$route.params.idBureau;
    },
    // section() {
    //   return this.$route.params.section;
    // },
  },
};
</script>

<style scoped>
.text-check-red {
  color: red;
  font-size: 25px;
}
.text-check-orange {
  color: #ff9800;
  font-size: 25px;
}

.title-table {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.color-inquires {
  color: #ff9100;
  fill: #ff9100;
  margin-right: 10px;
}
.custom-table-report::v-deep thead tr th {
  background-color: #3a72ea !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-table-report::v-deep thead tr th:first-child {
  border-radius: 15px 0 0 0 !important;
}

.custom-table-report::v-deep thead tr th:last-child {
  border-radius: 0 15px 0 0 !important;
}
</style>
