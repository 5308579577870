import { amgApi } from '@/service/axios'

class ClientBoostDashboardService {
  async getDeleteAccountCrPdf(params) {
    const data = await amgApi.post('/dispute/get-delete-account-cr-pdf', params)
    return data
  }

  async generateDeleteAccountCrPdf(params) {
    const data = await amgApi.post('/dispute/generate-delete-account-cr-pdf', params)
    return data
  }

  async getCreditReportPdf(params) {
    const data = await amgApi.post('/dispute/get-credit-report-pdf', params)
    return data
  }

  async getCreditReportAccountPdf(params) {
    const data = await amgApi.post('/dispute/generate-credit-report-account-pdf', params)
    return data
  }

  async getAllTypeGoal(params) {
    const data = await amgApi.get('commons/boost-credit/get-all-type-goal', params)
    return data
  }

  async updateGoals(params) {
    const data = await amgApi.post('commons/boost-credit/update-goals', params)
    return data
  }
  async downloadDeleteAccountPdfAccountCr(params) {
    const data = await amgApi.post('/dispute/download-delete-account-cr-pdf', params)
    return data
  }
  async downloadDeleteAccountPdfCreditReport(params) {
    const data = await amgApi.post('/dispute/download-credit-report-pdf', params)
    return data
  }
}
export default new ClientBoostDashboardService()
