import { amgApi } from "@/service/axios";
class ReportLeadService {
  async getClientData(id) {
    try {
      const { data } = await amgApi.post("commons/search-account", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getNumberNotes(body) {
    try {
      const data = await amgApi.post("/credit-expert/get-notes-grilla", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getNotes(body) {
    try {
      const data = await amgApi.post("/credit-expert/get-all-notes", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getUpdateFilesPending(account_id) {
    try {
      const data = await amgApi.post(
        "commons/dispute-results/get-update-files-pending",
        { account_id: account_id }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getReports(body) {
    try {
      const data = await amgApi.post("/lead/credit-report/get-reports", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCreditReportData(body) {
    try {
      const data = await amgApi.post(
        "/lead/credit-report/get-credit-report-data",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async updateAccountIndividual(body) {
    try {
      const data = await amgApi.post(
        "/lead/credit-report/update-account-individual",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getChargesCreditCard(body) {
    try {
      const data = await amgApi.post(
        "/lead/credit-report/get-charges-for-credit-card",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAccounts(body) {
    try {
      const data = await amgApi.post(
        "/lead/credit-report/delete-account-dispute-report",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async analyzeNewAccounts(body) {
    try {
      const data = await amgApi.post(
        "/lead/credit-report/analyze-new-accounts",
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new ReportLeadService();
