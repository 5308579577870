<template>
  <div>
    <div :class="isDarkSkin ? 'black' : 'light'" class="rounded card">
      <div class="p-2">
        <h3 class="text-primary" style="font-weight: bold">Information</h3>
        <hr style="border: 1px solid #0090e7" />
        <b-row class="my-1">
          <span class="attr-text col-md-5">CLIENT</span>
          <div class="col-md-7">
            <strong>{{ client_information.name_client }}</strong>
          </div>
        </b-row>
        <b-row class="my-1">
          <span class="attr-text col-md-5">SERVICE</span>
          <div class="col-md-7">
            <strong class="text-capitalize">{{
              client_information.module
            }}</strong>
          </div>
        </b-row>

        <b-row class="my-1">
          <span class="attr-text col-md-5">ADVISOR</span>
          <div class="col-md-7">
            <strong>{{ client_information.name_advisor }}</strong>
          </div>
        </b-row>

        <b-row class="my-1">
          <span class="attr-text col-md-5">TYPE</span>
          <div class="col-md-7">
            <strong>{{ client_information.name_charge }}</strong>
          </div>
        </b-row>
        <b-row class="my-1">
          <span class="attr-text col-md-5">CREATED BY</span>
          <div class="col-md-7">
            <strong>{{ client_information.recommended_by }}</strong>
          </div>
        </b-row>

        <b-row class="my-1">
          <span class="attr-text col-md-5">CREATED AT</span>
          <div class="col-md-7">
            <strong>{{
              client_information.created_at | myGlobalWithHour
            }}</strong>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ChatClientInformation",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      client_information: "BinnacleStore/G_CLIENT_INFORMATION",
    }),
  },
};
</script>