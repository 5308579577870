<template>
  <section class="time-line-box">
    <div class="swiper-container text-center">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(step, index) in steps" :key="index">
          <div class="status">
            <b-badge variant="primary">{{ step.status }}</b-badge>
          </div>
          <div class="timestamp">
            <span>{{ step.created_at | myGlobalDay }}</span>
          </div>
          <div class="created_by">
            <span
              class="h5 fw-bold"
              :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >{{ step.created_by }}</span
            >
          </div>
          <div class="comment">
            <p
              class="text-truncate"
              v-b-tooltip.hover.bottom
              :title="step.comment"
            >
              {{ step.comment }}
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style scoped>
.fw-bold {
  font-weight: bold !important;
}
.time-line-box {
  background-color: transparent;
}

.time-line-box .timeline {
  list-style-type: none;
  display: flex;
  padding: 0;
  text-align: center;
}

.time-line-box .status {
  margin: auto;
  margin-bottom: 5px;
  padding: 0px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-line-box .timestamp {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  border-top: 3px solid #455efc;
  position: relative;
  transition: all 200ms ease-in;
}
.time-line-box .timestamp span {
  padding-top: 8px;
}
.time-line-box .timestamp span:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #455efc;
  border-radius: 12px;
  border: 2px solid #455efc;
  position: absolute;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 200ms ease-in;
}

.swiper-container {
  width: 95%;
  margin: auto;
  overflow-y: auto;
}
.swiper-wrapper {
  display: inline-flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
}
.swiper-container::-webkit-scrollbar-track {
  background: #a8a8a8b6;
}
.swiper-container::-webkit-scrollbar {
  height: 2px;
}
.swiper-container::-webkit-scrollbar-thumb {
  background: #4f4f4f !important;
}
.swiper-slide {
  text-align: center;
  font-size: 12px;
  width: 200px;
  height: 100%;
  position: relative;
}
</style>
