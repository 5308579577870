<template>
  <b-container fluid style="margin-top: 100px">
    <div class="container py-12 md:py-24 lg:py-32">
      <!-- <ValidationObserver ref="form"> -->
      <b-row class="mb-4" align-h="center">
        <b-col md="4" v-for="(service, index) in servicesV2" :key="index">
          <b-card border-variant="light" class="h-100">
            <b-card-header class="d-flex align-items-center">
              <feather-icon :icon="service.icon" size="30" class="" />

              <h3 class="font-bold" style="font-family: MONOSPACE">
                {{ service.name }}
              </h3>
            </b-card-header>
            <hr class="p-0 m-0" />
            <b-card-body>
              <div class="grid gap-2 mt-1">
                <!-- <validation-provider
                    v-slot="{ errors }"
                    :name="service.name"
                    rules="atLeastOneSelected"
                  > -->
                <b-form-group>
                  <b-form-checkbox
                    v-for="(option, optIndex) in visibleOptions(service)"
                    :key="optIndex"
                    v-model="service.selectedOptions"
                    :value="option"
                    :disabled="isCheckboxDisabled(service, option)"
                  >
                    {{ option.name }}
                  </b-form-checkbox>
                  <!-- <label v-if="errors[0]" class="text-danger"
                        >Concept {{ errors[0] }}</label
                      > -->
                </b-form-group>
                <feather-icon
                  v-if="service.options.length > 4"
                  class="clickable"
                  :icon="!service.showAll ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                  @click="service.showAll = !service.showAll"
                />
                <!-- </validation-provider> -->
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Comment" class="mt-1">
            <b-form-textarea
              rows="2"
              v-model="commentary"
              class="text-area-box"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- </ValidationObserver> -->
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
export default {
  props: {
    dataClient: {
      required: true,
      type: Object,
    },
    roundLetterId: {
      required: false,
    },
    comment: {
      required: false,
      type: String,
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
  },
  data() {
    return {
      ownControl: false,
      finishedLoading: false,
      services: [],
      description: [],
      showCreditCard: false,
      showAppointment: false,
      showOtherServices: false,
      optionsChargesCreditCard: [],
      optionsChargesAppointment: [],
      optionsChargesOthers: [],
      creditCard: null,
      appointment: null,
      recommend: [],
      otherServices: [],
      chargesServicesData: [],
      commentary: null,
      servicesV2: [
        {
          name: "CREDIT CARDS",
          title: "Powerful Analytics",
          description:
            "Get deep insights into your business with our advanced analytics tools.",
          options: [],
          icon: "CreditCardIcon",
          showAll: false,
          selectedOptions: [],
        },
        {
          name: "APPOINTMENTS",
          title: "Seamless Collaboration",
          description:
            "Streamline your team's workflow with our powerful collaboration tools.",
          options: [],
          icon: "CalendarIcon",
          showAll: false,
          selectedOptions: [],
        },
        {
          name: "OTHER SERVICES",
          title: "Intelligent Automation",
          description:
            "Streamline your workflows with our intelligent automation features.",
          options: [],
          icon: "GridIcon",
          showAll: false,
          selectedOptions: [],
        },
      ],
      selectedOptions: [],
      selectOthers: [],
      getSelectOthers: [],
    };
  },

  async mounted() {
    this.ownControl = true;
    await this.search();
    this.finishedLoading = true;
    await this.getChargeServices();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    modul_id() {
      return this.currentUser.modul_id;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
      A_CLEAR_DISPUTE_DATA: "RequestWorkPlanStore/A_CLEAR_DISPUTE_DATA",
      A_UPDATE_REQUEST_COUNTERS:
        "BoostCreditDisputeResult/A_UPDATE_REQUEST_COUNTERS",
    }),

    refresh() {
      this.$refs.refCards.refresh();
    },

    async search() {
      try {
        const data = await SettingsServiceSp.searchServices();
        this.services = data;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    getSelectedOthers() {
      this.getSelectOthers = this.servicesV2[2].options.filter((option) =>
        this.selectOthers.includes(option.id)
      );
    },
    async getChargeServices() {
      try {
        this.addPreloader();
        const data = await ReportService.getChargeServices();
        this.servicesV2[0].options = data.filter(
          (item) => item.type_services == "CREDIT CARD"
        );
        this.servicesV2[1].options = data.filter(
          (item) => item.type_services == "APPOINTMENT"
        );
        this.servicesV2[2].options = data.filter(
          (item) => item.type_services == "OTHER SERVICES"
        );
        this.chargesServicesData = data;
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    getServiceChargeByTyPypes(type) {
      let dataServices = this.chargesServicesData.filter(
        (x) => x.type_services == type
      );
      return dataServices.length > 0 ? dataServices : [];
    },
    async save() {
      try {
        this.addPreloader();
        const isAtLeastOneSelected = this.servicesV2.some(
          (service) => service.selectedOptions.length > 0
        );

        if (!isAtLeastOneSelected) {
          this.showToast(
            "warning",
            "top-right",
            "Attention",
            "CheckIcon",
            "Please select at least one option"
          );
          return;
        }

        const params = this.buildParams();
        if (this.section == "workplan") {
          await this.handleWorkplanRequest(params);
        } else if (this.section == "update") {
          await this.handleUpdateRequest(params);
        }

        this.showToast(
          "success",
          "top-right",
          "Congratulations",
          "CheckIcon",
          "Recommendation completed"
        );
        return true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.removePreloader();
      }
    },
    buildParams() {
      const selectedOptions = this.servicesV2.reduce((acc, service) => {
        if (Array.isArray(service.selectedOptions)) {
          acc.push(...service.selectedOptions);
        } else {
          acc.push(service.selectedOptions);
        }
        return acc;
      }, []);
      return {
        letters_id: this.roundLetterId,
        recommended_services: JSON.stringify(selectedOptions),
        save_by: this.currentUser.user_id,
        id: this.dataClient.id,
        recommend_comment: this.commentary,
        comment: this.comment,
        client_account_id: this.dataClient.client_account_id,
        module_id: this.dataClient.module_id,
      };
    },

    visibleOptions(data) {
      return data.showAll ? data.options : data.options.slice(0, 4);
    },

    async handleWorkplanRequest(params) {
      const res = await ReportService.toRevision(params);
      return res && res.status === 200 ? res : null;
    },

    async handleUpdateRequest(params) {
      const res = await DisputeResultsService.toRevisionUpdateRequest(params);
      return res && res.status === 200 ? res : null;
    },

    handleSuccess(res) {
      if (res) {
        this.showSuccessSwal(res.message);
        this.$emit("reload");
        this.handleRouteRedirect();
        this.A_CLEAR_DISPUTE_DATA([]);
        this.closeModal();
      }
    },

    handleRouteRedirect() {
      this.$router.push({ name: "sp-wp-to-revision" });
      this.A_REQUEST_WORKPLAN_COUNTERS(28);
    },

    handleError(error) {
      this.showErrorSwal(error);
    },

    closeModal() {
      this.$emit("close");
    },
    filteredOptions(data) {
      return data.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    isCheckboxDisabled(service, option) {
      if (service.name === "OTHER SERVICES") {
        return false;
      }
      return (
        service.selectedOptions.length > 0 &&
        !service.selectedOptions.includes(option)
      );
    },
  },
};
</script>

<style scoped>
.space {
  width: 63%;
}
.title_label {
  width: 150px;
}
.custom-input .form-control {
  opacity: 1 !important;
}
.rotate-180 {
  transform: rotate(180deg);
}
.font-bold {
  font-weight: bold;
}
</style>

