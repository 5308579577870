<template>
  <b-modal
    id="bank-info"
    title="Bank Information"
    title-tag="h3"
    modal-class="modal-primary"
    v-model="visible"
    @hidden="close"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-container>
      <b-row>
        <b-col>
          <b-form-group label="BANK NAME">
            <b-form-select
              v-model="info.bank"
              :options="banks"
              :disabled="disable.text_done"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="CREDIT LINE">
            <money
              class="form-control"
              v-bind="vMoney"
              v-model="info.credit_line"
              :disabled="disable.text_done"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="INTEREST %">
            <money
              class="form-control"
              v-bind="vPercent"
              v-model="info.interest"
              :disabled="disable.text_done"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="SUGGEST CHARGE $">
            <money
              class="form-control"
              v-bind="vMoney"
              v-model="info.suggest_charge"
              :disabled="disable.text_done"
              @input="updateSuggestCharge"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="FEATURES">
            <v-select
              v-model="info.features"
              place-holder="Pick some"
              label="name"
              :options="features"
              :disabled="disable.text_done"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="COMMENTS:">
            <b-form-textarea
              rows="5"
              v-model="info.comments"
              :disabled="disable.text_done"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isSpecialist">
        <b-col :cols="8" />
        <b-col :cols="2">
          <center>
            <b-button
              v-if="
                info.status_done === 0 && isCeo
                  ? true
                  : info.status_done === 0 &&
                    [11, 25, 28, 29, 30].includes(moduleId)
              "
              size="lg"
              variant="success"
              @click="saveData()"
            >
              SAVE
            </b-button>
          </center>
        </b-col>
        <b-col :cols="2">
          <b-button
            v-if="
              info.status_done === 0 && isCeo
                ? true
                : info.status_done === 0 &&
                  [11, 25, 28, 29, 30].includes(moduleId)
            "
            size="lg"
            variant="primary"
            @click="sendData"
          >
            SEND
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ApplicationsService from "@/views/commons/components/applications/services/applications.service";
import { VMoney } from "v-money";
import vSelect from "vue-select";
export default {
  components: { vSelect, ValidationProvider, ValidationObserver },
  directives: { money: VMoney },
  props: {
    row: { type: Object, required: true },
  },
  data() {
    return {
      info: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      vPercent: {
        decimal: ".",
        thousand: ",",
        suffix: " %",
        precision: 2,
        min: 0.0,
        max: 100,
      },
      visible: false,
      disable: {
        text_done: false,
        save: false,
      },
    };
  },
  methods: {
    async start() {
      const data = await ApplicationsService.getBankInfo({
        id: this.row.app_id,
      });
      this.info = { ...data.data[0] };
      if (this.info.credit_line == null) this.info.credit_line = 0;
      if (this.info.interest == null) this.info.interest = 0;
      if (this.info.suggets_charge == null) this.info.suggets_charge = 0;
      await this.getBanks({ t_charge: this.info.type });
      await this.getFeatures();
    },
    async saveData(status = 0) {
      try {
        this.dataProvider.done_status = status;

        let confirm = await this.showConfirmSwal();

        if (confirm.isConfirmed) {
          this.addPreloader();
          let result = await ApplicationsService.saveBankInfo({
            ...this.dataProvider,
          });
          this.removePreloader();
          if (result) {
            this.showSuccessSwal();
            this.$emit("refreshTable");
            this.close();
          } else {
            this.showErrorSwal();
          }
        } else {
          return false;
        }
      } catch (ex) {
        this.removePreloader();
      }
    },
    sendData() {
      this.saveData(1);
    },
    close() {
      this.$emit("close");
      this.visible = false;
    },
    updateSuggestCharge() {
      const { credit_line } = this.info;

      if (credit_line > 0) {
        if (credit_line <= 500) {
          this.info.suggest_charge = 50;
        } else if (credit_line <= 2000) {
          this.info.suggest_charge = 100;
        } else {
          this.info.suggest_charge = credit_line * 0.05;
        }
      } else {
        this.info.suggest_charge = 0;
      }
    },
    ...mapActions({
      getBanks: "DebtSolutionApplications/getBanksAction",
      getFeatures: "DebtSolutionApplications/getFeaturesAction",
    }),
  },
  computed: {
    dataProvider() {
      return {
        b_app_id: this.row.app_id,
        b_ca_id: this.row.ca_id,
        b_comments: this.info.comments,
        b_credit_line: this.info.credit_line,
        b_features: JSON.stringify(this.info.features),
        b_iduser: this.currentUser.user_id,
        b_interest: this.info.interest,
        b_name_bank: this.info.bank,
        done_status: 0,
        b_suggets_charge: this.info.suggest_charge,
      };
    },
    isSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId) ? true : false;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      features: "DebtSolutionApplications/features",
      banks: "DebtSolutionApplications/banks",
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
    this.disable.text_done = !(
      this.info.status_done === 0 &&
      [11, 25, 28, 29, 30].includes(this.moduleId) &&
      this.currentUser.role_id !== 8
    );
    this.visible = true;
  },
};
</script>


