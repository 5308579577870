<template>
  <div>
    <b-tabs
      pills
      class="mx-1 b-pills"
      :class="isDarkSkin ? 'br-nav-dark' : 'br-nav'"
    >
      <b-tab :title-link-class="[bgTabsNavs, 'h-full px-3']">
        <template v-slot:title>
          <span>Groups</span>
          <b-badge
            v-if="pendingDisputes > 0"
            pill
            variant="danger"
            class="ml-1"
            v-b-tooltip.hover.top="'Missing files'"
          >
            <span v-if="missingFiles > 0">{{ missingFiles }}</span>
          </b-badge>
        </template>
        <b-card
          class="border-top-primary border-3 border-table-radius pt-1"
          no-body
        >
          <TableNcrs />
        </b-card>
      </b-tab>
      <b-tab
        title="Individuals"
        :title-link-class="[bgTabsNavs, 'h-full px-3']"
      >
        <b-card class="border-top-primary border-3 border-table- pt-1" no-body>
          <TableLetters />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableNcrs from "./TableNcrs.vue";
import TableLetters from "./TableIndividuals.vue";
import SummaryCredit from "./SummaryCredit.vue";
export default {
  components: {
    TableNcrs,
    TableLetters,
    SummaryCredit,
  },
  computed: {
    ...mapGetters({
      missingFiles: "ReportStore/G_MISSING_FILES",
      pendingDisputes: "ReportStore/G_PENDING_DISPUTES",
    }),
  },
};
</script>

<style>
.b-pills .nav-pills {
  margin-bottom: 0 !important;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
}
</style>
