<template>
  <div :class="isDarkSkin ? 'black' : 'light'" class="rounded mt-1 card">
    <div class="px-1 mt-1">
      <h3 class="text-primary" style="font-weight: bold">Tracking status</h3>
      <hr style="border: 1px solid #0090e7" />
    </div>
    <template v-if="commentsStatus.length > 0">
      <template v-for="(item, index) in commentsStatus">
        <div class="card tracking mx-1 my-0" :key="index">
          <div class="timeline py-1">
            <div :key="index" class="timeline-event">
              <div class="timeline-event-header">
                {{ item.created_at | myGlobalDay }}
              </div>
              <div class="timeline-event-body">
                <h4>{{ getTitleStatus(item.status_binnacle) }}</h4>
                <span>{{ item.content || "-" }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="d-flex justify-content-center py-2">
        <p>Empty</p>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ChatTrackingStatus",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      commentsStatus: "BinnacleStore/G_COMMENTS_STATUS",
    }),
  },
  methods: {
    getTitleStatus(status) {
      let obj = {
        form: "Status form:",
        result: "Status result:",
        paid: "Status paid:",
      };

      return obj[status];
    },
  },
};
</script>