import { amgApi } from "@/service/axios";
class MotivesService {

    async getMotives(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-motives", params);
            return data;
        } catch (error) {
            console.log("Error getMotives");
        }
    }

    async setMotives(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-motives", params);
            return data;
        } catch (error) {
            console.log("Error setMotives");
        }
    }

    async deleteMotives(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/delete-motives", params);
            return data;
        } catch (error) {
            console.log("Error deleteMotives");
        }
    }

    async allMotives() {
        try {
            const data = await amgApi.post("credit-experts-digital/all-motives");
            return data;
        } catch (error) {
            console.log("Error deleteMotives");
        }
    }
}
export default new MotivesService(); 