<template>
  <div>
    <b-modal
      v-model="modalCenter"
      centered
      modal
      size="lg"
      title="TRACKING"
      hide-footer
      @hidden="hideModal"
    >
      <div class="mb-1">
        <b-row>
          <b-col cols="12" md="6">
            <span class="title-tag">ACCOUNT</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataClient.client_account || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">CLIENT</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataClient.client_name || "-" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: Date -->
          <template #cell(user_name)="data">
            <div>
              <p class="m-0">{{ data.item.user_name.trim() == "Paragon" ? "System" : data.item.user_name }}</p>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div style="padding-top: 5px">
              <p class="m-0">{{ data.item.created_at | myGlobalDay }}</p>
            </div>
          </template>
          <template #cell(obs)="data">
            <div class="text-left">
              <div class="observation" v-html="data.item.obs"></div>
            </div>
          </template>
          <!-- Column: OBSERVATION -->
          <template #cell(status_process)="data">
            <b-badge :variant="badgeStatus(data.item.state)">
              {{ badgeText(data.item.state) }}
            </b-badge>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import LettersRoundsService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";

export default {
  components: {},
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "user_name",
          label: "User",
        },
        {
          key: "status_process",
          label: "Status",
        },
        {
          key: "obs",
          label: "Observation",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        let result = await LettersRoundsService.getTrackingsLetters({
          id: this.dataClient.id,
        });

        let data = result.filter((item) =>
          [1, 2, 5, 7, 10, 11].includes(item.status_process_id)
        );

        return data || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    badgeStatus(status) {
      let badgeClass = {
        1: "light-warning",
        2: "light-primary",
        5: "light-info",
        7: "light-success",
        10: "light-danger",
        11: "danger",
      };
      return badgeClass[status] || "light-secondary";
    },

    badgeText(status) {
      let badgeClass = {
        1: "IN QUEUE",
        2: "PENDING",
        5: "IN CORRESPONDENCE",
        7: "SENT",
        10: "RETURN BY CORRESPENDENCE",
        11: "EXPIRED",
      };
      return badgeClass[status] || "STATE";
    },
  },
};
</script>
<style></style>
