<template>
  <div
    class="chat-container"
    :class="isDarkSkin ? 'bg-chat-dark' : 'bg-chat-light'"
    ref="scrollList"
  >
    <div class="chat-messages">
      <template v-for="(item, index) in comments">
        <div
          v-if="
            !(item.user_id == getUserId) && item.idUnread && item.idUnread === 1
          "
          :key="item.idUnread * 1000"
          class="d-flex justify-content-center"
        >
          <p>
            <b-badge href="#" variant="primary">
              {{ comments.filter((item) => item.unread).length }} unread
              messages</b-badge
            >
          </p>
        </div>
        <div
          :key="index"
          class="message"
          :class="
            item.user_id == getUserId
              ? 'message-sender '
              : `message-receiver ${item.unread ? 'unread' : 'read'}`
          "
        >
          <div class="row align-items-start">
            <b-avatar
              :variant="isDarkSkin ? 'warning' : 'info'"
              icon="person-fill
"
              :class="item.user_id == getUserId ? 'order-2' : 'order-1'"
            ></b-avatar>
            <div
              :class="
                item.user_id == getUserId ? 'order-1 mr-1' : 'order-2 ml-1'
              "
            >
              <p
                class="p-0 m-0"
                :class="item.user_id == getUserId ? 'text-right' : 'text-left'"
                style="font-weight: bold"
              >
                {{ item.user_id == getUserId ? "You" : item.user_name }}
              </p>
              <div
                :class="
                  item.user_id == getUserId
                    ? 'content-message-sender'
                    : 'content-message-receiver'
                "
              >
                <template v-if="item.is_file">
                  <div
                    v-if="
                      ['png', 'jpge', 'jpg', 'jpeg'].includes(item.file_type)
                    "
                    :style="containerImg(item)"
                    @click="openImage(item.file_type, item)"
                  ></div>
                  <div
                    v-else
                    @click="openImage(item.file_type, item)"
                    class="message-text"
                  >
                    <p class="cursor-pointer link-file">
                      {{ item.content }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="message-text">
                    {{ item.content }}
                  </div>
                </template>

                <div class="message-date text-muted text-right col-12">
                  {{ item.date_time | myGlobalWithHour }}
                  <template v-if="item.user_id == getUserId">
                    <tabler-icon
                      v-if="item.viewed"
                      size="18"
                      class="text-primary"
                      icon="ChecksIcon"
                    />
                    <tabler-icon
                      v-else
                      size="18"
                      class="text-secondary"
                      icon="ChecksIcon"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <CoolLightBox
      :items="itemImage"
      :index="showImage"
      :effect="'fade'"
      @close="showImage = null"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
export default {
  name: "ChatMessage",
  components: {
    CoolLightBox,
  },
  props: {
    getUserId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showImage: null,
      itemImage: [],
      readMessage: false,
      totalMessageUnread: 1,
    };
  },
  computed: {
    ...mapGetters({
      comments: "BinnacleStore/G_COMMENTS",
      newMessage: "BinnacleStore/G_NEW_MESSAGE_BINNACLE",
    }),
  },
  watch: {
    comments: {
      handler(value) {
        this.scrollComments();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    containerImg(item) {
      return `background-image: url(${item.file_path}); background-size: cover; background-position: center; width: 100%; height: 100px;border-radius:10px`;
    },
    openImage(extension, image) {
      if (this.isImage(extension)) {
        this.itemImage = [image.file_path];
        this.showImage = 0;
      } else {
        this.forceDownload(image.file_path, image.file_name);
      }
    },
    isImage(fileType) {
      const imageExtensions = ["jpg", "png", "jpeg", "gif"];
      return imageExtensions.includes(fileType.toLowerCase());
    },
    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    scrollComments() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
          setTimeout(() => {
            const chat = document.querySelector(".chat-container");
            const classDiv = ".message.message-receiver.unread";
            const unReadMessages = document.querySelectorAll(classDiv);
            if (unReadMessages.length > 0 && !this.readMessage) {
              const firstUnreadMessage = unReadMessages[0];
              const { offsetHeight, offsetTop } = firstUnreadMessage;
              const scroll = offsetTop - offsetHeight;
              chat.scrollTo({ top: scroll, behavior: "smooth" });
              if (this.totalMessageUnread === unReadMessages.length) {
                this.readMessage = true;
              }
              this.totalMessageUnread++;
            } else {
              this.$refs.scrollList.scrollTop =
                this.$refs.scrollList.scrollHeight;
            }
          }, 100);
        }, 100);
      });
    },
  },
};
</script>
<style lang="scss">
p.p-unread {
  border: 1px solid;
  border-radius: 10px;
  padding: 5px 10px;
}
</style>