var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{key:_vm.ncrs.id,staticClass:"table-new-customization custom-table",attrs:{"striped":"","small":"","items":_vm.ncrs,"tbody-tr-class":_vm.isDarkSkin ? 'table-round dark' : 'table-round',"fields":_vm.visibleFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(request)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.created_at)))])]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [(item.program_id)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(item.program_name),expression:"item.program_name",modifiers:{"bottom":true}}],staticClass:"d-flex justify-content-center m-0"},[_c('img',{attrs:{"src":_vm.getProgramImageRoute(item.program_id),"alt":"img_services"}})]):_vm._e()]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [([1, 2].includes(item.type_round))?_c('div',{staticClass:"text-primary cursor-pointer font-weight-bolder",on:{"click":function($event){return _vm.editFirstNote(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',[_vm._v("-")])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [([1, 2].includes(item.type_round))?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" Letter ")]):_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" Direct ")])]}},{key:"cell(tracking)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ListIcon","size":"18"},on:{"click":function($event){return _vm.openModalTracking(item)}}})]}},{key:"cell(items)",fn:function(data){return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.showRounds(data.index)}}},[_vm._v(" "+_vm._s(data.item.sumeq + data.item.sumtr + data.item.sumex)+" ")])]}},{key:"cell(disputes)",fn:function(ref){
var item = ref.item;
return [(
          item.state == 7 &&
          item.status_dispute == 0 &&
          item.date_diff > _vm.dateDiff
        )?_c('feather-icon',{staticClass:"cursor-pointer text-primary",staticStyle:{"font-size":"20px","margin-top":"5px"},attrs:{"icon":"PlusIcon","size":"22"},on:{"click":function($event){return _vm.openModalDispute(item)}}}):_vm._e(),(
          item.state == 7 &&
          ((item.status_dispute == 1 &&
            item.status_dispute_grilla == 'PENDING') ||
            item.status_dispute_grilla == 'COMPLETED') &&
          item.date_diff > _vm.dateDiff
        )?_c('b-badge',{staticClass:"cursor-pointer",staticStyle:{"font-weight":"bold","text-transform":"uppercase"},attrs:{"variant":item.status_dispute_grilla === 'PENDING'
            ? 'light-warning'
            : 'light-success'},on:{"click":function($event){return _vm.openModalViewDispute(item)}}},[_vm._v(" "+_vm._s(item.status_dispute_grilla)+" ")]):_vm._e()]}},{key:"row-details",fn:function(){return [_c('transition',{attrs:{"name":"fade"}},[_c('RoundDetail',{attrs:{"round":_vm.round,"ncrId":null,"clientData":_vm.client}})],1)]},proxy:true}])}),(_vm.showTracking)?_c('ModalTrackingRo',{attrs:{"data-tracking":_vm.trackingData},on:{"close":_vm.closeModalTracking}}):_vm._e(),(_vm.showDetailFN)?_c('DetailsModal',{attrs:{"details-round-data":_vm.roundData,"validate-modal":1},on:{"close":_vm.closeEditFirstNote}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }