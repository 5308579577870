<template>
  <div v-if="ownControl">
    <div class="mb-1">
      <b-button v-if="!isModal" variant="info" @click="closeModal">
        <feather-icon icon="ChevronLeftIcon" /> RETURN
      </b-button>
    </div>

    <b-table
      small
      responsive
      :fields="fields"
      :items="search"
      class="font-small-3 custom-table"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template v-slot:cell(date)="data">
        <p class="mb-0 font-weight-bold" style="min-width: 130px">
          {{ data.item.created_at | myGlobalDay }}
        </p>
      </template>
      <template v-slot:cell(user)="data">
        <p style="min-width: 130px" class="mb-0 font-weight-bold">
          {{ data.item.user_name }}
        </p>
      </template>
      <template v-slot:cell(action)="data">
        <p style="min-width: 130px" class="mb-0 font-weight-bold">
          {{ data.item.name_status }}
        </p>
      </template>
      <template v-slot:cell(status)="data">
        <p style="min-width: 180px" class="mb-0 font-weight-bold">
          {{ data.item.status_process }}
        </p>
      </template>
      <template v-slot:cell(observation)="data">
        <p style="min-width: 180px" class="mb-0 font-weight-bold">
          {{ data.item.obs }}
        </p>
      </template>
    </b-table>
  </div>
</template>
<script>
import dataFields from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/tracking-modal-ro/fields.data.js";

import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      fields: dataFields,
      trackings: [],
    };
  },
  created() {
    this.ownControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    async search() {
      try {
        const params = {
          id: this.data.id,
        };
        const data = await ClientsOptionsServices.getAllTrackingLetters(params);

        this.trackings = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.trackings;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
