<template>
  <b-modal
    v-model="visible"
    @hidden="close"
    title="Create Tracking"
    title-tag="h3"
    modal-class="modal-primary"
    hide-footer
  >
    <b-container>
      <account-client-header :account="account" :client="client" />
      <b-row>
        <b-col>
          <b-form-group label="Comment">
            <b-form-textarea v-model="comment" rows="5" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <b-button
            size="lg"
            @click="addTracking"
            class="fs-3"
            variant="success"
          >
            SAVE
          </b-button>
        </b-col>
      </b-row>
      <br />
    </b-container>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import AccountClientHeader from "../others/AccountClientHeader.vue";
import ApplicationsService from "../../../services/applications.service";
export default {
  components: { AccountClientHeader },
  props: { row: { type: Object, required: true } },
  data() {
    return {
      visible: false,
      comment: "",
    };
  },
  methods: {
    async addTracking() {
      let parameters = {
        caid: this.row.ca_id,
        done_form: this.row.done_form,
        file_image: "",
        file_name: "",
        file_size: "",
        id: this.row.app_id,
        iduser: this.currentUser.user_id,
        modul: this.moduleId,
        num: 0,
        status: 0, //this.action,
        status_file: null,
        t_comment: this.comment,
        type_charge: this.row.type,
      };

      let result = await this.showConfirmSwal();

      if (result.isConfirmed) {
        try {
          this.addPreloader();
          await ApplicationsService.updateStatusApp({ ...parameters });
          this.removePreloader();
          this.showSuccessSwal();
          this.close();
        } catch (error) {
          console.log(error);
          this.showErrorSwal(error);
        }
      } else {
        return;
      }
    },
    close() {
      this.visible = false;
      this.$emit("close");
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    account() {
      return this.row.account;
    },
    client() {
      return this.row.name_client;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.visible = true;
  },
};
</script>