<template>
  <b-card
    class="fixed-top-component border-warning"
    style="border-width: 2px !important"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="title-process">
          Credit Report
          <span class="title-platform">({{ nameProvider }})</span>
        </div>
        <b-badge :variant="`light-${process == 1 ? 'success' : 'warning'}`">
          {{ processInfo }}
        </b-badge>
      </div>
      <div
        class="d-flex justify-content-between align-items-center"
        style="gap: 10px"
      >
        <b-button variant="info" @click="showAccessCr" v-if="showInfoClient">
          <feather-icon icon="KeyIcon" />
          {{ showFiles && gridName == "showAccessCr" ? "Hide" : "Show" }}
          Access Credentials
        </b-button>
        <b-button variant="info" class="p-0" v-if="updateFiles.length > 0">
          <b-dropdown variant="link" no-caret boundary="viewport" class="p-0">
            <template #button-content>
              <span class="text-white">
                <feather-icon icon="FileIcon" />
                Update Files
                <span
                  v-if="updateFiles.length > 0"
                  class="bg-danger text-white rounded-circle"
                  style="padding: 1px 5px"
                  >{{ updateFiles.length }}
                </span>
              </span>
            </template>
            <b-dropdown-item
              v-for="file in updateFiles"
              :key="file.id"
              :href="file.url"
              target="_blank"
            >
              <amg-icon
                v-b-tooltip.hover.top="'View PDF'"
                class="cursor-pointer mt-0 mr-0 bigger text-danger"
                icon="FilePdfIcon"
                style="height: 18px; width: 18px"
              />
              <span>{{
                file.file_name.length > 15
                  ? file.file_name.slice(0, 15).concat("...")
                  : file.file_name
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-button>
        <b-button
          variant="info"
          @click="showFilesCollapsed"
          v-if="showInfoClient"
        >
          <feather-icon icon="FileIcon" />
          {{ showFiles && gridName == "showFiles" ? "Hide" : "Show" }}
          Files
        </b-button>
        <b-button
          variant="primary"
          :class="showInfoClient ? null : 'collapsed'"
          @click="toggleInfoClient"
          aria-controls="collapse-user"
          :aria-expanded="showInfoClient"
        >
          <feather-icon icon="UserIcon" />
          {{ showInfoClient ? "Hide" : "Show" }} Client Info
        </b-button>
        <b-button variant="outline-warning" @click="openFirstNoteModal">
          <feather-icon icon="FileTextIcon" class="mr-half" />FIRST NOTE
        </b-button>
      </div>
    </div>
    <b-collapse v-model="showInfoClient" id="collapse-user">
      <b-card class="border-1 border-secondary mt-2">
        <PersonalInformation
          :clientId="clientId"
          v-if="clientId"
          :showFiles="showFiles"
          :gridName="gridName"
        />
        <div v-else>No client selected</div>
      </b-card>
    </b-collapse>
     <!--First Note Modal-->
    <ModalNotasCredit
      v-if="firstNoteController && programId == 3"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesAll
      v-if="firstNoteController && programId == null"
      :note-info="noteInfo"
      :is-from-task-notes="true"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesTax
      v-if="firstNoteController && programId == 5"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesBoost
      v-if="firstNoteController && programId == 2"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesFirst
      v-if="firstNoteController && programId == 1"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesSpecialist
      v-if="firstNoteController && programId == 7"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
  </b-card>
</template>

<script>
import PersonalInformation from "@/views/commons/components/ncr/components/report/components/client-info/PersonalInformation.vue";
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";
import ModalNotasCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    dataWorkPlan: {
      type: Object,
      required: true,
    },
  },
  components: {
    PersonalInformation,
    ModalNotasCredit,
    ModalNotesAll,
    ModalNotesBoost,
    ModalNotesTax,
    ModalNotesFirst,
    ModalNotesParagon,
    ModalNotesSpecialist
  },
  async created() {
    await this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
      id: this.dataWorkPlan.client_account_id,
    });
    this.clientId = this.dataWorkPlan.client_account_id;
    this.getReport();
  },
  data() {
    return {
      showInfoClient: false,
      gridName: null,
      processed_by: "",
      processed_at: "",
      process: "",
      plataform_id: null,
      updateFiles: [],
      showFiles: false,
      clientId: this.dataWorkPlan.client_account_id,
      firstNoteController: false,
      programId: null,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
    }),

    nameProvider() {
      const names = {
        1: "IdentityIQ",
        3: "PrivacyGuard",
        7: "MyScoreIQ",
        8: "MyFreeScoreNow",
      };
      return names[this.plataform_id];
    },
    processInfo() {
      return this.process != 1
        ? "UNPROCESSED"
        : "PROCESSED BY: " + this.processed_by + " - " + this.processed_at;
    },
  },
  methods: {
    toggleInfoClient() {
      this.showInfoClient = !this.showInfoClient;
      if (this.showInfoClient) {
        this.clientId = this.dataWorkPlan.client_account_id;
      }
      this.gridName = "showInfoMain";
      this.showFiles = false;
    },
    async getReport() {
      try {
        this.addPreloader();
        const response = await ReportLeadService.getReports({
          id: this.dataWorkPlan.score_id,
        });
        this.plataform_id = response.data[0].plataform_id;
        this.process = response.data[0].process ?? 0;
        this.processed_by = response.data[0].processed_by;
        this.processed_at = response.data[0].processed_at;
        this.day_processed = response.data[0].day_processed;
        this.day_processed_format = this.day_processed_format
          ? moment(this.day_processed).format("MM/DD/YYYY")
          : moment().format("MM/DD/YYYY");
      } catch (error) {
      } finally {
        this.removePreloader();
      }
    },
    async openFirstNoteModal() {
      await this.setData();
      this.firstNoteController = true;
    },
    async setData() {
      return new Promise((resolve) => {
        this.noteInfo = {
          id: this.client.id,
          nameProgram: this.client.program_name,
          created: this.client.creates,
          nameClient: this.client.client_name,
          saleId: this.client.sale_id,
          sourcesName: this.client.sourcesname,
          pack: this.client.pack,
          capturedName: this.client.captured_name,
          sellerName: this.client.seller_name,
          module: this.client.module,
          clientType: this.client.client_type_id,
        };
        if ([1, 2, 3, 5, 7].includes(this.client.program_id)) {
          this.programId = this.client.program_id;
        }
        resolve();
      });
    },
    showFilesCollapsed() {
      if (this.showFiles && this.gridName == "showAccessCr") {
        this.showFiles = true;
        this.gridName = "showFiles";
      } else if (this.showFiles && this.gridName != "showAccessCr") {
        this.showFiles = false;
        this.gridName = "showInfoMain";
      } else {
        this.showFiles = true;
        this.gridName = "showFiles";
      }
    },
    showAccessCr() {
      if (this.showFiles && this.gridName == "showFiles") {
        this.showFiles = true;
        this.gridName = "showAccessCr";
      } else if (this.showFiles && this.gridName != "showFiles") {
        this.showFiles = false;
        this.gridName = "showInfoMain";
      } else {
        this.showFiles = true;
        this.gridName = "showAccessCr";
      }
    },
    closeFirstNoteModal() {
      this.firstNoteController = false;
    },
  },
};
</script>

<style>
</style>
