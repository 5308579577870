<template>
  <div>
    <div class="chat-input">
      <div class="input-container">
        <input v-model="comment.content" @keyup.enter="insertMessage" />
        <feather-icon
          icon="PaperclipIcon"
          size="22"
          class="cursor-pointer chat-action-paper-icon"
          @click="openModalFiles"
        />
        <feather-icon
          size="22"
          icon="SendIcon"
          class="chat-action-bar-icon cursor-pointer"
          @click="insertMessage"
        />
      </div>
    </div>
    <ModalUploadFiles
      v-if="modalFiles"
      @sendFiles="sendFiles"
      @closeModal="closeModalFiles"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ModalUploadFiles from "@/views/commons/binnacle/Components/Modals/ModalUploadFiles.vue";
export default {
  name: "MessageInput",
  components: {
    ModalUploadFiles,
  },
  data() {
    return {
      comment: {
        content: "",
        type: 1,
        is_file: false,
        file: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      client_information: "BinnacleStore/G_CLIENT_INFORMATION",
      currentUser: "auth/currentUser",
      modalFiles: "BinnacleStore/G_MODAL_FILES",
    }),
  },
  watch: {
    modalFiles(value) {
      if (!value) {
        this.removePreloader();
        this.closeModalFiles();
      }
    },
  },
  methods: {
    ...mapActions({
      insertCommentsBinnacle: "BinnacleStore/insertCommentsBinnacle",
      insertFilesBinnacle: "BinnacleStore/insertFilesBinnacle",
      showModalFiles: "BinnacleStore/showModalFiles",
    }),
    async insertMessage(event) {
      if (event.shiftKey) {
        event.preventDefault();
        return;
      }
      if (this.comment.content == null || this.comment.content.trim() == "") {
        return;
      }
      this.comment.content = this.comment.content.trim();
      try {
        const tempContent = this.comment.content;
        const params = {
          id: this.client_information.id,
          user_id: this.currentUser.user_id,
          content: tempContent,
          type: this.client_information.section,
        };
        this.insertCommentsBinnacle(params);
        this.comment.content = tempContent;
        this.comment.content = null;
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        });
      }
    },
    sendFiles(files) {
      if (files.length === 0) return;
      try {
        let formData = {
          id: this.client_information.id,
          user_id: this.currentUser.user_id,
          files: [],
          type: this.client_information.section,
        };
        files.forEach((file) => {
          formData.files.push(file);
        });
        this.addPreloader();
        this.insertFilesBinnacle(formData);
      } catch (ex) {}
    },
    openModalFiles() {
      this.showModalFiles(true);
    },
    closeModalFiles() {
      this.showModalFiles(false);
    },
  },
  mounted() {},
};
</script>