export default [
  {
    key: "name",
    label: "Creditor Name",
    thStyle: "width: 30%",
  },
  {
    key: "account_number",
    label: "Account Number",
    thStyle: "width: 12%",
  },
  {
    key: "account_type_text",
    label: "Account Type",
    thStyle: "width: 12%",
  },
  {
    key: "type_classification",
    label: "Removable",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 12%",
  },
  {
    key: "status_value",
    label: "Status",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 12%",
  },
  {
    key: "classification",
    label: "Classification",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 12%",
  },
  {
    key: "bureau_id",
    label: "Bureau",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 10%",
  },
];
