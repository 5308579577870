<template>
  <b-modal
    v-model="visible"
    id="modal-action"
    :title="actionText"
    title-tag="h3"
    modal-class="modal-primary"
    size="md"
    :header-bg-variant="actionClass"
    @hidden="close"
    no-close-on-backdrop
    centered
    no-close-on-esc
  >
    <template #default>
      <b-container>
        <account-client-header :account="account" :client="client_name" />
        <b-row v-if="(num == 1 && option == 1) || num == 2">
          <b-col>
            <b-form-group label="Comment:">
              <b-form-textarea v-model="comment" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="num == 1 && option == 2">
          <b-col>
            <validation-provider
              ref="motiveValidator"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Comment:">
                <div class="d-flex justify-content-start">
                  <v-select
                    transition=""
                    :options="motiveCe"
                    label="title"
                    class="w-100 sm"
                    :class="!comment || errors[0] ? 'border-danger' : ''"
                    :reduce="(val) => val.title"
                    v-model="comment"
                    :clearable="false"
                  />
                  <feather-icon
                    v-if="isCeo || isSupervisor || isChief"
                    class="text-primary ml-1"
                    style="margin-top: 5px"
                    icon="PlusCircleIcon"
                    size="20"
                    @click="createEditMotiveModalOn = true"
                  />
                </div>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <div v-if="num == 2 && moduleForSpecialists.includes(moduleId)">
          Optional:
          <upload-files
            :filesArray="filesArray"
            v-model="fileSelected"
            :single="true"
            :image="false"
          />
          {{ file.name }}
        </div>
        <modal-motives-form
          v-if="createEditMotiveModalOn"
          :stateModal="1"
          :motiveData="{}"
          @hide="closeModalMotive"
          @reload="reloadModalMotive"
        />
      </b-container>
    </template>

    <template #modal-footer>
      <b-button :variant="actionClass" @click="sendData" :disabled="isDisable">
        {{
          num != 3 ? actionText.substr(8) : option == 2 ? "SAVE" : actionText
        }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
import ApplicationsService from "@/views/commons/components/applications/services/applications.service.js";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import UploadFiles from "@/views/commons/utilities/UploadFiles.vue";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import MotivesService from "@/views/ce-digital/sub-modules/settings/views/motives/service/motives.service.js";
import ModalMotivesForm from "@/views/ce-digital/sub-modules/settings/views/motives/components/ModalMotivesForm.vue";
import AppointmentService from "@/views/commons/components/other-services/service/appointment.service";
export default {
  components: {
    AccountClientHeader,
    vSelect,
    ModalCardCreate,
    UploadFiles,
    ValidationProvider,
    ValidationObserver,
    ModalMotivesForm,
    OtherPaymentOptions,
  },
  props: {
    action: { required: true, type: Number },
    data: { required: true, type: Object },
    num: { required: true, type: Number },
    otherServices: { type: Boolean },
  },
  data() {
    return {
      visible: false,
      comment: "",
      filesArray: [],
      fileSelected: [],
      client_name: "",
      account: "",
      motiveCe: [],
      createEditMotiveModalOn: false,
      sendingData: false,
    };
  },
  methods: {
    close(visible = false) {
      this.$emit("close");
      this.$emit("changePaidOption", null);
      this.visible = visible;
    },
    params() {
      return {
        ca_id_client: this.data.ca_id,
        done_form: this.data.done_form,
        file_image: "",
        file_name: this.file.name,
        file_size: this.file.size,
        t_id: this.data.app_id,
        iduser: this.currentUser.user_id,
        modul: this.moduleId,
        num: this.num,
        t_status: this.option,
        status_file: null,
        t_comment: this.comment,
        type_charge: this.data.type,
      };
    },
    async sendData() {
      if (this.otherServices) {
        try {
          let { isConfirmed } = await this.showConfirmSwal();
          if (isConfirmed) {
            this.addPreloader();
            const {data,status}= await AppointmentService.updateAppointmentStatus(
              {
                type: "status",
                id: this.data.id,
                status: this.action == 1 ? "ACCEPTED" : "REJECTED",
                app_status: this.data.app_status,
                comment: this.comment,
                user_id: this.currentUser.user_id,
              }
            );
            if (status == 200) {
              this.showSuccessSwal();
              this.$emit("refreshTable");
              this.close();
            }

          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
        return;
      }
      try {
        const result = await this.showConfirmSwal();

        if (result.isConfirmed) {
          this.addPreloader();

          await this.updateStatusApp(this.params());

          if (this.option == 2 && (this.num == 1 || this.num == 2)) {
            await this.updateCompleted();
          }
          this.showSuccessSwal();
          this.$emit("refreshTable");
          this.close();
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status == 422) {
            this.showWarningSwal(error.response.data.errors.t_comment[0]);
          } else {
            this.showErrorSwal(error);
          }
        }
      } finally {
        this.removePreloader();
      }
    },
    async updateStatusApp(parameters) {
      try {
        return await ApplicationsService.updateStatusApp({ ...parameters });
      } catch (error) {
        throw error;
      }
    },
    async updateCompleted() {
      if (
        (this.option == 2 && this.num == 1) ||
        (this.option == 2 && this.num == 2)
      ) {
        const params = {
          id_app: this.data.app_id,
          clientAccountId: this.data.ca_id,
          userId: this.currentUser.user_id,
        };
        await ApplicationsService.updateStatusApplication(params);
      }
    },
    closeModalCardCreate(visible = false) {
      this.isActiveModalCreateCard = visible;
    },
    closeModalDeleteCard(visible = false) {
      this.isActiveModalDeleteCard = visible;
    },
    async getMotives() {
      try {
        const result = await MotivesService.allMotives();
        if (result.status == 200) {
          this.motiveCe = result.data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
    closeModalMotive() {
      this.createEditMotiveModalOn = false;
    },
    reloadModalMotive() {
      this.getMotives();
      this.closeModalMotive();
    },
    async getMotives() {
      try {
        const result = await MotivesService.allMotives();
        if (result.status == 200) {
          this.motiveCe = result.data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
    closeModalMotive() {
      this.createEditMotiveModalOn = false;
    },
    reloadModalMotive() {
      this.getMotives();
      this.closeModalMotive();
    },
  },
  computed: {
    file() {
      return this.fileSelected.length > 0 ? this.fileSelected[0] : "";
    },
    actionText() {
      switch (parseInt(this.option)) {
        case 0:
          if (this.num == 2) return "RESULT: PENDING";
          break;
        case 1:
          if (this.num == 1) return "Status: Accepted";
          if (this.num == 2) return "Result: Accepted";
          break;
        case 2:
          if (this.num == 1) return "Status: Rejected";
          if (this.num == 2) return "Result: Denied";
          break;
      }
    },
    actionClass() {
      switch (parseInt(this.option)) {
        case 1:
          return "success";
          break;
        case 2:
          return "danger";
          break;
      }
    },
    isDisable() {
      if (this.sendingData) {
        return true;
      }
      if (this.action !== 1) {
        return this.comment === "";
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      program: "DebtSolutionApplications/program",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    moduleForSpecialists() {
      return [11, 25, 28, 29, 30];
    },
  },
  async created() {
    this.option = this.action;

    if (this.num == 1 && this.option == 2) {
      this.getMotives();
    }
    this.visible = true;
  },
  mounted() {
    this.account = this.data.account
      ? this.data.account
      : this.data.client_account;
    this.client_name = this.data.name_client
      ? this.data.name_client
      : this.data.client_name;
  },
};
</script>
