<template>
  <div :class="isDarkSkin ? 'black' : 'light'" class="rounded mt-1 card">
    <b-tabs
      :content-class="otherFiles.length > 0 ? 'my-3' : 'my-3'"
      fill
      justified
    >
      <b-tab title="Files" class="text-center">
        <b-row
          align-h="center"
          :style="otherFiles.length > 0 ? 'height: 150px !important' : ''"
          style="overflow: auto !important"
        >
          <template v-if="otherFiles.length > 0">
            <template v-for="(item, index) in otherFiles">
              <b-col :key="index" md="3" class="px-1 cursor-pointer">
                <img
                  :src="getImageFile(item.content)"
                  width="50"
                  @click="forceDownload(item.file_path, item.content)"
                  class="cursor-pointer"
                  :alt="item.content"
                />
                <p
                  class="text-truncate link-file cursor-pointer"
                  v-b-tooltip.hover
                  :title="item.content"
                  @click="forceDownload(item.file_path, item.content)"
                >
                  {{ item.content }}
                </p>
              </b-col>
            </template></template
          >
          <template v-else>
            <b-icon icon="folder-x" font-scale="5"></b-icon>
          </template>
        </b-row>
      </b-tab>

      <b-tab title="Images" lazy class="text-center">
        <b-row
          align-h="center"
          :style="images.length > 0 ? 'height: 150px !important' : ''"
          style="overflow: auto !important"
        >
          <template v-if="images.length > 0">
            <template v-for="(item, index) in images">
              <b-col :key="index" md="3" class="px-1">
                <img
                  :src="getImageFile(item.content)"
                  width="50"
                  @click="forceDownload(item.file_path, item.content)"
                  class="cursor-pointer"
                  :alt="item.content"
                />
                <p
                  class="text-truncate link-file cursor-pointer"
                  v-b-tooltip.hover
                  :title="item.content"
                  @click="forceDownload(item.file_path, item.content)"
                >
                  {{ item.content }}
                </p>
              </b-col>
            </template></template
          >
          <template v-else>
            <b-icon icon="folder-x" font-scale="5"></b-icon>
          </template> </b-row
      ></b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ChatOtherFiles",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      files: "BinnacleStore/G_OTHER_FILES",
    }),
    images() {
      return this.files.filter((item) => this.isImage(item.file_type));
    },
    otherFiles() {
      return this.files.filter((item) => this.isOtherFile(item.file_type));
    },
  },
  methods: {
    isImage(fileType) {
      const imageExtensions = ["jpg", "png", "jpeg", "gif"];
      return imageExtensions.includes(fileType.toLowerCase());
    },
    isOtherFile(fileType) {
      return !this.isImage(fileType);
    },
    getImageFile(filename) {
      const extension = filename.split(".").pop().toLowerCase();

      const extensionMappings = {
        jpg: "jpg.png",
        png: "png.png",
        jpeg: "jpg.png",
        gif: "jpg.png",
        xls: "excel.png",
        xlsx: "excel.png",
        html: "html.png",
        pdf: "pdf.png",
        docx: "doc.png",
        pptx: "ppt.jpg",
        ppt: "ppt.jpg",
        default: "archivodesc.png",
      };

      const getImageFile =
        extensionMappings[extension] || extensionMappings.default;
      return `/assets/images/drag-drop/ext_files/${getImageFile}`;
    },
    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>