<template>
  <b-modal
    v-model="showModal"
    ref="modalName"
    modal-class="custom-modal-amg"
    title-class="h3 text-white"
    title="ADD NEW DISPUTE"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <b-card no-body header-class="p-1" style="padding: 2px" v-if="isAccount">
      <label style="margin-bottom: 3px">Accounts to dispute:</label>
      <b-table
        striped
        :items="selectedElements"
        :sticky-header="'55vh'"
        :fields="fields"
        class="table-new-customization"
      >
        <template #cell(bureau_id)="{ item }">
          <div v-if="item.bureau_id == 1">
            <span>TRANS</span><span style="color: #4d917a">UNION</span>
          </div>
          <span
            v-else-if="item.bureau_id == 2"
            style="color: #0566b7; font-weight: bold"
            >EXPERIAN</span
          >
          <span
            v-else
            style="color: #822a3a; font-weight: bold; font-style: italic"
            >EQUIFAX</span
          >
        </template>
      </b-table>
    </b-card>
    <validation-observer ref="form">
      <b-row>
        <b-col cols="12">
          <label> Dispute type: </label>
          <validation-provider v-slot="{ errors }" name="type" rules="required">
            <b-form-radio-group
              v-model="dispute_type"
              :options="optionsDisputeType"
              required
              @change="destination = null"
              class="w-100"
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              button-variant="outline-secondary"
              name="radio-btn-default"
              buttons
            >
            </b-form-radio-group>
          </validation-provider>
        </b-col>

        <b-col v-if="isLetterDispute" cols="12" class="w-100 mt-1">
          <label> Destination:</label>
          <validation-provider
            v-slot="{ errors }"
            name="destination"
            rules="required"
          >
            <b-form-radio-group
              v-model="destination"
              :options="optionsDestination"
              required
              class="w-100"
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              button-variant="outline-secondary"
              name="radio-btn-default"
              @change="
                (bureau = null),
                  (round_number = null),
                  (creditorId = null),
                  (creditorName = null)
              "
              buttons
            >
            </b-form-radio-group>
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isBureauDestination" class="mt-1">
          <label> Round number:</label>
          <validation-provider
            v-slot="{ errors }"
            name="round"
            rules="required"
          >
            <b-form-select
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              id="select"
              v-model="round_number"
              :options="optionsRound"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select an option</b-form-select-option
                >
              </template>
            </b-form-select>
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isBureauDestination" class="mt-1">
          <label> Bureau:</label>
          <b-input-group>
            <b-input-group-text class="w-100 d-flex justify-content-center">
              <b-img
                v-for="bureau in selectedBureaus"
                :key="bureau.id"
                class="mr-1 ml-1"
                v-b-tooltip.hover.top="bureau.name"
                :src="
                  bureau.id == 1
                    ? require('@/assets/images/icons/transunion.png')
                    : bureau.id == 2
                    ? require('@/assets/images/icons/experian.png')
                    : require('@/assets/images/icons/equifax.png')
                "
              />
            </b-input-group-text>
          </b-input-group>
        </b-col>

        <b-col cols="12" v-if="isCreditorDestination" class="mt-1">
          <label>Creditor: </label>
          <validation-provider
            v-slot="{ errors }"
            name="creditor"
            rules="required"
          >
            <v-select
              class="select__creditor"
              :class="errors.length > 0 ? 'border-danger' : ''"
              label="text"
              placeholder="Search..."
              :options="optionsCreditor"
              :value="creditorName"
              @search="searchCreditors"
              @input="selectCreditor"
            />
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isLetterDispute" class="mt-1">
          <label> Letter type:</label>
          <validation-provider
            v-slot="{ errors }"
            name="letter_type"
            rules="required"
          >
            <b-form-select
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              id="select"
              v-model="letter_type"
              :options="optionsLetterType"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select an option</b-form-select-option
                >
              </template>
            </b-form-select>
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isLetterDispute" class="mt-1">
          <label> Format:</label>
          <validation-provider
            v-slot="{ errors }"
            name="format"
            rules="required"
          >
            <b-form-select
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              id="select"
              v-model="format"
              :options="optionsFormat"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select an option</b-form-select-option
                >
              </template>
            </b-form-select>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="mt-1">
          <label> Reason:</label>
          <validation-provider
            v-slot="{ errors }"
            name="reason"
            rules="required"
          >
            <b-form-select
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              id="select"
              v-model="reason"
              :options="optionsReason"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select an option</b-form-select-option
                >
              </template>
            </b-form-select>
          </validation-provider>
        </b-col>

        <b-col cols="6" class="mt-1">
          <label> Instruction:</label>
          <validation-provider
            v-slot="{ errors }"
            name="instruction"
            rules="required"
          >
            <b-form-select
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              id="select"
              v-model="instruction"
              :options="optionsInstruction"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select an option</b-form-select-option
                >
              </template>
            </b-form-select>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>

    <template #modal-footer>
      <b-button variant="custom-amg" @click="save"> SAVE </b-button>
    </template>
  </b-modal>
</template>
<script>
import _ from "lodash";

// SERVICE
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
import LeadService from "@/views/crm/services/lead/index.js";

// STORE
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    selectedElements: {
      required: true,
    },
    isAccount: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "creditor_name",
          label: "Account",
        },
        {
          key: "account_number",
          label: "Account #",
        },
        {
          key: "bureau_id",
          label: "Bureau",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "classification_ncr",
          label: "Classification",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      showModal: true,
      dispute_type: null,
      bureau: null,
      creditorName: null,
      creditorId: null,
      letter_type: null,
      format: null,
      formatText: "",
      destination: null,
      round_number: null,
      optionsDestination: [
        {
          value: "Bureau",
          text: "BUREAU",
        },
        {
          value: "Creditor",
          text: "CREDITOR",
        },
      ],
      optionsDisputeType: [
        {
          value: "Letter",
          text: "LETTER",
        },
        {
          value: "Direct",
          text: "DIRECT",
        },
      ],
      optionsRound: [
        {
          value: 1,
          text: "FIRST ROUND",
          disabled: false,
        },
        {
          value: 2,
          text: "SECOND ROUND",
          disabled: false,
        },
        {
          value: 3,
          text: "THIRD ROUND",
          disabled: false,
        },

        {
          value: 4,
          text: "FOURTH ROUND",
          disabled: false,
        },
        {
          value: 5,
          text: "FIFTH ROUND",
          disabled: false,
        },
      ],

      optionsCreditor: [],
      optionsLetterType: [
        {
          value: "Regular",
          text: "REGULAR",
        },
        {
          value: "Certified",
          text: "CERTIFIED",
        },
      ],
      optionsFormat: [
        {
          value: 1,
          text: "SIMPLE LETTER 1",
        },
        {
          value: 2,
          text: "SIMPLE LETTER 2",
        },
        {
          value: 3,
          text: "PROVE IT LETTER",
        },
      ],
      optionsReason: [],
      optionsInstruction: [],
      ownControl: false,
      observation: null,
      selectedBureaus: [],
      optionsBureau: [
        {
          id: 1,
          name: "TransUnion",
        },
        {
          id: 2,
          name: "Experian",
        },
        {
          id: 3,
          name: "Equifax",
        },
      ],
      reason: null,
      instruction: null,
      instructionText: null,
      reasonText: null,
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  async created() {
    await this.getReasonsInstructions();
    await this.getBureaus();
    if (this.accountIsSelected) {
      this.disableRounds();
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/G_ID_REQUEST_WORKPLAN",
      G_ID_UPDATE_REQUEST: "RequestWorkPlanStore/G_ID_UPDATE_REQUEST",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
    }),
    isLetterDispute() {
      return this.dispute_type == "Letter";
    },

    isDirectDispute() {
      return this.dispute_type == "Direct";
    },

    isBureauDestination() {
      return this.destination == "Bureau";
    },

    isCreditorDestination() {
      return this.destination == "Creditor";
    },
    roundLetterDispute() {
      return this.$route.params.section == "update"
        ? this.$route.params.idSection
        : null;
    },
    accountIsSelected() {
      return this.selectedElements.some((elem) => elem.type_element == 4);
    },
    classifications() {
      return [
        ...new Set(
          this.selectedElements.map((element) => element.classification_ncr)
        ),
      ];
    },
  },

  methods: {
    ...mapActions({
      A_DISPUTE_ACCOUNTS: "RequestWorkPlanStore/A_DISPUTE_ACCOUNTS",
    }),

    disableRounds() {
      this.G_DISPUTE_DATA.filter(
        (data) => data.type_element == 4 && data.letter_type !== null
      ).forEach((item) => {
        this.optionsRound[item.round_number - 1].disabled = true;
      });
    },

    getBureaus() {
      const bureaus = this.selectedElements.map((ele) => ele.bureau_id);
      this.optionsBureau.forEach((element) => {
        if (bureaus.includes(element.id)) this.selectedBureaus.push(element);
      });
    },
    searchCreditors: _.debounce(async function (search) {
      if (search.length < 1 || search == undefined) {
        this.optionsCreditor = [];
        return;
      }
      const res = await DebtSolutionService.getCreditorsByName(search);
      res.data.forEach((item) => {
        this.optionsCreditor.push({
          value: item.id,
          text: item.value,
        });
      });
    }, 500),
    async selectCreditor(item) {
      if (item == null) {
        this.creditorName = null;
        this.creditorId = null;
        return;
      }
      this.creditorName = item.text;
      this.creditorId = item.value;
    },
    async getReasonsInstructions() {
      if (
        this.isAccount &&
        this.classifications.length == 1 &&
        this.classifications[0] !== null
      ) {
        const data = await RequestWorkPlanService.getDisputeReasonsInstructions(
          { type: this.classifications[0] }
        );
        this.orderArray(data.reasons);
        this.orderArray(data.instructions);
        this.optionsReason = data.reasons;
        this.optionsInstruction = data.instructions;
      } else {
        // personal profile, inquirires, public information
        const resReasons = await LeadService.getReasons();
        if (resReasons.status == 200) {
          this.setDataOptions(resReasons.data, this.optionsReason);
        }

        const resInstructions = await LeadService.getInstructions();
        if (resInstructions.status == 200) {
          this.setDataOptions(resInstructions.data, this.optionsInstruction);
        }
      }
    },
    orderArray(arr) {
      arr.sort(function (a, b) {
        let textA = a.text.toUpperCase();
        let textB = b.text.toUpperCase();

        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
    },

    setDataOptions(data, options) {
      data.forEach((item) => {
        options.push({
          value: item.id,
          text: item.value,
        });
      });
    },

    closeModal() {
      this.$emit("close");
    },

    async save() {
      try {
        this.addPreloader();
        const result = await this.$refs.form.validate();

        if (result) {
          const params = {
            dispute_type: this.dispute_type,
            destination: this.destination,
            creditor_id: this.creditorId,
            creditor_name_selected: this.creditorName,
            round_number:
              this.isDirectDispute || this.isCreditorDestination
                ? 1
                : this.round_number,
            letter_type: this.letter_type,
            format: this.format,
            observation: this.observation,
            format_name: this.formatText,
            request_work_plan_id: this.G_ID_REQUEST_WORKPLAN,
            round_letter_dispute_id: this.G_ID_UPDATE_REQUEST,
            reason_id: this.reason,
            reason_name: this.reasonText,
            instruction_id: this.instruction,
            instruction_name: this.instructionText,
          };

          this.selectedElements.forEach(async (element) => {
            const accountToDispute = { ...element, ...params };
            await this.A_DISPUTE_ACCOUNTS(accountToDispute);
          });

          this.showSuccessSwal("Dispute detail generated successfully");
          this.$emit("resetSelectedElem");
          this.closeModal();
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
  },
  watch: {
    format(value) {
      this.optionsFormat.forEach((option) => {
        if (option.value === value) {
          this.formatText = option.text;
        }
      });
    },
    reason(value) {
      this.optionsReason.forEach((option) => {
        if (option.value === value) {
          this.reasonText = option.text;
        }
      });
    },
    instruction(value) {
      this.optionsInstruction.forEach((option) => {
        if (option.value === value) {
          this.instructionText = option.text;
        }
      });
    },
  },
};
</script>

<style scoped>
.select__creditor--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}
</style>
