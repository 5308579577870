<template>
  <div
    v-if="ownControl"
  >
    <div class="col-lg-12 col-md-12">

      <div
        class="mb-1"
      >
        <b-button
          v-if="!isModal"
          variant="info"
          @click="closeModal"
        >
          <feather-icon
            icon="ChevronLeftIcon"
          /> RETURN
        </b-button>
      </div>
      <div
        v-if="!direct"
        class="form-group"
      >
        <p>FORMAT :<span
          class="ml-1"
          style="font-weight: bold;font-size: 15px;"
        >{{ this.dataRound.format }} </span>
          <b-button
            variant="success"
            size="sm"
            class="cursor-pointer ml-1"
            @click="showviewpdf"
          >
            <feather-icon
              icon="EyeIcon"
              size="14"
            /> SHOW LETTERS BY BUREAU
          </b-button></p>
      </div>
      <div
        class="form-group"
        style="text-align:right"
      >
        <b-button
          v-if="((modulId === 1 || ((modulId === 2 || modulId === 6) && modul === 4) || ((modulId === 2 || modulId === 3) && modul === 11)) && validateModal === 0)"
          variant="primary"

          @click="openmodal"
        >
          <feather-icon
            icon="PlusIcon"
            size="15"
          /> ADD
        </b-button>
      </div>
      <div
        class="row"
        style="height: 250px;overflow: auto;"
      >
        <div class="col-lg-12">
          <span style="font-size: 17px;color: #BAA345;font-weight: bold;">PERSONAL INFORMATION</span>
        </div>
        <div class="col-lg-12">
          <b-table-simple
            v-if="view1"
            class="table"
          >
            <b-tbody>
              <b-tr style="height: 50px;">
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-else>
            <b-table-simple
              v-if="deleteAccountsPi.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr class="th-bg">
                  <b-th style="width:150px" />
                  <b-th style="font-weight: bold;width:300px;text-align:center">
                    <span>Trans</span><span style="color:#4d917a">Union</span>
                  </b-th>
                  <b-th style="color:#0566b7;font-weight: bold;width:300px;text-align:center">
                    Experian
                  </b-th>
                  <b-th style="color:#822a3a;font-weight: bold;width:300px;text-align:center">
                    EQUIFAX
                  </b-th>
                </b-tr>
                <b-tr
                  v-for="(item, index) in piname"
                  v-show="item.type == 1"
                  :key="index"
                >
                  <b-td
                    v-if="index == 0"
                    style="width:150px"
                  >
                    NAME
                  </b-td>
                  <b-td
                    v-else
                    style="width:150px"
                  />
                  <b-td colspan="3">
                    <b-table-simple
                      class="table"
                      style="margin-bottom:0"
                    >
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[1].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[2].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[2].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple
              v-if="deleteAccountsPi.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr
                  v-for="(item, index) in pidob"
                  v-show="item.type == 2"
                  :key="index"
                >
                  <b-td
                    v-if="index == 0"
                    style="width:150px"
                  >
                    DATE OF BIRTH
                  </b-td>
                  <b-td v-else />
                  <b-td colspan="3">
                    <b-table-simple
                      class="table"
                      style="margin-bottom:0"
                    >
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[1].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[2].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[2].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple
              v-if="deleteAccountsPi.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr
                  v-for="(item, index) in piadd"
                  v-show="item.type == 3"
                  :key="index"
                >
                  <b-td
                    v-if="index == 0"
                    style="width:150px"
                  >
                    ADDRESS
                  </b-td>
                  <b-td v-else />
                  <b-td colspan="3">
                    <b-table-simple
                      class="table"
                      style="margin-bottom:0"
                    >
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[1].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[2].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[2].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple
              v-if="deleteAccountsPi.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr
                  v-for="(item, index) in pissn"
                  v-show="item.type == 4"
                  :key="index"
                >
                  <b-td
                    v-if="index == 0"
                    style="width:150px"
                  >
                    SSN
                  </b-td>
                  <b-td v-else />
                  <b-td colspan="3">
                    <b-table-simple
                      class="table"
                      style="margin-bottom:0"
                    >
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[1].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[2].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[2].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[1].content }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editPi(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deletePi(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        >
                          <span>{{ JSON.parse(item.pi)[0].content }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editPi(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deletePi(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;position:relative;border-top:0;padding: 0px;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div
              v-if="deleteAccountsPi.length == 0"
              class="alert-not-found"
            >
              No Registers Found
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <span style="font-size: 17px;color: #BAA345;font-weight: bold;">INQUIRIES</span>
        </div>
        <div class="col-lg-12">
          <b-table-simple
            v-if="view2"
            class="table"
          >
            <b-tbody>
              <b-tr style="height: 50px;">
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-else>
            <b-table-simple
              v-if="deleteAccountsIn.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr class="th-bg">
                  <b-th style="width:150px">
                    Credit Inquire
                  </b-th>
                  <b-th style="font-weight: bold;width:300px;text-align:center">
                    <span>Trans</span><span style="color:#4d917a">Union</span>
                  </b-th>
                  <b-th style="color:#0566b7;font-weight: bold;width:300px;text-align:center">
                    Experian
                  </b-th>
                  <b-th style="color:#822a3a;font-weight: bold;width:300px;text-align:center">
                    EQUIFAX
                  </b-th>
                </b-tr>
                <b-tr
                  v-for="(item, index) in deleteAccountsIn"
                  :key="index"
                >
                  <b-td style="width:150px">
                    <div><span>{{ JSON.parse(item.pi)[0].content }}</span></div>
                    <div><span>{{ item.date }}</span></div>
                  </b-td>
                  <b-td colspan="4">
                    <b-table-simple class="table">
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 1"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[2].bureau_id == 1"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 1 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></feather-icon></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;padding-left: 25px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 2"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[2].bureau_id == 2"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 2 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></feather-icon></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 3"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[2].bureau_id == 3"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 3 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 3 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></feather-icon></feather-icon></feather-icon></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 1"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;padding-left: 25px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 2"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 3"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></feather-icon></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;border-top:none;text-align:center"
                        >
                          <i class="fas fa-check" /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;padding-left: 25px;border-top:none;text-align:center"
                        >
                          <i class="fas fa-check" /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;padding-left: 25px;border-top:none;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;border-top:none;text-align:center"
                        >
                          <i class="fas fa-check" /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editIn(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deleteIn(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div
              v-if="deleteAccountsIn.length == 0"
              class="alert-not-found"
            >
              No Registers Found
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <span style="font-size: 17px;color: #BAA345;font-weight: bold;">PUBLIC RECORDS</span>
        </div>
        <div class="col-lg-12">
          <b-table-simple
            v-if="view3"
            class="table"
          >
            <b-tbody>
              <b-tr style="height: 50px;">
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-else>
            <b-table-simple
              v-if="deleteAccountsPr.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr class="th-bg">
                  <b-th style="width:150px" />
                  <b-th style="font-weight: bold;width:300px;text-align:center">
                    <span>Trans</span><span style="color:#4d917a">Union</span>
                  </b-th>
                  <b-th style="color:#0566b7;font-weight: bold;width:300px;text-align:center">
                    Experian
                  </b-th>
                  <b-th style="color:#822a3a;font-weight: bold;width:300px;text-align:center">
                    EQUIFAX
                  </b-th>
                </b-tr>
                <b-tr
                  v-for="(item, index) in deleteAccountsPr"
                  :key="index"
                >
                  <b-td style="width:150px">
                    <div><span>{{ JSON.parse(item.pi)[0].public_record }}</span></div>
                    <div><span>{{ JSON.parse(item.pi)[0].court_sase }}</span></div>
                    <div><span>{{ item.date }}</span></div>
                  </b-td>
                  <b-td colspan="4">
                    <b-table-simple class="table">
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 1"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[2].bureau_id == 1"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 1 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></feather-icon></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;padding-left: 25px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 2"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[2].bureau_id == 2"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 2 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></feather-icon></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 3"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[2].bureau_id == 3"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 3 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[2].bureau_id == 3 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></feather-icon></feather-icon></feather-icon></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 1"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 1 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;padding-left: 25px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 2"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 2 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></feather-icon></feather-icon></b-td>
                        <b-td style="width:300px;border-top:none;text-align:center">
                          <i
                            v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                            class="fas fa-check"
                          /><i
                            v-else-if="JSON.parse(item.pi)[1].bureau_id == 3"
                            class="fas fa-check"
                          /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[1].bureau_id == 3 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></feather-icon></feather-icon></b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;border-top:none;text-align:center"
                        >
                          <i class="fas fa-check" /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 1 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;padding-left: 25px;border-top:none;text-align:center"
                        >
                          <i class="fas fa-check" /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 2 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;padding-left: 25px;border-top:none;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;border-top:none;text-align:center"
                        >
                          <i class="fas fa-check" /><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3)"
                            icon="EditIcon"
                            style="cursor:pointer;"
                            @click="editPr(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && JSON.parse(item.pi)[0].bureau_id == 3 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            style="cursor:pointer;margin-left: 5px;"
                            @click="deletePr(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div
              v-if="deleteAccountsPr.length == 0"
              class="alert-not-found"
            >
              No Registers Found
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <span style="font-size: 17px;color: #BAA345;font-weight: bold;">ACCOUNTS</span>
        </div>
        <div class="col-lg-12">
          <b-table-simple
            v-if="view4"
            class="table"
          >
            <b-tbody>
              <b-tr style="height: 50px;">
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-else>
            <b-table-simple
              v-if="deleteAccountsAc.length > 0"
              class="table"
            >
              <b-tbody>
                <b-tr class="th-bg">
                  <b-th style="width:150px" />
                  <b-th style="font-weight: bold;width:300px;text-align:center">
                    <span>Trans</span><span style="color:#4d917a">Union</span>
                  </b-th>
                  <b-th style="color:#0566b7;font-weight: bold;width:300px;text-align:center">
                    Experian
                  </b-th>
                  <b-th style="color:#822a3a;font-weight: bold;width:300px;text-align:center">
                    EQUIFAX
                  </b-th>
                </b-tr>
                <b-tr
                  v-for="(item, index) in deleteAccountsAc"
                  :key="index"
                >
                  <b-td style="width:150px"  v-if="JSON.parse(item.pi) != null">
                    <div><span>Account Name</span></div>
                    <div><span>Account #</span></div>
                    <div><span>Balance</span></div>
                    <div><span>Account Type</span></div>
                  </b-td>
                  <b-td colspan="3">
                    <b-table-simple class="table" v-if="JSON.parse(item.pi) != null">
                      <b-tr v-if="JSON.parse(item.pi).length == 3">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:none;text-align:center"
                        >
                          <div><span>{{ JSON.parse(item.pi)[0].account_name }}</span><br><span>{{ JSON.parse(item.pi)[0].account_number }}</span><br><span>$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><br><span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editAc(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deleteAc(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></div>
                        </b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[1].bureau_id == 2"
                          style="width:300px;position:relative;border-top:none;text-align:center"
                        >
                          <div><span>{{ JSON.parse(item.pi)[1].account_name }}</span><br><span>{{ JSON.parse(item.pi)[1].account_number }}</span><br><span>$ {{ (JSON.parse(item.pi)[1].balance).toFixed(2) }}</span><br><span>{{ JSON.parse(item.pi)[1].account_type }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editAc(JSON.parse(item.pi)[1].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deleteAc(JSON.parse(item.pi)[1].id)"
                          /></feather-icon></div>
                        </b-td>
                        <b-td
                          v-if="JSON.parse(item.pi)[2].bureau_id == 3"
                          style="width:300px;position:relative;border-top:none;text-align:center"
                        >
                          <div><span>{{ JSON.parse(item.pi)[2].account_name }}</span><br><span>{{ JSON.parse(item.pi)[2].account_number }}</span><br><span>$ {{ (JSON.parse(item.pi)[2].balance).toFixed(2) }}</span><br><span>{{ JSON.parse(item.pi)[2].account_type }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editAc(JSON.parse(item.pi)[2].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[2].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deleteAc(JSON.parse(item.pi)[2].id)"
                          /></feather-icon></div>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 2">
                        <b-td style="width:300px;position:relative;border-top:none;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[0].account_name }}</span>
                          </div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[1].account_name }}</span>
                          </div>
                          <div><span v-if="JSON.parse(item.pi)[0].bureau_id == 1">{{ JSON.parse(item.pi)[0].account_number }}</span><span v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">{{ JSON.parse(item.pi)[1].account_number }}</span></div>
                          <div><span v-if="JSON.parse(item.pi)[0].bureau_id == 1">$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><span v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">$ {{ (JSON.parse(item.pi)[1].balance).toFixed(2) }}</span></div>
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editAc(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deleteAc(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 1">
                            <span>{{ JSON.parse(item.pi)[1].account_type }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editAc(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deleteAc(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:none;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[0].account_name }}</span>
                          </div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[1].account_name }}</span>
                          </div>
                          <div><span v-if="JSON.parse(item.pi)[0].bureau_id == 2">{{ JSON.parse(item.pi)[0].account_number }}</span><span v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">{{ JSON.parse(item.pi)[1].account_number }}</span></div>
                          <div><span v-if="JSON.parse(item.pi)[0].bureau_id == 2">$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><span v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">$ {{ (JSON.parse(item.pi)[1].balance).toFixed(2) }}</span></div>
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editAc(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deleteAc(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 2">
                            <span>{{ JSON.parse(item.pi)[1].account_type }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editAc(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deleteAc(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                        <b-td style="width:300px;position:relative;border-top:none;text-align:center">
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[0].account_name }}</span>
                          </div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[1].account_name }}</span>
                          </div>
                          <div><span v-if="JSON.parse(item.pi)[0].bureau_id == 3">{{ JSON.parse(item.pi)[0].account_number }}</span><span v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">{{ JSON.parse(item.pi)[1].account_number }}</span></div>
                          <div><span v-if="JSON.parse(item.pi)[0].bureau_id == 3">$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><span v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">$ {{ (JSON.parse(item.pi)[1].balance).toFixed(2) }}</span></div>
                          <div v-if="JSON.parse(item.pi)[0].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editAc(JSON.parse(item.pi)[0].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deleteAc(JSON.parse(item.pi)[0].id)"
                            /></feather-icon></div>
                          <div v-else-if="JSON.parse(item.pi)[1].bureau_id == 3">
                            <span>{{ JSON.parse(item.pi)[1].account_type }}</span><br><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                              icon="EditIcon"
                              class="cursor-pointer"
                              @click="editAc(JSON.parse(item.pi)[1].id)"
                            ><feather-icon
                              v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[1].state_created == 1))"
                              icon="TrashIcon"
                              class="cursor-pointer"
                              @click="deleteAc(JSON.parse(item.pi)[1].id)"
                            /></feather-icon></div>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="JSON.parse(item.pi).length == 1">
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 1"
                          style="width:300px;position:relative;border-top:none;text-align:center"
                        >
                          <div><span>{{ JSON.parse(item.pi)[0].account_name }}</span><br><span>{{ JSON.parse(item.pi)[0].account_number }}</span><br><span>$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><br><span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editAc(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deleteAc(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></div>
                        </b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 2"
                          style="width:300px;position:relative;border-top:none;text-align:center"
                        >
                          <div><span>{{ JSON.parse(item.pi)[0].account_name }}</span><br><span>{{ JSON.parse(item.pi)[0].account_number }}</span><br><span>$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><br><span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editAc(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deleteAc(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></div>
                        </b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                        <b-td
                          v-if="JSON.parse(item.pi)[0].bureau_id == 3"
                          style="width:300px;position:relative;border-top:none;text-align:center"
                        >
                          <div><span>{{ JSON.parse(item.pi)[0].account_name }}</span><br><span>{{ JSON.parse(item.pi)[0].account_number }}</span><br><span>$ {{ (JSON.parse(item.pi)[0].balance).toFixed(2) }}</span><br><span>{{ JSON.parse(item.pi)[0].account_type }}</span><br><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0)"
                            icon="EditIcon"
                            class="cursor-pointer"
                            @click="editAc(JSON.parse(item.pi)[0].id)"
                          ><feather-icon
                            v-if="((modulId == 1 || ((modulId == 2 || modulId == 6) && modul == 4) || ((modulId == 2 || modulId == 3) && modul == 11)) && validateModal == 0 && (JSON.parse(item.pi)[0].state_created == 1))"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="deleteAc(JSON.parse(item.pi)[0].id)"
                          /></feather-icon></div>
                        </b-td>
                        <b-td
                          v-else
                          style="width:300px;border-top:none;text-align:center"
                        />
                      </b-tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div
              v-if="deleteAccountsAc.length == 0"
              class="alert-not-found"
            >
              No Registers Found
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'

import { mapGetters } from 'vuex'

export default {

  props: {
    dataRound: {
      type: Object,
      default: null,
    },
    validateModal: {
      type: Number,
      default: null,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

      ownControl: false,
      deleteAccountsPi: [],
      deleteAccountsIn: [],
      deleteAccountsPr: [],
      deleteAccountsAc: [],
      piname: [],
      pidob: [],
      piadd: [],
      pissn: [],
      view1: true,
      view2: true,
      view3: true,
      view4: true,
      direct: true,
      modul: 5,
    }
  },
  async created() {
    this.ownControl = true

    await Promise.all([this.getDeleteAccountIn(),
      this.getDeleteAccountPi(),
      this.getDeleteAccountPr(),
      this.getDeleteAccountAc()])
  },
  mounted() {
    this.validateTypeRound()
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    modulId() {
      return this.currentUser.modul_id
    },
  },
  methods: {

    async validateTypeRound() {
      try {
        const params = {

          id: this.dataRound.id,
        }
        const data = await ClientsOptionsServices.getValidateDirect(params)

        this.direct = data.data.is_direct
        // Must return an array of items or an empty array if an error occurred

        return this.direct
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    returnq() {
      this.$emit('return', true)
    },
    showviewpdf() {

      this.$emit('viewpdf')
    },
    openmodal() {
      this.$emit('addnewaccount', true)
    },

    async deletePr(id) {
      const params = {
        iduser: this.currentUser.user_id,
        id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await ClientsOptionsServices.deleteDeleteAccountPinw(params)

          await this.getDeleteAccountPr()

          this.showSuccessSwal()
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    editPr(id) {
      this.$emit('editPr', id)
    },

    async deleteIn(id) {
      const params = {
        iduser: this.currentUser.user_id,
        id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await ClientsOptionsServices.deleteDeleteAccountInnw(params)

          await this.getDeleteAccountIn()

          this.showSuccessSwal()
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    editIn(id) {
      this.$emit('editIn', id)
    },

    async deleteAc(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const params = {
            iduser: this.currentUser.user_id,
            id: id,
          }
          await ClientsOptionsServices.deleteDeleteAccountAcnw(params)
          await this.getDeleteAccountAc()
          this.showSuccessSwal()
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },

    editAc(id) {
      this.$emit('editAc', id)
    },

    async deletePi(id) {
      const params = {
        iduser: this.currentUser.user_id,
        id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await ClientsOptionsServices.deleteDeleteAccountPinw(params)

          await this.getDeleteAccountPi()

          this.showSuccessSwal()
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    editPi(id) {
      this.$emit('editPi', id)
    },

    async getDeleteAccountAc() {
      try {
        const params = {
          id: this.dataRound.id,
        }
        const data = await ClientsOptionsServices.getDeleteAccountCnAc(params)
        if (data.status === 200) {
          this.view4 = false
          this.deleteAccountsAc = data.data
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },

    async getDeleteAccountPr() {
      try {
        const params = {

          id: this.dataRound.id,
        }
        const data = await ClientsOptionsServices.getDeleteAccountCnPr(params)
        if (data.status === 200) {
          this.deleteAccountsPr = data.data
          this.view3 = false
        }
        // Must return an array of items or an empty array if an error occurred

        return this.deleteAccountsPr
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async getDeleteAccountIn() {
      try {
        const params = {

          id: this.dataRound.id,
        }
        const data = await ClientsOptionsServices.getDeleteAccountCnIn(params)

        if (data.status === 200) {
          this.deleteAccountsIn = data.data
          this.view2 = false
        }

        // Must return an array of items or an empty array if an error occurred

        return this.deleteAccountsIn
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async getDeleteAccountPi() {
      try {
        const params = {

          id: this.dataRound.id,
        }
        const data = await ClientsOptionsServices.getDeleteAccountCnPi(params)

        if (data.status === 200) {
          this.deleteAccountsPi = data.data
          this.piname = []
          this.pidob = []
          this.piadd = []
          this.pissn = []
          this.deleteAccountsPi.map(dato => {
            if (dato.type === 1) {
              this.piname.push(dato)
            } else if (dato.type === 2) {
              this.pidob.push(dato)
            } else if (dato.type === 3) {
              this.piadd.push(dato)
            } else if (dato.type === 4) {
              this.pissn.push(dato)
            }
          })
          this.view1 = false
        }

        return this.deleteAccountsIn
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    closeModal() {
      this.$emit('close')
    },
  },

}
</script>

<style scoped>

</style>
