export default [
  {
      text: 'Account Name',
      key: 'creditor_name',
      visible: true,
      info: true
  },
  {
      text: 'Account',
      key: 'account_number',
      visible: true,
      info: true
  },
  {
      text: 'Type',
      key: 'account_type',
      visible: true,
      info: true
  },
  {
      text: 'Type Detail',
      key: 'account_type_detail',
      visible: true,
      info: true
  },
  {
      text: 'Bureau Code',
      key: 'bureau_code',
      visible: true,
      info: true
  },
  {
      text: 'Account Status',
      key: 'account_status',
      visible: true,
      info: true
  },
  {
      text: 'Monthly Payment',
      key: 'monthly_payment',
      visible: true,
      info: true
  },
  {
      text: 'Date Opened',
      key: 'date_opened',
      visible: true,
      info: true
  },
  {
      text: 'Balance',
      key: 'balance',
      visible: true,
      info: true
  },
  {
      text: 'Comments',
      key: 'comments',
      visible: true,
      info: true
  },
  {
      text: 'No. Of Months (Terms) ',
      key: 'n_months',
      visible: true,
      info: true
  },
  {
      text: 'Credit Limit',
      key: 'credit_limit',
      visible: true,
      info: true
  },
  {
      text: 'Payment Status',
      key: 'payment_status',
      visible: true,
      info: true
  },
  {
      text: 'Last Payment Status',
      key: 'last_payment',
      visible: true,
      info: true
  },
  {
      text: 'Highest Credit',
      key: 'h_credit',
      visible: true,
      info: true
  }
];
