<template>
  <b-modal
    v-model="onControl"
    title="Edit Public Record"
    modal-class="modal-primary"
    title-tag="h3"
    scrollable
    size="xmd"
    @hidden="close"
  >
    <b-row class="mt-2">
      <b-col>
        <span>PUBLIC RECORDS</span>
      </b-col>
      <b-col>
        <span>DATE</span>
      </b-col>
      <b-col>
        <span>COURT CASE #</span>
      </b-col>
      <b-col class="text-center">
        <span>Trans<span style="color: #4d917a">Union</span></span>
      </b-col>
      <b-col class="text-center">
        <span style="color: #0566b7; font-weight: bold">Experian</span>
      </b-col>
      <b-col class="text-center">
        <span style="color: #822a3a; font-weight: bold">EQUIFAX</span>
      </b-col>
    </b-row>
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <b-form-input v-model="publicRecord" />
        </b-col>
        <b-col>
          <kendo-datepicker
            v-model="prItemLocal.date"
            v-mask="'##/##/####'"
            :style="isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''"
            :class="[
              'w-100 rounded bg-transparent k-picker-custom',
              { 'text-white': isDarkSkin },
            ]"
            :format="'MM/dd/yyyy'"
            class="leads-datepicker form-control"
          />
        </b-col>
        <b-col>
          <b-form-input v-model="courtCase" />
        </b-col>
        <b-col class="d-flex justify-content-center">
          <validation-provider
            v-slot="{ errors }"
            name="Trans union check"
            :rules="
              itemProcessed[1].checkBox === false &&
              itemProcessed[2].checkBox === false
                ? 'truthy'
                : ''
            "
          >
            <b-form-checkbox
              v-model="itemProcessed[0].checkBox"
              class="check-bigger d-flex justify-content-center"
              :state="errors[0] ? false : null"
            />
          </validation-provider>
        </b-col>
        <b-col class="d-flex justify-content-center">
          <validation-provider
            v-slot="{ errors }"
            name="Experian check"
            :rules="
              itemProcessed[0].checkBox === false &&
              itemProcessed[2].checkBox === false
                ? 'truthy'
                : ''
            "
          >
            <b-form-checkbox
              v-model="itemProcessed[1].checkBox"
              class="check-bigger d-flex justify-content-center"
              :state="errors[0] ? false : null"
            />
          </validation-provider>
        </b-col>
        <b-col class="d-flex justify-content-center">
          <validation-provider
            v-slot="{ errors }"
            name="Equifax check"
            :rules="
              itemProcessed[0].checkBox === false &&
              itemProcessed[1].checkBox === false
                ? 'truthy'
                : ''
            "
          >
            <b-form-checkbox
              v-model="itemProcessed[2].checkBox"
              class="check-bigger d-flex justify-content-center"
              :state="errors[0] ? false : null"
            />
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col />
        <b-col />
        <b-col />
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            :name="`Union status`"
            :rules="itemProcessed[0].checkBox === true ? 'required' : null"
          >
            <b-form-select
              v-model="itemProcessed[0].status"
              :options="statuses"
              text-field="value"
              value-field="id"
            />
            <span v-if="errors[0]" class="text-danger">
              Status {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="Experian status"
            :rules="itemProcessed[1].checkBox === true ? 'required' : null"
          >
            <b-form-select
              v-model="itemProcessed[1].status"
              :options="statuses"
              text-field="value"
              value-field="id"
            />
            <span v-if="errors[0]" class="text-danger">
              Status {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="Equifax status"
            :rules="itemProcessed[2].checkBox === true ? 'required' : null"
          >
            <b-form-select
              v-model="itemProcessed[2].status"
              :options="statuses"
              text-field="value"
              value-field="id"
            />
            <span v-if="errors[0]" class="text-danger">
              Status {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <button-save @click="saveEdit" />
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

export default {
  components: {
    ButtonSave,
  },
  props: {
    prItem: null,
    userId: null,
  },
  data() {
    return {
      statuses: null,
      onControl: false,
      prItemLocal: {},
      itemProcessed: [
        {
          publicr: "",
          checkBox: false,
          status: null,
          court: "",
          id: "",
          value: "",
          bureau_id: 1,
        },
        {
          publicr: "",
          court: "",
          checkBox: false,
          status: null,
          id: "",
          value: "",
          bureau_id: 2,
        },
        {
          publicr: "",
          court: "",
          checkBox: false,
          status: null,
          id: "",
          value: "",
          bureau_id: 3,
        },
      ],
      dataB: [],
    };
  },
  async created() {
    this.prItemLocal = { ...this.prItem };
    this.prItemLocal.pi = JSON.parse(this.prItemLocal.pi);
    this.dataB = this.prItemLocal.pi;
    this.onControl = true;
    this.processData();
    await this.getStatuses();
  },
  computed: {
    courtCase: {
      get() {
        return this.prItemLocal.pi[0].court_sase == "null"
          ? ""
          : this.prItemLocal.pi[0].court_sase;
      },
      set(value) {
        this.prItemLocal.pi[0].court_sase = value;
      },
    },
    publicRecord: {
      get() {
        return this.prItemLocal.pi[0].public_record == "null"
          ? ""
          : this.prItemLocal.pi[0].public_record;
      },
      set(value) {
        this.prItemLocal.pi[0].public_record = value;
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async saveEdit() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            this.addPreloader();
            this.itemProcessed.forEach((item) => {
              item.publicr = this.prItemLocal.pi[0].public_record;
              item.court = this.prItemLocal.pi[0].court_sase;
              item.value = item.checkBox === true ? 1 : 0;
            });
            const dt = [];
            dt.push(this.itemProcessed);
            const data = await ClientsOptionsServices.editPublicRecord({
              iduser: this.userId,
              id: this.prItemLocal.id,
              date: this.prItemLocal.date,
              dt,
              instruction: null,
              reason: null,
            });
            if (data.status === 200) {
              this.close();
              this.$emit("reload", 3);
              this.removePreloader();
              this.showSuccessSwal();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    processData() {
      if (this.dataB.length === 3 || this.dataB.length === 2) {
        this.dataB.map((dato) => {
          if (dato.bureau_id === 1) {
            this.itemProcessed[0].checkBox = true;
            this.itemProcessed[0].status = dato.status;
            this.itemProcessed[0].id = dato.id;
          } else if (dato.bureau_id === 2) {
            this.itemProcessed[1].checkBox = true;
            this.itemProcessed[1].status = dato.status;
            this.itemProcessed[1].id = dato.id;
          } else {
            this.itemProcessed[2].checkBox = true;
            this.itemProcessed[2].status = dato.status;
            this.itemProcessed[2].id = dato.id;
          }
        });
      } else if (this.dataB.length === 1) {
        if (this.dataB[0].bureau_id === 1) {
          this.itemProcessed[0].checkBox = true;
          this.itemProcessed[0].status = this.dataB[0].status;
          this.itemProcessed[0].id = this.dataB[0].id;
        } else if (this.dataB[0].bureau_id === 2) {
          this.itemProcessed[1].checkBox = true;
          this.itemProcessed[1].status = this.dataB[0].status;
          this.itemProcessed[1].id = this.dataB[0].id;
        } else {
          this.itemProcessed[2].checkBox = true;
          this.itemProcessed[2].status = this.dataB[0].status;
          this.itemProcessed[2].id = this.dataB[0].id;
        }
      }
    },
  },
};
</script>