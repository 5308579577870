<template>
  <b-modal
    v-model="ownControl"
    scrollable
    hide-footer
    modal-class="custom-modal-amg"
    title-class="h3 text-white font-weight-bolder"
    title="Tracking"
    size="md"
    header-bg-variant="transparent"
    header-class="p-0"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Tracking
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div>
      <div class="mb-2">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <!-- <span class="title-tag">CLIENT NAME</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client | {{ data.clientName }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="pt-md-0 pt-1">
            <!-- <span class="title-tag">CLIENT ACCOUNT</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Account | {{ data.account }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-card
        no-body

        class="mb-0"
      >

        <b-table

          small
       responsive
          :fields="fields"
          :items="search"
          class="font-small-3 custom-table"
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template v-slot:cell(date)="data">
            <p
              class="mb-0 font-weight-bold "
              style="min-width: 130px"
            >
              {{data.item.date | myGlobalDay}}
            </p>

          </template>
          <template v-slot:cell(user)="data">
            <p
                style="min-width: 130px"
                class="mb-0 font-weight-bold "
            >
              {{data.item.user_name}}
            </p>

          </template>
          <template v-slot:cell(action)="data">
            <p
                style="min-width: 130px"
                class="mb-0 font-weight-bold "
            >
              {{data.item.name_status}}
            </p>

          </template>
          <template v-slot:cell(status)="data">
            <p
                style="min-width: 180px"
                class="mb-0 font-weight-bold "
            >
              {{data.item.status_process}}
            </p>

          </template>
          <template v-slot:cell(observation)="data">
            <p
                style="min-width: 180px"
                class="mb-0 font-weight-bold "
            >
              {{data.item.obs }}
            </p>

          </template>




        </b-table>
      </b-card>
    </div>
  </b-modal>
</template>
<script>
import dataFields
from '@/views/commons/components/clients/dashboard/options/dis-module/modals/tracking-modal-dis/fields.js'


import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services"

export default {
  props: {

    data: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      fields: dataFields,
      items: {},
    }
  },
  created() {
    this.ownControl = true
  },
  methods: {

    closeModal() {
      this.$emit('close')
    },

    async search() {
      try {
        const params = {

          id:this.data.id
        }
        const data = await ClientsOptionsServices.getAllTrackingNcr(params)

        this.items = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
</style>
