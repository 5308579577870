<template>
  <div>
    <div v-if="round" class="text-right">
      <b-button variant="outline-primary" class="btn-icon">
        <feather-icon icon="XIcon" class="cursor-pointer" />
      </b-button>
    </div>
    <div class="mt-2">
      <fieldset class="border p-1 rounded border-primary">
        <legend class="w-auto text-primary mb-0">CREDENTIALS</legend>
        <b-row
          style="margin-left: 2rem; margin-right: 2rem"
          class="d-flex justify-content-between"
        >
          <b-col cols="4">
            <b-input-group>
              <template #prepend>
                <b-button variant="primary" @click="addCredential">
                  ADD <feather-icon icon="PlusIcon"
                /></b-button>
              </template>
              <b-form-select v-model="bureau" :options="optionsBureaus" />
            </b-input-group>
          </b-col>
          <b-col cols="4">
            <b-input-group prepend="CLIENT">
              <b-form-input v-model="client.client_name" disabled />
            </b-input-group>
          </b-col>
          <b-col cols="3">
            <div class="w-100 d-flex justify-content-end">
              <b-button
                v-if="credentials.length > 0"
                variant="success"
                title="Save Credentials"
                @click="saveCredentials"
              >
                SAVE
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div v-for="(credential, index) in credentials" :key="index">
          <div class="d-flex align-items-center">
            <div
              style="margin-left: 2.9rem; margin-right: 1.6rem; width: 98%"
              class="my-2 border py-1 px-2 rounded border-primary"
            >
              <b-img
                :src="
                  baseImg +
                  '/assets/images/bureaus-bigger/' +
                  (credential.bureau == 4
                    ? 'transunion'
                    : credential.bureau == 3
                    ? 'experian'
                    : 'equifax') +
                  '-logo.png'
                "
                width="90"
              />
              <b-row class="p-1 justify-content-between">
                <b-form-group label="Sources">
                  <b-form-input v-model="credential.source" />
                </b-form-group>
                <b-form-group label="Email">
                  <b-form-input v-model="credential.email" />
                </b-form-group>
                <b-form-group label="Username">
                  <b-form-input v-model="credential.userName" />
                </b-form-group>
                <b-form-group label="Password">
                  <b-form-input v-model="credential.password" />
                </b-form-group>
                <b-form-group label="Security Answer">
                  <b-form-input v-model="credential.answer" />
                </b-form-group>
                <b-form-group label="Pin">
                  <b-form-input v-model="credential.pin" />
                </b-form-group>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Observation">
                    <b-form-textarea v-model="credential.description" />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div>
              <feather-icon
                v-if="credential.id == null"
                class="text-danger cursor-pointer"
                icon="TrashIcon"
                size="20"
                title="Delete Credential"
                @click="deleteCredential(index)"
              />
              <feather-icon
                v-if="credential.id != null"
                class="text-danger cursor-pointer"
                icon="TrashIcon"
                size="20"
                title="Delete Credential"
                @click="deleteCredentialById(credential.id, index)"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    round: {
      type: Boolean,
      default: false,
    },
    accountId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      credentials: [
        {
          id: null,
          bureau: 4,
          source: null,
          email: null,
          userName: null,
          password: null,
          answer: null,
          pin: null,
          description: null,
        },
      ],
      optionsBureaus: [
        { value: "4", text: "TRANSUNION" },
        { value: "3", text: "EXPERIAN" },
        { value: "5", text: "EQUIFAX" },
      ],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      bureau: 4,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  async created() {
    const data = await ClientsOptionsServices.getSpecialistCredentials({
      account_id: this.$route.params.idClient ?? this.accountId,
      typeOfView: 2,
    });
    if (data.status === 200) {
      if (data.data.length > 0) {
        this.credentials = data.data;
      }
    }
  },
  methods: {
    async saveCredentials() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await ClientsOptionsServices.saveSpecialistCredentials({
            account_id: this.$route.params.idClient ?? this.accountId,
            user_id: this.currentUser.user_id,
            credentials: this.credentials,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    deleteCredential(index) {
      this.credentials.splice(index, 1);
    },
    addCredential() {
      const credential = {
        id: null,
        bureau: this.bureau,
        source: null,
        email: null,
        userName: null,
        password: null,
        answer: null,
        pin: null,
        description: null,
      };
      this.credentials.push(credential);
    },
    async deleteCredentialById(id, index) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data =
            await ClientsOptionsServices.deleteSpecialistCredentialsById({
              credential_id: id,
              user_id: this.currentUser.user_id,
            });
          if (data.status === 200) {
            this.deleteCredential(index);
            this.showSuccessSwal();
          }
        }
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
