<template>
  <b-modal
    v-model="showModal"
    title-class="h3 text-white"
    title="Send Report"
    size="sm"
    @hidden="closeModal()"
    centered
    header-class="p-0 custom-modal-header"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    footer-class="text-start"
    no-close-on-backdrop
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Send Report
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div class="mb-2">
      <b-row>
        <b-col cols="12">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
            >
              Client | {{ client.client_name }}
            </p>
          </div>
        </b-col>

        <b-col cols="12" class="pt-md-0 pt-1 mt-1">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
            >
              Account | {{ client.account }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <div class="d-flex">
        <div class="rounded-numeration">
          1
        </div>
        <div class="ml-1">
          <div>
            <h5>Select type send</h5>
          </div>
          <div>
            <b-form-radio-group
              v-model="letter"
              :options="optionSend"
              @change="changeGenerate"
              class="custom-radio-button"
              plain
            />
          </div>
        </div>
      </div>
      <div class="d-flex mt-1">
        <div class="rounded-numeration">
          2
        </div>
        <div class="ml-1">
          <div>
            <h5>Report</h5>
          </div>
          <div>
            <b-button
              v-if="!url_pdf"
              :disabled="btnGenerate"
              @click="generateStatusReport"
              variant="success"
              style="font-size: 16px; background-color: #794AFF"
              class="cursor-not-allowed"
              :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
            >
              Generate pdf
            </b-button>
            <a v-else target="_blank" :href="url_pdf">
              <b-img
                v-if="isDarkSkin"
                :src="require('@/assets/images/icons/pdf.png')"
                width="21"
                height="28"
                v-b-tooltip.hover.right="'Show report'"
              />

              <amg-icon
                v-else
                icon="FilePdfIcon"
                size="24"
                class="text-danger"
                v-b-tooltip.hover.right="'Show report'"
              />
              <span>{{ name_pdf }}</span>
            </a>
          </div>
        </div>
      </div>

    </div>
    <!-- STEP 2 -->

    <template #modal-footer>
      <b-button
        class="d-flex justify-content-start custom-button"
        variant="primary"
        :disabled="!validation"
        @click="save"
      >
        Send
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    client: Object,
  },

  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      showModal: false,
      options: [
        {
          value: "1",
          text: "Email",
        },
        {
          value: "2",
          text: "Correspondence",
        },
      ],
      letter: null,
      btnGenerate: true,
      url_pdf: null,
      name_pdf: null,
      name_file: null,
      route_file: null,
    };
  },
  created() {
    this.openModal();
  },

  computed: {
    state() {
      return Boolean(this.value)
    },
    optionSend() {
      if (this.client.email != null && this.client.email != "") {
        return this.options;
      } else {
        return this.options.filter((item) => item.value != 1);
      }
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    validation() {
      if (this.letter != null && this.url_pdf != null) return true;
    },
  },

  methods: {
    async save() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const params = {
            idAccount: this.$route.params.idClient,
            status: 1,
            programId: this.client.program_id,
            createdBy: this.currentUser.user_id,
            moduleId: this.moduleId,
            statement_status: this.letter,
            url: this.route_file,
            nameFile: this.name_file,
            type_send: this.letter,
          };
          const data = await ClientsOptionsServices.sendReportPdfCorrespondece(
            params
          );
          if (data.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            this.closeModal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    async generateStatusReport() {
      try {
        this.addPreloader();
        this.url_pdf = "";
        const params = {
          idAccount: this.$route.params.idClient,
          type: this.letter,
        };
        const { data } = await ClientsOptionsServices.generateStatusReport(
          params
        );
        this.url_pdf = data.url;
        this.route_file = data.route;
        this.name_file = data.nameFile;
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    changeGenerate() {
      if (this.letter == 1 || this.letter == 2) {
        this.btnGenerate = false;
      }
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style>
* {
  --primary-color: #3f7afa;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.rounded-numeration {
  background-color: var(--primary-color);
  height: 30px;
  width: 30px;
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
  display: grid;
  align-items: center;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
.custom-button {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>



