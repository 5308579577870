export default [
  {
    key: "id",
    label: "#",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center  p-0 v-top",
  },
  {
    key: "accounts",
    label: "accounts",
    visible: true,
    thClass: "text-center",
    tdClass: "px-0 py-1 col-2 text-center v-top",
  },
  {
    key: "classification_ncr",
    label: "type",
    visible: true,
    thClass: "text-center",
    tdClass: "px-0 py-1 text-center col-2 v-top",
  },
  {
    key: "transunion",
    label: "TU",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center px-0 py-1 col-3 v-top",
  },
  {
    key: "experian",
    label: "EX",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center px-0 py-1 col-3 v-top",
  },
  {
    key: "equifax",
    label: "EQ",
    visible: true,
    thClass: "text-center text-center px-0 py-1 col-3",
    tdClass: "text-center px-0 py-1 col-3 v-top",
  },

  {
    key: "detail",
    label: "",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center  p-0 v-top",
  },
];
