<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <b-button
            variant="info"
            @click="closeModal"
          >
            <feather-icon
              icon="ChevronLeftIcon"
            /> RETURN
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-container
      class="mt-2"
    >
      <div>
        <label v-if="bureauLetter === 1 &&letterTable === 4">TRANS <label class="text-success">UNION</label></label>
        <label
          v-if="bureauLetter === 2 && letterTable === 4"
          class="text-primary"
        >EXPERIAN</label>
        <label
          v-if="bureauLetter === 3 && letterTable === 4"
          class="text-danger"
        >EQUIFAX</label>
      </div>
      <b-row :cols="letterTable === 4 ? '3' : null">
        <b-col v-if="letterTable === 4">
          <b-form-group
            label="Account Name"
            :disabled="true"
          >
            <b-form-input
              v-model="accountData.accountName"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="letterTable === 4">
          <b-form-group
            label="Account #"
            :disabled="true"
          >
            <b-form-input
              v-model="accountData.accountNumber"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="letterTable === 4">
          <b-form-group
            label="Balance"
            :disabled="true"
          >
            <b-form-input
              v-model="accountData.balance"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="letterTable === 4">
          <b-form-group
            label="Account Type"
            :disabled="true"
          >
            <b-form-select
              v-model="accountData.accountType"
              :options="accountTypeData"
              text-field="value"
              value-field="id"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="letterTable !== 2 && letterTable !== 1 && letterTable !== 4"
        >
          <b-form-group
            label="PUBLIC RECORDS"
          >
            <b-form-input
              v-model="formData.publicRecords"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="letterTable !== 1 && letterTable !== 4"
        >
          <b-form-group
            label="DATE"
          >
            <b-form-input
              v-model="formData.date"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="letterTable !== 1 && letterTable !== 4"
        >
          <b-form-group
            label="COMPANY"
          >
            <b-form-input
              v-model="formData.company"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="bureauLetter === 1 && letterTable !== 4"
        >
          <b-form-group
            :disabled="true"
          >
            <label
              style="font-weight: bold;"
            >TRANS <label class="text-success">UNION</label></label>
            <b-form-checkbox
              v-if="letterTable !== 1"
              v-model="formData.check"
              size="xl"
              class="ml-2"
              style="margin-top: 10px;"
            />
            <b-form-input
              v-if="letterTable === 1"
              v-model="formData.bureauContent"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="bureauLetter === 2 && letterTable !== 4"
        >
          <b-form-group
            :disabled="true"
          >
            <label
              class="text-primary"
              style="font-weight: bold;"
            >EXPERIAN</label>
            <b-form-checkbox
              v-if="letterTable !== 1"
              v-model="formData.check"
              size="xl"
              class="ml-2"
              style="margin-top: 10px;"
            />
            <b-form-input
              v-if="letterTable === 1"
              v-model="formData.bureauContent"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="bureauLetter === 3 && letterTable !== 4"
        >
          <b-form-group
            :disabled="true"
          >
            <label
              class="text-danger"
              style="font-weight: bold;"
            >EQUIFAX</label>
            <b-form-checkbox
              v-if="letterTable !== 1"
              v-model="formData.check"
              size="xl"
              class="ml-2"
              style="margin-top: 10px;"
            />
            <b-form-input
              v-if="letterTable === 1"
              v-model="formData.bureauContent"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Reason"
          >
            <b-form-select
              v-model="formData.reason"
              :options="reasonData"
              text-field="text"
              value-field="value"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Instruction"
          >
            <b-form-select
              v-model="formData.instruction"
              :options="instructionData"
              text-field="text"
              value-field="value"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import RequestWorkplanServices from '@/views/commons/components/request-workplan/services/request-workplan.service.js'
import { mapActions } from 'vuex'

export default {
  name: 'EditLetter',
  props: {
    bureauLetter: {
      type: Number,
    },
    letterTable: {
      type: Number,
    },
    editLetterData: {
      type: Object,
    },
  },
  data() {
    return {
      editLetterModal: false,
      accountData: {
        accountNameId: null,
        accountName: null,
        accountNumber: null,
        balance: null,
        accountType: null,
      },
      formData: {
        bureauContent: null,
        publicRecords: null,
        date: null,
        company: null,
        check: false,
        reason: null,
        instruction: null,
        status: null,
      },
      bureau: null,
      sampleLetterData: [],
      reasonData: [],
      instructionData: [],
      accountTypeData: [],
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        const obj = JSON.parse(this.editLetterData.pi)
        if (this.letterTable === 1) {
          const params = {
            content: newVal.bureauContent,
            id: obj[0].id,
            instruction: newVal.instruction,
            reason: newVal.reason,
            status: newVal.status,
            letterTable: this.letterTable,
          }
          this.A_BUREAU_LETTERS_DATA(params)
        } else if (this.letterTable === 2 || this.letterTable === 3) {
          const params = {
            id: obj[0].id,
            instruction: newVal.instruction,
            reason: newVal.reason,
            letterTable: this.letterTable,
          }
          this.A_BUREAU_LETTERS_DATA(params)
        } else if (this.letterTable === 4) {
          const params = {
            accountN: this.accountData.accountNumber,
            accountName_id: this.accountData.accountNameId,
            balance: this.accountData.balance,
            id: obj[0].id,
            instruction: newVal.instruction,
            reason: newVal.reason,
            type: this.accountData.accountType,
            status: this.formData.status,
            letterTable: this.letterTable,
          }
          this.A_BUREAU_LETTERS_DATA(params)
        }
      },
      deep: true,
    },
  },
  async created() {
    this.editLetterModal = true
    if (this.letterTable === 4) {
      await this.getAccountTypes()
    }
    await this.getReasonsInstructions()
    await this.getDeleteAccountInfo()
  },
  methods: {
    ...mapActions({
      A_IS_FOOTER_MODAL_ACTIVE: 'SpecialistsDigitalRoundLettersStore/A_IS_FOOTER_MODAL_ACTIVE',
      A_BUREAU_LETTERS_DATA: 'SpecialistsDigitalRoundLettersStore/A_BUREAU_LETTERS_DATA',
      A_DETAILS_MODAL_ON: 'SpecialistsDigitalRoundLettersStore/A_DETAILS_MODAL_ON',
    }),
    async getDeleteAccountPi() {
      const objId = JSON.parse(this.editLetterData.pi)
      const params = {
        id: objId[0].id,
        type: 1,
      }
      const data = await ClientsOptionsServices.callDeleteAccountPi(params)
      if (data.status === 200) {
        this.formData.bureauContent = data.data[0].content
        this.formData.reason = data.data[0].reason_id
        this.formData.instruction = data.data[0].instruction_id
        this.formData.status = data.data[0].status
      }
    },
    async getDeleteAccountInfo(){
      if(this.letterTable === 1) return await this.getDeleteAccountPi()
      if(this.letterTable === 2) return await this.getDeleteAccountIn()
      if(this.letterTable === 3) return await this.getDeleteAccountPr()
      return await this.getDeletedAccount()
    },
    async getDeleteAccountPr() {
      const objId = JSON.parse(this.editLetterData.pi)
      const params = {
        id: objId[0].id,
        type: 1,
      }
      const data = await ClientsOptionsServices.callDeleteAccountPr(params)
        if (data.status === 200) {
        this.formData.publicRecords = data.data[0].public_record
        this.formData.date = data.data[0].date
        this.bureau = data.data[0].bureau_id
        this.formData.check = data.data[0].bureau_id === 1
        this.formData.company = data.data[0].court_sase
        this.formData.reason = data.data[0].reason_id
        this.formData.instruction = data.data[0].instruction_id
      }
    },
    async getDeleteAccountIn() {
      const objId = JSON.parse(this.editLetterData.pi)
      const params = {
        id: objId[0].id,
        type: 1,
      }
      const data = await ClientsOptionsServices.callDeleteAccountIn(params)
      if (data.status === 200) {
        this.formData.date = data.data[0].date
        this.bureau = data.data[0].bureau_id
        this.formData.check = data.data[0].bureau_id === 2 || data.data[0].bureau_id === 3 || data.data[0].bureau_id === 1
        this.formData.company = data.data[0].content
        this.formData.reason = data.data[0].reason_id
        this.formData.instruction = data.data[0].instruction_id
        this.formData.status = data.data[0].status
      }
    },
    async getDeletedAccount() {
      const objId = JSON.parse(this.editLetterData.pi)
      const params = {
        id: objId[0].id,
        type: 1,
      }
      const data = await ClientsOptionsServices.callDelteAccount(params)
      if (data.status === 200) {
        this.accountData.accountNameId = data.data[0].ca_id
        this.accountData.accountName = data.data[0].name
        this.accountData.accountNumber = data.data[0].account_number
        this.accountData.balance = data.data[0].balance
        this.accountData.accountType = data.data[0].account_type
        this.formData.reason = data.data[0].reason_id
        this.formData.instruction = data.data[0].instruction_id
        this.formData.status = data.data[0].status
      }
    },
    async getAccountTypes() {
      const data = await ClientsOptionsServices.getStatusAccountsLetter({
        id: this.accountData.accountType,
      })
      if (data.status === 200) {
        this.accountTypeData = data.data
      }
    },

    async getReasonsInstructions(){
        const objId = JSON.parse(this.editLetterData?.pi)
          if( objId[0].classification != undefined || objId[0].classification  != null){
           const data = await RequestWorkplanServices.getDisputeReasonsInstructions(
          {
            type: objId[0].classification,
          }
        );
          this.reasonData = data.reasons;
          this.instructionData = data.instructions;
        }
        else {
          await this.getReasons();
          await this.getInstructions();
        }
    },

    async getReasons() {
      const data = await ClientsOptionsServices.getReasons()
      if (data.status === 200) {
        this.setDataOptions(data.data, this.reasonData);
      }
    },
    async getInstructions() {
      const data = await ClientsOptionsServices.getInstructions()
      if (data.status === 200) {
        this.setDataOptions(data.data ,this.instructionData);
      }
    },

    setDataOptions(data, options) {
      data.forEach((item) => {
        options.push({
          value: item.id,
          text: item.value,
        });
      });
    },
    closeModal() {
      this.A_IS_FOOTER_MODAL_ACTIVE(false)
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
