<template>
  <center>
    <span
      v-if="!statusPending.value"
      :class="[
        data.item.status == 'PENDING' ? 'cursor-pointer' : '',
        StatusColorText(data.item.status),
      ]"
    >
      {{ data.item.status }}
    </span>
    <feather-icon
      v-if="!statusPending.value && data.item.status == 'PENDING'"
      icon="EditIcon"
      class="text-warning cursor pointer"
      v-b-tooltip.hover.right="'Edit'"
      style="margin-left: 5px"
      size="15"
      @click="changeStatusPending(data.index)"
    />
    <div
      style="display: inline-flex"
      v-if="data.item.status == 'PENDING' && statusPending.value"
    >
      <b-input-group size="sm">
        <b-form-select  
          placeholder="--select--"
          :options="options"
          v-model="actionSelected"
          @change="openModal"
        />
        <b-input-group-append is-text @click="clearStatus()">
          <feather-icon
            icon="XIcon"            
            class="text-danger cursor-pointer"
            size="20"
          />
        </b-input-group-append>
      </b-input-group>
    </div>

    <modal-action
      v-if="activeModal"
      :action="actionSelected"
      :data="data.item"
      :num="1"
      :otherServices="true"
      @close="closeModal"
      @refreshTable="refreshTable"
    />
  </center>
</template>
<script>
import ModalAction from "./ModalAction.vue";
export default {
  components: { ModalAction },
  props: {
    data: { type: Object, required: true },
    options: { type: Array, required: true },
  },
  data() {
    return {
      activeModal: false,
      statusPending: {
        id: 0,
        value: false,
      },
      actionSelected: 0,
    };
  },
  methods: {
    refreshTable() {
      this.statusPending.value = false;
      this.$emit("refreshTable");
    },
    closeModal() {
      this.activeModal = false;
    },
    clearStatus(){
      this.statusPending.value = true;
      this.actionSelected = null;
    },
    openModal() {
      if(this.actionSelected == 1){
        this.$emit("addCharge")
      }else{
        this.activeModal = true;
      }
    },
    changeStatusPending(id) {
      if (this.data.item.status == 0 || "PENDING") {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = id;
      }
    },
    clearStatus(){
      this.actionSelected = null;
      this.statusPending.value = false;
    },
    StatusColorText(status) {
      switch (status) {
        case "PENDING":
          return "text-warning";
          break;
        case "ACCEPTED":
          return "text-success";
          break;
        case "REJECTED":
          return "text-danger";
          break;
        default:
          return "bg-secondary text-light";
          break;
      }
    },
  },
};
</script>

