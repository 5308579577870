import { render, staticRenderFns } from "./PersonalInfoModal.vue?vue&type=template&id=4ba0aa1c&scoped=true"
import script from "./PersonalInfoModal.vue?vue&type=script&lang=js"
export * from "./PersonalInfoModal.vue?vue&type=script&lang=js"
import style0 from "./PersonalInfoModal.vue?vue&type=style&index=0&id=4ba0aa1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba0aa1c",
  null
  
)

export default component.exports