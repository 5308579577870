var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title-class":"h3 text-white","title":"Assign Specific","size":"md","centered":"","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"d-flex justify-content-center",attrs:{"variant":"primary","disabled":_vm.verifyUserOnline()},on:{"click":_vm.assignWorkplan}},[_vm._v(" ASSIGN ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"container-keywords"},[_c('div',{staticClass:"mb-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('span',{staticClass:"title-tag"},[_vm._v("CLIENT NAME")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.item.client_name || _vm.item.lead_name)+" ")])])]),_c('b-col',{staticClass:"pt-md-0 pt-1",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('span',{staticClass:"title-tag"},[_vm._v("CLIENT TYPE")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.item.type_client == 1? "NEW CLIENT" : "REGULAR")+" ")])])])],1)],1),_c('b-table',{attrs:{"striped":"","hover":"","small":"","fields":_vm.fields,"items":_vm.data},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center ml-1"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{class:ariaDescribedby,attrs:{"name":"some-radios","disabled":item.status_session == 0 || item.status_session == 4,"value":item},model:{value:(_vm.selectSpecialist),callback:function ($$v) {_vm.selectSpecialist=$$v},expression:"selectSpecialist"}})]}}],null,true)})],1)]}},{key:"cell(task)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.task ? item.task : 0)+" ")])]}},{key:"cell(full_name)",fn:function(ref){
var item = ref.item;
return [([1, 2, 3].includes(item.status_session))?_c('div',[_c('feather-icon',{staticClass:"mr-50 text-success",attrs:{"size":"16","icon":"UserCheckIcon"}}),_vm._v(" "+_vm._s(item.full_name)+" ")],1):_c('div',[_c('feather-icon',{staticClass:"mr-50 text-danger",attrs:{"size":"16","icon":"UserXIcon"}}),_vm._v(" "+_vm._s(item.full_name)+" ")],1)]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")((item.updated_at || item.created_at)))+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }