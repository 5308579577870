import { amgApi } from "@/service/axios";

class SpecialistsCommissionsService {
  async createCommissionSpecialists(body) {
    try {
      const data = await amgApi.post(
        "/commissions/create-commission-specialists",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getReportCommission(body) {
    try {
      const data = await amgApi.post(
        "/specialists/financial-approval/commission/index",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailCommission(body) {
    try {
      const data = await amgApi.post(
        "/specialists/financial-approval/commission/show",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SpecialistsCommissionsService();
