export default [

  {
    key: 'date',
    sortable: false,
    visible: true,
    label: 'Date',
  },
  {
    key: 'user',
    sortable: false,
    visible: true,
    label: 'User',
  },
  {
    key: 'action',
    sortable: false,
    visible: true,
    label: 'Action',
  },
  {
    key: 'status',
    sortable: false,
    visible: true,
    label: 'Status',
  },
  {
    key: 'observation',
    sortable: false,
    visible: true,
    label: 'Observation',
  },



]
