<template>
  <b-modal
    v-model="visible"
    id="personal-info"
    size="xlg"
    @hidden="closeModal"
    :modal-class="
      isDarkSkin
        ? 'bg-modal-binnacle-dark bg-modal-binnacle-ca'
        : 'bg-modal-binnacle-light bg-modal-binnacle-ca'
    "
    :hide-footer="editable"
    title="APPLICATION FORM"
    title-class="h3 text-white"
    no-close-on-backdrop
    centered
  >
    <b-container fluid>
      <b-row>
        <!-- PERSONAL INFORMATION -->
        <b-col cols="5">
          <div :class="isDarkSkin ? 'black' : 'light'" class="rounded">
            <div class="p-2">
              <h3 class="text-primary" style="font-weight: bold">
                Personal Information
              </h3>
              <hr style="border: 1px solid #0090e7" />
              <template v-for="item in personalInformation">
                <b-row class="content-att my-1" :key="item.label">
                  <span class="col-md-4 text-truncate"
                    ><feather-icon :icon="item.icon" size="20" class="mr-1" />
                    {{ item.label }}
                  </span>
                  <div class="col-md-8">
                    <template v-if="item.label == 'DOCUMENTS'">
                      <strong
                        v-for="(valor, clave) in JSON.parse(item.value)"
                        :key="clave"
                      >
                        <template v-if="valor != null">
                          {{ clave }}: {{ valor }}
                        </template>
                      </strong>
                    </template>
                    <template v-else-if="item.label == 'DOB'">
                      <strong>{{ item.value | myGlobal }}</strong>
                    </template>
                    <template v-else>
                      <strong>{{ item.value }}</strong>
                    </template>
                  </div>
                </b-row>
              </template>
            </div>
          </div>
        </b-col>

        <!-- FINANCIAL INFORMATION -->
        <b-col cols="7">
          <div :class="isDarkSkin ? 'black' : 'light'" class="rounded">
            <div class="p-2">
              <h3 class="text-primary" style="font-weight: bold">
                Financial Information
              </h3>
              <hr style="border: 1px solid #0090e7" />
              <validation-observer ref="form">
                <b-row>
                  <template v-for="(item, index) in questions">
                    <b-col :cols="12" lg="6" xl="4" :key="index">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <b-form-group :label="item.name.toUpperCase()">
                          <b-form-select
                            :class="[
                              errors[0] ? 'border-danger' : '',
                              !editable ? '' : 'cursor-not-allowed',
                            ]"
                            :options="JSON.parse(item.question)"
                            v-model="infoForm[item.name]"
                            :disabled="editable"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </template>

                  <b-col :cols="12" lg="6" xl="4">
                    <validation-provider
                      rules="validate-amount"
                      v-slot="{ errors }"
                    >
                      <b-form-group label="ANNUAL INCOME $ :">
                        <money
                          v-model="infoForm.monthly_income"
                          v-bind="vMoney"
                          class="form-control"
                          :class="[
                            errors[0] && validAmount ? 'border-danger' : '',
                            !editable ? '' : 'cursor-not-allowed',
                          ]"
                          :disabled="editable"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4" v-if="infoForm.dual_citizenship == 0">
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      v-if="true"
                    >
                      <b-form-group label="HAS OTHER ORIGIN COUNTRY?">
                        <b-form-select
                          :options="dualCitizenshipOptions"
                          v-model="infoForm.dual_citizenship"
                          :class="errors[0] ? 'border-danger' : ''"
                          :disabled="completed"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4" v-if="infoForm.dual_citizenship == 1">
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      slim
                    >
                      <b-form-group label="COUNTRY OF BIRTH :">
                        <v-select
                          v-model="country"
                          :reduce="(country) => country.id"
                          :options="countriesOptions"
                          :disabled="editable"
                          :class="errors[0] ? 'border-danger' : ''"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="INTERNAL EMAIL (optional) :">
                      <b-form-select
                        v-model="emailSelected"
                        label="email"
                        :disabled="editable"
                        :options="emailOptions"
                        :class="!editable ? '' : 'cursor-not-allowed'"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <label>CREDITOR: </label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="creditor"
                      rules="required"
                    >
                      <v-select
                        class="select__creditor"
                        :class="errors.length > 0 ? 'border-danger' : ''"
                        label="text"
                        placeholder="Search..."
                        :options="optionsCreditor"
                        @search="searchCreditors"
                        v-model="infoForm.creditor"
                        :disabled="editable"
                      />
                    </validation-provider>
                  </b-col>
                  <b-col :cols="12" lg="6" xl="6">
                    <b-form-group label="TYPE (optional):">
                      <b-form-radio-group
                        :options="typeOptions"
                        @change="selectTypeOption"
                        v-model="infoForm.type"
                        buttons
                        button-variant="outline-primary"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col :cols="12" md="6" xl="4" v-if="isSccCard">
                    <b-form-group
                      label="ROUTING NUMBER:"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-input
                        v-model="infoForm.routing_number"
                        :aria-describedby="ariaDescribedby"
                        name="buttons-1"
                        type="number"
                        :disabled="editable"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col :cols="12" md="6" xl="4" v-if="isSccCard">
                    <b-form-group
                      label="ACCOUNT NUMBER:"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-input
                        v-model="infoForm.n_account"
                        :aria-describedby="ariaDescribedby"
                        name="buttons-1"
                        type="number"
                        :disabled="editable"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col :cols="12" md="6" xl="4">
                    <b-form-group
                      label="PRIOR TAX RETURN (optional):"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        v-model="infoForm.prior_tax"
                        :options="[
                          { text: 'Yes', value: true },
                          { text: 'NO', value: false },
                        ]"
                        :aria-describedby="ariaDescribedby"
                        name="buttons-1"
                        buttons
                        button-variant="outline-primary"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <b-button
        size="md"
        variant="outline-success"
        @click="saveData(0)"
        class="mx-1"
      >
        SAVE
      </b-button>

      <b-button
        size="md"
        variant="outline-primary"
        @click="sendData"
        class="mx-1"
      >
        SEND
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import ApplicationsService from "../../../services/applications.service";
import FinancialApprovalService from "@/views/specialists/sub-modules/financial-approval/services/financial-approval.service";
import vSelect from "vue-select";
import { VMoney } from "v-money";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: { vSelect, VMoney, ValidationProvider, ValidationObserver },
  directives: { money: VMoney },
  props: {
    id: { type: String, required: true },
    completed: { type: Boolean, required: true },
    client_information: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      test: true,
      validAmount: false,
      infoClient: {},
      infoForm: {},
      visible: false,
      type: "",
      country: 0,
      emailSelected: "",
      emailOptions: [],
      isOtherOcupation: false,
      employedOptions: [],
      selfEmployedOptions: [],
      typeOptions: [
        { text: "Checkings", value: { checkings: 1, savings: 0 } },
        { text: "Savings", value: { checkings: 0, savings: 1 } },
        { text: "Checkings & Savings", value: { checkings: 1, savings: 1 } },
      ],
      dualCitizenshipOptions: [
        { value: 0, text: "NO" },
        { value: 1, text: "YES" },
      ],
      countriesOptions: [],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      questions: [],
      optionsCreditor: [],
      icons: [
        "UserIcon",
        "CalendarIcon",
        "ShieldIcon",
        "PhoneIcon",
        "MapPinIcon",
        "MapPinIcon",
        "FileTextIcon",
      ],
      personalInformation: [],
      price: 0,
    };
  },
  methods: {
    ...mapActions({
      getCountries: "DebtSolutionApplications/getCountriesAction",
    }),
    async start() {
      let data = await ApplicationsService.getPersonalInformation({
        id: this.id,
      });

      this.infoClient = { ...data.data.data_client[0] };
      this.infoForm = { ...data.data.data_form[0] };

      const keysToFilter = ["type", "created_at", "app_id", "ca_id", "email"];
      this.personalInformation = Object.keys(this.infoClient)
        .filter((key) => !keysToFilter.includes(key)) // Filtrar las claves no deseadas
        .map((key, index) => ({
          label: key,
          value: this.infoClient[key],
          icon: this.icons[index],
        }));

      this.infoForm.dual_citizenship = 1;
      this.assignValue(this.personalInformation);
      await Promise.all([
        this.assingEmail(),
        this.getOccupations(),
        this.getCountries(),
      ]);
      this.assignCountries();

      this.infoForm.type = {
        savings: this.infoForm.type_account_savings || 0,
        checkings: this.infoForm.type_account_checkings || 0,
      };
      this.country = this.infoForm.id_country ? this.infoForm.id_country : 146;
      this.infoForm.prior_tax = !!this.infoForm.prior_tax;
    },
    async assingEmail() {
      let data = await ApplicationsService.getInternalEmail(
        this.infoClient.ca_id
      );
      data.map((el) => {
        this.emailOptions.push({ value: el.id, text: el.email });
        if (el.id == this.infoForm.internal_email) {
          this.emailSelected = el.id;
        }
      });
    },
    async saveData(doneValue) {
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.infoForm.status_done = doneValue || 0;
        this.addPreloader();
        let result = await ApplicationsService.savePersonalInfo({
          ...this.dataProvider,
        });
        this.removePreloader();
        if (result) {
          this.showSuccessSwal();
          this.$emit("refreshTable");
        } else {
          this.showErrorSwal();
        }
        this.closeModal();
      }
    },
    async sendData() {
      this.validAmount = true;
      let isReady = await this.$refs.form.validate();

      isReady
        ? this.saveData(1)
        : this.showInfoSwal(
            "You must complete all fields to continue",
            "Empty fields"
          );
    },
    async getOccupations() {
      const { employment_occupations, self_employment_occupations } =
        await ApplicationsService.getOccupations();
      this.employedOptions = [...employment_occupations];
      this.selfEmployedOptions = [...self_employment_occupations];
    },
    closeModal() {
      this.$emit("closeModal");
      this.visible = false;
    },
    assignCountries() {
      this.countries.map((country) => {
        this.countriesOptions.push({ id: country.id, label: country.name });
      });
    },
    assignValue(obj) {
      if (!obj.mhome_payment) obj.mhome_payment = 0;
      if (!obj.monthly_income) obj.monthly_income = 0;
      if (!obj.deposit_amount) obj.deposit_amount = 0;
    },
    async getForm() {
      const params = {
        type: "CREDIT CARDS",
      };
      let result = await FinancialApprovalService.getFormQuestion(params);
      this.questions = result;
      return result;
    },
    searchCreditors: _.debounce(async function (search) {
      if (search.length < 1 || search == undefined) {
        this.optionsCreditor = [];
        return;
      }
      const res = await amgApi.post(
        "sales-made/debt-solution/search-creditors",
        {
          q: search,
        }
      );

      res.data.forEach((item) => {
        this.optionsCreditor.push({
          value: item.id,
          text: item.value,
        });
      });
    }, 500),
    selectTypeOption() {
      this.infoForm.type_account_savings = this.infoForm.type.savings;
      this.infoForm.type_account_checkings = this.infoForm.type.checkings;
    },
  },
  computed: {
    ...mapGetters({
      countries: "DebtSolutionApplications/countries",
      isBigWindow: "app/bigWindow",
      currentUser: "auth/currentUser",
    }),
    isSccCard(){
      return this.client_information.type == 4;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isPending() {
      if (this.infoForm) {
        return this.infoForm.status_done === 0;
      } else {
        return false;
      }
    },
    isSpecialist() {
      const allowedModuleIds = [11, 25, 28, 29, 30];
      return allowedModuleIds.includes(this.moduleId);
    },

    isSeller() {
      return this.currentUser.role_id === 8;
    },
    editable() {  
      if (this.isPending) {
        if (this.client_information.program_id === 7) {
          return false;
        } else {
          return this.isSpecialist || this.isSeller;
        }
      }
      return true;
    },
    dataProvider() {
      return {
        f_n_account: this.infoForm.n_account || null,
        f_idapp: this.id || null,
        f_brand: this.infoForm.brand || null,
        f_ca_id: this.infoClient.ca_id || null,
        f_type_account_checkings: this.infoForm.type_account_checkings || null,
        f_country: this.country || null,
        f_deposit_amount: this.infoForm.deposit_amount || null,
        f_done:
          this.infoForm.status_done !== null ? this.infoForm.status_done : null,
        f_dual_citizenship: this.infoForm.dual_citizenship || null,
        f_months_at_employer: this.infoForm.months_at_employer || null,
        f_years_at_employer: this.infoForm.years_at_employer || null,
        f_employment_status: this.infoForm["Employment Status"] || null,
        f_housing_status: this.infoForm["Housing Status"] || null,
        f_iduser: this.infoForm.user_id || this.currentUser.user_id,
        f_interest_rate: this.infoForm.interest_rate || null,
        f_internal_email: this.emailSelected || null,
        f_mainb_account: this.infoForm.main_bank_account || null,
        f_model_vehicle: this.infoForm.model_vehicle || null,
        f_mhome_payment: this.infoForm.mhome_payment || null,
        f_montthly_income: this.infoForm.monthly_income || null,
        f_name_employer: this.infoForm.name_employer || null,
        f_occupation: this.infoForm["Occupation"] || null,
        f_plate: this.infoForm.plate || null,
        f_purchase_date: this.infoForm.purchase_date || null,
        f_months_at_residence: this.infoForm.months_at_residence || null,
        f_years_at_residence: this.infoForm.years_at_residence || null,
        f_routing_number: this.infoForm.routing_number || null,
        f_type_account_savings: this.infoForm.type_account_savings || null,
        f_type_charge: this.infoForm.type_charge || null,
        f_vehicle_year: this.infoForm.vehicle_year || null,
        f_vin: this.infoForm.vin || null,
        f_prior_tax: this.infoForm.prior_tax ? 1 : 0,
        f_creditor_id:
          this.infoForm.creditor && this.infoForm.creditor.value !== undefined
            ? this.infoForm.creditor.value
            : null,
      };
    },
  },
  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
    this.visible = true;
    await this.getForm();
  },
};
</script>
<style scoped>
.black {
  background: #1a1c25;
}
.light {
  background: #f1f4f7;
}
.black >>> .custom-select:disabled {
  opacity: 1 !important;
  color: #6e6b7d !important;
}
.light >>> .custom-select:disabled {
  opacity: 1 !important;
  color: #6e6b7d !important;
}
</style>
