<template>
  <b-modal
    v-model="visible"
    id="personal-info"
    size="xlg"
    @hidden="closeModal"
    :modal-class="
      isDarkSkin
        ? 'bg-modal-binnacle-dark bg-modal-binnacle-ca'
        : 'bg-modal-binnacle-light bg-modal-binnacle-ca'
    "
    title="APPOINTMENT FORM"
    title-class="h3 text-white"
    no-close-on-backdrop
    centered
    :hide-footer="disabled"
  >
    <b-container fluid>
      <b-row>
        <!-- PERSONAL INFORMATION -->
        <b-col cols="5">
          <div :class="isDarkSkin ? 'black' : 'light'" class="rounded">
            <div class="p-2">
              <h3 class="text-primary" style="font-weight: bold">
                Personal Information
              </h3>
              <hr style="border: 1px solid #0090e7" />
              <template v-for="item in personalInformation">
                <b-row class="content-att my-1" :key="item.label">
                  <span class="col-md-4 text-truncate"
                    ><feather-icon
                      :icon="item.icon || 'UserIcon'"
                      size="20"
                      class="mr-1"
                    />
                    {{ item.label }}
                  </span>
                  <div class="col-md-8">
                    <strong v-if="item.label != 'DOCUMENTS'">{{
                      item.value
                    }}</strong>
                    <strong
                      v-else
                      v-for="(valor, clave) in JSON.parse(item.value)"
                      :key="clave"
                    >
                      <template v-if="valor != null">
                        {{ clave }}: {{ valor }}
                      </template>
                    </strong>
                  </div>
                </b-row>
              </template>
            </div>
          </div>
        </b-col>

        <!-- FINANCIAL INFORMATION -->
        <b-col cols="7">
          <div :class="isDarkSkin ? 'black' : 'light'" class="rounded">
            <div class="p-2">
              <h3 class="text-primary" style="font-weight: bold">
                Appointment Information
              </h3>
              <hr style="border: 1px solid #0090e7" />
              <div>
                <validation-observer ref="formAppointment">
                  <b-row>
                    <template v-for="(item, index) in questions">
                      <b-col :cols="12" lg="6" xl="4" :key="index">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group :label="item.name.toUpperCase()">
                            <b-form-select
                              :disabled="disabled"
                              :class="
                                errors[0] && validate ? 'border-danger' : ''
                              "
                              :options="JSON.parse(item.question)"
                              v-model="infoForm[item.name]"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </template>
                    <b-col
                      :cols="12"
                      lg="6"
                      xl="4"
                      v-if="
                        clientData.type_description == 'LOAN APPOINTMENT' ||
                        clientData.type_description == 'MORTGAGE APPOINTMENT'
                      "
                    >
                      <validation-provider
                        rules="validate-amount"
                        v-slot="{ errors }"
                      >
                        <b-form-group label="AMOUNT NEEDED $ :">
                          <money
                            v-model="infoForm.amount_needed"
                            v-bind="vMoney"
                            class="form-control"
                            :class="
                              errors[0] && validate ? 'border-danger' : ''
                            "
                            :disabled="disabled"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <template
                      v-if="
                        clientData.type_description == 'AUTO LOAN APPOINTMENT'
                      "
                    >
                      <b-col :cols="12" lg="6" xl="4">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="MAKE:">
                            <b-form-input
                              v-model="infoForm.make"
                              class="form-control"
                              :class="
                                errors[0] && validate ? 'border-danger' : ''
                              "
                              :disabled="disabled"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col :cols="12" lg="6" xl="4">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="MODEL:">
                            <b-form-input
                              v-model="infoForm.model"
                              class="form-control"
                              :class="
                                errors[0] && validate ? 'border-danger' : ''
                              "
                              :disabled="disabled"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col :cols="12" lg="6" xl="4">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="YEAR:">
                            <b-form-select
                              v-model="infoForm.year"
                              class="form-control"
                              :options="yearsList"
                              :class="
                                errors[0] && validate ? 'border-danger' : ''
                              "
                              :disabled="disabled"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </template>

                    <b-col :cols="12" lg="6" xl="4">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <b-form-group label="DAY: ">
                          <b-form-datepicker
                            v-model="infoForm.inputDate"
                            class="form-control"
                            :class="
                              errors[0] && validate ? 'border-danger' : ''
                            "
                            :min="new Date()"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            @input="changeDate"
                            :disabled="disabled"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col :cols="12" lg="6" xl="4">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <b-form-group label="HOUR: ">
                          <b-form-select
                            v-model="infoForm.inputTime"
                            :options="timeOptions"
                            class="form-control"
                            :class="
                              errors[0] && validate ? 'border-danger' : ''
                            "
                            :disabled="infoForm.inputDate == null || disabled"
                          />
                        </b-form-group>
                        <span v-if="availableHour" class="text-danger"
                          >This time is not available. All loan examiners are
                          busy</span
                        >
                      </validation-provider>
                    </b-col>

                    <b-col :cols="12" lg="6" xl="4">
                      <b-form-group label="COMMENTS (optional):">
                        <b-form-textarea
                          :disabled="disabled"
                          v-model="infoForm.comments"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <b-button
        size="md"
        variant="outline-success"
        @click="saveData()"
        class="mx-1"
      >
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";

// SERVICES
import FinancialApprovalService from "@/views/specialists/sub-modules/financial-approval/services/financial-approval.service";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";

import { VMoney } from "v-money";
export default {
  directives: { money: VMoney },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    typeService: {
      required: false,
      type: Number,
      default: 1, // credit card
    },
  },
  data() {
    return {
      infoForm: {},
      visible: false,
      questions: [],
      icons: [
        "UserIcon",
        "BoxIcon",
        "GridIcon",
        "MapPinIcon",
        "MapPinIcon",
        "FileTextIcon",
        "CalendarIcon",
        "PhoneIcon",
        "ShieldIcon",
        "DollarSignIcon",
      ],
      label: [
        "LEGAL NAME",
        "MODULE",
        "TYPE OF APPOINTMENT",
        " MAILING ADDRESSES",
        "ANOTHER ADDRESSES",
        "DOCUMENTS",
        "DOB",
        "PHONE NUMBER",
        "LEGAL STATUS",
        "AMOUNT",
      ],
      personalInformation: [],
      yearsList: [],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      validate: false,
      timeOptions: [],
      schedule: [],
      availableHour: false,
    };
  },

  methods: {
    async start() {
      if (this.typeService === 1) {
        const keysToFilter = [
          "cc",
          "id",
          "status_account_id",
          "created_at",
          "type_id",
          "client_account",
          "name_advisor",
          "name_specialist",
          "section",
          "account_id",
          "responsible",
          "status",
          "status_paid",
          "app_status",
          "total_binnacle",
          "total_binnacle_files",
          "total",
          "is_potencial",
          "files_counter",
          "minimum_price",
          "lead_id",
          "program_id",
          "client_type_id",
          "recommended_by",
          "date_event",
          "hour_event",
          "alert_id",
          "form_appointment_id",
          "specialist_id",
          "comment",
        ];
        this.personalInformation = Object.keys(this.clientData)
          .filter((key) => !keysToFilter.includes(key))
          .map((key, index) => ({
            label: this.label[index],
            value: this.clientData[key],
            icon: this.icons[index],
          }));
      } else {
        const keysToShow = [
          "client_name",
          "module",
          "type_description",
          "mailing_addressed",
          "another_addresses",
          "documents",
          "dob",
          "phone",
          "legal_status",
          "amount",
        ];

        this.personalInformation = Object.keys(this.clientData)
        .filter(key => keysToShow.includes(key))
        .map(
          (key, index) => {
            return {
              label: this.label[index],
              value: this.clientData[key],
              icon: this.icons[index],
            };
          }
        );
      }
    },
    async saveData() {
      this.validate = true;
      const isValid = await this.$refs.formAppointment.validate();
      if (!isValid) {
        return;
      }
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await FinancialApprovalService.storeForm({
          ...this.dataProvider,
        });
        this.removePreloader();
        if (result.success) {
          this.showSuccessSwal();
          this.$emit("refreshTable");
          this.availableHour = false;
          this.closeModal();
        } else {
          this.infoForm.inputTime = "";
          this.availableHour = true;
          // this.showErrorSwal();
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
      this.visible = false;
    },

    async getForm() {
      const params = {
        type: "APPOINTMENTS",
        type_charge: this.clientData.type_id,
      };
      let result = await FinancialApprovalService.getFormQuestion(params);
      this.questions = result;
      return result;
    },

    async getInfo() {
      try {
        this.addPreloader();
        let data = await FinancialApprovalService.getForm({
          id: this.clientData.id,
        });
        this.infoForm = data[0] || {
          amount_needed: 0.00
        };
        this.changeDate(this.infoForm.inputDate);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    generateYearsList() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear;
      const endYear = currentYear - 50;

      for (let year = startYear; year >= endYear; year--) {
        this.yearsList.push(year.toString());
      }
    },

    async getSchedule() {
      const { data } = await SettingsServiceSp.getAppointmentSchedule();
      this.schedule = data;
    },
    changeDate(value) {
      if(!value){
        return;
      }
      this.timeOptions = [];
      this.inputTime = null;
      const selectedDate = new Date(value);
      const dayOfWeek = selectedDate.getDay();
      const { start_hour, end_hour } = this.schedule.find(
        (item) => item.day == dayOfWeek + 1
      );
      this.timeOptions = this.generateTimeOptions(start_hour, end_hour, 60);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    dataProvider() {
      return {
        appointment_id: this.clientData.id || null,
        principal_concerns_id: this.infoForm["Principal concerns"] || null,
        personal_loan_purpose_id: this.infoForm["Personal Loan Purpose"] || null,
        auto_loan_purpose_id: this.infoForm["Auto Loan purpose"] || null,
        auto_loan_use_id: this.infoForm["Auto Loan use"] || null,
        mortgage_purpose_id: this.infoForm["Mortgage purpose"] || null,
        comments: this.infoForm.comments || null,
        amount_needed: this.infoForm.amount_needed || 0.00,
        year: this.infoForm.year || null,
        make: this.infoForm.make || null,
        model: this.infoForm.model || null,
        created_by: this.currentUser.user_id || null,
        appointment_date: this.infoForm.inputDate || null,
        appointment_time: this.infoForm.inputTime || null,
      };
    },
  },
  async created() {
    this.visible = true;
    await this.start();
    await this.getSchedule();
    await this.getForm();
    await this.getInfo();
    this.generateYearsList();
  },
};
</script>
<style scoped>
.black {
  background: #1a1c25;
}
.light {
  background: #f1f4f7;
}
</style>
