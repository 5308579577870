<template>
  <b-modal
    v-model="onControl"
    title="Delete Accounts"
    title-tag="h3"
    header-class="p-0"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    size="lg"
    hide-footer
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Delete Accounts
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <div class="d-flex justify-content-between mb-1">
      <div />
      <b-button variant="primary" @click="generatePdf" :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        ><amg-icon
          icon="FilePdfIcon"
          size="15"
          style="margin-right: 0.5rem"
        />GENERATE</b-button
      >
    </div>
    <b-table
      ref="refDeleteAccounts"
      no-border-collapse
      class="position-relative custom-table table-new-customization"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :busy="isBusy"
      :items="deletedAccounts"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          class="mr-1 cursor-pointer text-primary"
          icon="CopyIcon"
          size="15"
          @click="copyAnswerName(data.item.short_link)"
        />
        <feather-icon
          class="mr-1 cursor-pointer text-success"
          icon="DownloadIcon"
          size="15"
          @click="downloadPDF(data.item.route, data.item.file_name)"
        />
      </template>
    </b-table>
    <modal-approve-supervisor
      v-if="modalApproveSupervisorOn"
      :modal-approve-supervisor="modalApproveSupervisorOn"
      @closeModalApprove="modalApproveSupervisorOn = false"
      @approveSup="postGeneratePdf"
    />
  </b-modal>
</template>

<script>
import ClientsBoostDashboardServices from "@/views/boost-credit/views/clients/dashboard/services/clients.boost.dashboard.services";
import { useToast } from "vue-toastification/composition";
import { useClipboard } from "@vueuse/core";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    clientAccountId: {
      type: String,
      default: null,
    },
  },
  components: {
    ModalApproveSupervisor,
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      fields: [
        {
          key: "user_name",
          label: "Generated By",
        },
        {
          key: "short_link",
          label: "Link",
        },
        {
          key: "created_at",
          label: "Generated At",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      deletedAccounts: [],
      modalApproveSupervisorOn: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.onControl = true;
    await this.getPDF();
  },
  setup() {
    const toast = useToast();
    const { copy } = useClipboard();

    const copyAnswerName = (answerName) => {
      copy(answerName);

      toast({
        component: ToastificationContent,
        props: {
          title: "Copied",
          icon: "CopyIcon",
          variant: "success",
        },
      });
    };

    return {
      copyAnswerName,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async downloadPDF(route, file_name) {
      const data =
        await ClientsBoostDashboardServices.downloadDeleteAccountPdfAccountCr({
          route,
          file_name,
        });
      if (data.status === 200) {
        window.open(data.data);
      }
    },
    async getPDF() {
      this.isBusy = true;
      const data = await ClientsBoostDashboardServices.getDeleteAccountCrPdf({
        accountid: this.$route.params.idClient || this.clientAccountId,
      });
      if (data.status === 200) {
        this.deletedAccounts = data.data;
        this.isBusy = false;
      }
      this.isBusy = false;
    },
    async postGeneratePdf() {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const data =
            await ClientsBoostDashboardServices.generateDeleteAccountCrPdf({
              accountid: this.$route.params.idClient || this.clientAccountId,
              user_id: this.currentUser.user_id,
            });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.deletedAccounts = data.data;
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    async generatePdf() {
      if (
        this.isCeo ||
        this.isSupervisor ||
        this.isTeamLeader ||
        this.currentUser.role_id === 14 ||
        this.currentUser.role_id === 17
      ) {
        await this.postGeneratePdf();
      } else {
        this.modalApproveSupervisorOn = true;
      }
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
</style>
