import { render, staticRenderFns } from "./TableNcrs.vue?vue&type=template&id=f4066d32&scoped=true"
import script from "./TableNcrs.vue?vue&type=script&lang=js"
export * from "./TableNcrs.vue?vue&type=script&lang=js"
import style0 from "./TableNcrs.vue?vue&type=style&index=0&id=f4066d32&prod&scoped=true&lang=scss"
import style1 from "./TableNcrs.vue?vue&type=style&index=1&id=f4066d32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4066d32",
  null
  
)

export default component.exports