<template>
  <b-col :lg="cols + ''" :md="mdCols + ''" sm="12">
    <b-form-group :label="label" :class="{ 'mb-0': mbNone }">
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <template #prepend>
          <div class="input-group-text">
            <slot name="icon">
              <component
                :is="componentIcon"
                :icon="icon"
                class="h4 m-0"
                v-b-tooltip.hover.right="tooltip"
              />
            </slot>
          </div>
        </template>
        <div
          :class="`form-control d-flex align-items-center justify-content-between ${classForDiv}`"
          style="gap: 10px"
        >
          <slot name="content">
            <div v-if="documents.length > 0">
              <span style="font-weight: bolder">{{
                `${documents[0].key}: `
              }}</span>
              <span>{{ documents[0].value }}</span>
              <feather-icon
                v-if="documents.length > 1"
                icon="EyeIcon"
                size="16"
                class="text-primary ml-1"
                id="tooltip-docs"
              />
              <b-tooltip
                :target="'tooltip-docs'"
                triggers="hover"
                placement="top"
              >
                <template v-for="doc in documents">
                  <b-badge
                    :key="doc.key"
                    variant="light-primary"
                    class="d-block mb-1s"
                  >
                    {{ `${doc.key}: ${doc.value}` }}
                  </b-badge>
                </template>
              </b-tooltip>
            </div>
            <div v-else>
              <span
                class="text-truncate"
                style="max-width: 100%"
                v-b-tooltip.hover.right="content"
              >
                {{ content }}
              </span>
            </div>
          </slot>
        </div>
      </b-input-group>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  props: {
    cols: Number,
    label: String,
    content: String,
    documents: {
      type: Array,
      default: () => [],
    },
    typeIcon: String,
    icon: String,
    tooltip: String,
    mbNone: Boolean,
  },
  computed: {
    mdCols() {
      return this.cols == 8 ? 12 : this.cols + 2;
    },
    classForDiv() {
      return this.isDarkSkin ? null : "div-light";
    },
    componentIcon() {
      switch (this.typeIcon) {
        case "bootstrap":
          return "b-icon";
        case "feather":
          return "feather-icon";
        case "tabler":
          return "tabler-icon";
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  background: transparent !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
  color: white;
}
.div-light {
  background: #efefef;
  border-color: rgba(191, 191, 191, 0.4) !important;
  color: #6e6b7b;
}
</style>