<template>
  <b-modal
    v-model="visible"
    size="lg"
    title="Files"
    title-tag="h3"
    modal-class="modal-primary"
    centered
    @hidden="close"
  >
    <b-container>
      <b-row v-if="isProcessStatus" class="justify-content-end">
        <b-button
          v-if="currentUser.modul_id == 29"
          :class="addFile ? 'bg-danger' : 'bg-success'"
          size="md"
          style="border: none; font-size: 15px"
          @click="changeStateAddFile"
        >
          <feather-icon :icon="addFile ? 'MinusIcon' : 'PlusIcon'" size="15" />
          {{ this.addFile ? "Hide" : "Add File" }}
        </b-button>
      </b-row>
      <br />
      <b-row v-if="addFile">
        <drag-and-drop
          ref="dyd"
          v-model="files"
          class="w-100 mb-1"
          :files-array="files"
        />

        <b-col>
          <b-row class="justify-content-center">
            <b-button
              variant="warning"
              :disabled="!isProcessStatus"
              @click="uploadFiles"
            >
              <feather-icon icon="UploadCloudIcon" /> UPLOAD
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-table ref="FileTable" :items="items" :fields="fields" show-empty>
          <template #cell(file_name)="data">
            <feather-icon :icon="fileIcon(data.item.file_type)" />
            <a :href="data.item.file_url" target="_blank" class="ml-1">{{
              data.value
            }}</a>
          </template>
          <template #cell(actions)="data">
            <center>
              <feather-icon
                v-if="isProcessStatus"
                size="18"
                class="text-danger cursor-pointer"
                icon="Trash2Icon"
                @click="deleteFile(data.item.id)"
              />
            </center>
          </template>
        </b-table>
      </b-row>
      <b-row class="mt-1 pt-1 border-top" v-if="isProcessStatus">
        <b-col cols="12">
          <b-form-group label="Comment">
            <b-form-textarea
              placeholder="Write your comment here..."
              v-model="comment"
              :disabled="!inSpecialist"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-row>
        <b-col cols="12">
          <b-button
            v-if="items.length && isProcessStatus && currentUser.modul_id == 29"
            variant="primary"
            @click="sendCompleted"
          >
            Send
            <feather-icon icon="SendIcon" />
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import AppointmentService from "@/views/commons/components/other-services/service/appointment.service";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: { DragAndDrop },
  props: {
    appointment_id: { type: String, required: true },
    statusService: { type: Number, required: false },
    program: { type: Number, required: true },
    type_charge: { type: Number, required: true },
  },
  data() {
    return {
      visible: false,
      addFile: false,
      items: [],
      files: [],
      hasFile: false,
      routesModules: {
        5: "debt-solution-other-services",
        6: "credit-experts-other-services",
        7: "boost-credit-other-services",
        20: "connection-other-services",
        22: "customerservice-other-services",
        29: "other-services-financial-approval",
      },
      comment: null,
    };
  },
  methods: {
    ...mapMutations({
      M_PENDING_OTHER_SERVICES: "OtherServices/M_PENDING_OTHER_SERVICES",
    }),
    async start() {
      const data = await AppointmentService.getFiles(this.appointment_id);
      this.items = [...data.data];
    },
    async uploadFiles() {
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files[]", file, file.name);
      });
      formData.append("program_id", this.program);
      formData.append("idApp", this.appointment_id);
      formData.append("idUser", this.currentUser.user_id);

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const result = await AppointmentService.uploadFiles(formData);

        if (result) {
          this.files = [];
          this.changeStateAddFile();
          this.hasFile = true;
          await this.start();
          this.removePreloader();
          this.showSuccessSwal();
        } else {
          this.removePreloader();
          this.showErrorSwal();
        }
      }
    },
    async deleteFile(id) {
      const data = { idFile: id, idUser: this.currentUser.user_id };
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const result = await AppointmentService.deleteFile(data);
        this.removePreloader();

        if (result) {
          this.showSuccessSwal();
          this.hasFile = true;
          await this.start();
        } else {
          this.showErrorSwal();
        }
      }
    },

    close() {
      this.visible = false;

      this.$emit("close", this.hasFile);
    },
    changeStateAddFile() {
      this.addFile = !this.addFile;
    },
    fileIcon(doc) {
      switch (doc.toLowerCase()) {
        case "pdf":
          return "FileTextIcon";
          break;
        case "docx":
          return "FileTextIcon";
          break;
        case "xlsx":
          return "FileTextIcon";
          break;
        case "png":
          return "ImageIcon";
          break;
        case "jpg":
          return "ImageIcon";
          break;
        default:
          return "FileIcon";
          break;
      }
    },
    async sendCompleted() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          id_service: parseInt(this.appointment_id, 10),
          created_by: this.currentUser.user_id,
          status_Service: this.statusService,
          type_charge: this.type_charge,
          comment: this.comment
        };
        const { status } = await AppointmentService.sendCompleted(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.hasFile = true;
          this.close();
          this.$emit("refresh");
          await this.refreshCountInSidebar();
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
      // implementar
    },
    async refreshCountInSidebar() {
      try {
        const { modul_id, user_id, role_id } = this.currentUser;
        const data = await AppointmentService.getCountPending({
          module_id: modul_id !== 29 ? modul_id : null,
          user_id,
          role_id,
        });
        const payload = {
          routeName: this.routesModules[modul_id],
          tag: data,
        };
        this.$store.commit(
          "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
          payload
        );
        this.M_PENDING_OTHER_SERVICES(data);
      } catch (error) {
        console.log("error: ", error);
      }
    },
  },
  computed: {
    ...mapState({
      fields: (state) => state.DebtSolutionApplications.fieldsVerification,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    inSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
    isProcessStatus() {
      return this.statusService === 1 && this.inSpecialist;
    },
  },
  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
    this.visible = true;
  },
};
</script>
