<template>
  <div>
    <div
      v-if="showTitle"
      class="h5 font-weight-bolder mb-1"
      :class="{ 'text-white': isDarkSkin }"
    >
      <span> {{ title }}</span>
      <b-badge
        variant="info"
        class="mb-1 ml-1 clickable"
        v-if="data.length > 0"
        @click="showInfo = !showInfo"
      >
        {{ data.length }} Items
      </b-badge>
    </div>
    <div class="d-flex justify-content-between parent-header">
      <div
        class="cell-style d-flex justify-content-center align-items-center top-left"
      >
        <b-img
          :src="'/assets/images/icons/bureaus/transunion.png'"
          width="98"
        />
      </div>
      <div class="cell-style d-flex justify-content-center align-items-center">
        <b-img :src="'/assets/images/icons/bureaus/experian.png'" width="98" />
      </div>
      <div
        class="cell-style d-flex justify-content-center align-items-center top-right"
      >
        <b-img :src="'/assets/images/icons/bureaus/equifax.png'" width="98" />
      </div>
    </div>
    <b-collapse v-model="showInfo" id="collapse-item">
      <div
        class="container-report"
        :style="data.length <= 2 ? 'width:100%' : ''"
      >
        <div class="container-inner">
          <div
            class="d-flex justify-content-between parent-body"
            v-for="(items, index) in data"
            :key="index"
          >
            <div class="cell-style" v-for="(item, index) in items" :key="index">
              <Item
                :data="item"
                :statusOptions="statusOptions"
                v-if="!item.empty"
              />
              <div
                class="d-flex flex-column justify-content-center align-items-center h-100"
                
                :style="isDarkSkin ? 'color: #726F6E' : 'color: #B7B2B1'"
                v-else
              >
                <feather-icon icon="XCircleIcon" size="16" />
                <div>No Info</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Item from "@/views/commons/components/ncr/components/delete/Item.vue";
export default {
  components: {
    Item,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Accounts",
    },
    data: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.parent-header {
  div.cell-style:nth-child(1) {
    border-top-left-radius: 10px !important;
    border-top: 1px solid #e5e5e5 !important;
    border-left: 1px solid #e5e5e5 !important;
    border-right: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
  div.cell-style:nth-child(2) {
    border-top: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
  div.cell-style:nth-child(3) {
    border-top-right-radius: 10px !important;
    border-top: 1px solid #e5e5e5 !important;
    border-right: 1px solid #e5e5e5 !important;
    border-left: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
}

.parent-body {
  div.cell-style:nth-child(1) {
    border-left: 1px solid #e5e5e5 !important;
    border-right: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
  div.cell-style:nth-child(2) {
    border-bottom: 1px solid #e5e5e5 !important;
  }
  div.cell-style:nth-child(3) {
    border-right: 1px solid #e5e5e5 !important;
    border-left: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
}
.cell-style {
  width: 33.33%;
  padding: 0.5rem !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.container-report {
  max-height: 350px;
  overflow-y: auto;
  width: calc(100% + 8px);
}
</style>
