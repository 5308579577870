<template>
  <b-modal
    ref="createReasonAndInstructionModal"
    no-close-on-backdrop
    title-class="h3 text-white font-weight-bolder"
    modal-class="modal-primary"
    :title="title"
    scrollable
    size="sm"
    @hidden="closeModal"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider
            v-slot="{ errors }"
            :name="createType === 1? 'REASON' : 'INSTRUCTION'"
            rules="required"
          >
            <b-form-group
              :label="createType === 1? 'REASON' : 'INSTRUCTION'"
            >
              <b-form-input
                v-model="formData"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>

    <template #modal-footer>
      <b-button
        variant="success"
        @click="addAccountCreditor"
      >SAVE</b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'

export default {
  name: 'CreateReasonAndInstructionModal',
  props: {
    createType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title: null,
      formData: null,
    }
  },
  mounted() {
    this.toggleModal('createReasonAndInstructionModal')
    this.title = this.createType === 1 ? 'ADD REASON' : 'ADD INSTRUCTION'
  },
  methods: {
    async addAccountCreditor() {
      const result = await this.$refs.form.validate()
      if (result) {
        try {
          const swal = await this.showConfirmSwal()
          if (swal.isConfirmed) {
            const params = {
              accountName: this.formData,
              typeinsert: this.createType === 1 ? 2 : 3,
            }
            this.addPreloader()
            const response = await ClientsOptionsServices.addAccountCreditor(params)
            if (response.status === 200) {
              this.removePreloader()
              this.closeModal()
            }
          }
        } catch (e) {
          this.removePreloader()
          this.closeModal()
          console.log(e)
        }
      }
    },
    closeModal() {
      this.$emit('close')
      this.$emit('reload')
    },
  },
}
</script>

<style scoped>

</style>
