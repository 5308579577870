<template>
  <div>
    <div class="mb-1">
      <b-button v-if="!isModal" variant="info" @click="closeModal">
        <feather-icon icon="ChevronLeftIcon" /> RETURN
      </b-button>
    </div>
    <b-form-group label="Observation">
      <b-form-textarea v-model="observation" />
    </b-form-group>
    <div class="d-flex justify-content-between">
      <div />
      <b-button variant="success" @click="processRoundLetters">
        PROCESS
      </b-button>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services"
import { mapGetters } from "vuex"

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    typestate: null,
    ittra: null,
    itexp: null,
    itequ: null,
    idround: null,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module
    },
  },
  data() {
    return {
      observation: null,
    }
  },
  methods: {
    async processRoundLetters() {
      try {
        this.addPreloader()
        const params = {
          id: this.idround,
          user: this.currentUser.user_id,
          type: this.typestate,
          obs: this.observation,
          ittra: this.ittra ? this.ittra : 0,
          itexp: this.itexp ? this.itexp : 0,
          itequ: this.itequ ? this.itequ : 0,
          module: this.moduleId,
        }
        const data = await ClientsOptionsServices.processRoundLetters(params)
        if (data.status === 200) {
          this.removePreloader()
          this.closeModal()
          this.showToast()
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    closeModal() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped></style>
