import { amgApi } from '@/service/axios'

class NrcService {
    //NCR Main
    async ncrLeadsCountInProcess(params) {
        const data = await amgApi.post("/lead/ncr/ncr-leads-count-in-process", params)
        return data
    }

    //Content pending
    async changeStatus(params) {
        const data = await amgApi.post("/lead/ncr/change-status", params)
        return data
    }

    // Modal Tracking Status
    async getTrackingStatus(params) {
        const data = await amgApi.post("/lead/ncr/get-lead-tracking-status-by-id", params)
        return data
    }

    //Modal Questionnaire
    async getQuestionnaire(params) {
        const data = await amgApi.post("/lead/ncr/get-all-leads-questions", params)
        return data
    }

    async saveQuestionnaire(params) {
        const data = await amgApi.post("/lead/ncr/create-leads-questionnaire", params)
        return data
    }

    // Mixin
    async getSellers(params) {
        const data = await amgApi.post("/commons/sellerall/2", params)
        return data
    }



    async updateCanceledDispute(params) {
        try {
            const data = await amgApi.post("request-workplan/update-canceled-dispute", params)
            return data
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async toRevision(params) {
        try {
            const data = await amgApi.post(
                `request-workplan/to-revision`,
                params
            );
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async getAllSecureSocial(params) {
        try {
            const data = await amgApi.post("/lead/ncr/all-secure-social", params)
            return data.data
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async processRound(params) {
        try {
            const data = await amgApi.post("/request-workplan/process-rounds", params)
            return data
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async getClientWpReport(params) {
        try {
            const data = await amgApi.post(
                "/request-workplan/get-client-wp-report",
                params
            );
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }

    async getChargeServices(params) {
        try {
            const { data } = await amgApi.post(
                "/request-workplan/get-charges-services",
                params
            );
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            throw new Error(errorMessage);
        }
    }
}

export default new NrcService()