<template>
  <b-modal
    v-model="showModal"
    title="Added accounts"
    size="xlg"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    hide-footer
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">New Items</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <items-to-remove
      ref="itemsToRemove"
      :dataGrid="addedAccounts"
      :editMode="false"
      :clientData="{
        client_name: rowData.client_name,
        client_account_id: rowData.client_account
      }"
    />
  </b-modal>
</template>
<script>
import ItemsToRemove from "@/views/commons/components/request-ncr/views/components/ItemsToRemove.vue";
import DisputeResultsServices from "@/views/commons/components/dispute-results/services/dispute-results.service.js";
export default {
  components: {
    ItemsToRemove,
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      addedAccounts: [],
      clientData: {},
    };
  },
  async mounted() {
    await this.getAddedAccounts();
    this.showModal = true;
  },
  methods: {
    async getAddedAccounts() {
      const params = {
        updateRequestId: this.rowData.letter_id,
      };
      try {
        this.addPreloader();
          const data = await DisputeResultsServices.getAddedAccounts(params);
          this.addedAccounts = data.map((item) => {
            return {
              ...item,
              showDetails: false,
              items: item.items.map((item) => {
                return {
                  ...item,
                  is_remove: item.is_remove == 1 ? true : false,
                  new_item_selected: false,
                };
              }),
            };
          });
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
