<template>
  <b-modal
    ref="chat"
    title="Chat"
    hide-header
    hide-footer
    scrollbar
    centered
    @hidden="closeModal()"
    size="xlg"
    :modal-class="
      isDarkSkin
        ? 'bg-modal-binnacle-dark bg-modal-binnacle-ca modal-binnacle-main'
        : 'bg-modal-binnacle-light bg-modal-binnacle-ca modal-binnacle-main'
    "
  >
    <!--BUTTON CLOSE MODAL-->
    <b-button
      variant="white"
      class="bg-white position-absolute"
      style="right: -10px; top: -10px; z-index: 20"
      size="sm"
      @click="closeModal"
    >
      <feather-icon icon="XIcon" size="20" />
    </b-button>
    <b-row class="p-0">
      <b-col sm="12" md="8" lg="9">
        <ChatMessage :getUserId="getUserId" />
        <MessageInput />
      </b-col>
      <b-col sm="12" md="4" lg="3">
        <ChatClientInformation />
        <ChatOtherFiles />
        <ChatTrackingStatus />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ChatMessage from "@/views/commons/binnacle/Components/ChatMessage.vue";
import MessageInput from "@/views/commons/binnacle/Components/MessageInput.vue";
import ChatClientInformation from "@/views/commons/binnacle/Components/ChatClientInformation.vue";
import ChatOtherFiles from "@/views/commons/binnacle/Components/ChatOtherFiles.vue";
import ChatTrackingStatus from "@/views/commons/binnacle/Components/ChatTrackingStatus.vue";
export default {
  name: "BinnacleModal",
  components: {
    ChatMessage,
    MessageInput,
    ChatClientInformation,
    ChatOtherFiles,
    ChatTrackingStatus,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      comments: "BinnacleStore/G_COMMENTS",
      newMessage: "BinnacleStore/G_SEND_MESSAGE_BINNACLE",
      setNewMessageBinnacle: "BinnacleStore/G_NEW_MESSAGE_BINNACLE",
      setNewFileBinnacle: "BinnacleStore/G_NEW_FILE_BINNACLE",
      setMarkMessageAsViewed: "BinnacleStore/G_MARK_MESSAGE_AS_VIEWED",
      clientInformation: "BinnacleStore/G_CLIENT_INFORMATION",
      totalNewComments: "BinnacleStore/G_TOTAL_NEW_COMMENTS",
    }),
    getUserId() {
      return this.currentUser.user_id;
    },
  },
  watch: {
    newMessage: {
      handler(value) {
        if (value && typeof value === "object") {
          this.triggerMessageSocket(value);
        }
      },
      deep: true,
      immediate: true,
    },
    setNewMessageBinnacle: {
      async handler(value) {
        if (value && typeof value === "object") {
          this.$emit("newMessageBinnacle", value);
        }
      },
      deep: true,
      immediate: true,
    },
    setNewFileBinnacle: {
      async handler(value) {
        if (value && typeof value === "object") {
          this.$emit("newFileBinnacle", value);
        }
      },
      deep: true,
      immediate: true,
    },
    setMarkMessageAsViewed: {
      async handler(data) {
        if (typeof data === "object") {
          this.$emit("markMessageAsViewed", data);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      removeClientInformation: "BinnacleStore/removeClientInformation",
      addComments: "BinnacleStore/addComments",
      sendMarkMessageAsViewed: "BinnacleStore/markMessageAsViewed",
      addOtherFiles: "BinnacleStore/addOtherFiles",
    }),
    closeModal() {
      this.$emit("close", this.totalNewComments);
      this.removeClientInformation(null);
    },
    initializePusherChannel() {
      const channel = socket.channel("application-notifications", {});
      channel.bind("client-mark-as-viewed", ({ messageId }) => {
        const message = this.comments.find((m) => m.id === messageId);
        if (message) {
          message.viewed = true;
        }
      });
    },
    closePusherChannel() {
      const channel = socket.channel("application-notifications", {});
      channel.unbind_all();
    },
    triggerMessageSocket(message) {
      const channel = socket.channel("application-notifications", {});
      if (channel) {
        channel.trigger("client-send-message", message);
      }
    },
    async markMessageAsViewed(message) {
      if (!message.viewed) {
        message.viewed = true;
        const channel = socket.channel("application-notifications", {});
        if (channel) {
          channel.trigger("client-mark-as-viewed", { messageId: message.id });
          const params = {
            _id: message.id,
            viewed: true,
            is_file: message.is_file ? message.is_file : false,
          };
          this.sendMarkMessageAsViewed(params);
        }
      }
    },
    async markUnviewedMessages() {
      const receiverId = this.currentUser.user_id;
      if (this.comments.length > 0) {
        for (const message of this.comments) {
          if (
            !message.viewed &&
            !message.isSender &&
            message.user_id != receiverId
          ) {
            await this.markMessageAsViewed(message);
          }
        }
      }
    },
  },
  beforeDestroy() {
    this.closePusherChannel();
  },
  async mounted() {
    this.toggleModal("chat");
    this.initializePusherChannel();
    const channel = socket.channel("application-notifications", {});
    channel.bind("client-send-message", (payload) => {
      const { section, id } = this.clientInformation;
      if (payload.section === section && payload.binnacleId === id) {
        /** se procede a setear los comentarios del otro usuario */
        this.addComments(payload);
        this.markMessageAsViewed(payload);
        if (payload.is_file) {
          this.addOtherFiles(payload);
        }
      }
    });
    await this.markUnviewedMessages();
  },
};
</script>
<style lang="scss">
.modal-binnacle-main {
  .black {
    background: #1a1c25;
  }
  .light {
    background: #f1f4f7;
  }
}

.bg-modal-binnacle-dark.modal-binnacle-main {
  .content-message-receiver {
    background: #656565;
    border-radius: 10px 0 10px 10px;
  }
  .content-message-sender {
    background: #052440;
    border-radius: 10px 0 10px 10px;
  }
  .chat-input {
    background: #1a1c25;
    padding: 0 5px 15px 5px;
    border-top: 0px;
    border-radius: 0 0 10px 10px;
  }
  input {
    background: #17171a;
    border: 1px solid #656565;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    outline: none !important;
    padding-right: 40px;
    padding-left: 40px;
    color: #fff;
  }
  .link-file {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
  .message-date {
    color: #fff !important;
  }
  .bg-chat-dark {
    background: #1a1c25;
  }
}

.bg-modal-binnacle-light.modal-binnacle-main .modal-body {
  background: #e2eef8 !important;
}

.bg-modal-binnacle-light.modal-binnacle-main {
  .link-file {
    text-decoration: underline;
    font-weight: bold;
    color: #0090e7;
  }

  .bg-chat-light {
    background: #f1f4f7;
  }

  .content-message-receiver {
    background: #fff;
    border-radius: 0 10px 10px 10px;
  }
  .content-message-sender {
    background: #d4edff;
    border-radius: 10px 0 10px 10px;
  }

  .chat-input {
    padding: 0 5px 15px 5px;
    border-top: 0px;
    background: #f1f4f7;
    border-radius: 0 0 10px 10px;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none !important;
    padding-right: 40px;
    padding-left: 40px;
  }
}
.bg-modal-binnacle-ca.modal-binnacle-main {
  .input-container {
    position: relative;
  }
  .chat-container {
    height: 85vh;
    max-height: 85vh;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    overflow-y: auto;
    border-radius: 10px 10px 0 0;
  }
  .chat-action-bar-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .chat-action-paper-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .message-sender {
    align-self: flex-end;
  }
  .message-receiver {
    align-self: flex-start;
  }
  .chat-messages {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .message {
    padding: 8px;
    border-radius: 5px;
    width: fit-content;
  }

  .message-text {
    padding: 8px;
    border-radius: 5px;
    max-width: 500px;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.timeline-event {
  border-left: 2px solid #007bff; /* Timeline line color */
  padding-left: 20px;
}

.timeline-event-header {
  font-weight: bold;
}

.timeline-event-body {
  padding-left: 10px;
}
.tracking {
  margin-bottom: 5px !important;
}
</style>
