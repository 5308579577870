<template>
  <div>
    <b-modal
      ref="modalName"
      modal-class="modal-primary"
      title-class="h3 text-white text-uppercase"
      title="synchronization summary table"
      size="lg"
      @hidden="closeModal()"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col cols="6">
          <h5 class="text-center text-uppercase text-warning">Current Items</h5>
          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              Total items
              <b-badge variant="primary" pill>{{
                dataCurrentItems.total
              }}</b-badge>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              Total synchronized items
              <b-badge variant="primary" pill>{{
                dataCurrentItems.totalMatch
              }}</b-badge>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              Total unsynchronized items
              <b-badge variant="primary" pill>{{
                dataCurrentItems.totalNotMatch
              }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="6">
          <h5 class="text-center text-uppercase text-primary">New Items</h5>
          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              Total items
              <b-badge variant="primary" pill>{{ dataNewItems.total }}</b-badge>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              Total synchronized items
              <b-badge variant="primary" pill>{{
                dataNewItems.totalMatch
              }}</b-badge>
            </b-list-group-item>

            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              Total unsynchronized items
              <b-badge variant="primary" pill>{{
                dataNewItems.totalNotMatch
              }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click="done" variant="success">Done</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ncrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import { mapActions } from "vuex";
export default {
  name: "SynchronizationSummaryTable",
  props: {
    requestWorkplan: {
      type: Object,
      required: true,
    },
    newItems: {
      type: Array,
      required: true,
    },
    currentItems: {
      type: Array,
      required: true,
    },
    sendParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    dataCurrentItems() {
      const dataAll = this.currentItems
        .flatMap((item) => item.items)
        .filter((x) => x.valid);
      const dataMatch = dataAll.filter((item) => item.selected);
      const dataNotMatch = dataAll.filter((item) => !item.selected);
      return {
        total: dataAll.length,
        totalMatch: dataMatch.length,
        totalNotMatch: dataNotMatch.length,
        dataAll,
        dataMatch,
        dataNotMatch,
      };
    },
    dataNewItems() {
      const dataAll = this.newItems
        .flatMap((item) => item.items)
        .filter((x) => x.valid);
      const dataMatch = dataAll.filter((item) => item.match);
      const dataNotMatch = dataAll.filter((item) => item.valid && !item.match);
      return {
        total: dataAll.length,
        totalMatch: dataMatch.length,
        totalNotMatch: dataNotMatch.length,
        dataMatch,
        dataNotMatch,
        dataAll,
      };
    },
  },
  mounted() {
    this.toggleModal("modalName");
  },
  created() {},
  methods: {
    ...mapActions("RequestWorkPlanStore", [
      "A_UPDATE_STEP_WP",
    ]),
    closeModal() {
      this.$emit("close");
    },
    updateStepWorkPlan() {
      const updatedData = {
        id: this.requestWorkplan.id,
        step: 3,
      };
      this.A_UPDATE_STEP_WP(updatedData);
    },
    async done() {
      try {
        this.addPreloader();
        const { status } = await ncrRequestService.storeSyncronizeItems(
          this.sendParams
        );
        this.removePreloader();
        this.updateStepWorkPlan();
        this.closeModal();
        return status;
      } catch (ex) {
        console.log(ex);
        this.removePreloader();
      }
    },
  },
};
</script>
