<template>
  <div class="d-flex justify-content-center align-items-center">
    <div v-if="!statusPending.value" class="d-flex justify-content-center">
      <span
        @click="changeStatusPending(data.index)"
        :class="[
          data.item.status == 0 ? 'cursor-pointer' : '',
          StatusColorText(data.item.status + 1),
        ]"
      >
        {{ data.item.status | statusApplication }}
      </span>
      <feather-icon
        @click="changeStatusPending(data.index)"
        v-if="data.item.status == 0"
        icon="EditIcon"
        style="margin-left: 5px"
        v-b-tooltip.hover.right="'Edit'"
        class="text-warning cursor-pointer"
      />
    </div>

    <div
      style="display: inline-flex"
      v-if="data.item.status == 0 && statusPending.value"
    >
      <b-input-group size="sm">
        <b-form-select  
          placeholder="--select--"
          :options="options"
          v-model="actionSelected"
          @change="openModal"
        />

        <b-input-group-append is-text>
          <feather-icon
            icon="XIcon"
            @click="changeStatusPending(data.index)"
            class="text-danger cursor-pointer"
            size="20"
          />
        </b-input-group-append>
      </b-input-group>
    </div>
    <modal-action
      v-if="activeModal"
      :action="action"
      :data="data.item"
      :num="1"
      @close="closeModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import ModalAction from "../modals/ModalAction.vue";
export default {
  components: { ModalAction },
  props: {
    data: { type: Object, required: true },
    options: { type: Array, required: true },
  },
  data() {
    return {
      activeModal: false,
      statusPending: {
        id: 0,
        value: false,
      },
      actionSelected: 0,
      action: null,
    };
  },
  created() {},
  methods: {
    refreshTable() {
      this.statusPending.value = false;
      this.$emit("refreshTable");
    },
    closeModal() {
      this.activeModal = false;
    },
    openModal() {
      this.action = this.actionSelected;
      this.actionSelected = null;
      this.activeModal = true;
    },
    changeStatusPending(id) {
      if (this.data.item.status == 0) {
        this.statusPending.value = !this.statusPending.value;
        this.statusPending.id = id;
      }
    },
    StatusColorText(status) {
      switch (status) {
        case 1:
          return "text-warning";
          break;
        case 2:
          return "text-success";
          break;
        case 3:
          return "text-danger";
          break;
        default:
          return "bg-secondary text-light";
          break;
      }
    },
  },
};
</script>