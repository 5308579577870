<template>
  <b-row class="d-flex flex-column gap-5">
    <div>
      <b-button
        class="buttons-menu"
        :class="
          tabs[0].id == actualTab
            ? 'button-active'
            : isDarkSkin
            ? 'button-inactive-dark'
            : 'button-inactive-light'
        "
        @click="actualTab = tabs[0].id"
      >
        {{ tabs[0].label }}
        <span v-if="accountCounters.toRemove > 0" class="ml-1">
          <feather-icon
            icon
            :badge="
              accountCounters.toRemove > 99 ? '99+' : accountCounters.toRemove
            "
            badge-classes="badge-danger"
          />
        </span>
      </b-button>
      <b-button
        v-if="personalInformation.length"
        class="buttons-menu"
        :class="
          tabs[1].id == actualTab
            ? 'button-active'
            : isDarkSkin
            ? 'button-inactive-dark'
            : 'button-inactive-light'
        "
        @click="actualTab = tabs[1].id"
      >
        {{ tabs[1].label }}
        <span v-if="personalInformation.length > 0" class="ml-1">
          <feather-icon
            icon
            :badge="
              personalInformation.length > 99
                ? '99+'
                : personalInformation.length
            "
            badge-classes="badge-danger"
          />
        </span>
      </b-button>
      <b-button
        v-if="inquiries.length"
        class="buttons-menu"
        :class="
          tabs[2].id == actualTab
            ? 'button-active'
            : isDarkSkin
            ? 'button-inactive-dark'
            : 'button-inactive-light'
        "
        @click="actualTab = tabs[2].id"
      >
        {{ tabs[2].label }}
        <span v-if="inquiries.length > 0" class="ml-1">
          <feather-icon
            icon
            :badge="inquiries.length > 99 ? '99+' : inquiries.length"
            badge-classes="badge-danger"
          />
        </span>
      </b-button>
      <b-button
        v-if="publicRecords.length"
        class="buttons-menu"
        :class="
          tabs[3].id == actualTab
            ? 'button-active'
            : isDarkSkin
            ? 'button-inactive-dark'
            : 'button-inactive-light'
        "
        @click="actualTab = tabs[3].id"
      >
        {{ tabs[3].label }}
        <span v-if="publicRecords.length > 0" class="ml-1">
          <feather-icon
            icon
            :badge="publicRecords.length > 99 ? '99+' : publicRecords.length"
            badge-classes="badge-danger"
          />
        </span>
      </b-button>
    </div>
    <div>
      <FirstStepTable
        v-if="actualTab == 1"
        :isBusy="isBusy"
        :statusOptions="statusOptions"
        :statusOptionsRevert="statusOptionsRevert"
        :client="client"
        :items="items"
        :section="section"
      />
      <b-card v-if="actualTab == 2">
        <GridReport
          :data="personalInformation"
          :statusOptions="statusOptionsGridReport"
          :showTitle="false"
          :title="'Personal Information'"
          :showInfo="true"
        />
      </b-card>
      <b-card v-if="actualTab == 3">
        <GridReport
          :data="inquiries"
          :statusOptions="statusOptionsGridReport"
          :showTitle="false"
          :title="'Inquiries'"
          :showInfo="true"
        />
      </b-card>
      <b-card v-if="actualTab == 4">
        <GridReport
          :data="publicRecords"
          :statusOptions="statusOptionsGridReport"
          :showTitle="false"
          :title="'Public Records'"
          :showInfo="true"
        />
      </b-card>
    </div>
  </b-row>
</template>
<script>
//Import Fields
import FirstStepTable from "@/views/commons/components/request-workplan/views/components/modal/FirstStepTable.vue";
import GridReport from "@/views/commons/components/ncr/components/delete/GridReport.vue";
//Import Service
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import ReportService from "@/views/commons/components/ncr/service/report.service";
import ClientsServices from "@/views/credit-experts/views/clients/dashboard/services/clients.ce.dashboard.services";
import { mapGetters } from "vuex";

export default {
  props: {
    client: {
      type: [Object, Array],
      required: true,
      default: [],
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
  },
  components: {
    FirstStepTable,
    GridReport,
  },
  data() {
    return {
      personalInformation: [],
      inquiries: [],
      publicRecords: [],
      accounts: [],
      actualTab: 1,
      tabs: [
        {
          id: 1,
          label: "Accounts",
        },
        {
          id: 2,
          label: "Personal Information",
        },
        {
          id: 3,
          label: "Inquiries",
        },
        {
          id: 4,
          label: "Public Records",
        },
      ],
      accountCounters: {},
      items: [],
      isBusy: true,
      statusOptions: [
        {
          value: "DELETED",
          id: 4,
        },
        {
          value: "UPDATED",
          id: 3,
        },
        {
          value: "VERIFIED",
          id: 2,
        },
      ],
      statusOptionsRevert: [
        {
          value: "IN DISPUTE",
          id: 1,
        },
        {
          value: "NEGATIVE",
          id: 6,
        },
      ],
      statusOptionsGridReport: [],
    };
  },
  async created() {
    await this.getReport();
    await this.getAccountCounters();
    this.statusOptionsGridReport = this.statusOptions.filter(
      (item) => item.id != 2
    );
    if (this.section == "workplan") {
      this.statusOptions = this.statusOptions.filter((item) => item.id != 2);
    }
  },
  methods: {
    async getAccountCounters() {
      try {
        const params = {
          idClient: this.client.client_account_id,
          isOthers: false,
          score_id: this.section == "workplan" ? this.client.score_id : null,
        };
        const { data } = await ClientsServices.countAnalysisCr(params);
        this.accountCounters = data;
      } catch (error) {
        throw error;
      }
    },
    activeTab(tab) {
      Object.keys(this.tabs).forEach((key) => {
        this.tabs[key] = key == tab;
      });
    },
    async getReport() {
      try {
        this.addPreloader();
        const params = {
          idClient: this.client.client_account_id,
          isOthers: false,
          score_id: this.section == "workplan" ? this.client.score_id : null,
        };
        const { data } = await NcrRequestService.getReportInfoStep1(params);
        this.items = data.remove_data.map((item) => {
          item.items.map(
            (item) => (
              (item.is_remove = item.is_remove == 1 ?? true),
              (item.statusOld = item.status_letter),
              (item.statusIdOld = item.status)
            )
          );
          return {
            ...item,
            showDetails: false,
          };
        });
        this.personalInformation = data.personal_information;
        this.inquiries = data.inquiries;
        this.publicRecords = data.public_record;
        this.isBusy = false;
      } catch (error) {
        this.showErrorSwal(error);
        console.log("Error in getReport: ", error);
      } finally {
        this.removePreloader();
      }
    },
    obtainChecked(data) {
      return data
        .map((item) =>
          item.filter((subItem) => [3, 4].includes(subItem.status))
        )
        .flat()
        .map((item) => {
          return {
            id: item.id,
            status_id: item.status,
          };
        });
    },
    obtainCheckedAccounts(data) {
      return data
        .map(
          // (item) => item.items.filter((subItem) => [3, 4].includes(subItem.status))
          (item) => item.items.filter((subItem) => subItem.status)
        )
        .flat()
        .map((item) => {
          return {
            id: item.id,
            status_id: item.status,
            old_status_id: item.statusIdOld,
          };
        });
    },
    async updateStatus() {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to update the status of this report?"
      );
      if (!confirm.value) return;
      const checkedInfo = this.obtainChecked(this.personalInformation);
      const checkedInquiries = this.obtainChecked(this.inquiries);
      const checkedPublicRecords = this.obtainChecked(this.publicRecords);
      const checkedAccounts = this.obtainCheckedAccounts(this.items);
      try {
        this.addPreloader();
        return await ReportService.changeStatus({
          id_account: this.client.client_account_id,
          personal_information: checkedInfo,
          inquiries: checkedInquiries,
          public_records: checkedPublicRecords,
          accounts: checkedAccounts,
          user_id: this.currentUser.user_id,
          id_ncr: this.client.ncr_request_id,
          score_id: this.client.score_id,
          id_request: this.client.id,
          section: this.section,
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_ID_UPDATE_REQUEST: "RequestWorkPlanStore/G_ID_UPDATE_REQUEST",
      G_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/G_ID_REQUEST_WORKPLAN",
    }),
  },
};
</script>
<style scoped>
* {
  --light-bg-color: #f1f1f1;
  --dark-bg-color: #232328;
  --primary-color: #3f7afa;
}
.buttons-menu {
  margin: 0 15px;
  width: 200px;
  border: 0px !important;
}
.button-active {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.button-inactive-light {
  background-color: #e8ecf3 !important;
  color: #606060 !important;
}
.button-inactive-dark {
  background-color: #243250 !important;
  color: var(--primary-color) !important;
}
</style>
