var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[((!_vm.onlyRead && !_vm.isSpecialist) || (_vm.isSpecialist && _vm.type === 1))?_c('div',{staticClass:"d-flex justify-content-between mb-2"},[_c('div'),_c('b-button',{attrs:{"disabled":_vm.isResponsibleAccount,"variant":"primary"},on:{"click":function($event){return _vm.openCredentialsModal(1)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_vm._v("NEW ")],1)],1):_vm._e(),_c('div'),(_vm.isSpecialist)?_c('div',[_c('b-nav',{staticClass:"m-0",attrs:{"pills":""}},[_c('b-nav-item',{attrs:{"exact":"","active":_vm.type === 1,"exact-active-class":"active","link-classes":['px-3', _vm.bgTabsNavs]},on:{"click":function($event){_vm.type = 1;
          _vm.getCredentials;}}},[_vm._v("GENERAL")]),_c('b-nav-item',{attrs:{"exact":"","active":_vm.type === 2,"exact-active-class":"active","link-classes":['px-3', _vm.bgTabsNavs]},on:{"click":function($event){_vm.type = 2}}},[_vm._v("SPECIALIST")])],1)],1):_vm._e(),_c('b-card',{class:_vm.isSpecialist
        ? 'border-top-primary border-3 border-table-radius px-0'
        : '',attrs:{"no-body":""}},[(_vm.type === 1)?_c('b-table',{staticClass:"position-relative font-small-3 table-new-customization filter-slot-new-customization",attrs:{"responsive":"","fields":_vm.fieldsR,"items":_vm.credentials,"busy":_vm.isBusy,"no-border-collapse":"","show-empty":"","sticky-header":"50vh","primary-key":"id"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(user)",fn:function(ref){
        var value = ref.value;
return [_c('div',{staticClass:"rounded overflow-hidden row justify-content-between cursor-pointer",class:_vm.isDarkSkin ? 'bg-primary-dark' : 'bg-primary-custom',on:{"click":function($event){return _vm.copyEmail(value)}}},[_c('span',{staticClass:"overflow-hidden text-ellipsis"},[_vm._v(_vm._s(value))]),_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1)]}},{key:"cell(password)",fn:function(ref){
        var value = ref.value;
return [_c('div',{staticClass:"rounded overflow-hidden row justify-content-between cursor-pointer",class:_vm.isDarkSkin ? 'bg-primary-dark' : 'bg-primary-custom',on:{"click":function($event){return _vm.copyPassword(value)}}},[_c('span',{staticClass:"overflow-hidden text-ellipsis"},[_vm._v(_vm._s(value))]),_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1)]}},{key:"cell(username)",fn:function(data){return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image
                  ? data.item.user_image
                  : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-9 p-0"},[_c('span',[_vm._v(_vm._s(data.item.username))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.created_at)))])])])]}},{key:"cell(updatename)",fn:function(data){return [(data.item.updated_at != null)?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image_updated
                  ? data.item.user_image_updated
                  : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-9 px-1"},[_c('span',[_vm._v(_vm._s(data.item.updatename))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.updated_at)))])])]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(!_vm.onlyRead)?_c('div',[(!_vm.isResponsibleAccount)?_c('feather-icon',{staticClass:"text-primary mr-1 cursor-pointer",attrs:{"icon":"EditIcon","size":"15"},on:{"click":function($event){return _vm.openCredentialsModal(2, data.item.id)}}}):_vm._e(),(!_vm.isResponsibleAccount)?_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"TrashIcon","size":"15"},on:{"click":function($event){return _vm.deleteCredential(data.item.id)}}}):_vm._e()],1):_vm._e()]}}],null,false,1043081900)}):_vm._e(),(_vm.type === 2)?_c('add-list-credential-component',{attrs:{"accountId":_vm.clientId}}):_vm._e()],1),(_vm.credentialsModalController)?_c('edit-add-credential',{attrs:{"name-client":_vm.client.client_name,"name-program":_vm.client.program_name,"account-name":_vm.client.account,"type-modal":1,"type":_vm.typeModal,"id-cred":_vm.idToPass},on:{"close":_vm.closeCredentialsModal,"reload":_vm.getCredentials}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }