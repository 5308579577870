<template>
  <b-modal
    v-model="onControl"
    title="Edit Inquiry"
    modal-class="custom-modal-amg"
    title-tag="h3"
    scrollable
    header-bg-variant="transparent"
    header-class="p-0"
    size="md"
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">Edit Inquiry</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-row>
        <b-col cols="12">
          <validation-provider v-slot="{ errors }" name="iq" rules="required">
            <label>{{ itemIn.content[0].key }}:</label>
            <b-form-input v-model="inquiry" />
            <span v-if="errors[0]" class="text-danger">
              {{ itemIn.content[0].key }} {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <label>{{ itemIn.content[1].key }}:</label>
          <validation-provider v-slot="{ errors }" rules="required">
            <kendo-datepicker
              v-model="date"
              v-mask="'##/##/####'"
              :style="
                isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''
              "
              :class="[
                'w-100 rounded bg-transparent k-picker-custom',
                { 'text-white': isDarkSkin },
              ]"
              :format="'MM/dd/yyyy'"
              class="leads-datepicker form-control"
            />
            <span v-if="errors[0]" class="text-danger">
              {{ itemIn.content[1].key }} {{ errors[0] }}</span
            >
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <label>Status:</label>
          <b-form-select
            v-model="status"
            :options="statuses"
            text-field="value"
            value-field="id"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-button @click="saveEdit" class="custom-button"> Save </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapActions } from "vuex";
export default {
  props: {
    itemIn: null,
    userId: null,
  },

  data() {
    return {
      inquiry: null,
      date: null,
      status: null,
      statuses: null,
      onControl: false,
    };
  },
  created() {
    this.onControl = true;
    this.start();
    this.getStatuses();
  },

  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),

    start() {
      this.inquiry = this.itemIn.content[0].value;
      this.date = this.itemIn.content[1].value;
      this.status = this.itemIn.status;
    },

    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data;
        }
        this.statuses = this.statuses.filter((status) => status.id !== 1);
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
    async saveEdit() {
      this.addPreloader();
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          let params = {
            date: this.date,
            status: this.status,
            id: this.itemIn.cr_accounts_id,
            bureau_id: this.itemIn.bureau,
            content: this.inquiry,
            value: 1,
            iduser: this.userId,
            instruction: null,
            reason: null,
          };
          const data = await ClientsOptionsServices.editSingleAccountReportIn(
            params
          );
          if (data.status == 200) {
            this.updateRefreshReport(2);
            this.$emit("close");
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-button {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
</style>
