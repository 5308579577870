<template>
  <div>
    <b-table
      striped
      small
      :items="ncrs"
      :key="ncrs.id"
      :tbody-tr-class="isDarkSkin ? 'table-round dark' : 'table-round'"
      :fields="visibleFields"
      class="table-new-customization custom-table"
      show-empty
    >
      <template v-slot:cell(request)="{ item }">
        <div>{{ item.created_at | myGlobalDay }}</div>
      </template>
      <template #cell(service)="{ item }">
        <div
          v-if="item.program_id"
          v-b-tooltip.bottom="item.program_name"
          class="d-flex justify-content-center m-0"
        >
          <img
            :src="getProgramImageRoute(item.program_id)"
            alt="img_services"
          />
        </div>
      </template>
      <template v-slot:cell(title)="{ item }">
        <div
          v-if="[1, 2].includes(item.type_round)"
          class="text-primary cursor-pointer font-weight-bolder"
          @click="editFirstNote(item)"
        >
          {{ item.title }}
        </div>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(type)="{ item }">
        <b-badge v-if="[1, 2].includes(item.type_round)" variant="primary">
          Letter
        </b-badge>
        <b-badge v-else variant="warning"> Direct </b-badge>
      </template>
      <template v-slot:cell(tracking)="{ item }">
        <feather-icon
          icon="ListIcon"
          class="cursor-pointer"
          size="18"
          @click="openModalTracking(item)"
        />
      </template>
      <template v-slot:cell(items)="data">
        <b-badge
          variant="primary"
          pill
          class="cursor-pointer"
          @click="showRounds(data.index)"
        >
          {{ data.item.sumeq + data.item.sumtr + data.item.sumex }}
        </b-badge>
      </template>
      <template v-slot:cell(disputes)="{ item }">
        <feather-icon
          v-if="
            item.state == 7 &&
            item.status_dispute == 0 &&
            item.date_diff > dateDiff
          "
          icon="PlusIcon"
          size="22"
          class="cursor-pointer text-primary"
          style="font-size: 20px; margin-top: 5px"
          @click="openModalDispute(item)"
        />
        <b-badge
          v-if="
            item.state == 7 &&
            ((item.status_dispute == 1 &&
              item.status_dispute_grilla == 'PENDING') ||
              item.status_dispute_grilla == 'COMPLETED') &&
            item.date_diff > dateDiff
          "
          class="cursor-pointer"
          style="font-weight: bold; text-transform: uppercase"
          :variant="
            item.status_dispute_grilla === 'PENDING'
              ? 'light-warning'
              : 'light-success'
          "
          @click="openModalViewDispute(item)"
        >
          {{ item.status_dispute_grilla }}
        </b-badge>
      </template>
      <template #row-details>
        <transition name="fade">
          <RoundDetail :round="round" :ncrId="null" :clientData="client" />
        </transition>
      </template>
    </b-table>
    <ModalTrackingRo
      v-if="showTracking"
      :data-tracking="trackingData"
      @close="closeModalTracking"
    />
    <DetailsModal
      v-if="showDetailFN"
      :details-round-data="roundData"
      :validate-modal="1"
      @close="closeEditFirstNote"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RoundDetail from "@/views/commons/components/clients/dashboard/options/dis-module/round/RoundDetail.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalTrackingRo from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/tracking-modal-ro/ModalTrackingRo.vue";
import DetailsModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsModal.vue";
export default {
  components: {
    ModalTrackingRo,
    DetailsModal,
    RoundDetail,
  },
  data() {
    return {
      ncrs: [],
      fields: [
        {
          key: "request",
          label: "Date",
          visible: true,
        },
        {
          key: "service",
          label: "Service",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "type",
          label: "Type",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "title",
          label: "Title",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "tracking",
          label: "Tracking",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "items",
          label: "Items",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
      ],
      showTracking: false,
      trackingData: {},
      roundData: {},
      showDetailFN: false,
      round: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dateDiff() {
      return 44;
    },
  },
  methods: {
    async getIndividuals() {
      const { data } = await ClientsOptionsServices.searchLettersAccount({
        accountId: this.$route.params.idClient,
        typeId: null,
      });
      this.ncrs = data || [];
      if (this.ncrs.length > 0) this.showFirstElement();
    },
    showRounds(index) {
      this.round = {
        id: this.ncrs[index].id,
        state: this.ncrs[index].state,
        date_send: this.ncrs[index].date_send,
        title: this.ncrs[index].title,
        title_id: this.ncrs[index].title_id,
        type_send: this.ncrs[index].type_send
      };
      // this.roundId = this.ncrs[index].id;
      this.ncrs.forEach((element, i) => {
        if (i != index) {
          element._showDetails = false;
          element.detailsShowing = false;
        }
      });
      this.showDetails = !this.showDetails;
      this.ncrs[index]._showDetails = !this.ncrs[index]._showDetails;
      this.ncrs[index].detailsShowing = !this.ncrs[index].detailsShowing;
    },
    showFirstElement() {
      this.round = {
        id: this.ncrs[0].id,
        state: this.ncrs[0].state,
        date_send: this.ncrs[0].date_send,
        title: this.ncrs[0].title,
        title_id: this.ncrs[index].title_id,
        type_send: this.ncrs[0].type_send,
      };
      this.showDetails = !this.showDetails;
      this.ncrs[0]._showDetails = !this.ncrs[0]._showDetails;
      this.ncrs[0].detailsShowing = !this.ncrs[0].detailsShowing;
    },
    openModalTracking(item) {
      this.trackingData = {
        id: item.id,
        account: item.account,
        clientName: this.client.client_name,
      };
      this.showTracking = true;
    },
    closeModalTracking() {
      this.showTracking = false;
      this.trackingData = {};
    },
    editFirstNote(item) {
      const { id, clientAccountId, account, title, format } = item;
      this.roundData = {
        id,
        clientAccountId,
        account,
        title,
        format,
        clientName: this.client.client_name,
      };
      this.showDetailFN = true;
    },
    closeEditFirstNote() {
      this.roundData = {};
      this.showDetailFN = false;
    },
    counterBureaus(item) {
      const { sumeq, sumtr, sumex } = item;
      let result =
        (sumtr != null ? "TU(" + sumtr + ")" : "") +
        (sumeq != null ? " | EQ(" + sumeq + ")" : "") +
        (sumex != null ? " | EX(" + sumex + ")" : "");
      return result;
    },
    openModalDispute(item) {
      console.log(item);
    },
    openModalViewDispute(item) {
      console.log(item);
    },
  },
  mounted() {
    this.getIndividuals();
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.font-score {
  font-size: 1.2rem;
  font-weight: 600;
}
.custom-table >>> th {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child {
  border-top-right-radius: 10px !important;
}
</style>
