import { amgApi } from "@/service/axios";

class ReportService {
  async getReportForEdit(params) {
    try {
      const data = await amgApi.post(
        "/specialists/get-report-for-edit",
        params
      );
      return data;
    } catch (error) {
      console.error("getReportForEdit", error);
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }

  async changeStatus(params) {
    try {
      const data = await amgApi.post("/specialists/change-status", params);
      return data;
    } catch (error) {
      console.error("changeStatus", error);
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }
}

export default new ReportService();
