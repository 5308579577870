<template>
  <div>
    <div
      v-if="(!onlyRead && !isSpecialist) || (isSpecialist && type === 1)"
      class="d-flex justify-content-between mb-2"
    >
      <div />
      <b-button
        :disabled="isResponsibleAccount"
        variant="primary"
        @click="openCredentialsModal(1)"
      >
        <feather-icon icon="PlusIcon" class="mr-1" />NEW
      </b-button>
    </div>
    <div></div>
    <div v-if="isSpecialist">
      <b-nav pills class="m-0">
        <b-nav-item
          exact
          :active="type === 1"
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          @click="
            type = 1;
            getCredentials;
          "
          >GENERAL</b-nav-item
        >
        <b-nav-item
          exact
          :active="type === 2"
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          @click="type = 2"
          >SPECIALIST</b-nav-item
        >
      </b-nav>
    </div>
    <b-card
      no-body
      :class="
        isSpecialist
          ? 'border-top-primary border-3 border-table-radius px-0'
          : ''
      "
    >
      <b-table
        v-if="type === 1"
        responsive
        :fields="fieldsR"
        :items="credentials"
        :busy="isBusy"
        no-border-collapse
        class="position-relative font-small-3 table-new-customization filter-slot-new-customization"
        show-empty
        sticky-header="50vh"
        primary-key="id"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(user)="{ value }">
          <div
            class="rounded overflow-hidden row justify-content-between cursor-pointer"
            @click="copyEmail(value)"
            :class="isDarkSkin ? 'bg-primary-dark' : 'bg-primary-custom'"
          >
            <span class="overflow-hidden text-ellipsis">{{ value }}</span>
            <feather-icon icon="CopyIcon" />
          </div>
        </template>
        <template #cell(password)="{ value }">
          <div
            class="rounded overflow-hidden row justify-content-between cursor-pointer"
            @click="copyPassword(value)"
            :class="isDarkSkin ? 'bg-primary-dark' : 'bg-primary-custom'"
          >
            <span class="overflow-hidden text-ellipsis">{{ value }}</span>
            <feather-icon icon="CopyIcon" />
          </div>
        </template>
        <template #cell(username)="data">
          <div class="row align-items-center">
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image
                    ? data.item.user_image
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 p-0">
              <span>{{ data.item.username }}</span> <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
        <template #cell(updatename)="data">
          <div
            class="row align-items-center"
            v-if="data.item.updated_at != null"
          >
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_updated
                    ? data.item.user_image_updated
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.updatename }}</span> <br />
              <span>{{ data.item.updated_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div v-if="!onlyRead">
            <feather-icon
              v-if="!isResponsibleAccount"
              icon="EditIcon"
              class="text-primary mr-1 cursor-pointer"
              size="15"
              @click="openCredentialsModal(2, data.item.id)"
            />
            <feather-icon
              v-if="!isResponsibleAccount"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="15"
              @click="deleteCredential(data.item.id)"
            />
          </div>
        </template>
      </b-table>
      <add-list-credential-component v-if="type === 2" :accountId="clientId" />
    </b-card>
    <edit-add-credential
      v-if="credentialsModalController"
      :name-client="client.client_name"
      :name-program="client.program_name"
      :account-name="client.account"
      :type-modal="1"
      :type="typeModal"
      :id-cred="idToPass"
      @close="closeCredentialsModal"
      @reload="getCredentials"
    />
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import EditAddCredential from "@/views/commons/components/clients/dashboard/options/acr-module/modals/EditAddCredential.vue";
import { mapGetters } from "vuex";
import AddListCredentialComponent from "@/views/commons/components/clients/dashboard/options/acr-module/components/AddListCredentialComponent.vue";
import { useClipboard } from "@vueuse/core";

export default {
  components: {
    EditAddCredential,
    AddListCredentialComponent,
  },
  props: {
    clientId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      type: 1,
      typeModal: "",
      credentials: [],
      credentialsModalController: false,
      isBusy: false,
      fieldsR: [],
      fields: [
        {
          key: "entity",
          label: "Entity",
        },
        {
          key: "user",
          label: "User",
          class: "text-center"
        },
        {
          key: "password",
          label: "Password",
        },
        {
          key: "types",
          label: "Type",
        },
        {
          key: "observation",
          label: "Observation",
        },
        {
          key: "username",
          label: "Created By",
        },
        {
          key: "updatename",
          label: "Updated By",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      idToPass: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    onlyRead() {
      return this.moduleId === 18;
    },
    isSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    await this.getCredentials();
  },
  methods: {
    async getCredentials() {
      this.fieldsR = [...this.fields];
      try {
        if (this.currentUser.module_id !== 7) {
          //ver esto
          this.fieldsR.splice(3, 1);
        }
        this.isBusy = true;
        const data = await ClientsOptionsServices.getAllCredentials({
          id: this.$route.params.idClient || this.$route.params.accountId,
          typeOfView: 1,
        });
        if (data.status === 200) {
          this.credentials = data.data;
          this.isBusy = false;
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    openCredentialsModal(type, id) {
      this.typeModal = type;
      this.idToPass = id;
      this.credentialsModalController = true;
    },
    closeCredentialsModal() {
      this.credentialsModalController = false;
    },
    async deleteCredential(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const data = await ClientsOptionsServices.deleteCredential({
            idcredential: id,
            id_user: this.currentUser.user_id,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            await this.getCredentials();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    copyEmail(email) {
      const { copy } = useClipboard();
      copy(email);
      this.showGenericToast({
        position: "top-right",
        variant: "success",
        icon: "CheckIcon",
        title: "Copied",
        text: "The operation was successful",
      });
    },
    copyPassword(email) {
      const { copy } = useClipboard();
      copy(email);
      this.showGenericToast({
        position: "top-right",
        variant: "success",
        icon: "CheckIcon",
        title: "Copied",
        text: "The operation was successful",
      });
    },
  },
};
</script>

<style scoped>
.bg-primary-custom {
  background: #f3f8ff !important;
  color: #3f7afa !important;
  padding: 5px 5px;
}
.bg-primary-dark {
  background: rgba(0, 28, 68, 0.4) !important;
  color: rgba(63, 122, 250, 1) !important;
  padding: 5px 5px;
}
.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-new-customization >>> thead tr th {
  background: rgba(63, 122, 250, 1) !important;
}
</style>
