<template>
  <b-modal
    id="AddEditCredential"
    v-model="onControl"
    size="lg"
    centered
    no-close-on-backdrop
    @hidden="close"
    modal-class="custom-modal-amg"
    header-bg-variant="custom-amg"
    :title="type === 1 ? 'ADD CREDENTIALS' : 'EDIT CREDENTIALS'"
    title-class="h3 text-white"
  >

    <b-container class="mt-1">
      <b-row align-v="center" class="mb-1" style="gap: 10px">
        <b-col
          v-if="typeModal === 1"
          class="d-flex align-items-center"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>Program | {{ nameProgram }}</p>
        </b-col>
        <b-col
          v-if="typeModal === 2"
          class="d-flex align-items-center"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>Account | {{ accountName }}</p>
        </b-col>
        <b-col
          class="d-flex align-items-center"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>Client | {{ nameClient }}</p>
        </b-col>
      </b-row>
      <b-row v-if="moduleId === 7">
        <b-col cols="12">
          <b-form-group label="Type">
            <b-form-select v-model="credential.types" :options="typesOptions" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Entity">
            <b-form-input v-model="credential.entity" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="User">
            <b-form-input v-model="credential.user" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Password">
            <b-form-input v-model="credential.password" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Observation">
            <b-form-textarea v-model="credential.observation" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button variant="primary" @click="saveCredential"
        ><feather-icon icon="SaveIcon" /> Save
      </b-button>
      <b-button @click="closeS" variant="outline-primary">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  components: {
    ButtonSave,
    ButtonCancel,
  },
  props: {
    nameClient: null,
    nameProgram: null,
    accountName: null,
    typeModal: null,
    type: null,
    idCred: null,
    accountIdFromCred: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
      credential: {
        id: "",
        entity: "",
        user: "",
        password: "",
        observation: "",
        types: "",
        idcredential: "",
      },
      typesOptions: [
        { value: 1, text: "UB ONLINE" },
        { value: 2, text: "EMAIL" },
        { value: 3, text: "MONITOREO EXPERIAN" },
        { value: 4, text: "MONITOREO TRANSUNION" },
        { value: 5, text: "MONITOREO EQUIFAX" },
        { value: 6, text: "OTHER" },
      ],
    };
  },
  async created() {
    this.onControl = true;
    if (this.type === 2) {
      await this.getCredential();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    closeS() {
      this.$bvModal.hide("AddEditCredential");
    },
    close() {
      this.$emit("close");
    },
    async getCredential() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.getCredential({
          idcredential: this.idCred,
        });
        if (data.status === 200) {
          this.credential = data.data[0];
          this.credential.types =
            this.credential.types !== "" ? this.credential.types : 0;
          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async saveCredential() {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const idToSend =
            this.accountIdFromCred === null
              ? this.$route.params.idClient || this.$route.params.accountId
              : this.accountIdFromCred;
          const data = await ClientsOptionsServices.saveCredential({
            id: idToSend,
            idcredential: this.credential.id,
            user: this.credential.user,
            entity: this.credential.entity,
            password: this.credential.password,
            observation: this.credential.observation,
            user_id: this.currentUser.user_id,
            typee: this.credential.types,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.close();
            this.$emit("reload");
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-modal {
  border-radius: 3px 3px 0px 0px;
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.border-custom {
  border: 1.2px solid rgba(255, 255, 255, 1) !important;
  border-radius: 5px;
  padding: 13px, 16px, 13px, 16px !important;
  height: 46px;
}
p {
  margin: 0 !important;
  font-size: 18px !important;
}
.border-custom-light {
  border: 1.2px solid rgba(35, 42, 70, 1) !important;
  border-radius: 5px;
  padding: 13px, 16px, 13px, 16px !important;
  height: 46px;
  color: rgba(35, 42, 70, 1) !important;
}
.header-modal {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
</style>
