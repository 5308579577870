var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollList",staticClass:"chat-container",class:_vm.isDarkSkin ? 'bg-chat-dark' : 'bg-chat-light'},[_c('div',{staticClass:"chat-messages"},[_vm._l((_vm.comments),function(item,index){return [(
            !(item.user_id == _vm.getUserId) && item.idUnread && item.idUnread === 1
          )?_c('div',{key:item.idUnread * 1000,staticClass:"d-flex justify-content-center"},[_c('p',[_c('b-badge',{attrs:{"href":"#","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.comments.filter(function (item) { return item.unread; }).length)+" unread messages")])],1)]):_vm._e(),_c('div',{key:index,staticClass:"message",class:item.user_id == _vm.getUserId
              ? 'message-sender '
              : ("message-receiver " + (item.unread ? 'unread' : 'read'))},[_c('div',{staticClass:"row align-items-start"},[_c('b-avatar',{class:item.user_id == _vm.getUserId ? 'order-2' : 'order-1',attrs:{"variant":_vm.isDarkSkin ? 'warning' : 'info',"icon":"person-fill\n"}}),_c('div',{class:item.user_id == _vm.getUserId ? 'order-1 mr-1' : 'order-2 ml-1'},[_c('p',{staticClass:"p-0 m-0",class:item.user_id == _vm.getUserId ? 'text-right' : 'text-left',staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.user_id == _vm.getUserId ? "You" : item.user_name)+" ")]),_c('div',{class:item.user_id == _vm.getUserId
                    ? 'content-message-sender'
                    : 'content-message-receiver'},[(item.is_file)?[(
                      ['png', 'jpge', 'jpg', 'jpeg'].includes(item.file_type)
                    )?_c('div',{style:(_vm.containerImg(item)),on:{"click":function($event){return _vm.openImage(item.file_type, item)}}}):_c('div',{staticClass:"message-text",on:{"click":function($event){return _vm.openImage(item.file_type, item)}}},[_c('p',{staticClass:"cursor-pointer link-file"},[_vm._v(" "+_vm._s(item.content)+" ")])])]:[_c('div',{staticClass:"message-text"},[_vm._v(" "+_vm._s(item.content)+" ")])],_c('div',{staticClass:"message-date text-muted text-right col-12"},[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(item.date_time))+" "),(item.user_id == _vm.getUserId)?[(item.viewed)?_c('tabler-icon',{staticClass:"text-primary",attrs:{"size":"18","icon":"ChecksIcon"}}):_c('tabler-icon',{staticClass:"text-secondary",attrs:{"size":"18","icon":"ChecksIcon"}})]:_vm._e()],2)],2)])],1)])]})],2),_c('CoolLightBox',{attrs:{"items":_vm.itemImage,"index":_vm.showImage,"effect":'fade'},on:{"close":function($event){_vm.showImage = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }