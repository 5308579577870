<template>
  <b-modal
    v-model="ownControl"
    scrollable
    hide-footer
    modal-class="custom-modal-amg"
    title-class="h3 text-white font-weight-bolder"
    :title="'EDIT ' + detailsRoundData.title"
    size="md"
    header-bg-variant="transparent"
    header-class="p-0"
    class="custom-table"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Edit {{ titleName(detailsRoundData.title) }}
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div class="mb-2">
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <!-- <span class="title-tag">CLIENT NAME</span> -->
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client | {{ detailsRoundData.clientName || detailsRoundData.client_name }}
            </p>
          </div>
        </b-col>

        <b-col cols="12" md="6" lg="6" class="pt-md-0 pt-1">
          <!-- <span class="title-tag">CLIENT ACCOUNT</span> -->
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Account | {{ detailsRoundData.account }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <details-component2
      v-if="detailsModalOn"
      :data-round="detailsRoundData"
      :is-modal="true"
      :validate-modal="validateModal"
      :validate-state-created="false"
      @viewpdf="showviewpdf"
      @close="closeDetailsModal"
    />
    <show-letters-by-bureau
      v-if="showLetterByBureau"
      :data-round="detailsRoundData"
      @close="closeShowViewPdf"
    />
    <!--        TODO poner para aniadir accounts y editarlos cuando cumpla con el v-if del boton ADD-->
  </b-modal>
</template>

<script>
import DetailsComponent2 from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsComponent2.vue";
import ShowLettersByBureau from "@/views/commons/components/clients/dashboard/options/dis-module/modals/show-letters-by-bureu/ShowLettersByBureau.vue";

export default {
  components: {
    ShowLettersByBureau,
    DetailsComponent2,
  },
  props: {
    detailsRoundData: null,
    validateModal: null,
  },
  data() {
    return {
      ownControl: false,
      showLetterByBureau: false,
      detailsModalOn: true,
    };
  },
  created() {
    this.ownControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeShowViewPdf() {
      this.detailsModalOn = true;
      this.showLetterByBureau = false;
    },
    showviewpdf() {
      this.closeDetailsModal();
      this.showLetterByBureau = true;
    },
    closeDetailsModal() {
      this.detailsModalOn = false;
    },
    titleName(item){
      let words = item.split(' ');
      return words.map(x => {
        return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
      }).join(' ')
    }
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}

</style>
