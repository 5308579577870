<template>
  <div :class="isDarkSkin ? 'dark-bg-card' : 'light-bg-card'">
    <div class="d-flex justify-content-between mb-1">
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="!isFromModal">
          <b-button
            id="add-marks"
            @click="openModalSendReport"
            class="custom-button"
          >
            <feather-icon icon="SendIcon" size="15" class="mr-1" />
            <span>Send Report</span>
          </b-button>
          <b-button
            id="marks-tracking"
            variant="link"
            :class="isDarkSkin ? 'dark-button' : ''"
            style="margin-left: 5px; color: #3f7afa; padding: 0.6rem"
            @click="openModalHistoryReports"
          >
            <feather-icon icon="ListIcon" size="20" style="margin-left: 5px" />
            <!-- History Report -->
          </b-button>
          <b-button
            variant="link"
            :class="isDarkSkin ? 'dark-button' : ''"
            style="margin-left: 5px; color: #3f7afa"
            @click="openScoresModal"
          >
            Scores
          </b-button>
          <b-button
            variant="link"
            :class="isDarkSkin ? 'dark-button' : ''"
            style="margin-left: 5px; color: #3f7afa"
            @click="openModal(itemWONcr ,'PERSONAL INFO')"
          >
            Personal Information added
          </b-button>
        </div>
      </div>
      <div v-if="[11, 28, 29].includes(moduleId)">
        <b-dropdown
          class="custom-button"
          variant="transparent"
          toggle-class="d-flex align-items-center dropdown-user-link"
        >
          <template v-slot:button-content>
            <div class="text-white">Options</div>
          </template>
          <b-dropdown-item v-if="moduleId == 7" @click="openModalCreditReport">
            <tabler-icon
              icon="CurrencyDollarIcon"
              style="margin-right: 5px"
              size="13"
            />
            Credit Report
          </b-dropdown-item>
          <b-dropdown-item
            variant="success"
            v-if="isEnabledForEditAndDelete && !isFromModal"
            :disabled="isResponsibleAccount"
            @click="openAddAccountsModal"
          >
            <feather-icon icon="EditIcon" size="13" style="margin-left: 5px" />
            Add Personal Information
          </b-dropdown-item>
          <b-dropdown-item
            variant="danger"
            v-if="[6, 11, 7, 20, 22, 23, 25, 18, 28, 29].includes(moduleId)"
            :disabled="isResponsibleAccount"
            @click="openModalDacPdf"
          >
            <feather-icon
              icon="Trash2Icon"
              size="13"
              style="margin-left: 5px"
            />
            Delete Accounts
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div>
      <b-table
        :fields="fields"
        :items="creditReport"
        responsive
        show-empty
        class="table-new-customization custom-table"
      >
        <template #cell(created_at)="{ item }">
          <span class="d-flex justify-content-center m-0">
            {{ item.created_at | myGlobal }}
          </span>
        </template>
        <template #cell(emit_date)="{ item }">
          <span class="d-flex justify-content-center m-0">
            {{ item.emit_date | myGlobal }}
          </span>
        </template>
        <template #cell(service)="{ item }">
          <div
            v-if="item.program_id"
            v-b-tooltip.bottom="item.program_name"
            class="d-flex justify-content-center m-0"
          >
            <img
              :src="getProgramImageRoute(item.program_id)"
              alt="img_services"
            />
          </div>
        </template>
        <template #cell(status)="{ item }">
          <p
            class="d-flex justify-content-center m-0"
            :class="
              item.status == 'COMPLETED' ? 'text-success' : 'text-warning'
            "
          >
            {{ item.status }}
          </p>
        </template>
        <template #cell(file)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <a
              v-if="item.link_pdf != null"
              :href="item.link_pdf"
              target="_blank"
            >
              <amg-icon size="18" class="text-danger" icon="FilePdfIcon" />
            </a>
            <span v-else>--</span>
          </div>
        </template>
        <template #cell(to_remove)="{ item }">
          <b-btn
            class="btn-icon rounded-circle"
            variant="outline-info"
            size="sm"
            :disabled="item.created_at == null"
            @click="openModal(item, 'REMOVE')"
          >
            <feather-icon icon="EyeIcon" />
          </b-btn>
        </template>
        <template #cell(to_settlement)="{ item }">
          <b-btn
            class="btn-icon rounded-circle"
            variant="outline-success"
            size="sm"
            :disabled="item.created_at == null"
            @click="openModal(item, 'SETTLEMENTS')"
          >
            <feather-icon icon="EyeIcon" />
          </b-btn>
        </template>
        <template #cell(personal_info)="{ item }">
          <b-btn
            class="btn-icon rounded-circle"
            variant="outline-warning"
            size="sm"
            @click="openModal(item, 'PERSONAL INFO')"
          >
            <feather-icon icon="EyeIcon" />
          </b-btn>
        </template>
        <template #cell(inquiries)="{ item }">
          <b-btn
            class="btn-icon rounded-circle"
            variant="outline-primary"
            size="sm"
            @click="openModal(item, 'INQUIRIES')"
          >
            <feather-icon icon="EyeIcon" />
          </b-btn>
        </template>
        <template #cell(public_records)="{ item }">
          <b-btn
            class="btn-icon rounded-circle"
            variant="outline-danger"
            size="sm"
            @click="openModal(item, 'PUBLIC RECORDS')"
          >
            <feather-icon icon="EyeIcon" />
          </b-btn>
        </template>
      </b-table>
    </div>

    <b-modal
      v-model="showModal"
      :title="titleModal"
      size="xlg"
      @hidden="
        showModal = false;
        typeModal = null;
      "
      scrollable
      hide-footer
      header-class="p-0"
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            {{ titleModal }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="
                showModal = false;
                typeModal = null;
              "
            />
          </div>
        </div>
      </template>
      <AccountsClients
        v-if="typeModal == 'REMOVE'"
        :onlyScoreId="itemSelected.score_id"
        :fromCreditReport="true"
      />
      <accounts-remove-settlement
        v-if="typeModal == 'SETTLEMENTS'"
        class="mt-4"
        :leadId="client.lead_id"
        :ncrRequestId="itemSelected.ncr_request_id"
        :scoreId="itemSelected.score_id"
        :editMode="false"
        :typeView_="typeModal"
        :showTabs="false"
      />

      <personal-information-report
        v-if="typeModal == 'PERSONAL INFO'"
        :clientId="clientId"
        :scoreId="itemSelected.score_id"
      />

      <inquiries-report
        v-if="typeModal == 'INQUIRIES'"
        :clientId="clientId"
        :scoreId="itemSelected.score_id"
      />

      <public-records-report
        v-if="typeModal == 'PUBLIC RECORDS'"
        :clientId="clientId"
        :scoreId="itemSelected.score_id"
      />
    </b-modal>
    <!-- <c-report-detail-vue /> -->
    <!-- <account-history-report :clientId="clientId" /> -->

    <add-accounts
      v-if="accountsModalController"
      :account-name="client.account"
      :name-client="client.client_name"
      @close="closeAddAccountsModal"
    />

    <modal-dac-pdf
      v-if="openModalDacPdfOn"
      @close="openModalDacPdfOn = false"
      :client-account-id="clientId"
    />
    <modal-credit-report-pdf
      v-if="openModalCreditReportPdf"
      @close="openModalCreditReportPdf = false"
    />

    <modal-send-report
      v-if="showModalSendReport"
      @close="closeModalSendReport"
      :client="clientSelect"
    />

    <modal-history-reports
      :client="clientSelect"
      v-if="showModalHistoryReport"
      @close="closeModalHistoryReports"
    />

    <all-scores-modal
      v-if="showAllScoresModal"
      :lead_id="client.lead_id"
      @closeModal="showAllScoresModal = false"
    />
  </div>
</template>

<script>
import AddAccounts from "@/views/commons/components/clients/dashboard/options/report-module/modals/AddAccounts.vue";
import { mapGetters } from "vuex";
import ModalDacPdf from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalDacPdf.vue";
import ModalCreditReportPdf from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalCreditReportPdf.vue";

// Components Client Report
import ScoreReport from "@/views/commons/components/clients/dashboard/options/report-module/components/ScoreReport.vue";
import PersonalInformationReport from "@/views/commons/components/clients/dashboard/options/report-module/components/PersonalInformationReport.vue";
import InquiriesReport from "@/views/commons/components/clients/dashboard/options/report-module/components/InquiriesReport.vue";
import PublicRecordsReport from "@/views/commons/components/clients/dashboard/options/report-module/components/PublicRecordsReport.vue";
import AccountHistoryReport from "@/views/commons/components/clients/dashboard/options/report-module/components/AccountHistoryReport.vue";
import ModalSendReport from "@/views/commons/components/clients/dashboard/information-client/modals/ModalSendReport.vue";
import ModalHistoryReports from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalHistoryReport.vue";
import OnlyAccountsInDisputeReport from "@/views/commons/components/clients/dashboard/options/report-module/components/OnlyAccountsInDisputeReport.vue";
import AllScoresModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/AllScoresModal.vue";
import CReportDetailVue from "../dis-module/CReportDetail.vue";
import AccountsClients from "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue";
import AccountsRemoveSettlement from "@/views/commons/components/request-ncr/views/components/AccountsRemoveSettlement.vue";
import ClientDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import Fields from "@/views/commons/components/clients/dashboard/options/report-module/data/credit-report.fields.js";
export default {
  props: {
    clientId: {
      type: String,
    },
    isFromModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddAccounts,
    ModalDacPdf,
    ModalCreditReportPdf,
    ScoreReport,
    PersonalInformationReport,
    InquiriesReport,
    PublicRecordsReport,
    AccountHistoryReport,
    ModalSendReport,
    ModalHistoryReports,
    OnlyAccountsInDisputeReport,
    CReportDetailVue,
    AllScoresModal,
    AccountsRemoveSettlement,
    AccountsClients,
  },
  data() {
    return {
      itemWONcr: {score_id: null},
      module: "",
      accountsModalController: false,
      openModalDacPdfOn: false,
      openModalCreditReportPdf: false,
      showModalSendReport: false,
      clientSelect: null,
      showModalHistoryReport: false,
      showAllScoresModal: false,
      fields: Fields,
      creditReport: [],
      showModal: false,
      itemSelected: {},
      typeModal: null,
      titleModal: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    isEnabledForEditAndDelete() {
      return (
        this.currentUser.role_id == 1 ||
        ([2,3,22,25,31].includes(this.currentUser.role_id) &&
          [28,29].includes(this.moduleId))
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  watch: {
    typeModal: function () {
      if (this.typeModal == "REMOVE") this.titleModal = "ITEMS TO REMOVE";
      else if (this.typeModal == "SETTLEMENTS")
        this.titleModal = "ACCOUNTS TO SETTLEMENT";
      else this.titleModal = this.typeModal;
    },
  },
  async mounted() {
    await this.getCreditReportData();
  },
  methods: {
    openModal(item, type) {
      console.log(item)
      this.addPreloader();
      this.itemSelected = item;
      this.typeModal = type;
      this.showModal = true;
    },
    async getCreditReportData() {
      this.addPreloader();
      const params = {
        client_account_id: this.client.id,
        lead_id: this.client.lead_id,
      };
      try {
        const { data } = await ClientDashboardServices.getCreditReportData(
          params
        );
        this.creditReport = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
    },
    async getAllReports() {
      this.isBusyAccounts = true;
      this.isBusyPersonalInfo = true;
      this.isBusyPublicRecords = true;
      this.isBusyInquiries = true;
      await Promise.all([
        this.getDeleteAccountIn(),
        this.getDeleteAccountPr(),
        this.getDeleteAccountAc(),
        this.getDeleteAccountPi(),
      ]);
    },
    async reloadTable(category) {
      if (category === 1) {
        await this.getDeleteAccountPi();
      } else if (category === 2) {
        await this.getDeleteAccountIn();
      } else if (category === 3) {
        await this.getDeleteAccountPr();
      } else if (category === 4) {
        await this.getDeleteAccountAc();
      }
    },
    openModalDacPdf() {
      this.openModalDacPdfOn = true;
    },
    closeAddAccountsModal() {
      this.accountsModalController = false;
    },

    openAddAccountsModal() {
      this.accountsModalController = true;
    },
    openModalCreditReport() {
      this.openModalCreditReportPdf = true;
    },
    openModalSendReport() {
      this.clientSelect = this.client;
      this.showModalSendReport = true;
    },

    closeModalSendReport() {
      this.clientSelect = null;
      this.showModalSendReport = false;
    },

    openModalHistoryReports() {
      this.clientSelect = this.client;
      this.showModalHistoryReport = true;
    },

    closeModalHistoryReports() {
      this.clientSelect = null;
      this.showModalHistoryReport = false;
    },
    openScoresModal() {
      this.showAllScoresModal = true;
    },
  },
};
</script>

<style scoped>
* {
  --light-bg-color: #f1f1f1;
  --dark-bg-color1: #232328;
  --dark-bg-color2: #17171a;
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.dark-bg-card {
  border-radius: 10px;
  background-color: var(--dark-bg-color1);
}
.light-bg-card {
  border-radius: 10px;
  background-color: var(--light-bg-color1);
}
.dark-button {
  background-color: #243250;
  color: #3f7afa;
}
.custom-button {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
