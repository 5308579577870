<template>
  <b-modal
    v-model="visible"
    title="TRACKING: CREDIT CARDS"
    title-tag="h3"
    modal-class="modal-primary"
    size="xmd"
    @hidden="close"
    hide-footer
  >
    <b-container>
      <account-client-header :account="account" :client="client" />

      <b-row>
        <br />
        <b-table :items="items" :fields="fields">
          <template #cell(created_at)="data">
            <span>
              <center>
                {{ data.value | myGlobalWithHour }}
              </center>
            </span>
          </template>
          <template #cell(name_user)="data">
            <span>
              <center>
                {{ data.value }}
              </center>
            </span>
          </template>
          <template #cell(app_action)="data">
            <span>
              <center>
                <b-badge v-if="data.value" :variant="getBadgeColor(data.value)">
                  {{ data.value }}
                </b-badge>
              </center>
            </span>
          </template>
          <template #cell(comment)="data">
            <div class="w-600">
              {{ data.value }}
            </div>
          </template>
        </b-table>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AccountClientHeader from "../others/AccountClientHeader.vue";
import ApplicationsService from "../../../services/applications.service";
export default {
  components: { AccountClientHeader },
  props: {
    row: { type: Object, required: true },
  },
  data() {
    return {
      visible: false,
      items: [],
    };
  },
  methods: {
    async start() {
      let data = { idapp: this.row.app_id, iduser: this.currentUser.user_id };
      let result = await ApplicationsService.getTracking(data);
      this.items = result.data;
    },
    fileIcon(doc = "") {
      let d = doc ? doc : "";
      switch (d.toLowerCase()) {
        case "pdf":
          return "FileTextIcon";
        case "docx":
          return "FileTextIcon";
        case "xlsx":
          return "FileTextIcon";
        case "png":
          return "ImageIcon";
        case "jpg":
          return "ImageIcon";
        default:
          return "FileIcon";
      }
    },
    getBadgeColor(status) {
      const classes = {
        CREATED: "light-primary",
        ACCEPTED: "light-success",
        APPROVED: "light-success",
        FINISHED: "light-success",  
        "BANK INFORMATION DONE": "light-info",
        REJECTED: "light-danger",
        DELETED: "light-danger",
        DENIED: "light-danger",        
        EXPIRED: "light-danger",
        "PAID: YES": "light-success",
        "PAID: NO": "light-warning",
      };

      return classes[status] || "light-secondary";
    },
    close() {
      this.visible = false;
      this.$emit("close");
    },
  },
  computed: {
    client() {
      const name = this.row.name_client
        ? this.row.name_client
        : this.items[0].name_client;
      return name;
    },
    account() {
      const account = this.row.account
        ? this.row.account
        : this.items[0].account;
      return account;
    },

    ...mapState({
      fields: (state) => state.DebtSolutionApplications.fieldsTracking,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();

    this.visible = true;
  },
};
</script>
<style scoped>
table tbody tr td .w200 {
  width: 200px !important;
}
.w-600 {
  max-width: 600px;
}
</style>