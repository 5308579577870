<template>
  <b-card class="mb-1">
    <div class="mb-1 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-between">
        <feather-icon icon="CircleIcon" size="15" class="color-inquires" />
        <span class="title-table d-inline-block lh-0 mr-1"
          >Public Information</span
        >
        <b-badge
          class="cursor-pointer"
          variant="light-primary"
          :class="showPublicInfo ? null : 'collapsed'"
          @click="showPublicInfo = !showPublicInfo"
          aria-controls="collapse-inquires"
          :aria-expanded="showPublicInfo"
        >
          {{ showPublicInfo ? "Hide" : "Show" }}
        </b-badge>
      </div>
      <div v-if="isForSpecialist">
        <b-button
          variant="success text-black"
          class="mr-1"
          @click="addPublicRecord()"
        >
          ADD RECORD
        </b-button>
      </div>
    </div>
    <b-collapse v-model="showPublicInfo" id="collapse-inquires">
      <b-table-simple
        small
        caption-top
        responsive
        bordered
        class="position-relative custom-table-report"
        :sticky-header="'65vh'"
      >
        <b-thead>
          <b-tr>
            <b-th class="td-left text-right header-width"></b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">TransUnion</span>
              <b-img :src="require('@/assets/images/icons/transunion.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Experian</span>
              <b-img :src="require('@/assets/images/icons/experian.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Equifax</span>
              <b-img :src="require('@/assets/images/icons/equifax.png')" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(item, publicIndex) in public_info">
            <b-tr :key="publicIndex + 'pi'" class="bg-dark">
              <b-th class="td-left text-right col-2">
                <b-badge
                  class="cursor-pointer"
                  variant="danger"
                  v-if="isForSpecialist"
                  @click="removeRecord(publicIndex)"
                >
                  <feather-icon icon="TrashIcon" size="13" />
                  Remove Record
                </b-badge>
              </b-th>
              <template>
                <b-th
                  v-for="t in 3"
                  :key="t - 1"
                  class="font-weight-bold col-3 text-center"
                  :class="
                    item.element[t - 1].status == 1 ? 'bg-warning' : 'bg-dark'
                  "
                >
                  <template v-if="isForSpecialist">
                    <b-badge
                      class="cursor-pointer"
                      variant="info"
                      @click="
                        (item.element[t - 1].edit = !item.element[t - 1].edit),
                          editRecord(1, t - 1, publicIndex)
                      "
                      v-if="!item.element[t - 1].edit"
                    >
                      <feather-icon icon="EditIcon" size="13" />
                      Edit Record
                    </b-badge>
                    <div v-else>
                      <b-badge
                        class="cursor-pointer mr-1"
                        variant="danger"
                        @click="
                          (item.element[t - 1].edit =
                            !item.element[t - 1].edit),
                            editRecord(0, t - 1, publicIndex)
                        "
                      >
                        <feather-icon icon="XIcon" size="13" />
                        Cancel
                      </b-badge>
                      <b-badge
                        class="cursor-pointer"
                        variant="success"
                        @click="
                          item.element[t - 1].edit = !item.element[t - 1].edit
                        "
                      >
                        <feather-icon icon="SaveIcon" size="13" />
                        Save
                      </b-badge>
                    </div>
                  </template>
                  <template v-if="isForRound">
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox
                        v-model="item.element[t - 1].to_select"
                        v-if="
                          item.element[t - 1].status != 1 &&
                          item.element[t - 1].content != null &&
                          item.element[t - 1].court != null &&
                          (arrPubInf.includes(item.element[t - 1].id) ||
                            arrPubInf.length == 0)
                        "
                        style="margin: 5px 0"
                        class="font-weight-bold select-color"
                        @change="toSelected(item, [t - 1])"
                        >TO SELECT</b-form-checkbox
                      >
                      <span
                        v-else-if="item.element[t - 1].status == 1"
                        class="font-weight-bold"
                        >IN DISPUTE
                        <template>
                          <b-badge
                            :variant="
                              item.element[t - 1].type_send == 'CERTIFIED'
                                ? 'primary'
                                : 'info'
                            "
                          >
                            <template
                              v-if="
                                item.element[t - 1].type_send == 'CERTIFIED'
                              "
                            >
                              <feather-icon
                                icon="PhoneIcon"
                                size="20"
                                class="clickable"
                                v-b-tooltip.hover.top="'Direct'"
                              />
                              DIRECT
                            </template>

                            <template v-else>
                              <amg-icon
                                class="cursor-deafult"
                                icon="LetterIcon"
                                size="17"
                                v-b-tooltip.hover.top="'Letter'"
                              />

                              {{ getRoundNumber(item.element[t - 1].title_id) }}
                            </template>
                          </b-badge>
                        </template>
                      </span>
                    </div>
                  </template>
                </b-th>
              </template>
            </b-tr>

            <b-tr
              v-for="(header, indexHeader) in headerPublics"
              :key="indexHeader + 'r' + publicIndex"
            >
              <b-td
                class="td-left text-left"
                v-if="
                  header.key == 'round' &&
                  item.element.filter((item) => item.to_select).length > 0
                "
              >
              </b-td>

              <b-td class="td-left text-right" v-else>
                <span class="alignTitle">{{ header.label }}</span>
              </b-td>
              <template v-for="(value, indexItem) in item.element">
                <template v-for="(valueItem, key, indexValue) in value">
                  <b-td
                    :key="indexValue + indexItem"
                    v-if="header.key == key"
                    class="td-center text-center"
                    :class="
                      !(value.content && header.key != 'round')
                        ? ''
                        : skin == 'dark'
                        ? 'bg-danger'
                        : 'bg-light-red'
                    "
                  >
                    <b-input
                      v-if="value.edit"
                      :type="header.key == 'date' ? 'date' : 'text'"
                      v-model="value[key]"
                      :disabled="disableModule"
                    />
                    <span
                      :class="
                        !(value.content && header.key != 'round')
                          ? ''
                          : skin == 'dark'
                          ? 'text-white'
                          : ''
                      "
                      v-else
                      >{{ valueItem }}</span
                    >
                    <div v-if="header.key == 'round' && value.to_select">
                      <b-button
                        @click="openDisputeDetailsModal"
                        class="my-2"
                        variant="info"
                        v-if="!inRound(value.id)"
                        :disabled="shouldDisableDisputeButton(value.id)"
                      >
                        ADD NEW ROUND
                      </b-button>

                      <b-button class="my-2" variant="primary" v-else disabled>
                        IN ROUND
                      </b-button>
                    </div>
                  </b-td>
                </template>
              </template>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-collapse>
    <dispute-details-modal
      v-if="showDisputeDetailsModal"
      :selectedElements="selectedElements"
      @resetSelectedElem="resetSelectedElements"
      @close="closeDisputeDetailsModal()"
    />
  </b-card>
</template>

<script>
import DisputeDetailsModal from "@/views/commons/components/request-workplan/views/components/modal/DisputeDetailsModal.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    DisputeDetailsModal,
  },
  props: {
    public_info: {
      required: true,
    },
    process: {
      required: true,
    },
    day_processed: {
      required: true,
    },
    round_id: {
      required: true,
    },
    idSection: {
      required: false,
    },
  },
  data() {
    return {
      showDisputeDetailsModal: false,
      selectedElements: [],
      arrPubInf: [],
      showPublicInfo: true,
      selectedElementIndex: null,
      headerPublics: [
        {
          key: "content",
          label: "Type",
        },
        {
          key: "status_show",
          label: "Status",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "reference",
          label: "Reference",
        },
        {
          key: "closing",
          label: "Closing",
        },
        {
          key: "court",
          label: "Court",
        },
      ],
      setPublicRecord: {
        reason: [],
        instruction: [],
      },
      oldInfo: [],
    };
  },
  computed: {
    isForSpecialist() {
      return this.process != 1 && (this.moduleId == 28 || this.moduleId == 25);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    disableModule() {
      return !(this.moduleId == 28 || this.moduleId == 25);
    },
    isForRound() {
      return (
        this.process == 1 &&
        this.moduleId == 28 &&
        this.day_processed > "2021-09-27"
      );
    },
    idBureau() {
      return this.$route.params.idBureau;
    },
    ...mapGetters({
      skin: "appConfig/skin",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
    }),
  },
  methods: {
    ...mapActions({
      A_REMOVE_ACCOUNT: "RequestWorkPlanStore/A_REMOVE_ACCOUNT",
    }),

    getRoundNumber(action) {
      switch (action) {
        case 1:
          return "FIRST ROUND";
        case 2:
          return "SECOND ROUND";
        case 3:
          return "THIRD ROUND";
        case 4:
          return "FOURTH ROUND";
        default:
          return "FIFTH ROUND";
      }
    },
    shouldDisableDisputeButton(id) {
      if (this.selectedElementIndex == null) {
        this.selectedElementIndex = id;
      }

      return id !== this.selectedElementIndex;
    },

    resetSelectedElements() {
      this.selectedElementIndex = null;
      this.selectedElements = [];
      this.arrPubInf = [];
    },

    removeRecord(index) {
      this.public_info.splice(index, 1);
      this.oldInfo = JSON.parse(JSON.stringify(this.public_info));
    },
    editRecord(action, index, publicIndex) {
      if (action == 0) {
        this.public_info[publicIndex].element[index] =
          this.oldInfo[publicIndex].element[index];
        this.public_info[publicIndex].element[index].edit = false;
        let band = true;
        this.public_info[publicIndex].element.map((item) => {
          if (item.edit) {
            band = false;
          }
        });
        if (band) {
          this.oldInfo = JSON.parse(JSON.stringify(this.public_info));
        }
      } else {
        this.oldInfo = JSON.parse(JSON.stringify(this.public_info));
      }
    },

    inRound(item_id) {
      let bool = false;
      this.G_DISPUTE_DATA.forEach((el) => {
        if (el.id == item_id) {
          bool = true;
        }
      });
      return bool;
    },

    toSelected(item, index) {
      if (item.element[index].to_select) {
        item.element[index].round_id = this.round_id;
        item.element[index].type_element = 3;
        this.selectedElements.push(item.element[index]);
        if (this.arrPubInf.length == 0) {
          item.element.forEach((el) => {
            this.arrPubInf.push(el.id);
          });
        }
      }

      if (!item.element[index].to_select) {
        this.A_REMOVE_ACCOUNT(item.element[index].id);
        this.selectedElementIndex = null;
        this.selectedElements = this.selectedElements.filter(
          (element) => element.id != item.element[index].id
        );
        if (this.selectedElements.length == 0) this.arrPubInf = [];
      }

      //data
      let count = 0;
      item.element.map((items) => {
        if (items.to_select == 1) {
          count = count + 1;
        }
      });
      if (count > 0) {
        this.btnRound = true;
      }
      this.$emit("setPublicRecord", this.setPublicRecord);
    },
    reloadHeaders() {
      if (this.isForRound) {
        this.headerPublics.push({
          key: "round",
          label: "Round",
        });
      }
    },

    addPublicRecord() {
      let elements = [];
      for (let i = 0; i < 3; i++) {
        elements.push({
          content: null,
          status: 6,
          bureau_id: i + 1,
          court: null,
          date: null,
          reference: null,
          closing: null,
          status_show: null,
          round: null,
          edit: false,
        });
      }
      this.public_info.push({
        element: elements,
        date: null,
      });
      this.oldInfo = JSON.parse(JSON.stringify(this.public_info));
    },

    removeAllRecord() {
      this.public_info = [];
    },
    closeDisputeDetailsModal() {
      this.showDisputeDetailsModal = false;
    },
    openDisputeDetailsModal() {
      this.showDisputeDetailsModal = true;
    },
  },
  created() {
    this.reloadHeaders();
  },
};
</script>

<style lang="scss" scoped>
.title-table {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.color-inquires {
  color: #0059ff;
  fill: #0059ff;
  margin-right: 10px;
}
.custom-table-report::v-deep thead tr th {
  background-color: #3a72ea !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-table-report::v-deep thead tr th:first-child {
  border-radius: 15px 0 0 0 !important;
}

.custom-table-report::v-deep thead tr th:last-child {
  border-radius: 0 15px 0 0 !important;
}
</style>

<style lang="scss">
.select-color {
  .custom-control-label {
    color: white !important;
  }
}
</style>
