<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="TRACKING"
    hide-footer
    modal-class="custom-modal-amg"
    header-bg-variant="transparent"
    size="md"
    header-class="p-0"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Tracking
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div>
      <div class="mb-2">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <!-- <span class="title-tag">CLIENT NAME</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Client | {{ dataTracking.clientName }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="pt-md-0 pt-1">
            <!-- <span class="title-tag">CLIENT ACCOUNT</span> -->
            <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
              <p
                class="m-0"
                style="gap: 5px"
              >
                Account | {{ dataTracking.account }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <tracking-modal-ro
      :is-modal="true"
      :data="dataTracking"
    />
  </b-modal>
</template>

<script>
import TrackingModalRo from '@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/tracking-modal-ro/TrackingModalRo.vue'

export default {
  components: {
    TrackingModalRo,
  },
  props: {
    dataTracking: null,
  },
  data() {
    return {
      ownControl: false,
    }
  },
  async created() {
    this.ownControl = true
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
</style>
