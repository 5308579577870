<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="CLIENT RISK CLASSIFICATION"
    size="md"
    @hidden="closeModal"
  >
    <b-row class="mb-2 fw-bold custom-input">
      <b-col lg="6" class="mt-1">
        <b-input-group>
          <b-input-group-prepend class="w-45">
            <b-input-group-text class="w-100 badge-name-group">
              <feather-icon icon="LayersIcon" class="mr-1" />
              <span>MODULE</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            class="text-truncate"
            v-if="finishedLoading"
            :value="dataClient.module_name"
            readonly
            v-b-tooltip.hover.top="dataClient.module_name"
          />
        </b-input-group>
      </b-col>
      <b-col lg="6" class="mt-1">
        <b-input-group>
          <b-input-group-prepend class="w-45">
            <b-input-group-text class="w-100 badge-name-group">
              <feather-icon icon="UserIcon" class="mr-1" />
              <span>CLIENT</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            class="text-truncate"
            v-if="finishedLoading"
            :value="dataClient.client_name"
            readonly
            v-b-tooltip.hover.top="dataClient.client_name"
          />
        </b-input-group>
      </b-col>
      <b-col lg="6" class="mt-1">
        <b-input-group>
          <b-input-group-prepend class="w-45">
            <b-input-group-text class="w-100 badge-name-group">
              <tabler-icon icon="UserExclamationIcon" class="mr-1" />
              <span class="text-truncate" v-b-tooltip.hover.top="'CLIENT TYPE'"
                >CLIENT TYPE</span
              >
            </b-input-group-text>
          </b-input-group-prepend>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            v-if="finishedLoading"
            :value="dataClient.type_client == 1 ? 'NEW CLIENT' : 'REGULAR'"
            :disabled="true"
          />
        </b-input-group>
      </b-col>
      <b-col lg="6" class="mt-1">
        <b-input-group>
          <b-input-group-prepend class="w-45">
            <b-input-group-text class="w-100 badge-name-group">
              <feather-icon icon="UserPlusIcon" class="mr-1" />
              <span>SPECIALIST</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            v-if="finishedLoading"
            :value="dataClient.specialist"
            :disabled="true"
          />
        </b-input-group>
      </b-col>
      <b-col lg="6" class="mt-1">
        <b-input-group>
          <b-input-group-prepend class="w-45">
            <b-input-group-text class="w-100 badge-name-group">
              <feather-icon icon="PackageIcon" class="mr-1" />
              <span>ACCOUNT</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            v-if="finishedLoading"
            :value="dataClient.client_account"
            :disabled="true"
          />
        </b-input-group>
      </b-col>
      <b-col lg="6" class="mt-1">
        <b-input-group>
          <b-input-group-prepend class="w-45">
            <b-input-group-text class="w-100 badge-name-group">
              <feather-icon icon="UserCheckIcon" class="mr-1" />
              <span
                class="text-truncate"
                v-b-tooltip.hover.top="'AGENT/ADVISOR'"
                >AGENT/ADVISOR</span
              >
            </b-input-group-text>
          </b-input-group-prepend>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            class="text-white"
            v-if="finishedLoading"
            :value="dataClient.agent"
            :disabled="true"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <hr />
    <span class="d-inline-block font-weight-bolder text-primary mb-1">
      RISK CLASSIFICATION
    </span>
    <validation-observer ref="form">
      <b-row style="gap: 10px">
        <b-col md="12">
          <label>Type</label>
          <validation-provider v-slot="{ errors }" name="type" rules="required">
            <b-form-radio-group
              v-model="typeData"
              :options="optionsType"
              required
              @change="levelData = null"
              class="w-100"
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              button-variant="outline-secondary"
              name="radio-btn-default"
              buttons
            >
            </b-form-radio-group>
            <label v-if="errors[0]" class="text-danger">
              Type {{ errors[0] }}
            </label>
          </validation-provider>
        </b-col>

        <b-col v-if="typeData == 2" cols="12" class="w-100">
          <label> Level</label>
          <validation-provider
            v-slot="{ errors }"
            name="level"
            rules="required"
          >
            <b-form-select
              :class="errors[0] == 'is required' && 'border-danger rounded'"
              id="select"
              v-model="levelData"
              :options="optionsLevel"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Select an option</b-form-select-option
                >
              </template>
            </b-form-select>
            <label v-if="errors[0]" class="text-danger">
              Level {{ errors[0] }}
            </label>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <label> Comment</label>
          <validation-provider
            v-slot="{ errors }"
            name="comment"
            rules="required"
          >
            <b-form-textarea
              id="textarea"
              :class="errors[0] == 'is required' && 'border-danger'"
              v-model="commentsData"
              required
              placeholder="Enter something..."
            />
            <label v-if="errors[0]" class="text-danger">
              Comment {{ errors[0] }}
            </label>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>

    <template #modal-footer>
      <b-button variant="primary" @click="insertFlyerComments"> SAVE </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import RiskyService from "@/views/management/views/risky-clients/risky.service";

export default {
  props: {
    edit: null,
    index: null,
    client_id: null,
    comments: null,
    commentsEdit: null,
    type: null,
    level: null,
    dataClient: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      commentsData: this.comments,
      typeData: this.type,
      levelData: this.level,
      finishedLoading: false,
      optionsLevel: [
        {
          value: 1,
          text: "Low",
        },
        {
          value: 2,
          text: "Medium",
        },
        {
          value: 3,
          text: "High",
        },
      ],
      optionsType: [
        {
          value: 2,
          text: "RISK",
        },
        {
          value: 1,
          text: "NO RISK",
        },
      ],
      ownControl: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.ownControl = true;
    this.finishedLoading = true;
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
    // eslint-disable-next-line consistent-return
    async insertFlyerComments() {
      try {
        this.addPreloader();
        const result = await this.$refs.form.validate();
        if (result) {
          if (this.type == this.typeData && this.level == this.levelData) {
            this.$swal({
              title: "No Changes Detected",
              text: "To edit you need to change type, level or both",
              icon: "warning",
            });
            return;
          }

          const params = {
            comments: this.commentsData,
            type: this.typeData,
            client_id: this.client_id,
            user_id: this.currentUser.user_id,
            level: this.levelData,
          };
          const data = await RiskyService.addRiskyClient(params);
          if (data.status === 200) {
            this.$emit("refresh");
            this.showSuccessSwal();
            this.closeModal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
.custom-input .form-control {
  opacity: 1 !important;
}
</style>
