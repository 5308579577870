export default [
  {
    key: "cr",
    label: "cr",
    class: "text-left",
    visible: true,
  },
  {
    key: "specialist",
    label: "Specialist assigned",
    class: "text-center",
    visible: true,
  },
  {
    key: "risky",
    label: "Risk",
    class: "text-center",
    visible: true,
  },
  {
    key: "rounds",
    label: "Rounds",
    class: "text-center",
    visible: true,
  },
  {
    key: "client_type",
    label: "Client type",
    class: "text-center",
    visible: true,
  },
  // {
  //   key: "steps",
  //   label: "steps",
  //   class: "text-center",
  //   visible: true,
  // },

  // {
  //   key: "tracking",
  //   label: "Tracking",
  //   class: "text-center w-600",
  //   visible: true,
  //   tdClass: "p-0 w-25",
  //   thStyle: { width: "20px !important" },
  // },
  // {
  //   key: "detail",
  //   label: "detail",
  //   // class: "text-center",
  //   visible: true,
  // },
  {
    key: "completed_by",
    label: "completed by",
    visible: true,
    class: "text-center ",
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];
