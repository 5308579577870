<template>
  <div class="pl-1">
    <span class="sub-title-account" :class="{ dark: isDarkSkin }">
      {{ nameTitle }}
    </span>
    <b-table :items="value" :fields="fields">
      <template #cell(name)="{ item }">
        <div class="d-flex align-items-center gap-5">
          <span v-b-tooltip.hover.left="item.name">
            {{
              item.name.length > 32
                ? item.name.substring(0, 32) + "..."
                : item.name
            }}
          </span>
          <feather-icon
            v-if="type == 'accounts'"
            icon="HelpCircleIcon"
            class="text-primary cursor-pointer"
            v-b-tooltip.hover.top="'Detail of the arguments of the account'"
            size="16"
            @click="openDetail(item)"
          />
        </div>
      </template>
      <template #cell(type_classification)="{ item }">
        <b-badge
          :variant="getVariant(item.type_classification)"
          style="width: 100px;"
        >
          {{ item.type_classification != null ? item.type_classification : "NOT QUALIFIED" }}
        </b-badge>
      </template>
      <template #cell(classification)="{ item }">
        <span>{{ item.classification !== null ? item.classification : "NOT QUALIFIED" }}</span>
      </template>
      <template #cell(bureau_id)="{ item }">
        <b-img
          class="mr-1 ml-1"
          v-b-tooltip.hover.top="
            item.bureau_id == 1
              ? 'Transunion'
              : item.bureau_id == 2
              ? 'Experian'
              : 'Equifax'
          "
          :src="
            item.bureau_id == 1
              ? '/assets/images/icons/transunion.png'
              : item.bureau_id == 2
              ? '/assets/images/icons/experian.png'
              : '/assets/images/icons/equifax.png'
          "
        />
      </template>
    </b-table>

    <b-modal
      v-model="showDetail"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      size="timeline"
      hide-footer
      centered
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="showDetail = false"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            {{
              detailModal.title.length > 32
                ? detailModal.title.substring(0, 32) + "..."
                : detailModal.title
            }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="showDetail = false"
            />
          </div>
        </div>
      </template>
      <div class="d-flex flex-column">
        <span class="sub-title-account"> INSTRUCTION </span>
        <span class="text-justify ml-1">
          {{ detailModal.instruction }}
        </span>
        <span class="sub-title-account mt-1"> REASON </span>
        <span class="text-justify ml-1">
          {{ detailModal.reason }}
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import accountFields from "@/views/commons/components/clients/dashboard/options/dis-module/round/fields/accountFields";
import recordFields from "@/views/commons/components/clients/dashboard/options/dis-module/round/fields/recordFields";
import personalFields from "@/views/commons/components/clients/dashboard/options/dis-module/round/fields/personalFields";
import inquireFields from "@/views/commons/components/clients/dashboard/options/dis-module/round/fields/inquireFields";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    round: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "accounts",
    },
  },
  data() {
    return {
      showDetail: false,
      detailModal: {},
    };
  },
  computed: {
    nameTitle() {
      const names = {
        accounts: "ACCOUNTS",
        inquiries: "INQUIRIES",
        public_records: "PUBLIC RECORDS",
        personal_information: "PERSONAL INFORMATION",
      };
      return names[this.type];
    },
    fields() {
      if (this.type === "accounts") {
        return accountFields;
      }
      if (this.type === "inquiries") {
        return inquireFields;
      }
      if (this.type === "public_records") {
        return recordFields;
      }
      if (this.type === "personal_information") {
        return personalFields;
      }
    },
  },
  methods: {
    getVariant(probability) {
      const probs = {
        "LOW": "danger",
        "MEDIUM": "warning",
        "HIGH": "success"
      }

      return probs[probability]
    },
    colorType(type) {
      if ([3, 4, 5].includes(type)) {
        return "light-warning";
      } else {
        return "light-primary";
      }
    },
    openDetail(item) {
      this.detailModal = {
        title: item.name,
        instruction: item.instruction_text,
        reason: item.reason_text,
      };
      this.showDetail = true;
    },
  },
};
</script>

<style scoped lang="css">
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.sub-title-account {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 5px 0;
}
.sub-title-account.dark {
  color: #fff;
}
</style>
