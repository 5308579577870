import { render, staticRenderFns } from "./ViewDisputeComponentNew.vue?vue&type=template&id=a98bc504&scoped=true"
import script from "./ViewDisputeComponentNew.vue?vue&type=script&lang=js"
export * from "./ViewDisputeComponentNew.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a98bc504",
  null
  
)

export default component.exports