<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center"
      style="height: 200px"
    >
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <div v-else class="d-flex justify-content-between">
      <div
        v-for="(item, index) in summary"
        :key="index"
        class="card-item"
        :class="{ dark: isDarkSkin }"
        :style="`border: 1px solid ${item.color}`"
      >
        <span class="title-card" :style="`color:${item.color}`"
          >{{ item.title }}
          <span class="font-size-15" v-if="index > 0">
            ({{ item.average.toFixed(2) }} %)
          </span>
        </span>

        <hr class="m-0 mb-1" :style="`border: 1px solid ${item.color}`"/>
        <div
          class="d-flex justify-content-between align-items-center px-1"
          :class="index != item.length - 1 ? 'mb-1' : ''"
          v-for="({ title, value }, index) in item.data"
          :key="index"
        >
          <span class="font-weight-bolder">{{ title }}</span>
          <span :class="colorTitle(index)">{{ value > 0 ? value : "-" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
export default {
  data() {
    return {
      summary: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  methods: {
    async getSummary() {
      this.loading = true;
      try {
        const { data } = await ClientsOptionsServices.getSummary({
          accountId: this.$route.params.idClient,
        });
        this.summary = data || [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    colorTitle(index) {
      const colors = {
        0: "text-danger",
        1: "text-warning",
        2: "text-success",
        3: "text-success",
      };
      return `${colors[index]} font-weight-bolder font-size-15`;
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  width: 25%;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  &.dark {
    background-color: #1e1e1e;
    color: #fff;
  }
}
.title-card {
  font-size: 1.2rem;
  font-weight: bold;
}
.font-size-15 {
  font-size: 15px;
}
</style>