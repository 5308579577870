export default [
  {
    key: "court_sase",
    label: "Court Name",
    thStyle: "width: 40%",
  },
  {
    key: "name",
    label: "Court Type",
    thStyle: "width: 40%",
  },
  {
    key: "bureau_id",
    label: "Bureau",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 20%",
  },
];
