<template>
  <span>
    <span>{{ item.account_name }}</span
    ><br />
    <span>{{ item.account_number }}</span
    ><br />
    <span>$ {{ item.balance | currencyZero }}</span
    ><br />
    <span>{{ item.account_type }}</span
    ><br />
    <template v-if="!isInsideModal">
      <span>{{ item.p_status == null ? "-" : item.p_status }}</span
      ><br />
      <span>$ {{ item.c_limit }}</span
      ><br />
      <span>$ {{ item.m_payment | currencyZero }}</span
      ><br />
    </template>
  </span>
</template>

<script>
export default {
  props: {
    item: null,
    isInsideModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
