<template>
  <div>
    <div class="d-flex justify-content-end mb-1">
      <b-dropdown
        v-if="[4, 5, 6, 7, 11, 20, 22, 25, 18, 28, 29].includes(moduleId)"
        variant="outline-custom-amg"
        class="ml-custom"
        :disabled="client.total_wp == 0"
      >
        <template #button-content>
          <tabler-icon icon="FileTextIcon" class="mr-half" />
          OLD WORKPLANS
          <b-badge variant="custom-amg" pill class="ml-1">{{
            client.total_wp
          }}</b-badge>
        </template>
        <b-dropdown-item
          v-for="(item, index) in oldWorkplans"
          :key="index"
          @click="openOldWorkplanDetail(item.id)"
        >
          <span>{{ item.created_at | myGlobalDay }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-table
      ref="workplansTable"
      slot="table"
      class="blue-scrollbar table-new-customization"
      :items="myProvider"
      :fields="visibleFields"
      :busy.sync="isBusy"
      primary-key="ncr_id"
      responsive="md"
      sticky-header="50vh"
      show-empty
      no-provider-filtering
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(cr)="{ item }">
        <div class="d-flex flex-column" style="gap: 10px">
          <div>
            <b-badge
              v-if="item.type_ncr === 'AUTOMATIC'"
              class="item-automatic"
              >{{ item.type_ncr }}</b-badge
            >

            <b-badge v-if="item.type_ncr === 'MANUAL'" class="item-manual">{{
              item.type_ncr
            }}</b-badge>
            <amg-icon
              v-if="item.route_pdf != null"
              v-b-tooltip.hover.top="'View PDF'"
              @click="viewPdf(item)"
              class="cursor-pointer mt-0 mr-0 ml-1 bigger text-danger"
              icon="FilePdfIcon"
              style="height: 18px; width: 18px"
            />
          </div>

          <div class="d-flex">
            <span>{{ item.created_at | myGlobalDay }}</span>
          </div>
        </div>
      </template>

      <template #cell(specialist)="{ item }">
        <div v-if="isAnalystDepartment">
          <feather-icon
            v-if="!item.specialist"
            @click="openModalAssign(item)"
            size="16"
            icon="UserPlusIcon"
            class="mr-50 text-success cursor-pointer"
          />
          <span v-if="item.specialist && !isCreditAnalyst" class="d-block">{{
            item.specialist
          }}</span>
        </div>
        <div v-else>
          <span v-if="item.specialist">{{ item.specialist }}</span>
          <span v-else>-</span>
        </div>
      </template>
      <template #cell(risky)="{ item }">
        <div v-if="item.type_client == 1">
          <div v-if="item.typerisk == null">
            <feather-icon
              class="text-warning cursor pointer oscillate"
              v-b-tooltip.hover.left="
                'Classify the risk level of this new client'
              "
              @click="
                openRiskClassificationModal(
                  item.client_account_id,
                  null,
                  null,
                  item
                )
              "
              icon="AlertTriangleIcon"
              size="20"
            />
          </div>

          <div v-else class="d-flex justify-content-center ml-2">
            <div
              @click="openRiskClassificationTrackingModal(item)"
              v-b-tooltip.hover.top="'View Tracking'"
              class="cursor-pointer"
            >
              <b-badge
                style="width: 100px"
                :variant="getRiskyBadgeVariant(item.level)"
                >{{ getRiskyLevelName(item.level) }}</b-badge
              >
            </div>

            <div class="d-flex align-items-center">
              <feather-icon
                icon="Edit2Icon"
                class="cursor-pointer text-warning"
                v-b-tooltip.hover.right="'Edit Classification'"
                style="margin-left: 5px"
                size="13"
                @click="
                  openRiskClassificationModal(
                    item.client_account_id,
                    item.typerisk,
                    item.level,
                    item
                  )
                "
              />
            </div>
          </div>
        </div>
        <div v-else>-</div>
      </template>

      <template #cell(rounds)="{ item }">
        <b-badge
          variant="primary"
          class="pointer"
          @click="openModalRounds(item)"
        >
          {{ item.total_rounds_ncr ? item.total_rounds_ncr : 0 }}
        </b-badge>
      </template>

      <template #cell(client_type)="{ item }">
        <b-badge
          style="width: 90px"
          :class="
            item.type_client == 1
              ? 'badge-purple'
              : item.type_client == 2
              ? 'badge-navy'
              : 'badge-red'
          "
        >
          <span>{{ getBadgeText(item.type_client) }}</span>
        </b-badge>
      </template>

      <template #cell(steps)="{ item }">
        <feather-icon
          icon="SettingsIcon"
          size="22"
          class="cursor-pointer"
          @click="openModalSteps(item)"
        />
      </template>

      <template #cell(completed_by)="{ item }">
        <!-- <pre>{{item.tracking_data}}</pre> -->
        <span class="d-block">{{
          completedBy(JSON.parse(item.tracking_data))
        }}</span>
        <span class="d-block">{{
          dateOfCompletion(JSON.parse(item.tracking_data)) | myGlobalDay
        }}</span>
      </template>
      <template #cell(actions)="{ item }">
        <div
          class="d-flex justify-content-center align-items-center"
          style="gap: 15px"
        >
          <feather-icon
            class="cursor-pointer"
            :class="
              item.view == 'PENDING' ? 'text-warning oscillate' : 'text-primary'
            "
            v-b-tooltip.hover.right="'Completed Workplan'"
            @click="openActionModal(item)"
            size="20"
            icon="EyeIcon"
            v-if="
              item.status == 'COMPLETED' ||
              item.status == 'WAITING FOR APPROVAL'
            "
          />
          <feather-icon
            class="text-danger cursor-pointer"
            v-b-tooltip.hover.right="'Remove Workplan'"
            @click="deleteWorkplan(item)"
            size="20"
            icon="TrashIcon"
            v-if="
              item.status == 'PENDING' || item.status == 'WAITING FOR APPROVAL'
            "
          />
          <div
            v-if="isAnalystDepartment"
            class="d-flex flex-column align-items-center"
            @click="openModalSteps(item)"
          >
            <amg-icon
              size="25"
              :icon="iconsSteps[item.current_step - 1]"
              class="text-primary cursor-pointer"
            />
          </div>
          <feather-icon
            class="text-info cursor-pointer"
            v-b-tooltip.hover.right="'Tracking'"
            @click="openWpModalTracking(item)"
            size="20"
            icon="ListIcon"
          />
        </div>
      </template>
    </b-table>

    <modal-add-work-plan
      v-if="workplanController"
      :id-work-plan="oldWorkplanId"
      :client-name="client.client_name"
      :account="client.account"
      @reload="refreshOldWorkplans"
      @close="workplanController = false"
    />

    <risky-classification-modal
      v-if="showRiskClassificationModal"
      :type="typeRisk"
      :client_id="client_account_id"
      :level="levelRiskyClient"
      :comments="commentRiskyClient"
      :dataClient="dataModal"
      @refresh="refresh"
      @close="showRiskClassificationModal = false"
    />

    <risk-classification-tracking-modal
      v-if="showRiskClassificationTrackingModal"
      :data="infoclient"
      @close="showRiskClassificationTrackingModal = false"
    />

    <add-round-modal
      v-if="showModalRounds"
      :dataRound="dataClient"
      :validateModal="0"
      :fromWpTable="true"
      @close="showModalRounds = false"
    />

    <ActionDoneReturnModal
      v-if="showModalAction"
      :dataClient="dataWp"
      :revision="revision"
      :section="'WORKPLAN'"
      @close="showModalAction = false"
      @reload="refresh"
    />
    <AssignSpecificModal
      v-if="showModalAssign"
      :item="selectedItem"
      :section="section"
      @close="closeModalAssign"
      @refresh="refresh"
    />
    <StepsCreditReport
      v-if="showModalSteps"
      @close="showModalSteps = false"
      :dataWorkPlan="dataWorkPlan"
    />
    <request-workplan-tracking
      v-if="showWpModalTracking"
      :dataWp="dataWp"
      @close="showWpModalTracking = false"
    />
  </div>
</template>
<script>
// DATA
import Fields from "@/views/commons/components/clients/dashboard/options/dis-module/data/fileds";

// MODAL
import RiskyClassificationModal from "@/views/specialist-digital/views/clients/components/ModalInsertRisky.vue";
import RiskClassificationTrackingModal from "@/views/management/views/risky-clients/modals/ModalInsertCommentsRisky.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import AssignSpecificModal from "@/views/commons/components/request-workplan/views/components/modal/AssignSpecificModal.vue";
import StepsCreditReport from "@/views/commons/components/request-workplan/views/components/modal/StepsCreditReport.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";

// COMPONENTS
import StepProgress from "@/views/commons/steps/StepProgress.vue";
import TimeLime from "@/views/commons/components/clients/dashboard/options/dis-module/components/TimeLine.vue";
import RequestWorkplanTracking from "@/views/commons/components/request-workplan/views/components/modal/RequestWorkplanTracking.vue";
// SERVICES
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

// STORE
import { mapGetters, mapActions } from "vuex";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    RiskyClassificationModal,
    RiskClassificationTrackingModal,
    AddRoundModal,
    ActionDoneReturnModal,
    AssignSpecificModal,
    StatusAccount,
    StepsCreditReport,
    StepProgress,
    TimeLime,
    RequestWorkplanTracking,
    ModalAddWorkPlan,
  },
  data() {
    return {
      oldWorkplans: [],
      oldWorkplanId: null,
      workplanController: false,
      Fields,
      isBusy: false,
      showModalRecommend: false,
      showRiskClassificationModal: false,
      showRiskClassificationTrackingModal: false,
      showWpModalTracking: false,
      infoclient: "",
      typeRisk: "",
      client_account_id: "",
      levelRiskyClient: null,
      commentRiskyClient: null,
      dataWp: null,
      dataClient: null,
      dataModal: null,
      showModalRounds: false,
      showModalAction: false,
      showModalAssign: false,
      isLoading: false,
      revision: false,
      selectedItem: null,
      section: null,
      showModalSteps: false,
      dataWorkPlan: null,
      iconsSteps: [
        "UpdatedIcon",
        "SyncIcon",
        "DisputeIcon",
        "RecommendIcon",
        "CheckIcon",
      ],
      steps: [
        "UPDATE ITEMS",
        "SYNCHRONIZE",
        "DISPUTE",
        "RECOMMEND",
        "TO REVIEW",
      ],
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
      getRefreshTable: "RequestWorkPlanStore/getRefreshTable",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    isAnalystDepartment() {
      return this.moduleId == 28;
    },
    metaIndex() {
      return this.moduleId == 5 ? 2 : 1;
    },
    isCompletedTab() {
      const { routeCompleted } = this.$route.matched[this.metaIndex].meta;
      return routeCompleted == this.$route.name;
    },
    isToRevisionTab() {
      const { routeToRevision } = this.$route.matched[this.metaIndex].meta;
      return routeToRevision == this.$route.name;
    },
    isDeleteTab() {
      const { routeDeleted } = this.$route.matched[this.metaIndex].meta;
      return routeDeleted == this.$route.name;
    },
    visibleFields() {
      return this.Fields.filter((field) => field.visible);
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    getRiskType() {
      return this.FiltersForSlot.filters[4].model == 1 ? 1 : 2;
    },
    isCreditAnalyst() {
      return this.currentUser.role_id == 22;
    },
    hasPermission() {
      return this.isCeo || this.isChief || this.isSupervisor;
    },
  },
  async created() {
    await this.start();
    await this.getAllWorks();
  },
  methods: {
    async refreshOldWorkplans() {
      await this.getAllWorks();
      this.client.total_wp =
        this.oldWorkplans == null ? 0 : this.oldWorkplans.length;
    },
    async getAllWorks() {
      try {
        const data = await ClientsOptionsServices.getAllWorkplansOld({
          id: this.$route.params.idClient,
        });

        if (data.status === 200) {
          this.oldWorkplans = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async openOldWorkplanDetail(id) {
      this.oldWorkplanId = id;
      this.workplanController = true;
    },
    async saveView(item) {
      try {
        const swal = await this.showConfirmSwal();
        if (swal.isConfirmed) {
          const params = {
            id: item.id,
            status_view: 2,
            save_by: this.currentUser.user_id,
          };
          const res = await RequestWorkPlanService.saveView(params);
          this.showSuccessSwal();
          item.view = "COMPLETED";
          this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
          return res;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestWorkPlanStore/CHANGE_REFRESH_TABLE",
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
    }),
    async start() {
      // FIELDS
      this.filterColumn("risky", this.isAnalystDepartment);
      // FILTERS
      if (this.isCompletedTab) {
        this.Fields.find((field) => field.key == "actions").label =
          "Show Details";
      } else {
        this.Fields.find((field) => field.key == "actions").label = "Actions";
      }
    },
    async deleteWorkplan(data) {
      try {
        const swal = await this.showConfirmSwal();
        if (swal.isConfirmed) {
          const params = { id: data.id, user_id: this.currentUser.user_id };
          await RequestWorkPlanService.deleteWorkplan(params);
          this.refresh();
          this.showSuccessSwal("Request Workplan deleted");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getFilterByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `${key}`
      );
      return filter;
    },

    async myProvider() {
      this.addPreloader();
      try {
        const params = {
          client_account_id: this.$route.params.idClient,
        };
        const data = await RequestWorkPlanService.searchWorkPlanClient(params);
        return data || [];
      } catch (error) {
        return [];
      } finally {
        this.removePreloader();
      }
    },

    filterColumn(key, bool) {
      let field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    refresh() {
      this.$refs.workplansTable.refresh();
      this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
    },


    async assignWorkplan() {
      this.isLoading = true;
      const params = {
        section: "REQUEST_WORK_PLAN",
      };
      const data = await RequestWorkPlanService.assignWorkplan(params);
      if (data.status == 200) {
        this.isLoading = false;
        this.$refs.workplansTable.refresh();
      }
    },

    openModalAssign(item) {
      this.selectedItem = item;
      this.section = "REQUEST_WORK_PLAN";
      this.showModalAssign = true;
    },

    closeModalAssign() {
      this.showModalAssign = false;
    },

    openRiskClassificationModal(data, type, level, dataClient) {
      this.levelRiskyClient = level;
      this.showRiskClassificationModal = true;
      this.client_account_id = data;
      this.typeRisk = type;
      this.commentRiskyClient = dataClient.comment_risky;
      this.dataModal = dataClient;
    },

    openRiskClassificationTrackingModal(data) {
      this.showRiskClassificationTrackingModal = true;

      let param = {
        id_account: data.client_account_id,
      };
      this.infoclient = param;
    },

    openModalRounds(item) {
      this.dataClient = {
        id: item.ncr_id,
        account: item.client_account,
        idAccount: item.client_account_id,
        clientName: item.client_name,
        idWorkplan: item.id,
        clientData: {
          dob: item.dob,
          address: item.address,
          ssn: item.ssn,
          origin_country: item.origin_country,
        },
      };
      this.showModalRounds = true;
    },

    getBadgeText(type_client) {
      const types = {
        1: "NEW CLIENT",
        2: "REGULAR",
        3: "RETURNED",
      };
      return types[type_client] || "N/A";
    },

    getRiskyBadgeVariant(level) {
      const variants = {
        1: "info",
        2: "warning",
        3: "danger",
      };
      return variants[level] || "success";
    },

    getRiskyLevelName(level) {
      const names = {
        1: "RISK - LOW",
        2: "RISK - MEDIUM",
        3: "RISK - HIGH",
      };
      return names[level] || "NO RISK";
    },
    async saveView(item) {
      try {
        const params = {
          id: item.id,
          status_view: 2,
          save_by: this.currentUser.user_id,
        };
        const res = await RequestWorkPlanService.saveView(params);
        item.view = "COMPLETED";
        this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
        this.A_COUNT_PENDING_WP_CLIENT({
          client_account_id: item.client_account_id,
        });
        return res;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async openActionModal(item) {
      try {
        this.addPreloader();
        const { data } = await RequestWorkPlanService.getCompletedWpInfo({
          id: item.id,
        });

        this.showModalAction = true;
        this.dataWp = item;
        this.dataWp.rounds = data.rounds;
        this.dataWp.creditUtilized = data.creditUtilized;
        this.dataWp.deletedAccounts = data.deletedAccounts;
        this.dataWp.newAccounts = data.newAccounts;
        this.revision = this.isToRevisionTab;

        if (item.view == "PENDING") {
          await this.saveView(item);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    async viewPdf(item) {
      try {
        this.addPreloader();
        let params = {
          file_pdf: item.route_pdf,
        };
        const { data } = await RequestWorkPlanService.addUrlTemporaryPdf(
          params
        );
        window.open(data, "_blank");
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
    openModalSteps(item) {
      this.showModalSteps = true;
      this.dataWorkPlan = item;
    },
    openWpModalTracking(item) {
      this.showWpModalTracking = true;
      this.dataWp = item;
    },
    displayedServices(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services;
      }
    },
    completedBy(data) {
      const completedItem = data.find((item) => item.status === "COMPLETED");
      return completedItem ? completedItem.created_by : null;
    },
    dateOfCompletion(data) {
      const completedItem = data.find((item) => item.status === "COMPLETED");
      return completedItem ? completedItem.created_at : null;
    },
  },

  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["workplansTable"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>
<style scoped>
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.badge-purple {
  background-color: #800080 !important;
  border-color: #800080 !important;
}

.badge-navy {
  background-color: #000080 !important;
  border-color: #000080 !important;
}

.badge-red {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
span.item-automatic {
  background-color: #89375f !important;
  border-color: #89375f !important;
}
span.item-manual {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.table-new-customization >>> .w-600 {
  width: 600px !important;
  max-width: 600px !important;
}
.title {
  font-size: 15px;
  font-weight: bold;
  color: #ff9c3f;
}
</style>
