<template>
  <div>
    <div v-if="data.length" class="mb-2 position-relative">
      <div
        class="sticky-top"
        style="z-index: 1"
        :style="isFromModalFinancial ? 'top: 20px' : 'top: 80px'"
      >
        <div
          :id="collapseId"
          v-b-toggle="collapseId"
          class="text-center rounded py-1 my-0 px-2 position-relative"
          :class="`bg-warning`"
        >
          <h2 class="d-inline text-white font-weight-bolder custom-title">
            ACCOUNTS IN DISPUTE
          </h2>
          <span
            class="cursor-pointer icon-collapse text-white"
            style="margin-top: 2px"
          >
            <tabler-icon
              :icon="iconIsDown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="20"
            />
          </span>
        </div>
      </div>
      <b-collapse :id="collapseId" v-model="iconIsDown" class="rounded mt-1">
        <header-grid-report />

        <div class="container-report pt-1 px-1" ref="container-report">
          <div
            class="container-grid mb-1"
            v-for="(items, index) in data"
            :key="index"
          >
            <div
              class="shadow w-auto text-secondary p-2 rounded position-relative"
              :style="
                isDarkSkin
                  ? 'background: #17171A; color:white'
                  : 'background: #fff'
              "
              v-for="(account, index) in items"
              :key="index"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100 text-muted"
                v-if="account.empty"
              >
                <feather-icon
                  icon="SlashIcon"
                  size="16"
                  class="cursor-pointer text-end text-info text-muted"
                  style="margin-right: 3px"
                />
                <p class="m-0">NO CONTENT</p>
              </div>
              <div
                class="d-flex justify-content-start pr-3"
                v-for="(infoAc, index) in account.content"
                :key="index"
              >
                <span class="w-35 text-truncate mr-1 font-weight-bolder"
                  >{{ infoAc.key }}:
                </span>
                <span
                  class="w-65 text-truncate"
                  v-b-tooltip.hover.top="infoAc.value"
                >
                  {{ infoAc.value }}
                </span>

                <div
                  class="action-data"
                  v-if="
                    isEnabledForEditAndDelete &&
                    !isFromModalFinancial &&
                    !account.empty
                  "
                >
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        v-b-tooltip.hover.right="'Actions'"
                        class="align-middle text-info"
                      />
                    </template>
                    <b-dropdown-item
                      v-b-modal.modal-primary
                      @click="openModalTracking(account)"
                    >
                      <feather-icon
                        icon="ListIcon"
                        size="16"
                        class="mr-1 cursor-pointer text-end text-info"
                      />
                      <span class="align-middle">View Tracking</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="isSpecialist"
                      v-b-modal.modal-primary
                      @click="editAccount(account.id)"
                    >
                      <feather-icon
                        size="16"
                        class="mr-1 cursor-pointer text-end text-warning"
                        icon="EditIcon"
                      />
                      <span class="align-middle">Edit Account</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="isSpecialist"
                      v-b-modal.modal-primary
                      @click="deleteAccount(account)"
                    >
                      <feather-icon
                        size="16"
                        class="mr-1 cursor-pointer text-danger"
                        icon="TrashIcon"
                      />
                      <span class="align-middle">Delete Account</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <b-badge
                class="float-right mt-1"
                v-if="!account.empty"
                :variant="getColorStatus(account.status)"
              >
                {{ account.status_name }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-collapse>

      <modal-tracking-letter
        v-if="modalTrackingController"
        :pItem="itemAcc"
        :pType="4"
        :client-name="client.client_name"
        :account="client.account"
        @close="closeModalTracking"
      />
      <edit-account-modal
        v-if="editAcModalController"
        :user-id="currentUser.user_id"
        :item-id="itemIdToSend"
        @close="closeEditAccount()"
      />
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalTrackingLetter from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalTrackingLetter.vue";
import EditAccountModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditAccountModal.vue";
import HeaderGridReport from "@/views/commons/components/clients/dashboard/options/report-module/components/HeaderGridReport.vue";
// Service Client Report
import ClientReportService from "@/views/commons/components/clients/dashboard/services/client.report.js";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ModalTrackingLetter,
    EditAccountModal,
    HeaderGridReport,
  },

  props: {
    clientId: {
      type: String,
    },
  },
  data() {
    return {
      collapseId: `collapse-only-accounts-in-dispute`,
      iconIsDown: false,
      modalTrackingController: false,
      editAcModalController: false,
      itemAcc: null,
      isBusyAccounts: false,
      data: [],
      busy: true,
    };
  },

  async created() {
    await this.getOnlyAccountsInDispute();
  },
  computed: {
    isSpecialist() {
      return [28,29,30].includes(this.moduleId);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      getRefreshReport: "ReportStore/G_REFRESH_REPORT",
    }),

    isFromModalFinancial() {
      return this.moduleId == 23;
    },

    isEnabledForEditAndDelete() {
      return (
        this.currentUser.role_id == 1 ||
        ((this.currentUser.role_id == 2 || this.currentUser.role_id == 3 || this.currentUser.role_id == 22))
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),
    getColorStatus(number) {
      let setColorStatus = {
        1: "light-warning",
        2: "light-danger",
        5: "light-primary",
        6: "light-danger",
      };

      return setColorStatus[number] || "light-success";
    },

    async getOnlyAccountsInDispute() {
      try {
        this.loading = true;
        let params = {
          idClientAccount: this.$route.params.idClient || this.clientId,
        };
        const data = await ClientReportService.getOnlyAccountsInDispute(params);
        if (data.status == 200) {
          this.$emit("uploadedData");
        }
        this.data = data.data;
        this.busy = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something has gone wrong with the report!"
        );
      } finally {
        this.loading = false;
      }
    },

    async deleteAccount(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteDelteAccountAc({
            iduser: this.currentUser.user_id,
            id,
            client_account_id: this.$route.params.idClient,
          });
          if (data.status === 200) {
            this.showSuccessSwal("Account has been deleted successfully");
            this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
              id: this.$route.params.idClient,
            });
            await this.getOnlyAccountsInDispute();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async editAccount(id) {
      this.editAcModalController = true;
      this.itemIdToSend = id;
    },
    closeModalTracking() {
      this.modalTrackingController = false;
    },
    openModalTracking(account) {
      this.itemAcc = account;
      this.modalTrackingController = true;
    },
    closeEditAccount() {
      this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: this.$route.params.idClient,
      });
      this.editAcModalController = false;
    },
  },
  watch: {
    async getRefreshReport(state) {
      if (state == 4) {
        await this.getOnlyAccountsInDispute();
        this.updateRefreshReport(0);
      }
    },
  },
};
</script>

<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 1em;
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.container-report {
  overflow-y: auto;
}

.action-data {
  position: absolute;
  right: -10px;
  top: 0;
}

.icon-collapse {
  position: absolute;
  right: 15px;
  top: 25%;
}
.btn-delete-all {
  position: absolute;
  left: 15px;
  top: 25%;
}
</style>
