import store from "@/store";
const ADD_COMMENTS_ACTION = "BinnacleStore/addComments";
const ADD_COMMENTS_STATUS_ACTION = "BinnacleStore/addCommentsStatus";
const SEND_MESSAGE_BINNACLE = "BinnacleStore/sendMessageBinnacle";
const SHOW_MODAL_FILES = "BinnacleStore/showModalFiles";
const ADD_OTHERS_FILES = "BinnacleStore/addOtherFiles";
const TOTAL_NEW_COMMENTS = "BinnacleStore/totalNewComments";
export class BinnacleService {
    constructor(otherService) {
        this.nameClass = "";
        this.otherService = otherService;
        this.valid = true;
        this.messageError = [];
        const validateMethods = () => {
            const requiredMethods = ['getCommentsBinnacle', 'getMessageStatus', 'insertMessageBinnacle', "insertFileBinnacle"];
            if (!this.otherService || typeof this.otherService !== 'object') {
                this.valid = false;
                this.messageError.push("The otherService object must have a method.");
                return;
            }
            this.nameClass = otherService.constructor.name;
            for (const method of requiredMethods) {
                if (!this.otherService[method] || typeof this.otherService[method] !== 'function') {
                    this.valid = false;
                    this.messageError.push(` The ${this.nameClass} object must have a method ${method}`);
                }
            }
        }
        validateMethods();
    }
    async getComments(params) {
        try {
            this.validateOtherServiceInterface();
            let data = await this.otherService.getCommentsBinnacle(params);
            let contunread = 1;
            data = data.map((item) => ({ ...item, unread: !item.viewed, idUnread: !item.viewed ? contunread++ : 0 }));
            // data = data.map((item) => {
            //     if (!item.unread) {
            //         return { ...item, unread: !item.viewed, idUnread: contunread++ }
            //     }
            //     return item;
            // })
            /** llenar los comentarios */
            store.dispatch(ADD_COMMENTS_ACTION, data);

            /** obtiene los files */
            BinnacleService.setOthersFiles(data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
    async getMessageStatus(params) {
        try {
            this.validateOtherServiceInterface();
            const data = await this.otherService.getMessageStatus(params);
            store.dispatch(ADD_COMMENTS_STATUS_ACTION, data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
    async insertMessage(params) {
        try {
            this.validateOtherServiceInterface();
            const { section, id } = BinnacleService.getClientInformation();
            /** debe retornar el id del registro recientemente */
            const data = await this.otherService.insertMessageBinnacle(params);
            const { user_id, fullName } = store.state.auth.currentUser;
            const { S_NEW_MESSAGE_BINNACLE } = store.state.BinnacleStore;

            const newMessage = {
                binnacleId: id,
                user_id: user_id,
                content: S_NEW_MESSAGE_BINNACLE.content,
                date_time: new Date(),
                user_name: fullName,
                id: data.id,
                viewed: false,
                isSender: true,
                section
            }

            /** se envia los mensaje al otro usuario */
            store.dispatch(SEND_MESSAGE_BINNACLE, newMessage);

            /** se procede a insertar el comentario*/
            store.dispatch(ADD_COMMENTS_ACTION, newMessage);

            store.dispatch(TOTAL_NEW_COMMENTS, 1);
            /** se procede a setear a null */
            setTimeout(() => {
                store.dispatch(ADD_COMMENTS_ACTION, null);
                store.dispatch(SEND_MESSAGE_BINNACLE, null);
            }, 5);
        } catch (ex) {
            throw new Error(ex);
        }
    }
    async insertFiles(params) {
        try {
            this.validateOtherServiceInterface();
            const { section, id } = BinnacleService.getClientInformation();
            const data = await this.otherService.insertFileBinnacle(params);
            const { user_id, fullName } = store.state.auth.currentUser;
            const newMessage = data.map(fileInfo => {
                return {
                    binnacleId: id,
                    user_id: user_id,
                    content: fileInfo.content,
                    date_time: new Date(),
                    user_name: fullName,
                    id: fileInfo.id,
                    file_path: fileInfo.file_path,
                    file_type: fileInfo.file_type,
                    viewed: false,
                    isSender: true,
                    is_file: true,
                    section
                }
            });

            /** se procede a insertar el comentario */
            data.forEach(element => {
                store.dispatch(ADD_COMMENTS_ACTION, element);
            });

            newMessage.forEach(message => {
                setTimeout(() => {
                    store.dispatch(SEND_MESSAGE_BINNACLE, message);
                    BinnacleService.setOthersFiles(message);
                }, 100);
            });

            /** procedemos a setear a null */
            setTimeout(() => {
                store.dispatch(ADD_COMMENTS_ACTION, null);
                store.dispatch(SEND_MESSAGE_BINNACLE, null);
            }, 5);
            store.dispatch(SHOW_MODAL_FILES, false);
        } catch (ex) {
            throw new Error(ex);
        }
    }
    static setOthersFiles(data) {
        try {
            if (Array.isArray(data)) {
                let otherFiles = [];
                data = data.filter(item => item.is_file);
                data.forEach((item) => {
                    otherFiles.push({
                        id: item.id,
                        user_id: item.user_id,
                        content: item.content,
                        date_time: item.date_time,
                        is_file: item.is_file,
                        file_path: item.file_path,
                        created_at: item.created_at,
                        file_type: item.file_type
                    });
                })
                store.dispatch(ADD_OTHERS_FILES, otherFiles);
            } else {
                if (data && typeof data === 'object') {
                    store.dispatch(ADD_OTHERS_FILES, data);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    static getClientInformation() {
        return store.state.BinnacleStore.S_CLIENT_INFORMATION;
    }
    validateOtherServiceInterface() {
        if (!this.valid) {
            for (let key in this.messageError) {
                console.error(this.messageError[key]);
            }
            throw new Error(`the ${this.nameClass} class is missing the above mentioned methods`);
        }
    }
}