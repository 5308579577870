<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    :header-bg-variant="actionClass"
    title-class="h3 text-white"
    :title="`${nameOption}: ${action}`"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <template #default>
      <b-container>        
        <b-row>
          <b-col v-if="data.account || data.client_account">
            <h5>Account</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py-1"
            >
              {{ data.account || data.client_account }}
            </p>
          </b-col>
          <b-col>
            <h5>Client</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py-1"
            >
              {{ data.client_name }}
            </p>
          </b-col>
        </b-row>
        <ValidationObserver ref="form">
          <b-row>
            <b-col>
              <ValidationProvider
              v-slot="{ errors }"
              name="comment"
              :rules="action.toUpperCase() == 'APPROVED' ? null : 'required'"
              >
                <b-form-group label="Comment:">
                  <b-form-textarea no-resize rows="4" v-model="form.comment" :state="errors[0] ? false : null" placeholder="Write your comment here ..." />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </ValidationObserver>
      </b-container>
    </template>
    <template #modal-footer>
      <b-button
        :variant="actionClass"
        @click.prevent="updatePotencialAppointment"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";


export default {
  name: "ModalActions",
  props: {
    data: {
      type: Object,
      required: true,
    },
    action: { type: String, required: true },
    option: { type: String, required: true },
  },
  data() {
    return {
      form: {
        comment: "",
        id: 0,
        status: "",
      },
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    actionClass() {
      switch (this.action) {
        case "PENDING":
          return "primary";
        case "ACCEPTED":
        case "APPROVED":
        case "YES":
          return "success";
        case "REJECTED":
        case "DENIED":
        case "NO":
          return "danger";
      }
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    nameOption(){
      return this.option == "StatusPaid" ? "Status paid" : this.option;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async updatePotencialAppointment() {
      const validate = await this.$refs.form.validate()
      if(!validate) return;

      const confirm = await this.showConfirmSwal();
      if(!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const data = {
          n_id: this.data.id,
          n_module_id: this.moduleId,
          n_user_id: this.currentUser.user_id,
          n_action: this.action,
          n_option: this.option,
          n_comment: this.form.comment,
          n_app_id: this.data.app_id || null
        };
        // await potencialAppointmentsService.updateStatus(data);
        this.closeModal();
        this.$emit("send", data);
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
