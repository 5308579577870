<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="info" @click="closeModal(null)">
          <feather-icon icon="ChevronLeftIcon" /> RETURN
        </b-button>
      </b-col>
    </b-row>

    <validation-observer ref="form">
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend style="width: 11rem">
                <b-input-group-text class="w-100">
                  <span>METHOD</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-select v-model="methodF" :options="methodOptions" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend style="width: 11rem">
                  <b-input-group-text class="w-100">
                    <span>TYPE</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select
                  v-model="typeSend"
                  :state="errors[0] ? false : null"
                >
                  <b-form-select-option v-if="methodF === 4" value="4">
                    PHONE
                  </b-form-select-option>
                  <b-form-select-option v-if="methodF === 4" value="5">
                    ONLINE
                  </b-form-select-option>
                  <b-form-select-option v-if="methodF === 1" value="1">
                    REGULAR
                  </b-form-select-option>
                  <b-form-select-option v-if="methodF === 1" value="2">
                    CERTIFIED
                  </b-form-select-option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col v-if="typeSend == 2" md="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="bureau"
          >
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend style="width: 11rem">
                  <b-input-group-text class="w-100">
                    <span>BUREAU</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select
                  v-model="bureauId"
                  :state="errors[0] ? false : null"
                  :options="itemBureau"
                  value-field="id"
                  text-field="name"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-if="methodF == 1">
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="title"
          >
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend style="width: 11rem">
                  <b-input-group-text class="w-100">
                    <span>TITLE</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select
                  v-model="title"
                  :state="errors[0] ? false : null"
                  :options="itemTitle"
                  value-field="id"
                  text-field="value"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="format"
          >
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend style="width: 11rem">
                  <b-input-group-text class="w-100">
                    <span>FORMAT</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select
                  v-model="format"
                  :state="errors[0] ? false : null"
                  :options="itemFormat"
                  value-field="id"
                  text-field="value"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend style="width: 11rem">
                  <b-input-group-text class="w-100">
                    <span>SECURE NUMBER</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-if="!ss.ssn && !ss.itin && !ss.other"
                  disabled
                />
                <b-form-radio-group
                  v-else
                  v-model="social"
                  :class="[
                    errors[0] ? 'border border-danger' : '',
                    isDarkSkin ? 'border-dark' : '',
                  ]"
                  :style="isDarkSkin ? 'background-color: #17171A;' : ''"
                  class="d-flex justify-content-center align-items-center form-control"
                >
                  <b-form-radio
                    v-if="ss.ssn"
                    :value="ss.ssn"
                    class="ml-1"
                    @change="typeSocial = 1"
                  >
                    <span class="ml-1"
                      >{{ " SSN: " + ss.ssn }}
                    </span></b-form-radio
                  >
                  <b-form-radio
                    v-if="ss.itin"
                    :value="ss.itin"
                    @change="typeSocial = 2"
                    ><span class="ml-1">
                      {{ " ITIN: " + ss.itin }}</span
                    ></b-form-radio
                  >
                  <b-form-radio
                    v-if="ss.other"
                    :value="ss.other"
                    @change="typeSocial = 3"
                    ><span class="ml-1">
                      {{ " CPN: " + ss.other }}</span
                    ></b-form-radio
                  >
                </b-form-radio-group>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend style="width: 11rem">
                <b-input-group-text class="w-100">
                  <span>OBSERVATION</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-textarea v-model="observation" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button variant="success" @click="saveRound()"> SAVE </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CreateCRRoundModal",
  props: {
    ncrId: {
      type: String,
      Default: null,
    },
    roundLetterData: {
      type: Object,
      Default: { id: null },
    },
    editing: {
      type: Boolean,
      Default: false,
    },
    hasWorkPlan: {
      type: Boolean,
      Default: null,
    },
    clientAccountId: {
      type: String,
      Default: null,
    },
    idWorkplan: {
      type: Number,
      Default: null,
    },
    createdRounds: {
      type: Array,
      Default: null,
    },
  },
  data() {
    return {
      secureSocial: null,
      createCRRoundModal: false,
      dataForm: [],
      itemCRRound: [],
      itemTitle: [],
      itemFormat: [],
      itemBureau: [],
      itemSecureSocial: [],
      methodOptions: [
        {
          value: 1,
          text: "LETTER",
          disabled: false,
        },
        {
          value: 4,
          text: "DIRECT",
          disabled: false,
        },
      ],
      methodF: null,
      typeSend: null,
      bureauId: null,
      title: "",
      format: "",
      social: "",
      ss: {
        ssn: null,
        itin: null,
        other: null,
      },
      typeSocial: "",
      observation: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getAllTitles();
    await this.getAllFormats();
    await this.getAllBureaus();
    await this.getAllSecureSocial();

    if (this.editing) {
      // get data of round to edit
      await this.getRoundId();
    } else {
      // validate if all round letters are created to avoid duplicated rounds
      await this.getLettersRoundCounter();
    }
    this.createCRRoundModal = true;
  },
  methods: {
    ...mapActions({
      REFRESH_WORKPLAN_TABLE: "RequestWorkPlanStore/CHANGE_REFRESH_TABLE",
      REFRESH_DISPUTE_TABLE: "SpecialistsDigitalRoundLettersStore/CHANGE_REFRESH_TABLE",
    }),
    async getLettersRoundCounter() {
      let countLetters = 0;
      this.createdRounds?.forEach((round) => {
        if ([1, 2].includes(round.type_round)) {
          countLetters++;
        }
      });
      if (countLetters == 5) {
        this.methodF = 4; // set direct method by default
        this.methodOptions[0].disabled = true; // unable letter method
      } else {
        this.methodF = 1; // set letter method by default
      }
    },
    async getRoundId() {
      const params = {
        id: this.roundLetterData.id,
      };
      const data = await ClientsOptionsServices.getRoundId(params);
      if (data.status === 200) {
        if (data.data.length > 0) {
          this.title = data.data[0].title_id;
          this.format = data.data[0].format_id;
          this.typeSend = data.data[0].type_send_id;
          this.methodF = [3, 4, 5].includes(data.data[0].type_send_id) ? 4 : 1;
          this.bureauId = data.data[0].bureau_send_id;
          this.observation = data.data[0].obs;
          this.social = data.data[0].lead_ssn;
          this.typeSocial = data.data[0].ss_type;
        }
      }
    },
    async getAllTitles() {
      const data = await ClientsOptionsServices.getAllTitles();
      if (data.length > 0) {
        this.createdRounds?.forEach((round) => {
          // ignore directs
          if ([1, 2].includes(round.type_round)) {
            data.forEach((item) => {
              if (item.value == round.title_r) {
                // disable all letters already created if roundLetterData is null, which means user is creating a new round letter
                if (this.roundLetterData == null) {
                  item.disabled = true;
                } else {
                  // disable all letters already created, except the value of roundLetterData, which means user is editing a round
                  item.disabled = item.value != this.roundLetterData.title_r;
                }
              }
            });
          }
        });

        this.itemTitle = data;
      }
    },
    async getAllFormats() {
      const data = await ClientsOptionsServices.getAllFormats();
      if (data.length > 0) {
        this.itemFormat = data;
      }
    },
    async getAllBureaus() {
      const data = await ClientsOptionsServices.getAllBureaus();
      if (data.length > 0) {
        this.itemBureau = data;
      }
    },
    async getAllSecureSocial() {
      const params = {
        ncrid: this.editing ? this.roundLetterData.id : this.ncrId,
      };
      const data = await ClientsOptionsServices.getAllSecureSocial(params);
      if (data.length > 0) {
        this.itemSecureSocial = data[0];
        this.ss = this.itemSecureSocial;

        const { ssn, itin, other } = data[0];
        if (ssn !== null && itin === null && other === null) {
          this.social = ssn;
        } else if (ssn === null && itin !== null && other === null) {
          this.social = itin;
        } else if (ssn === null && itin === null && other !== null) {
          this.social = other;
        }
      }
    },
    async saveRound() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const params = {
            id: this.editing ? this.roundLetterData.id : this.ncrId,
            title: this.title,
            format: this.format,
            obs: this.observation,
            user: this.currentUser.user_id,
            social: this.social,
            type_social: this.typeSocial,
            type_send: this.typeSend,
            bureau_id: this.bureauId,
            has_workplan: this.hasWorkPlan,
            client_account_id: this.clientAccountId,
            id_workplan: this.idWorkplan,
          };
          const data = this.editing
            ? await ClientsOptionsServices.editRound(params)
            : await ClientsOptionsServices.saveRound(params);
          if (data.status === 200) {
            this.REFRESH_DISPUTE_TABLE(true);
            this.REFRESH_WORKPLAN_TABLE(true);
            let dataToPass = "";
            if (!this.editing) dataToPass = { ...data.data[0] };
            this.closeModal(dataToPass);
          }
        }
      } catch (e) {
        console.log("e ", e);
      }
    },
    closeModal(data = null) {
      this.$emit("close", data);
    },
  },
};
</script>
