<template>
  <b-tabs
    active-tab-class="p-0"
    pills
    nav-class="mb-0"
    active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
  >
    <b-tab active :title-link-class="[bgTabsNavs, 'px-3']" title="Strategies">
      <b-row class="border-top-primary border-3 border-table-radius p-1">
        <b-col lg="5">
          <div class="title mb-1">Strategies</div>
          <div class="d-flex flex-wrap gap-5">
            <b-dropdown
              variant="primary"
              v-for="(round, index) in rounds"
              :key="index"
              :disabled="round.data.length == 0"
            >
              <template #button-content>
                <span style="font-size: 15px">{{ round.title }}</span>
                <b-badge variant="warning" class="ml-1">{{
                  round.data.length
                }}</b-badge>
              </template>
              <b-dd-item
                v-for="item in round.data"
                :key="item.id"
                @click="openRoundDetails(item)"
              >
                <div class="d-flex justify-content-between">
                  <span v-if="round.type == 1">{{
                    item.created_at | myGlobal
                  }}</span>
                  <span v-else>{{ getTitleName(item.title) }}</span>
                  <b-badge variant="warning" class="ml-1">{{
                    item.total
                  }}</b-badge>
                </div>
              </b-dd-item>
            </b-dropdown>
          </div>

          <div class="title mt-2 mb-1">General Recommendations</div>
          <ul>
            <li>No aplicar por crédito durante el proceso de reparación</li>
            <li>Pagar al día sus cuentas y con balances adecuados</li>
            <li>No llegar a acuerdos de pagos con compañía de COLLECTION</li>
            <li>
              Cualquier documento reciba en referencia a su crédito, nos lo
              envíe.
            </li>
            <li>
              Cualquier duda o consulta sobre su caso, se comunique con su
              agente.
            </li>
          </ul>
          <div v-if="dataClient.comment">
            <p class="title mt-2 mb-1">Comment:</p>
            <b-textarea
              v-model="dataClient.comment"
              rows="3"
              max-rows="6"
              disabled
            />
          </div>
          <p v-else class="text-warning m-0 rounded p-1">
            <feather-icon
              icon="InfoIcon"
              size="18"
              style="margin-right: 3px"
            />Comment not registered at the time of processing the Work Plan.
          </p>
        </b-col>
        <b-col lg="7">
          <div class="title mb-1">Recommended Services</div>
          <div class="d-flex flex-wrap gap-5">
            <template v-if="displayedServices()">
              <b-badge
                class="px-1"
                variant="primary"
                v-for="(item, index) in displayedServices()"
                :key="index"
              >
                <span style="font-size: 15px">{{
                  item.charge || item.name
                }}</span>
              </b-badge>
            </template>
            <template v-else>
              <span>No recommended services</span>
            </template>
          </div>
          <div class="title mb-1" style="margin-top: 35px">
            Credit utilization
          </div>
          <b-table
            id="my-table"
            striped
            small
            :items="creditUtilized"
            :fields="fields"
          >
            <template #cell(account)="{ item }">
              <span v-b-tooltip.hover.left="item.account">
                {{
                  item.account.length > 20
                    ? item.account.substring(0, 20) + "..."
                    : item.account
                }}
              </span>
            </template>
            <template #cell(percentage)="row">
              <b-badge
                style="font-size: 10px"
                v-if="row.item.percentage > 100"
                variant="light-danger"
              >
                OVERDRAFT
              </b-badge>
              <span v-else :class="colorPercent(row.item)">
                {{
                  row.item.percentage > 100
                    ? "100.00"
                    : row.item.percentage.toFixed(2)
                }}%
              </span>
            </template>
          </b-table>
        </b-col>
        <RoundDetail
          v-if="showRoundDetail"
          :title="modalTitle"
          :data="dataRound"
          @close="showRoundDetail = false"
        />
      </b-row>
    </b-tab>
    <b-tab
      :title-link-class="[bgTabsNavs, 'px-3']"
      title="Accounts"
      v-if="deletedAccounts.length > 0 || newAccounts.length > 0"
    >
      <div class="border-top-primary border-3 border-table-radius p-1">
        <AccountsWP
          v-if="deletedAccounts.length > 0"
          title="Deleted Accounts"
          :fields="fields"
          :items="deletedAccounts"
        />
        <hr />
        <AccountsWP
          v-if="newAccounts.length > 0"
          title="New Accounts"
          :fields="fields"
          :items="newAccounts"
        />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import RoundDetail from "@/views/commons/components/request-workplan/views/components/modal/RoundDetail.vue";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
import AccountsWP from "@/views/commons/components/request-workplan/views/components/modal/AccountsWP.vue";
export default {
  components: {
    RoundDetail,
    AccountsWP,
  },
  props: {
    rowId: {
      required: true,
      type: Number,
    },
    revision: {
      required: false,
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
  },
  data() {
    return {
      detailWorkplan: {
        strategies: [],
        services: [],
        comments: "",
      },
      finishedLoading: false,
      showRoundDetail: false,
      dataRound: {
        personal_information: [],
        inquiries: [],
        public_records: [],
        accounts: [],
      },
      modalTitle: "",
      creditUtilized: [],
      deletedAccounts: [],
      newAccounts: [],
      rounds: [],
      directs: {
        type: "1",
        title: "Directs",
        data: [],
      },
      letters: {
        type: "2",
        title: "Letters",
        data: [],
      },
      fields: [
        { key: "account", label: "Account" },
        { key: "account_number", label: "A. Number" },
        {
          key: "credit",
          label: "Credit",
          formatter: (value) => `$ ${value}`,
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "balance",
          label: "Balance",
          formatter: (value) => `$ ${value}`,
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "percentage",
          label: "Credit Utilization",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      recommended_services: [],
      dataClient: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    rowsCredit() {
      return this.creditUtilized.length;
    },
  },
  async created() {
    await this.myProvider();
    await this.prepareDisputeData();
    await this.displayedServices();
    this.finishedLoading = true;
  },

  methods: {
    ...mapActions({
      A_UPDATE_REQUEST_COUNTERS:
        "BoostCreditDisputeResult/A_UPDATE_REQUEST_COUNTERS",
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
      A_COUNTER_PENDING_ROUND_LETTERS:
        "SpecialistsDigitalRoundLettersStore/A_COUNTER_PENDING_ROUND_LETTERS",
      A_UPDATE_STEP_WP: "RequestWorkPlanStore/A_UPDATE_STEP_WP",
      A_IS_EXECUTING_METHOD: "RequestWorkPlanStore/A_IS_EXECUTING_METHOD",
      A_IS_EXECUTING_METHOD_UPDATE:
        "DebtSolutionDisputeResult/A_IS_EXECUTING_METHOD",
      A_UPDATE_STEP_UR: "DebtSolutionDisputeResult/A_UPDATE_STEP_UR",
    }),
    prepareDisputeData() {
      if (
        this.dataClient.rounds !== undefined &&
        this.dataClient.rounds !== null
      ) {
        const rounds = JSON.parse(this.dataClient.rounds);
        rounds.forEach((round) => {
          if ([1, 2].includes(round.type_send_id)) {
            this.letters.data.push(round);
          } else {
            this.directs.data.push(round);
          }
        });

        // order letters by round title
        this.letters.data = this.letters.data.sort(this.compareByRound);

        this.rounds = [this.directs, this.letters];
      }
      this.creditUtilized = this.dataClient.creditUtilized ?? [];
      this.deletedAccounts =
        this.dataClient.deletedAccounts.filter((item) => item.status == 4) ??
        [];
      this.newAccounts = this.dataClient.newAccounts ?? [];
    },
    compareByRound(a, b) {
      return a.title - b.title;
    },
    getTitleName(title) {
      const titles = {
        0: "Direct dispute",
        1: "1st round",
        2: "2nd round",
        3: "3rd round",
        4: "4th round",
        5: "5th round",
      };

      return titles[title];
    },
    async openRoundDetails(round) {
      const data = await RequestWorkPlanService.getRoundDetails({
        id: round.id,
      });
      this.dataRound = {
        personal_information: this.processData(data.personal_information, 1),
        inquiries: this.processData(data.inquiries, 2),
        public_records: this.processData(data.public_records, 3),
        accounts: this.processData(data.accounts, 4),
      };

      this.modalTitle = this.getTitleName(
        [3, 4, 5].includes(round.type_send_id) ? 0 : round.title
      );

      this.showRoundDetail = true;
    },
    processData(arr, num) {
      const keys = {
        1: "pi",
        2: "in",
        3: "pr",
        4: "ac",
      };
      arr.forEach((element) => {
        element.key = keys[num];
      });

      return arr;
    },
    closeModal() {
      this.$emit("close");
    },
    async returnAction(id) {
      try {
        const validateObservation = await this.$swal({
          title: "Your Observation",
          input: "textarea",
          showCancelButton: true,
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",

          inputValidator: (observation) => {
            // If the value is valid, you must return undefined. Otherwise, a string
            return !observation ? "Please enter your observation" : undefined;
          },
        });

        if (validateObservation.value) {
          let res;
          const swal = await this.showConfirmSwal(
            "Are you sure to return to pending?"
          );
          if (swal.isConfirmed) {
            this.addPreloader();
            const params = {
              id,
              created_by: this.currentUser.user_id,
              obs: validateObservation.value,
              score_id: this.dataClient.score_id,
            };
            if (this.section == "update") {
              this.A_IS_EXECUTING_METHOD_UPDATE(true);
              res = await DisputeResultsService.returnToPending(params);
              this.A_UPDATE_REQUEST_COUNTERS(28);
              this.updateStep(2);
            } else if (this.section == "workplan") {
              this.A_IS_EXECUTING_METHOD(true);
              res = await RequestWorkPlanService.returnToPending(params);
              this.updateStep(3);
              this.A_REQUEST_WORKPLAN_COUNTERS(28);
            }

            this.showToast(
              "success",
              "top-right",
              "Congratulations",
              "CheckIcon",
              "Return completed"
            );
            this.$emit("reload");
            this.closeModal();
            return res;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async completeAction(item) {
      let specialistId =
        this.section == "workplan"
          ? item.specialist_id
          : item.specialist_assign_id;
      try {
        const swal = await this.showConfirmSwal("Are you sure to complete?");
        if (swal.isConfirmed) {
          let res;
          this.addPreloader();
          const params = {
            id: item.id,
            created_by: this.currentUser.user_id,
            client_account_id: item.client_account_id,
            specialist_id: specialistId ?? this.currentUser.user_id,
            recommended_services: this.dataClient.recommended_services,
          };

          if (this.section == "update") {
            res = await DisputeResultsService.completeUpdateRequest(params);
          } else if (this.section == "workplan") {
            res = await RequestWorkPlanService.completeWp(params);
          }

          // update dispute letters counter
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Dispute completed"
          );
          await this.A_COUNTER_PENDING_ROUND_LETTERS();
          this.$emit("reload");
          this.closeModal();
          return res;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    displayedServices() {
      if (this.dataClient.recommended_services) {
        const services = JSON.parse(this.dataClient.recommended_services);
        return services;
      }
    },

    colorPercent(item) {
      switch (true) {
        case item.percentage <= 30:
          return "text-success";
        case item.percentage <= 60:
          return "text-warning";
        default:
          return "text-danger";
      }
    },
    rename(moduleName) {
      const names = {
        "CUSTOMER SERVICE DIGITAL": "C.S. DIGITAL",
        "CUSTOMER SERVICE REGULAR": "C.S. REGULAR",
      };
      return names[moduleName] || moduleName;
    },
    updateStep(step) {
      const updatedData = {
        id: this.rowId,
        step: step,
      };
      if (this.section == "workplan") {
        this.A_UPDATE_STEP_WP(updatedData);
      } else if (this.section == "update") {
        this.A_UPDATE_STEP_UR(updatedData);
      }
    },
    async myProvider() {
      try {
        this.addPreloader();

        let service;
        let params;

        if (this.section === "workplan") {
          service = RequestWorkPlanService.getCompletedWpInfo.bind(
            RequestWorkPlanService
          );
          params = { id: this.rowId };
        } else if (this.section === "update") {
          service = UpdateService.getCompletedUrInfo.bind(UpdateService);
          params = { id: this.rowId };
        }

        if (service && params) {
          const { data } = await service(params);
          this.dataClient = data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
.custom-input .form-control {
  opacity: 1 !important;
}
.hr-divider {
  height: 1;
  border-right: 1px solid #555555;
}
.info-strategy {
  min-height: 300px;
  width: 46%;
  padding: 5px 10px;
}
.container-complete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: #ff9c3f;
}
.counter-round {
  background: #42c7fc;
  color: #17161a !important;
}
</style>
