import { render, staticRenderFns } from "./DisputeReport.vue?vue&type=template&id=f687636e&scoped=true"
import script from "./DisputeReport.vue?vue&type=script&lang=js"
export * from "./DisputeReport.vue?vue&type=script&lang=js"
import style0 from "./DisputeReport.vue?vue&type=style&index=0&id=f687636e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f687636e",
  null
  
)

export default component.exports