<template>
  <div>
    <div v-if="!personalInfoFormRoundModal">

      <div>
        <b-input-group prepend="CATEGORY" style="width: 415px">
          <b-form-select
            v-model="categoryData"
            value-field="id"
            text-field="value"
            :options="categoryItem"
            @change="searchCategory"
          />
        </b-input-group>
      </div>

      <b-row>
        <b-col v-if="categoryData === 1" class="mt-2">
          <b-table
            ref="tablePersonalInfo"
            no-border-collapse
            class="position-relative custom-table"
            :fields="fieldTablePi"
            show-empty
            :items="itemsPersonalInfo"
            sticky-header="50vh"
            :busy="isBusyPersonalInfo"
          >
            <template #head(trans)>
              <span>Transunion</span>
            </template>
            <template #head(experian)>
              <span class="font-weight-bolder"
                >Experian</span
              >
            </template>
            <template #head(equifax)>
              <span class="font-weight-bolder"
                >EQUIFAX</span
              >
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(date)="data">
              <span v-if="data.index === 0">
                <span v-if="data.item.type === 1">NAME</span>
                <span v-if="data.item.type === 2">DATE OF BIRTH</span>
                <span v-if="data.item.type === 3">ADDRESS</span>
                <span v-if="data.item.type === 4">SSN</span>
              </span>
              <span v-else>
                <span
                  v-if="
                    itemsPersonalInfo[data.index].type >
                    itemsPersonalInfo[data.index - 1].type
                  "
                >
                  <span v-if="data.item.type === 2">DATE OF BIRTH</span>
                  <span v-if="data.item.type === 3">ADDRESS</span>
                  <span v-if="data.item.type === 4">SSN</span>
                </span>
              </span>
            </template>
            <template #cell(trans)="data">
              <div
                v-if="
                  JSON.parse(data.item.pi).length === 3 ||
                  JSON.parse(data.item.pi).length === 2 ||
                  JSON.parse(data.item.pi)[0].bureau_id === 1
                "
                class="text-center"
              >
                <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                ><br />
                <span
                  :class="
                    JSON.parse(data.item.pi)[0].status === 1
                      ? 'text-warning bold'
                      : JSON.parse(data.item.pi)[0].status === 2 ||
                        JSON.parse(data.item.pi)[0].status === 6
                      ? 'text-danger bold'
                      : 'text-success bold'
                  "
                  >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                >
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox 
                    v-model="data.item.transChecked" 
                  />
                </div>
              </div>
            </template>
            <template #cell(experian)="data">
              <div
                v-if="
                  JSON.parse(data.item.pi).length === 3 ||
                  JSON.parse(data.item.pi).length === 2
                "
                class="text-center"
              >
                <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                  <span>{{ JSON.parse(data.item.pi)[1].content }}</span
                  ><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox 
                      v-model="data.item.expeChecked" 
                    />
                  </div>
                </div>
                <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                  <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                  ><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox 
                      v-model="data.item.expeChecked" 
                    />
                  </div>
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                class="text-center"
              >
                <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                ><br />
                <span
                  :class="
                    JSON.parse(data.item.pi)[0].status === 1
                      ? 'text-warning bold'
                      : JSON.parse(data.item.pi)[0].status === 2 ||
                        JSON.parse(data.item.pi)[0].status === 6
                      ? 'text-danger bold'
                      : 'text-success bold'
                  "
                  >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                >
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox 
                    v-model="data.item.expeChecked"
                  />
                </div>
              </div>
            </template>
            <template #cell(equifax)="data">
              <div
                v-if="JSON.parse(data.item.pi).length === 3"
                class="text-center"
              >
                <span>{{ JSON.parse(data.item.pi)[2].content }}</span
                ><br />
                <span
                  :class="
                    JSON.parse(data.item.pi)[0].status === 1
                      ? 'text-warning bold'
                      : JSON.parse(data.item.pi)[0].status === 2 ||
                        JSON.parse(data.item.pi)[0].status === 6
                      ? 'text-danger bold'
                      : 'text-success bold'
                  "
                  >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                >
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox 
                    v-model="data.item.equiChecked" 
                  />
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi).length === 2"
                class="text-center"
              >
                <div
                  v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                  class="text-center"
                >
                  <span>{{ JSON.parse(data.item.pi)[1].content }}</span
                  ><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox 
                      v-model="data.item.equiChecked"
                    />
                  </div>
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                class="text-center"
              >
                <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                ><br />
                <span
                  :class="
                    JSON.parse(data.item.pi)[0].status === 1
                      ? 'text-warning bold'
                      : JSON.parse(data.item.pi)[0].status === 2 ||
                        JSON.parse(data.item.pi)[0].status === 6
                      ? 'text-danger bold'
                      : 'text-success bold'
                  "
                  >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                >
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox 
                    v-model="data.item.equiChecked" 
                  />
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="primary"
                class="text-center"
                size="sm"
                :disabled="disableActionButton(data.item)"
                @click="openPiFormModal(data.index)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col v-if="categoryData === 2" class="mt-2">
          <b-table
            ref="tableInquiries"
            no-border-collapse
            class="position-relative custom-table"
            :fields="fieldTableInquiries"
            show-empty
            :items="itemsInquiries"
            sticky-header="50vh"
            :busy="isBusyInquiries"
          >
            <template #head(trans)>
              <span>Transunion</span>
            </template>
            <template #head(experian)>
              <span class="font-weight-bolder"
                >Experian</span
              >
            </template>
            <template #head(equifax)>
              <span class="font-weight-bolder"
                >EQUIFAX</span
              >
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(date)="data">
              <span>{{
                JSON.parse(data.item.pi)[0].content === "null"
                  ? "-"
                  : JSON.parse(data.item.pi)[0].content
              }}</span>
              <br />
              <span>{{ data.item.date }}</span>
            </template>
            <template #cell(trans)="data">
              <div
                v-if="
                  JSON.parse(data.item.pi).length === 3 ||
                  JSON.parse(data.item.pi).length === 2 ||
                  JSON.parse(data.item.pi)[0].bureau_id === 1
                "
                class="text-center"
              >
                <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 1">
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                    <div>
                      <div class="d-flex justify-content-center">
                        <b-checkbox v-model="data.item.transChecked" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(experian)="data">
              <div
                v-if="
                  JSON.parse(data.item.pi).length === 3 ||
                  JSON.parse(data.item.pi).length === 2
                "
                class="text-center"
              >
                <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                    <div class="d-flex justify-content-center">
                      <b-checkbox v-model="data.item.expeChecked" />
                    </div>
                  </div>
                </div>
                <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                    <div class="d-flex justify-content-center">
                      <b-checkbox v-model="data.item.expeChecked" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                class="text-center"
              >
                <div>
                  <feather-icon icon="CheckIcon" class="" size="18" /><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                  <div class="d-flex justify-content-center">
                    <b-checkbox v-model="data.item.expeChecked" />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(equifax)="data">
              <div
                v-if="JSON.parse(data.item.pi).length === 3"
                class="text-center"
              >
                <div>
                  <feather-icon icon="CheckIcon" class="" size="18" /><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                </div>
                <div class="d-flex justify-content-center">
                  <b-checkbox v-model="data.item.equiChecked" />
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi).length === 2"
                class="text-center"
              >
                <div
                  v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                  class="text-center"
                >
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                  </div>
                  <div class="d-flex justify-content-center">
                    <b-checkbox v-model="data.item.equiChecked" />
                  </div>
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                class="text-center"
              >
                <div>
                  <feather-icon icon="CheckIcon" class="" size="18" />
                </div>
                <br />
                <span
                  :class="
                    JSON.parse(data.item.pi)[0].status === 1
                      ? 'text-warning bold'
                      : JSON.parse(data.item.pi)[0].status === 2 ||
                        JSON.parse(data.item.pi)[0].status === 6
                      ? 'text-danger bold'
                      : 'text-success bold'
                  "
                  >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                >
                <div class="d-flex justify-content-center">
                  <b-checkbox v-model="data.item.equiChecked" />
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="primary"
                class="text-center"
                size="sm"
                :disabled="disableActionButton(data.item)"
                @click="openInquerieFormModal(data.index)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col v-if="categoryData === 3" class="mt-2">
          <b-table
            ref="tablePr"
            no-border-collapse
            class="position-relative custom-table"
            :fields="fieldTablePr"
            show-empty
            :items="itemsPr"
            sticky-header="50vh"
            :busy="isBusyPr"
          >
            <template #head(trans)>
              <span>Transunion</span>
            </template>
            <template #head(experian)>
              <span class="font-weight-bolder"
                >Experian</span
              >
            </template>
            <template #head(equifax)>
              <span class="font-weight-bolder"
                >EQUIFAX</span
              >
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(date)="data">
              <span>{{ JSON.parse(data.item.pi)[0].public_record }}</span>
              <br />
              <span>{{ JSON.parse(data.item.pi)[0].court_sase }}</span>
              <br />
              <span>{{ data.item.date }}</span>
            </template>
            <template #cell(trans)="data">
              <div
                v-if="
                  JSON.parse(data.item.pi).length === 3 ||
                  JSON.parse(data.item.pi).length === 2 ||
                  JSON.parse(data.item.pi)[0].bureau_id === 1
                "
                class="text-center"
              >
                <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 1">
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                  </div>
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox v-model="data.item.transChecked" />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(experian)="data">
              <div
                v-if="
                  JSON.parse(data.item.pi).length === 3 ||
                  JSON.parse(data.item.pi).length === 2
                "
                class="text-center"
              >
                <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                  </div>
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox v-model="data.item.expeChecked" />
                  </div>
                </div>
                <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                  </div>
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox v-model="data.item.expeChecked" />
                  </div>
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                class="text-center"
              >
                <div>
                  <feather-icon icon="CheckIcon" class="" size="18" /><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                </div>
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox v-model="data.item.expeChecked" />
                </div>
              </div>
            </template>
            <template #cell(equifax)="data">
              <div
                v-if="JSON.parse(data.item.pi).length === 3"
                class="text-center"
              >
                <div>
                  <feather-icon icon="CheckIcon" class="" size="18" /><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                </div>
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox v-model="data.item.equiChecked" />
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi).length === 2"
                class="text-center"
              >
                <div
                  v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                  class="text-center"
                >
                  <div>
                    <feather-icon icon="CheckIcon" class="" size="18" /><br />
                    <span
                      :class="
                        JSON.parse(data.item.pi)[0].status === 1
                          ? 'text-warning bold'
                          : JSON.parse(data.item.pi)[0].status === 2 ||
                            JSON.parse(data.item.pi)[0].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                    >
                  </div>
                  <div 
                    v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                    class="d-flex justify-content-center"
                  >
                    <b-checkbox v-model="data.item.equiChecked" />
                  </div>
                </div>
              </div>
              <div
                v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                class="text-center"
              >
                <div>
                  <feather-icon icon="CheckIcon" class="" size="18" /><br />
                  <span
                    :class="
                      JSON.parse(data.item.pi)[0].status === 1
                        ? 'text-warning bold'
                        : JSON.parse(data.item.pi)[0].status === 2 ||
                          JSON.parse(data.item.pi)[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ JSON.parse(data.item.pi)[0].status_name }}</span
                  >
                </div>
                <div 
                  v-if="JSON.parse(data.item.pi)[0].status_name != 'IN DISPUTE'"
                  class="d-flex justify-content-center"
                >
                  <b-checkbox v-model="data.item.equiChecked" />
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="primary"
                class="text-center"
                size="sm"
                :disabled="disableActionButton(data.item)"
                @click="openPrFormModal(data.index)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col v-if="categoryData === 4" class="mt-2">
          <b-table
            ref="tableAccount"
            no-border-collapse
            class="position-relative custom-table"
            :fields="fieldTableAccount"
            show-empty
            :items="itemsAccount"
            sticky-header="50vh"
            :busy="isBusyAccount"
          >
            <template #head(trans)>
              <span>Transunion</span>
            </template>
            <template #head(experian)>
              <span class="font-weight-bolder"
                >Experian</span
              >
            </template>
            <template #head(equifax)>
              <span class="font-weight-bolder"
                >EQUIFAX</span
              >
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(date)>
              <span>Account Name</span><br />
              <span>Account #</span><br />
              <span>Balance</span><br />
              <span>Account Type</span><br />
            </template>
            <template #cell(trans)="data">
              <div v-if="data.item.pi === null">
                <span>There is no specific information for this record</span>
              </div>
              <div v-else>
                <div
                  v-if="data.item.pi[0].id != null"
                  class="text-center"
                >
                  <item-table-account
                    :item="data.item.pi[0]"
                    :is-inside-modal="true"
                  />
                  <span
                    :class="
                      data.item.pi[0].status === 1
                        ? 'text-warning bold'
                        : data.item.pi[0].status === 2 ||
                          data.item.pi[0].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ data.item.pi[0].status_name }}</span
                  >
                  <div class="d-flex justify-content-center">
                    <b-checkbox 
                      v-model="data.item.transChecked" 
                      :disabled="data.item.transChecked && data.item.pi[0].status_name == 'IN DISPUTE'"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(experian)="data">
              <div v-if="data.item.pi === null">
                <span>There is no specific information for this record</span>
              </div>
              <div v-else>
                <div
                  v-if="data.item.pi[1].id != null"
                  class="text-center"
                >
                  <div v-if="data.item.pi[1].bureau_id === 2">
                    <item-table-account
                      :item="data.item.pi[1]"
                      :is-inside-modal="true"
                    />
                    <span
                      :class="
                        data.item.pi[1].status === 1
                          ? 'text-warning bold'
                          : data.item.pi[1].status === 2 ||
                            data.item.pi[1].status === 6
                          ? 'text-danger bold'
                          : 'text-success bold'
                      "
                      >{{ data.item.pi[1].status_name }}</span
                    >
                    <div class="d-flex justify-content-center">
                      <b-checkbox 
                        v-model="data.item.expeChecked" 
                        :disabled="data.item.expeChecked && data.item.pi[1].status_name == 'IN DISPUTE'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(equifax)="data">
              <div v-if="data.item.pi === null">
                <span>There is no specific information for this record</span>
              </div>
              <div v-else>
                <div
                  v-if="data.item.pi[2].id != null"
                  class="text-center"
                >
                  <item-table-account
                    :item="data.item.pi[2]"
                    :is-inside-modal="true"
                  />
                  <span
                    :class="
                      data.item.pi[2].status === 1
                        ? 'text-warning bold'
                        : data.item.pi[2].status === 2 ||
                          data.item.pi[2].status === 6
                        ? 'text-danger bold'
                        : 'text-success bold'
                    "
                    >{{ data.item.pi[2].status_name }}</span
                  >
                  <div class="d-flex justify-content-center">
                    <b-checkbox 
                      v-model="data.item.equiChecked" 
                      :disabled="data.item.equiChecked && data.item.pi[2].status_name == 'IN DISPUTE'"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="primary"
                class="text-center"
                size="sm"
                :disabled="disableActionButton(data.item)"
                @click="openAccountFormModal(data.index)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button variant="info" @click="closeModal">
          BACK
        </b-button>
      </div>
    </div>
    <personal-info-form-round-modal
      v-if="personalInfoFormRoundModal"
      :id-round="senderData.id"
      :bureau-data="bureauItem"
      :bureau-type="bureauType"
      :roundClassification="roundClassification"
      @saveAndClose="saveAndClosePersonalInfoFormRoundModal"
      @close="closePersonalInfoFormRoundModal"
    />
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
// modal components
import ItemTableAccount from "@/views/commons/components/clients/dashboard/options/report-module/components/ItemTableAccount.vue";
import PersonalInfoFormRoundModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/personal-info-form-round-modal/PersonalInfoFormRoundModal.vue";

export default {
  name: "CreateDetailLettersModal",
  components: {
    PersonalInfoFormRoundModal,
    ItemTableAccount,
  },
  props: {
    roundData: {
      type: Object,
    },
    senderData: {
      type: Object,
    },
  },
  data() {
    return {
      categoryItem: [
        { id: 1, value: "PERSONAL INFORMATION" },
        { id: 2, value: "INQUIRIES" },
        { id: 3, value: "PUBLIC RECORDS" },
        { id: 4, value: "ACCOUNTS" },
      ],
      categoryData: 1,
      // Personal Info table
      fieldTablePi: [
        {
          key: "date",
          label: "",
        },
        {
          key: "trans",
          label: "TransUnion",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "experian",
          label: "Experian",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "equifax",
          label: "Equifax",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            textAlign: "center",
          },
          class: "text-center",
        },
      ],
      itemsPersonalInfo: [],
      isBusyPersonalInfo: false,
      checkValuePi: {
        trans: null,
        experian: null,
        equifax: null,
      },
      // Inquiries Table
      fieldTableInquiries: [
        {
          key: "date",
          label: "Credit inquire",
        },
        {
          key: "trans",
          label: "TransUnion",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "experian",
          label: "Experian",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "equifax",
          label: "Equifax",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            textAlign: "center",
          },
          class: "text-center"
        },
      ],
      itemsInquiries: [],
      isBusyInquiries: false,
      checkValueIn: {
        trans: null,
        experian: null,
        equifax: null,
      },
      // Public Records table
      fieldTablePr: [
        {
          key: "date",
          label: "",
        },
        {
          key: "trans",
          label: "TransUnion",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "experian",
          label: "Experian",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "equifax",
          label: "Equifax",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            textAlign: "center",
          },
          class: "text-center",
        },
      ],
      itemsPr: [],
      isBusyPr: false,
      checkValuePr: {
        trans: null,
        experian: null,
        equifax: null,
      },
      // Account table
      fieldTableAccount: [
        {
          key: "date",
          label: "",
        },
        {
          key: "trans",
          label: "TransUnion",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "experian",
          label: "Experian",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "equifax",
          label: "Equifax",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            textAlign: "center",
          },
          class: "text-center",
        },
      ],
      itemsAccount: [],
      isBusyAccount: false,
      checkValueAccount: {
        trans: null,
        experian: null,
        equifax: null,
      },
      personalInfoFormRoundModal: false,
      typeSend: null,
      bureau_id: null,
      bureauItem: [],
      bureauType: null,
      roundId: null,
      roundClassification: null,
    };
  },
  async created() {
    await this.getTypeSender();
    await this.searchCategory();
  },
  methods: {
    disableActionButton(item) {
      return !(
        (item.transChecked && item.pi[0].status_name != 'IN DISPUTE') || 
        (item.expeChecked && item.pi[1].status_name != 'IN DISPUTE') || 
        (item.equiChecked && item.pi[2].status_name != 'IN DISPUTE'));
    },
    openPiFormModal(index) {
      this.roundId = this.itemsPersonalInfo[index].id;
      this.roundClassification = null;
      const itemType = JSON.parse(this.itemsPersonalInfo[index].pi);
      if (this.itemsPersonalInfo[index].transChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 1)[0]);
      }
      if (this.itemsPersonalInfo[index].expeChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 2)[0]);
      }
      if (this.itemsPersonalInfo[index].equiChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 3)[0]);
      }
      if (this.typeSend === "CERTIFIED") {
        this.bureauItem = [];
        this.bureauItem.push(
          itemType.filter((col) => col.bureau_id === this.bureau_id)[0]
        );
      }
      this.bureauType = 1;
      this.personalInfoFormRoundModal = true;
    },
    openInquerieFormModal(index) {
      this.roundId = this.itemsInquiries[index].id;
      this.roundClassification = null;
      const itemType = JSON.parse(this.itemsInquiries[index].pi);
      if (this.itemsInquiries[index].transChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 1)[0]);
      }
      if (this.itemsInquiries[index].expeChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 2)[0]);
      }
      if (this.itemsInquiries[index].equiChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 3)[0]);
      }
      if (this.typeSend === "CERTIFIED") {
        this.bureauItem = [];
        this.bureauItem.push(
          itemType.filter((col) => col.bureau_id === this.bureau_id)[0]
        );
      }
      this.bureauType = 2;
      this.personalInfoFormRoundModal = true;
    },
    openPrFormModal(index) {
      this.roundId = this.itemsPr[index].id;
      this.roundClassification = null;
      const itemType = JSON.parse(this.itemsPr[index].pi);
      if (this.itemsPr[index].transChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 1)[0]);
      }
      if (this.itemsPr[index].expeChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 2)[0]);
      }
      if (this.itemsPr[index].equiChecked === true) {
        this.bureauItem.push(itemType.filter((col) => col.bureau_id === 3)[0]);
      }
      if (this.typeSend === "CERTIFIED") {
        this.bureauItem = [];
        this.bureauItem.push(
          itemType.filter((col) => col.bureau_id === this.bureau_id)[0]
        );
      }
      this.bureauType = 3;
      this.personalInfoFormRoundModal = true;
    },
    openAccountFormModal(index) {
      this.roundId = this.itemsAccount[index].id;
      this.roundClassification = this.itemsAccount[index].pi.find(item => item != null)["classification"];
      const itemType = this.itemsAccount[index].pi;
      if (this.itemsAccount[index].transChecked === true && itemType[0].status_name != 'IN DISPUTE' && itemType[0] != null) {
        this.bureauItem.push(itemType[0]);
      }
      if (this.itemsAccount[index].expeChecked === true && itemType[1].status_name != 'IN DISPUTE' && itemType[1] != null) {
        this.bureauItem.push(itemType[1]);
      }
      if (this.itemsAccount[index].equiChecked === true && itemType[2].status_name != 'IN DISPUTE' && itemType[2] != null) {
        this.bureauItem.push(itemType[2]);
      }
      if (this.typeSend === "CERTIFIED") {
        this.bureauItem = [];
        this.bureauItem.push(
          itemType.filter((col) => col.id != null && col.bureau_id === this.bureau_id)[0]
        );
      }
      this.bureauType = 4;
      this.personalInfoFormRoundModal = true;
    },
    saveAndClosePersonalInfoFormRoundModal() {
      this.bureauItem = [];
      this.personalInfoFormRoundModal = false;
      this.searchCategory();
    },
    closePersonalInfoFormRoundModal() {
      this.bureauItem = [];
      this.personalInfoFormRoundModal = false;
    },
    async getTypeSender() {
      const data = await ClientsOptionsServices.getTypeSender(
        this.senderData.id
      );
      if (data.status === 200) {
        this.typeSend = data.data[0].type_send;
        this.bureau_id = data.data[0].bureau_send_id;
      }
    },
    async searchCategory() {
      if (this.categoryData === 1) {
        this.isBusyPersonalInfo = true;
        const params = {
          idaccount: this.roundData.idAccount,
          state: 0,
        };
        const data = await ClientsOptionsServices.getDeleteAccountPi(params);
        if (data.status === 200) {
          this.isBusyPersonalInfo = false;
          this.itemsPersonalInfo = data.data;
          this.itemsPersonalInfo = this.itemsPersonalInfo.sort((a, b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return 0;
          });
          // IS CERTIFIED
          if (this.typeSend === "CERTIFIED") {
            this.itemsPersonalInfo.forEach((arr) => {
              arr.pi = JSON.parse(arr.pi);
              arr.pi = arr.pi.filter(
                (arrDato) => arrDato.bureau_id == this.bureau_id
              );
              arr.pi = JSON.stringify(arr.pi);
            });
            this.itemsPersonalInfo = this.itemsPersonalInfo.filter(
              (arr) => arr.pi != "[]"
            );
          }
          this.itemsPersonalInfo.forEach((element) => {
            this.$set(element, "transChecked", false);
            this.$set(element, "expeChecked", false);
            this.$set(element, "equiChecked", false);
          });
        }
      } else if (this.categoryData === 2) {
        this.isBusyInquiries = true;
        const params = {
          idaccount: this.roundData.idAccount,
          state: 0,
        };
        const data = await ClientsOptionsServices.getDeleteAccountIn(params);
        if (data.status === 200) {
          this.isBusyInquiries = false;
          this.itemsInquiries = data.data;
          // IS CERTIFIED
          if (this.typeSend === "CERTIFIED") {
            this.itemsInquiries.forEach((arr) => {
              arr.pi = JSON.parse(arr.pi);
              arr.pi = arr.pi.filter(
                (arrDato) => arrDato.bureau_id == this.bureau_id
              );
              arr.pi = JSON.stringify(arr.pi);
            });
            this.itemsInquiries = this.itemsInquiries.filter(
              (arr) => arr.pi != "[]"
            );
          }
          this.itemsInquiries.forEach((element) => {
            this.$set(element, "transChecked", false);
            this.$set(element, "expeChecked", false);
            this.$set(element, "equiChecked", false);
          });
        }
      } else if (this.categoryData === 3) {
        this.isBusyPr = true;
        const params = {
          idaccount: this.roundData.idAccount,
          state: 0,
        };
        const data = await ClientsOptionsServices.getDeleteAccountPr(params);
        if (data.status === 200) {
          this.isBusyPr = false;
          this.itemsPr = data.data;
          // IS CERTIFIED
          if (this.typeSend === "CERTIFIED") {
            this.itemsPr.forEach((arr) => {
              arr.pi = JSON.parse(arr.pi);
              arr.pi = arr.pi.filter(
                (arrDato) => arrDato.bureau_id == this.bureau_id
              );
              arr.pi = JSON.stringify(arr.pi);
            });
            this.itemsPr = this.itemsPr.filter((arr) => arr.pi != "[]");
          }
          this.itemsPr.forEach((element) => {
            this.$set(element, "transChecked", false);
            this.$set(element, "expeChecked", false);
            this.$set(element, "equiChecked", false);
          });
        }
      } else if (this.categoryData === 4) {
        this.isBusyAccount = true;
        const params = {
          idaccount: this.roundData.idAccount,
          state: 0,
        };
        const data = await ClientsOptionsServices.getDeleteAccountAc(params);
        if (data.status === 200) {
          this.isBusyAccount = false;
          const emptyData = {
            id: null,
            status_name: null
          }
          this.itemsAccount = data.data.map(arr => {
            let parsed_pi = JSON.parse(arr.pi);
            let new_array_pi = [];
            
            let index_trans = parsed_pi.findIndex(index => index.bureau_id == 1);
            let index_experian = parsed_pi.findIndex(index => index.bureau_id == 2);
            let index_equifax = parsed_pi.findIndex(index => index.bureau_id == 3);
            
            new_array_pi.push(index_trans !== -1 ? parsed_pi[index_trans] : emptyData);
            new_array_pi.push(index_experian !== -1 ? parsed_pi[index_experian] : emptyData);
            new_array_pi.push(index_equifax !== -1 ? parsed_pi[index_equifax] : emptyData);
            
            return {
              ...arr,
              pi: new_array_pi,
              transChecked: index_trans !== -1 && new_array_pi[0].status_name == 'IN DISPUTE',
              expeChecked: index_experian !== -1 && new_array_pi[1].status_name == 'IN DISPUTE',
              equiChecked: index_equifax !== -1 && new_array_pi[2].status_name == 'IN DISPUTE'
            };
          });
        }
      }
    },
    closeModal() {
      this.$emit("close");
      this.$emit("reload");
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
