export default [
  {
    key: "content",
    label: "Name",
    thStyle: "width: 40%",
  },
  {
    key: "reason_text",
    label: "Reason",
    thStyle: "width: 40%",
  },
  {
    key: "bureau_id",
    label: "Bureau",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 20%",
  },
];
