<template>
  <div >
    <div class="container-grid px-1 pt-1">
      <div
        class="shadow w-auto p-1 rounded-top text-center border-bottom"
        :style="
          isDarkSkin
            ? 'background: #17171A; color:white'
            : 'background: #fff'
        "
      >
        <b-img
          :src="'/assets/images/icons/bureaus/transunion.png'"
          width="98"
        />
      </div>
      <div
        class="shadow w-auto p-1 rounded-top border-bottom text-center"
        :style="
          isDarkSkin
            ? 'background: #17171A; color:white'
            : 'background: #fff'
        "
      >
        <b-img :src="'/assets/images/icons/bureaus/experian.png'" width="98" />
      </div>
      <div
        class="shadow w-auto p-1 rounded-top border-bottom text-center"
        :style="
          isDarkSkin
            ? 'background: #17171A; color:white'
            : 'background: #fff'
        "
      >
        <b-img
          :src="'/assets/images/icons/bureaus/equifax.png'"
          width="98"
          class="pt-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontAmgHeaderGridReport",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 1em;
  flex-wrap: nowrap;
}
</style>
