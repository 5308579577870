var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.section == 'workplan')?_c('div',{staticClass:"float-right mb-1"},[_c('span',[_vm._v("Show CR:")]),_c('amg-icon',{staticClass:"text-danger ml-1",staticStyle:{"cursor":"pointer"},attrs:{"icon":"FilePdfIcon","size":"20"},on:{"click":_vm.showPdfNcrRequest}})],1):_vm._e(),_c('b-table',{staticClass:"position-relative table-new-customization mt-2 w-100",attrs:{"hover":"","items":_vm.items,"fields":_vm.visibleFields,"details-td-class":"p-0","show-empty":"","sticky-header":'45vh',"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"empty",fn:function(scope){return [_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(scope.emptyText))])]}},{key:"head(transunion)",fn:function(){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('TRANSUNION'),expression:"'TRANSUNION'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-img",attrs:{"src":require('@/assets/images/icons/transunion.png')}})]},proxy:true},{key:"head(experian)",fn:function(){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('EXPERIAN'),expression:"'EXPERIAN'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-img",attrs:{"src":require('@/assets/images/icons/experian.png')}})]},proxy:true},{key:"head(equifax)",fn:function(){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('EQUIFAX'),expression:"'EQUIFAX'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-img",attrs:{"src":require('@/assets/images/icons/equifax.png')}})]},proxy:true},{key:"cell(id)",fn:function(ref){
var index = ref.index;
return [_c('div',[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"cell(transunion)",fn:function(ref){
var item = ref.item;
var field = ref.field;
return [(_vm.validateItemBureau(item.items, field.key))?_c('div',{staticClass:"action-data mx-auto text-center",style:(("background-color: " + (_vm.getStatusBackground(
          item.items[0].priority
        )) + ";"))},[_c('b-dropdown',{staticClass:"wo-padding w-100",attrs:{"no-caret":"","variant":"none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-badge',{staticClass:"text-capitalize custom-badge",attrs:{"variant":item.items[0].id != '-'
                    ? _vm.variantBadge[item.items[0].status_letter]
                    : 'light-info'}},[_vm._v(" "+_vm._s(item.items[0].status_letter)+" ")]),_c('span',{staticClass:"status-name"},[_vm._v(_vm._s(item.items[0].priority || "NOT QUALIFIED"))]),(!_vm.validateRestoreButton(item.items, field.key))?_c('feather-icon',{staticClass:"ml-1 text-white",attrs:{"icon":"ChevronDownIcon","size":"22"}}):_vm._e()],1)]},proxy:true}],null,true)},_vm._l((_vm.getStatusOptions(item, 0)),function(data,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":[3, 4].includes(item.items[0].statusIdOld) && item.items[0].updated_from != _vm.sectionId},on:{"click":function($event){return _vm.changeStatus(item.items, field.key, data)}}},[_vm._v(" "+_vm._s(data.value)+" ")])}),1),(_vm.validateRestoreButton(item.items, field.key))?_c('feather-icon',{staticClass:"ml-1 text-white",attrs:{"icon":"XIcon","size":"22"},on:{"click":function($event){return _vm.restoreStatus(item.items, field.key)}}}):_vm._e()],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"custom-bg-logo-effectivity",attrs:{"src":require(("@/assets/images/icons/" + (field.key) + ".png")),"alt":"","width":"30px","height":"30px"}})])]}},{key:"cell(experian)",fn:function(ref){
                    var item = ref.item;
                    var field = ref.field;
return [(_vm.validateItemBureau(item.items, field.key))?_c('div',{staticClass:"action-data mx-auto text-center",style:(("background-color: " + (_vm.getStatusBackground(
          item.items[1].priority
        )) + ";"))},[_c('b-dropdown',{staticClass:"wo-padding w-100",attrs:{"no-caret":"","variant":"none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-badge',{staticClass:"text-capitalize custom-badge",attrs:{"variant":item.items[1].id != '-'
                    ? _vm.variantBadge[item.items[1].status_letter]
                    : 'light-info'}},[_vm._v(" "+_vm._s(item.items[1].status_letter)+" ")]),_c('span',{staticClass:"status-name"},[_vm._v(_vm._s(item.items[1].priority || "NOT QUALIFIED"))]),(!_vm.validateRestoreButton(item.items, field.key))?_c('feather-icon',{staticClass:"ml-1 text-white",attrs:{"icon":"ChevronDownIcon","size":"22"}}):_vm._e()],1)]},proxy:true}],null,true)},_vm._l((_vm.getStatusOptions(item, 1)),function(data,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":[3, 4].includes(item.items[1].statusIdOld) && item.items[1].updated_from != _vm.sectionId},on:{"click":function($event){return _vm.changeStatus(item.items, field.key, data)}}},[_vm._v(" "+_vm._s(data.value)+" ")])}),1),(_vm.validateRestoreButton(item.items, field.key))?_c('feather-icon',{staticClass:"ml-1 text-white",attrs:{"icon":"XIcon","size":"22"},on:{"click":function($event){return _vm.restoreStatus(item.items, field.key)}}}):_vm._e()],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"custom-bg-logo-effectivity",attrs:{"src":require(("@/assets/images/icons/" + (field.key) + ".png")),"alt":"","width":"30px","height":"30px"}})])]}},{key:"cell(equifax)",fn:function(ref){
                    var item = ref.item;
                    var field = ref.field;
return [(_vm.validateItemBureau(item.items, field.key))?_c('div',{staticClass:"action-data mx-auto text-center",style:(("background-color: " + (_vm.getStatusBackground(
          item.items[2].priority
        )) + ";"))},[_c('b-dropdown',{staticClass:"wo-padding w-100",attrs:{"no-caret":"","variant":"none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-badge',{staticClass:"text-capitalize custom-badge",attrs:{"variant":item.items[2].id != '-'
                    ? _vm.variantBadge[item.items[2].status_letter]
                    : 'light-info'}},[_vm._v(" "+_vm._s(item.items[2].status_letter)+" ")]),_c('span',{staticClass:"status-name"},[_vm._v(_vm._s(item.items[2].priority || "NOT QUALIFIED"))]),(!_vm.validateRestoreButton(item.items, field.key))?_c('feather-icon',{staticClass:"ml-1 text-white",attrs:{"icon":"ChevronDownIcon","size":"22"}}):_vm._e()],1)]},proxy:true}],null,true)},_vm._l((_vm.getStatusOptions(item, 2)),function(data,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":[3, 4].includes(item.items[2].statusIdOld) && item.items[2].updated_from != _vm.sectionId},on:{"click":function($event){return _vm.changeStatus(item.items, field.key, data)}}},[_vm._v(" "+_vm._s(data.value)+" ")])}),1),(_vm.validateRestoreButton(item.items, field.key))?_c('feather-icon',{staticClass:"ml-1 text-white",attrs:{"icon":"XIcon","size":"22"},on:{"click":function($event){return _vm.restoreStatus(item.items, field.key)}}}):_vm._e()],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"custom-bg-logo-effectivity",attrs:{"src":require(("@/assets/images/icons/" + (field.key) + ".png")),"alt":"","width":"30px","height":"30px"}})])]}},{key:"cell(accounts)",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex flex-column justify-content-start text-capitalize text-center"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],staticClass:"d-flex flex-column text-center justify-content-center font-size-xmd font-weight-bolder text-truncate",staticStyle:{"width":"250px"},attrs:{"title":_vm.getCreditorName(item.items)}},[_vm._v(" "+_vm._s(_vm.getCreditorName(item.items))+" ")]),_c('span',{staticClass:"w-100 mt-50 text-capitalize px-1",class:_vm.isDarkSkin ? 'text-white' : 'text-secondary',staticStyle:{"font-size":"12px"}},[_vm._v(" Account #: "+_vm._s(_vm.getCreditorAccount(item.items))+" ")])])]}},{key:"cell(classification_ncr)",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100"},[_c('h6',{staticClass:"text-uppercase font-weight-bolder text-center",style:(("color: " + (_vm.textColor[item.classification_ncr_id])))},[_vm._v(" "+_vm._s(item.classification_ncr || "NOT QUALIFIED")+" ")])])]}},{key:"cell(detail)",fn:function(ref){
                    var toggleDetails = ref.toggleDetails;
                    var detailsShowing = ref.detailsShowing;
return [_c('div',{on:{"click":toggleDetails}},[_c('feather-icon',{staticClass:"hover-me cursor-pointer",class:{ 'rotate-180': detailsShowing },attrs:{"icon":"ChevronDownIcon","size":"22"}})],1)]}},{key:"row-details",fn:function(data){return [_c('transition',{attrs:{"name":"fade"}},[_c('b-table',{staticClass:"tableDetailsEffectivity",class:_vm.isDarkSkin ? 'dark-bg' : '',attrs:{"thead-class":"d-none","items":_vm.transformData(data.item.items),"fields":_vm.visibleFields},scopedSlots:_vm._u([{key:"cell(experian)",fn:function(ref){
                    var value = ref.value;
return [_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.labelsRemove),function(label,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100"},[(
                        [
                          'balance',
                          'monthly_payment',
                          'credit_limit',
                          'h_credit' ].includes(label.key)
                      )?[_vm._v(" $"+_vm._s(_vm._f("currency")(isNaN(value[label.key]) ? 0 : value[label.key]))+" ")]:[_vm._v(" "+_vm._s(value.hasOwnProperty(label.key) ? value[label.key] : "-")+" ")]],2)])])}),0)]}},{key:"cell(transunion)",fn:function(ref){
                      var value = ref.value;
return [_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.labelsRemove),function(label,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100"},[(
                        [
                          'balance',
                          'monthly_payment',
                          'credit_limit',
                          'h_credit' ].includes(label.key)
                      )?[_vm._v(" $"+_vm._s(_vm._f("currency")(isNaN(value[label.key]) ? 0 : value[label.key]))+" ")]:[_vm._v(" "+_vm._s(value.hasOwnProperty(label.key) ? value[label.key] : "-")+" ")]],2)])])}),0)]}},{key:"cell(equifax)",fn:function(ref){
                      var value = ref.value;
return [_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.labelsRemove),function(label,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex justify-content-center align-items-center justify-content-center text-center text-capitalize"},[_c('div',{staticClass:"text-center",staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100"},[(
                        [
                          'balance',
                          'monthly_payment',
                          'credit_limit',
                          'h_credit' ].includes(label.key)
                      )?[_vm._v(" $"+_vm._s(_vm._f("currency")(isNaN(value[label.key]) ? 0 : value[label.key]))+" ")]:[_vm._v(" "+_vm._s(value.hasOwnProperty(label.key) ? value[label.key] : "-")+" ")]],2)])])}),0)]}},{key:"cell(accounts)",fn:function(){return [_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.labelsRemove),function(data,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100"},[_vm._v(_vm._s(data.text))])])])}),0)]},proxy:true},{key:"cell(classification_ncr)",fn:function(){return [_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.labelsRemove),function(data,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100"},[_vm._v(" ")])])])}),0)]},proxy:true},{key:"cell()",fn:function(){return [_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.labelsRemove),function(data,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex align-items-center justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100"},[_vm._v(" ")])])])}),0)]},proxy:true}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }