<template>
  <div>
    <b-button v-if="!isModal" variant="info" @click="closeModal" class="mb-2">
      <feather-icon icon="ChevronLeftIcon" /> RETURN
    </b-button>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Select:">
              <b-form-select
                v-model="dispute.type"
                :options="optionsType"
                value-field="value"
                text-field="name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col v-if="dispute.type === 2">
            <b-form-group label="Email of access credentials:">
              <b-form-select
                v-model="dispute.email_credential"
                :options="optionsEmail"
                value-field="id"
                text-field="user"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Observation:">
              <b-form-textarea v-model="dispute.observation" disabled />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-table :fields="fields" :items="items" :busy="isBusy" show-empty>
          <template #cell(file_name)="data">
            <a :href="data.item.url" target="_blank">{{
              data.item.file_name
            }}</a>
          </template>
          <template #cell(bureau)="data">
            <div class="d-flex justify-content-center align-items-center">
              <img
                v-if="data.item.bureau == 'transunion'"
                :src="imageTransUnion"
              />
              <img
                v-else-if="data.item.bureau == 'experian'"
                :src="imageExperian"
              />
              <img
                v-else-if="data.item.bureau == 'equifax'"
                :src="imageEquifax"
              />
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="dispute.type !== 2">
      <b-col cols="12">
        <b-form-group label="Recommendation:">
          <b-form-textarea
            v-model="dispute.recommendation"
            :disabled="!(moduleId === 11 && statuss == 'PENDING')"
          />
        </b-form-group>
      </b-col>

      <b-col md="12" v-if="Boolean(dispute.option_recommendation)">
        <b-input-group>
          <b-input-group-prepend style="width: 48%" variant="light">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >RECOMMENDATION</b-button
            >
          </b-input-group-prepend>

          <b-input-group-append is-text style="display: grid; width: 52%">
            <b-form-checkbox-group
              class="w-100"
              disabled
              v-model="observations"
            >
              <b-form-checkbox :value="1"
                ><span style="margin-left: 5px">NCR</span></b-form-checkbox
              >
              <b-form-checkbox :value="2"
                ><span style="margin-left: 5px"
                  >Application</span
                ></b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <template v-else-if="dispute.type === 2 && hasObservations">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="In this case if the customer did not receive letter these are the indications:"
          >
            <b-form-textarea
              v-model="dispute.commentary1"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Information was recovered and the results are as follows:"
          >
            <b-form-textarea
              v-model="dispute.commentary2"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Client recommendations:">
            <b-form-textarea
              v-model="dispute.commentary3"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Advisor recommendations:">
            <b-form-textarea
              v-model="dispute.commentary4"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Next steps:">
            <b-form-textarea
              v-model="dispute.commentary5"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Results:">
            <b-form-textarea
              v-model="dispute.commentary1"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Comments:">
            <b-form-textarea
              v-model="dispute.commentary2"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Next Steps:">
            <b-form-textarea
              v-model="dispute.commentary3"
              :disabled="!(moduleId === 11 && statuss == 'PENDING')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import equifax from "@/assets/images/icons/equifax.png";
import experian from "@/assets/images/icons/experian.png";
import transunion from "@/assets/images/icons/transunion.png";
export default {
  props: {
    dispute: null,
    dataDispute: null,
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isBusy: false,
      fields: [
        {
          key: "file_name",
          label: "Name",
        },
        {
          key: "size",
          label: "Size",
        },
        {
          key: "created_at",
          label: "Uploaded At",
        },
        {
          key: "bureau",
          label: "Bureau",
        },
      ],
      imageEquifax: equifax,
      imageExperian: experian,
      imageTransUnion: transunion,
      optionsType: [
        { name: "Select Request", value: "0" },
        { name: "LETTER", value: "1" },
        { name: "RECOVERY", value: "2" },
        { name: "NCR EXPIRED", value: "3" },
      ],
      optionsEmail: [],
    };
  },
  computed: {
    hasObservations() {
      return !!this.dispute.commentary5 || !!this.dispute.recommendation;
    },
    observations() {
      if (Boolean(this.dispute.option_recommendation)) {
        return JSON.parse(this.dispute.option_recommendation);
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.addPreloader();
    await this.getCredentialEmail();
    await this.getAllLettersDispute();
    this.removePreloader();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getCredentialEmail() {
      try {
        const params = {
          client_id: this.$route.params.idClient,
        };
        const data = await ClientsOptionsServices.getCredentialEmail(params);

        this.optionsEmail = data.data;
        return this.optionsEmail;
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllLettersDispute() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.allLettersDispute({
          id: this.dataDispute.idDispute,
        });
        if (data.status === 200) {
          this.isBusy = false;
          this.items = data.data;
        }
      } catch (e) {
        this.removePreloader();
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
