export default [
  {
    key: "content",
    label: "Inquire Name",
    thStyle: "width: 40%",
  },
  {
    key: "date_show",
    label: "Date Inquire",
    thStyle: "width: 40%",
  },
  {
    key: "bureau_id",
    label: "Bureau",
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "width: 20%",
  },
];
