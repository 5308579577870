var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalName",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"TRACKING: OTHER SERVICES","size":"lg","hide-footer":"","centered":""},on:{"hidden":function($event){return _vm.closeModal()}},model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"title-tag"},[_vm._v("ACCOUNT")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.clientData.client_account || "-")+" ")])])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"title-tag"},[_vm._v("NAME")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.clientData.client_name || "-")+" ")])])])],1)],1),_c('div',{staticClass:"table-responsive"},[_c('b-table',{ref:"trackingTable",staticClass:"blue-scrollbar mt-2",attrs:{"items":_vm.myProvider,"fields":_vm.visibleFields,"primary-key":"id","responsive":"sm","show-empty":"","sticky-header":"50vh","busy":_vm.isBusy,"no-provider-filtering":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(created_by)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_name)+" ")]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.created_at))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-info text-center"},[_c('b-badge',{staticClass:"w-100 text-uppercase",attrs:{"variant":_vm.badgeColor(item.status_id)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item))+" ")])],1)]}},{key:"cell(motive_description)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.motive_description || "-")+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }