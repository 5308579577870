<template>
  <b-modal
    v-model="visible"
    id="modal-action"
    :title="actionText"
    title-tag="h3"
    modal-class="modal-primary"
    size="md"
    :header-bg-variant="actionClass"
    @hidden="close"
    no-close-on-backdrop
    centered
    no-close-on-esc
  >
    <template #default>
      <b-container>
        <b-row>
          <b-col v-if="account">
            <h5>Account</h5>
            <p class="rounded text-primary border-primary font-medium-1 text-center py-1s">
              {{ account }}
            </p>
          </b-col>
          <b-col>
            <h5>Client</h5>
            <p class="rounded text-primary border-primary font-medium-1 text-center py-1s">
              {{ client_name }}
            </p>
          </b-col>
        </b-row>
        <b-row v-if="(action == 1)">
          <b-col>
            <b-form-group label="Comment:">
              <b-form-textarea v-model="comment" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="action == 2">
          <b-col>
            <validation-provider
              ref="motiveValidator"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Comment:">
                <div class="d-flex justify-content-start">
                  <v-select
                    transition=""
                    :options="motiveCe"
                    label="title"
                    class="w-100 sm"
                    :class="!comment || errors[0] ? 'border-danger' : ''"
                    :reduce="(val) => val.title"
                    v-model="comment"
                    :clearable="false"
                  />
                  <feather-icon
                    v-if="isCeo || isSupervisor || isChief"
                    class="text-primary ml-1"
                    style="margin-top: 5px"
                    icon="PlusCircleIcon"
                    size="20"
                    @click="createEditMotiveModalOn = true"
                  />
                </div>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <modal-motives-form
          v-if="createEditMotiveModalOn"
          :stateModal="1"
          :motiveData="{}"
          @hide="closeModalMotive"
          @reload="reloadModalMotive"
        />
      </b-container>
    </template>

    <template #modal-footer>
      <b-button :variant="actionClass" @click="sendData" :disabled="isDisable">
        {{
          actionText.substr(8)
        }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MotivesService from "@/views/ce-digital/sub-modules/settings/views/motives/service/motives.service.js";
import ModalMotivesForm from "@/views/ce-digital/sub-modules/settings/views/motives/components/ModalMotivesForm.vue";
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service.js";
export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalMotivesForm,
  },
  props: {
    action: { required: true, type: Number },
    data: { required: true, type: Object },
  },
  mounted(){
    console.log('sdasd')
  },
  data() {
    return {
      visible: false,
      comment: "",
      client_name: "",
      account: "",
      motiveCe: [],
      createEditMotiveModalOn: false,
      sendingData: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("changePaidOption", null);
      this.visible = false;
    },
    async sendData() {
        try {
          let { isConfirmed } = await this.showConfirmSwal();
          if (isConfirmed) {
            this.addPreloader();
            const data = await AppointmentService.updateAppointmentStatus(
              {
                type: "status",
                id: this.data.id,
                status: this.action == 1 ? "ACCEPTED" : "REJECTED",
                comment: this.comment,
                user_id: this.currentUser.user_id,
              }
            );
            if (data.status == 200) {
              this.showSuccessSwal();
              this.$emit("refreshTable");
              this.close();
            }

          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
        return;
    },
    async getMotives() {
      try {
        const result = await MotivesService.allMotives();
        if (result.status == 200) {
          this.motiveCe = result.data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
    closeModalMotive() {
      this.createEditMotiveModalOn = false;
    },
    reloadModalMotive() {
      this.getMotives();
      this.closeModalMotive();
    },
    closeModalMotive() {
      this.createEditMotiveModalOn = false;
    },
    reloadModalMotive() {
      this.getMotives();
      this.closeModalMotive();
    },
  },
  computed: {
    actionText() {
      switch (parseInt(this.action)) {
        case 1:
          return "Status: Accepted";
          break;
        case 2:
          return "Status: Rejected";
          break;
      }
    },
    actionClass() {
      switch (parseInt(this.action)) {
        case 1:
          return "success";
          break;
        case 2:
          return "danger";
          break;
      }
    },
    isDisable() {
      if (this.sendingData) {
        return true;
      }
      if (this.action !== 1) {
        return this.comment === "";
      }
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      program: "DebtSolutionApplications/program",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  async created() {

    if (this.action == 2) {
      this.getMotives();
    }
    this.visible = true;
  },
  mounted() {
    this.account = this.data.account
      ? this.data.account
      : this.data.client_account;
    this.client_name = this.data.name_client
      ? this.data.name_client
      : this.data.client_name;
  },
};
</script>
<style scoped>
.py-1s{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
