<template>
  <b-modal
    @hidden="closeModal()"
    v-model="onControl"
    centered
    modal-class="custom-modal-amg"
    size="caro"
    hide-footer
    title-class="h1 text-white fw-bold"
    title="work plan steps"
    no-close-on-esc
    no-close-on-backdrop
    no-enforce-focus
  >
    <template #modal-title>
      <tabler-icon icon="ReplaceIcon" class="text-white" size="22" />
      Work plan steps
    </template>
    <b-row>
      <b-col sm="12">
        <HeaderInformation :dataWorkPlan="dataWorkPlan" />
      </b-col>
      <b-col sm="12">
        <step-progress
          :steps="steps"
          :currentStep="currentStep"
          :icons="icons"
          @step-changed="handleStepNext"
          @step-prev="handleStepPrev"
          :validationNext="validateStepNext"
          :validationPrev="validateStepPrev"
        >
          <template v-slot:default="{ step }">
            <div v-if="step === 1">
              <FirstStep
                ref="firstStep"
                :client="dataWorkPlan"
                :section="'workplan'"
              />
            </div>
            <div v-else-if="step === 2">
              <SynchronizationOfItems ref="secondStep" :client="dataWorkPlan" />
            </div>
            <div v-else-if="step === 3">
              <b-card>
                <ThirdStep
                  ref="thirdStep"
                  :rowData="dataWorkPlan"
                  :section="'workplan'"
                />
              </b-card>
            </div>
            <div v-else-if="step === 4">
              <FourthStep
                ref="fourthStep"
                :dataClient="dataWorkPlan"
                :section="'workplan'"
              />
            </div>
            <div v-else-if="step === 5">
              <FifthStep
                ref="fifthStep"
                :rowId="dataWorkPlan.id"
                :section="'workplan'"
              />
            </div>
          </template>
        </step-progress>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import FirstStep from "@/views/commons/components/request-workplan/views/components/modal/steps/FirstStep.vue";
import FourthStep from "@/views/commons/components/request-workplan/views/components/modal/steps/FourthStep.vue";
import ThirdStep from "@/views/commons/components/ncr/components/report/DisputeReport.vue";
import FifthStep from "@/views/commons/components/request-workplan/views/components/modal/steps/FifthStep.vue";
import StepProgress from "@/views/commons/steps/StepProgress.vue";
import HeaderInformation from "@/views/commons/components/request-workplan/views/components/modal/HeaderInformation.vue";
import SynchronizationOfItems from "@/views/commons/components/request-workplan/views/components/modal/steps/syncronization/SynchronizationOfItems.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    dataWorkPlan: {
      required: true,
      type: Object,
      default: {},
    },
  },
  components: {
    FirstStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    StepProgress,
    HeaderInformation,
    SynchronizationOfItems,
  },
  data() {
    return {
      onControl: false,
      steps: [
        "UPDATE ITEMS",
        "SYNCHRONIZE",
        "DISPUTE",
        "RECOMMEND",
        "TO REVIEW",
      ],
      icons: [
        "UpdatedIcon",
        "SyncIcon",
        "DisputeIcon",
        "RecommendIcon",
        "CheckIcon",
      ],
      shouldUpdateStep: true,
    };
  },
  async created() {
    this.onControl = true;
    this.A_ID_REQUEST_WORKPLAN(this.dataWorkPlan.id);
    this.fetchStepWorkPlan();
  },
  methods: {
    ...mapActions("RequestWorkPlanStore", [
      "A_STEP_WP",
      "A_IS_EXECUTING_METHOD",
      "A_UPDATE_STEP_WP",
      "A_ID_REQUEST_WORKPLAN",
    ]),
    fetchStepWorkPlan() {
      this.A_STEP_WP();
    },
    closeModal() {
      this.$emit("close");
    },
    async handleChildStepChange(step) {
      switch (step) {
        case 1:
          return await this.$refs.firstStep.updateStatus();
        case 2:
          return await this.$refs.secondStep.done();
        case 3:
          return await this.$refs.thirdStep.toRevision();
        case 4:
          return await this.$refs.fourthStep.save();
        case 5:
          return await this.$refs.fifthStep.completeAction(this.dataWorkPlan);
        default:
          return true;
      }
    },

    async handleStepNext(step) {
      try {
        const adjustedStep = step - 1;
        const result = await this.handleChildStepChange(adjustedStep);
        if (result) {
          if (!this.G_IS_EXECUTING_METHOD) {
            await this.updateStepWorkPlan(step);
          }
        } else {
          console.error("Error occurred, unable to proceed to next step");
        }
        this.$emit("reload");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },

    async handleChildStepPrev(step) {
      switch (step) {
        case 1:
          return true;
        case 2:
          return await this.$refs.thirdStep.returnActionStep2(
            this.dataWorkPlan
          );
        case 3:
          return true;
        case 4:
          return await this.$refs.fifthStep.returnAction(this.dataWorkPlan.id);
        case 5:
          return false;
        default:
          return true;
      }
    },

    async handleStepPrev(step) {
      try {
        const result = await this.handleChildStepPrev(step);
        if (result) {
          if (!this.G_IS_EXECUTING_METHOD) {
            await this.updateStepWorkPlan(step);
          }
        } else {
          console.error("Error occurred, unable to go back to previous step");
        }
        this.$emit("reload");
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        this.A_IS_EXECUTING_METHOD(false);
      }
    },
    updateStepWorkPlan(step) {
      const updatedData = {
        id: this.dataWorkPlan.id,
        step: step,
      };
      this.A_UPDATE_STEP_WP(updatedData);
    },
  },
  computed: {
    ...mapGetters({
      G_IS_EXECUTING_METHOD: "RequestWorkPlanStore/G_IS_EXECUTING_METHOD",
      currentStep: "RequestWorkPlanStore/G_STEP_WP",
    }),
    adjustedCurrentStep() {
      return this.currentStep + 1;
    },
    validateStepNext() {
      switch (this.currentStep) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return true;
        case 4:
          return true;
        case 5:
          return this.isSupervisor || this.isChief || this.isCeo;
        default:
          return true;
      }
    },
    validateStepPrev() {
      switch (this.currentStep) {
        case 1:
          return false;
        case 2:
          return true;
        case 3:
          return this.dataWorkPlan.type_client == 1 ? false : true;
        case 4:
          return false;
        case 5:
          return this.isSupervisor || this.isChief || this.isCeo;
        case 6:
          return false;
        default:
          return true;
      }
    },
  },
};
</script>

<style lang="scss">
.fw-bold {
  font-weight: bold !important;
}
</style>
