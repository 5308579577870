var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{key:_vm.ncrs.ncr_id,staticClass:"table-new-customization custom-table",attrs:{"striped":"","small":"","items":_vm.ncrs,"fields":_vm.visibleFields,"tbody-tr-class":_vm.isDarkSkin ? 'table-round dark' : 'table-round'},scopedSlots:_vm._u([{key:"cell(request)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.request_by))]),_c('div',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.request_at)))])]}},{key:"cell(service)",fn:function(ref){
var item = ref.item;
return [(item.program_id)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(item.program_name),expression:"item.program_name",modifiers:{"bottom":true}}],staticClass:"d-flex justify-content-center m-0"},[_c('img',{attrs:{"src":_vm.getProgramImageRoute(item.program_id),"alt":"img_services"}})]):_vm._e()]}},{key:"cell(platform)",fn:function(ref){
var item = ref.item;
return [_c('b-img-lazy',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.platform_name),expression:"item.platform_name",modifiers:{"hover":true,"top":true}}],attrs:{"src":("/assets" + (item.platform_icon)),"alt":item.platform_name,"width":"20","height":"20"}})]}},{key:"cell(file)",fn:function(ref){
var item = ref.item;
return [(item.route_file)?_c('a',{staticClass:"text-primary",attrs:{"href":item.route_file,"target":"_blank"}},[_c('amg-icon',{staticClass:"text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1):_vm._e()]}},{key:"cell(cr)",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-primary",attrs:{"href":_vm.routeCR(item),"target":"_blank"}},[_c('amg-icon',{attrs:{"icon":"FileChartIcon","size":"18"}})],1)]}},{key:"cell(tracking)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ListIcon","size":"18"},on:{"click":function($event){return _vm.openModalTracking(item)}}})]}},{key:"cell(items)",fn:function(data){return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"primary","pill":""},on:{"click":function($event){_vm.jsonParse(data.item.rounds).length > 0 && _vm.showRounds(data.index)}}},[_vm._v(" "+_vm._s(_vm.jsonParse(data.item.rounds).length)+" ")])]}},{key:"cell(directs)",fn:function(data){return [_c('b-badge',{class:_vm.countRounds(data.item, 'directs') > 0 ? 'cursor-pointer' : '',staticStyle:{"width":"40px"},attrs:{"variant":"primary","pill":""},on:{"click":function($event){_vm.countRounds(data.item, 'directs') && _vm.showRounds(data.index, 1)}}},[_vm._v(" "+_vm._s(_vm.countRounds(data.item, "directs"))+" "),(_vm.countRounds(data.item, 'directs') > 0)?_c('feather-icon',{class:{
            'rotate-180': data.item.detailsShowing && _vm.typeDispute == 1,
          },attrs:{"icon":"ChevronDownIcon"}}):_vm._e()],1)]}},{key:"cell(letters)",fn:function(data){return [_c('b-badge',{class:_vm.countRounds(data.item, 'letters') > 0 ? 'cursor-pointer' : '',staticStyle:{"width":"40px"},attrs:{"variant":"primary","pill":""},on:{"click":function($event){_vm.countRounds(data.item, 'letters') > 0 && _vm.showRounds(data.index, 2)}}},[_vm._v(" "+_vm._s(_vm.countRounds(data.item, "letters"))+" "),(_vm.countRounds(data.item, 'letters') > 0)?_c('feather-icon',{class:{
            'rotate-180': data.item.detailsShowing && _vm.typeDispute == 2,
          },attrs:{"icon":"ChevronDownIcon"}}):_vm._e()],1)]}},{key:"cell(documents)",fn:function(ref){
          var item = ref.item;
return [(_vm.missingFiles && _vm.missingRoundFile(item))?_c('div',[_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.toAddFiles()}}},[_vm._v(" Add Files ")]),(_vm.countId == 0)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('ID file is missing'),expression:"'ID file is missing'",modifiers:{"hover":true}}],staticClass:"ml-1",attrs:{"variant":"warning"}},[_vm._v(" ID ")]):_vm._e(),(_vm.countUb == 0)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('UB file is missing'),expression:"'UB file is missing'",modifiers:{"hover":true}}],staticClass:"ml-1",attrs:{"variant":"warning"}},[_vm._v(" UB ")]):_vm._e()],1):_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" Completed ")])]}},{key:"row-details",fn:function(){return [_c('transition',{attrs:{"name":"fade"}},[_c('RoundDetail',{key:_vm.keyDetail,attrs:{"ncrId":_vm.ncrId,"typeDispute":_vm.typeDispute,"clientData":_vm.client}})],1)]},proxy:true}])}),(_vm.showTracking)?_c('TrackingModalDis',{attrs:{"data":_vm.trackingData},on:{"close":_vm.closeModalTracking}}):_vm._e(),(_vm.showRoundModal)?_c('AddRoundModal',{attrs:{"data-round":_vm.roundData,"validate-modal":1},on:{"close":_vm.closeRoundModal,"reload":_vm.getAllNcr}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }