<template >
  <div ref="myModalRecommend">
    <ValidationObserver ref="form">
      <div>
        <div
          v-if="!G_EDIT_LETTER_MODAL && !G_CREATE_DETAIL_LETTERS_MODAL"
          class="form-group d-flex justify-content-between align-items-center"
        >
          <div>
            <template v-if="!dataRound.isDirect">
              <span style="font-weight: bold; font-size: 13px"
                >FORMAT :{{ dataRound.format }}
              </span>
              <b-badge
                variant="light-success"
                class="cursor-pointer ml-1"
                @click="showviewpdf"
                :disabled="isEmptyDispute"
              >
                <feather-icon class="mr-1" icon="EyeIcon" size="14" /> SHOW
                LETTERS BY BUREAU
              </b-badge>
            </template>
          </div>
          <b-button
            v-if="!isModal && showAllLetters && !Boolean() && !fromGrid"
            variant="info"
            @click="closeModal"
          >
            <feather-icon icon="ChevronLeftIcon" />
            RETURN
          </b-button>
        </div>
        <div
          v-if="!G_EDIT_LETTER_MODAL"
          class="d-flex justify-content-end mb-2"
        >
          <b-button
            v-if="
              (currentUser.role_id === 1 ||
                ((currentUser.role_id === 2 || currentUser.role_id === 6) &&
                  moduleId === 4) ||
                ((currentUser.role_id === 2 || currentUser.role_id === 3) &&
                  moduleId === 11) ||
                ((currentUser.role_id === 2 ||
                  currentUser.role_id === 3 ||
                  currentUser.role_id === 17) &&
                  moduleId === 25) ||
                moduleId === 28) &&
              validateModal === 0
            "
            variant="primary"
            class="ml-1"
            @click="openCreateDetailLetterModal(clientData)"
          >
            <feather-icon icon="PlusIcon" class="mr-1" size="15" />ADD
          </b-button>
        </div>
        <b-col v-if="emptyTables" class="text-center mb-3">
          <span>There is no specific information for this record</span>
        </b-col>
        <b-col
          v-if="
            !G_EDIT_LETTER_MODAL &&
            items.personalInfo.length > 0
          "
          class="p-0 mt-2"
        >
          <b-row class="d-flex align-items-center">
            <b-col cols="12">
              <span class="mr-1 font-weight-bolder font-medium-1"
                >PERSONAL INFORMATION</span
              >
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-table
                ref="tablePersonalInfo"
                no-border-collapse
                small
                table-class="table-round-edit"
                class="position-relative mt-1 custom-table"
                :fields="fields"
                show-empty
                :items="items.personalInfo"
                sticky-header="50vh"
                :busy="isBusy.personalInfo"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #head(trans)>
                  <span>Transunion</span>
                </template>
                <template #head(experian)>
                  <span class="font-weight-bolder"
                    >Experian</span
                  >
                </template>
                <template #head(equifax)>
                  <span class="font-weight-bolder"
                    >EQUIFAX</span
                  >
                </template>
                <template #cell(date)="data">
                  <span v-if="data.index === 0">
                    <span v-if="data.item.type === 1">NAME</span>
                    <span v-if="data.item.type === 2">DATE OF BIRTH</span>
                    <span v-if="data.item.type === 3">ADDRESS</span>
                    <span v-if="data.item.type === 4">SSN</span>
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        items.personalInfo[data.index].type >
                        items.personalInfo[data.index - 1].type
                      "
                    >
                      <span v-if="data.item.type === 2">DATE OF BIRTH</span>
                      <span v-if="data.item.type === 3">ADDRESS</span>
                      <span v-if="data.item.type === 4">SSN</span>
                    </span>
                  </span>
                </template>
                <template #cell(trans)="data">
                  <div
                    v-if="
                      JSON.parse(data.item.pi).length === 3 ||
                      JSON.parse(data.item.pi).length === 2 ||
                      JSON.parse(data.item.pi)[0].bureau_id === 1
                    "
                    class="text-center"
                  >
                    <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                    ><br />
                    <div>
                      <feather-icon
                        v-if="validEditAndDelete(1)"
                        size="18"
                        class="mr-half1 cursor-pointer text-warning"
                        icon="EditIcon"
                        @click="
                          validateModal === 1
                            ? editPersonalInfo(JSON.parse(data.item.pi)[0])
                            : openEditLetterModal(data.item, 1, 1)
                        "
                      />
                      <feather-icon
                        v-if="
                          validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                        "
                        size="18"
                        class="mr-half1 cursor-pointer text-danger"
                        icon="TrashIcon"
                        @click="deleteDisputeItem(data.item, 1, 0)"
                      />
                    </div>
                  </div>
                </template>
                <template #cell(experian)="data">
                  <div
                    v-if="
                      JSON.parse(data.item.pi).length === 3 ||
                      JSON.parse(data.item.pi).length === 2
                    "
                    class="text-center"
                  >
                    <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                      <span>{{ JSON.parse(data.item.pi)[1].content }}</span
                      ><br />
                      <div>
                        <feather-icon
                          v-if="validEditAndDelete(1)"
                          size="18"
                          class="mr-half1 cursor-pointer text-warning"
                          icon="EditIcon"
                          @click="
                            validateModal === 1
                              ? editPersonalInfo(JSON.parse(data.item.pi)[1])
                              : openEditLetterModal(data.item, 2, 1)
                          "
                        />
                        <feather-icon
                          v-if="
                            validEditAndDelete(2, JSON.parse(data.item.pi)[1])
                          "
                          size="18"
                          class="mr-half1 cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteDisputeItem(data.item, 1, 1)"
                        />
                      </div>
                    </div>
                    <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                      <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                      ><br />
                      <div>
                        <feather-icon
                          v-if="validEditAndDelete(1)"
                          size="18"
                          class="mr-half1 cursor-pointer text-warning"
                          icon="EditIcon"
                          @click="
                            validateModal === 1
                              ? editPersonalInfo(JSON.parse(data.item.pi)[0])
                              : openEditLetterModal(data.item, 2, 1)
                          "
                        />
                        <feather-icon
                          v-if="
                            validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                          "
                          size="18"
                          class="mr-half1 cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteDisputeItem(data.item, 1, 0)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                    class="text-center"
                  >
                    <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                    ><br />
                    <div>
                      <feather-icon
                        v-if="validEditAndDelete(1)"
                        size="18"
                        class="mr-half1 cursor-pointer text-warning"
                        icon="EditIcon"
                        @click="
                          validateModal === 1
                            ? editPersonalInfo(JSON.parse(data.item.pi)[0])
                            : openEditLetterModal(data.item, 2, 1)
                        "
                      />
                      <feather-icon
                        v-if="
                          validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                        "
                        size="18"
                        class="mr-half1 cursor-pointer text-danger"
                        icon="TrashIcon"
                        @click="deleteDisputeItem(data.item, 1, 0)"
                      />
                    </div>
                  </div>
                </template>
                <template #cell(equifax)="data">
                  <div
                    v-if="JSON.parse(data.item.pi).length === 3"
                    class="text-center"
                  >
                    <span>{{ JSON.parse(data.item.pi)[2].content }}</span
                    ><br />
                    <div>
                      <feather-icon
                        v-if="validEditAndDelete(1)"
                        size="18"
                        class="mr-half1 cursor-pointer text-warning"
                        icon="EditIcon"
                        @click="
                          validateModal === 1
                            ? editPersonalInfo(JSON.parse(data.item.pi)[2])
                            : openEditLetterModal(data.item, 3, 1)
                        "
                      />
                      <feather-icon
                        v-if="
                          validEditAndDelete(2, JSON.parse(data.item.pi)[2])
                        "
                        size="18"
                        class="mr-half1 cursor-pointer text-danger"
                        icon="TrashIcon"
                        @click="deleteDisputeItem(data.item, 1, 2)"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi).length === 2"
                    class="text-center"
                  >
                    <div
                      v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                      class="text-center"
                    >
                      <span>{{ JSON.parse(data.item.pi)[1].content }}</span
                      ><br />
                      <div>
                        <feather-icon
                          v-if="validEditAndDelete(1)"
                          size="18"
                          class="mr-half1 cursor-pointer text-warning"
                          icon="EditIcon"
                          @click="
                            validateModal === 1
                              ? editPersonalInfo(JSON.parse(data.item.pi)[1])
                              : openEditLetterModal(data.item, 3, 1)
                          "
                        />
                        <feather-icon
                          v-if="
                            validEditAndDelete(2, JSON.parse(data.item.pi)[1])
                          "
                          size="18"
                          class="mr-half1 cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteDisputeItem(data.item, 1, 1)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                    class="text-center"
                  >
                    <span>{{ JSON.parse(data.item.pi)[0].content }}</span
                    ><br />
                    <div>
                      <feather-icon
                        v-if="validEditAndDelete(1)"
                        size="18"
                        class="mr-half1 cursor-pointer text-warning"
                        icon="EditIcon"
                        @click="
                          validateModal === 1
                            ? editPersonalInfo(JSON.parse(data.item.pi)[0])
                            : openEditLetterModal(data.item, 3, 1)
                        "
                      />
                      <feather-icon
                        v-if="
                          validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                        "
                        size="18"
                        class="mr-half1 cursor-pointer text-danger"
                        icon="TrashIcon"
                        @click="deleteDisputeItem(data.item, 1, 0)"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="
            !G_EDIT_LETTER_MODAL &&
            items.inquiries.length > 0
          "
          class="mt-1 p-0"
        >
          <b-row class="d-flex align-items-center">
            <b-col cols="12">
              <span class="mr-1 font-weight-bolder font-medium-1"
                >INQUIRIES</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="tableInquiries"
                no-border-collapse
                small
                table-class="table-round-edit"
                class="position-relative mt-1 custom-table"
                :fields="fields"
                show-empty
                :items="items.inquiries"
                sticky-header="50vh"
                :busy="isBusy.inquiries"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #head(date)> Credit inquiry </template>
                <template #head(trans)>
                  <span>Transunion</span>
                </template>
                <template #head(experian)>
                  <span class="font-weight-bolder"
                    >Experian</span
                  >
                </template>
                <template #head(equifax)>
                  <span class="font-weight-bolder"
                    >EQUIFAX</span
                  >
                </template>
                <template #cell(date)="data">
                  <span>{{
                    JSON.parse(data.item.pi)[0].content === "null"
                      ? "-"
                      : JSON.parse(data.item.pi)[0].content
                  }}</span>
                  <br />
                  <span>{{ data.item.date }}</span>
                </template>
                <template #cell(trans)="data">
                  <div
                    v-if="
                      JSON.parse(data.item.pi).length === 3 ||
                      JSON.parse(data.item.pi).length === 2 ||
                      JSON.parse(data.item.pi)[0].bureau_id === 1
                    "
                    class="text-center"
                  >
                    <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 1">
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                        <div>
                          <div>
                            <feather-icon
                              icon="EditIcon"
                              size="18"
                              class="text-warning cursor-pointer"
                              style="margin-right: 5px"
                              @click="openEditLetterModal(data.item, 1, 2)"
                            />
                            <feather-icon
                              icon="TrashIcon"
                              size="18"
                              class="text-danger cursor-pointer"
                              @click="deleteDisputeItem(data.item, 2, 0)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(experian)="data">
                  <div
                    v-if="
                      JSON.parse(data.item.pi).length === 3 ||
                      JSON.parse(data.item.pi).length === 2
                    "
                    class="text-center"
                  >
                    <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                        <div>
                          <feather-icon
                            icon="EditIcon"
                            size="18"
                            class="text-warning cursor-pointer"
                            style="margin-right: 5px"
                            @click="openEditLetterModal(data.item, 2, 2)"
                          />
                          <feather-icon
                            icon="TrashIcon"
                            size="18"
                            class="text-danger cursor-pointer"
                            @click="deleteDisputeItem(data.item, 2, 1)"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                        <div>
                          <feather-icon
                            icon="EditIcon"
                            size="18"
                            class="text-warning cursor-pointer"
                            style="margin-right: 5px"
                            @click="openEditLetterModal(data.item, 2, 2)"
                          />
                          <feather-icon
                            icon="TrashIcon"
                            size="18"
                            class="text-danger cursor-pointer"
                            @click="deleteDisputeItem(data.item, 2, 0)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                    class="text-center"
                  >
                    <div>
                      <feather-icon icon="CheckIcon" class="" size="18" />
                      <div>
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          class="text-warning cursor-pointer"
                          style="margin-right: 5px"
                          @click="openEditLetterModal(data.item, 2, 2)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          class="text-danger cursor-pointer"
                          @click="deleteDisputeItem(data.item, 2, 0)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(equifax)="data">
                  <div
                    v-if="JSON.parse(data.item.pi).length === 3"
                    class="text-center"
                  >
                    <div>
                      <feather-icon icon="CheckIcon" class="" size="18" />
                    </div>
                    <div>
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                        class="text-warning cursor-pointer"
                        style="margin-right: 5px"
                        @click="openEditLetterModal(data.item, 3, 2)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        @click="deleteDisputeItem(data.item, 2, 2)"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi).length === 2"
                    class="text-center"
                  >
                    <div
                      v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                      class="text-center"
                    >
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                      </div>
                      <div>
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          class="text-warning cursor-pointer"
                          style="margin-right: 5px"
                          @click="openEditLetterModal(data.item, 3, 2)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          class="text-danger cursor-pointer"
                          @click="deleteDisputeItem(data.item, 2, 1)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                    class="text-center"
                  >
                    <div>
                      <feather-icon icon="CheckIcon" class="" size="18" />
                    </div>
                    <div>
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                        class="text-warning cursor-pointer"
                        style="margin-right: 5px"
                        @click="openEditLetterModal(data.item, 3, 2)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        @click="deleteDisputeItem(data.item, 2, 0)"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="
            !G_EDIT_LETTER_MODAL &&
            items.publicRecords.length > 0
          "
          class="mt-1 p-0"
        >
          <b-row class="d-flex align-items-center">
            <b-col cols="12">
              <span class="mr-1 font-weight-bolder font-medium-1"
                >PUBLIC RECORDS</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="tablePublicRecords"
                no-border-collapse
                small
                table-class="table-round-edit"
                class="position-relative mt-1 custom-table"
                :fields="fields"
                show-empty
                :items="items.publicRecords"
                sticky-header="50vh"
                :busy="isBusy.publicRecords"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #head(trans)>
                  <span>Transunion</span>
                </template>
                <template #head(experian)>
                  <span class="font-weight-bolder"
                    >Experian</span
                  >
                </template>
                <template #head(equifax)>
                  <span class="font-weight-bolder"
                    >EQUIFAX</span
                  >
                </template>
                <template #cell(date)="data">
                  <span>{{ JSON.parse(data.item.pi)[0].public_record }}</span>
                  <br />
                  <span>{{ JSON.parse(data.item.pi)[0].court_sase }}</span>
                  <br />
                  <span>{{ data.item.date }}</span>
                </template>
                <template #cell(trans)="data">
                  <div
                    v-if="
                      JSON.parse(data.item.pi).length === 3 ||
                      JSON.parse(data.item.pi).length === 2 ||
                      JSON.parse(data.item.pi)[0].bureau_id === 1
                    "
                    class="text-center"
                  >
                    <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 1">
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                      </div>
                      <div v-if="isDisabledForEdit">
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          class="text-warning cursor-pointer"
                          style="margin-right: 5px"
                          @click="openEditLetterModal(data.item, 1, 3)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          class="text-danger cursor-pointer"
                          @click="deleteDisputeItem(data.item, 3, 0)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(experian)="data">
                  <div
                    v-if="
                      JSON.parse(data.item.pi).length === 3 ||
                      JSON.parse(data.item.pi).length === 2
                    "
                    class="text-center"
                  >
                    <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                      </div>
                      <div v-if="isDisabledForEdit">
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          class="text-warning cursor-pointer"
                          style="margin-right: 5px"
                          @click="openEditLetterModal(data.item, 2, 3)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          class="text-danger cursor-pointer"
                          @click="deleteDisputeItem(data.item, 3, 1)"
                        />
                      </div>
                    </div>
                    <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                      </div>
                      <div v-if="isDisabledForEdit">
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          class="text-warning cursor-pointer"
                          style="margin-right: 5px"
                          @click="openEditLetterModal(data.item, 2, 3)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          class="text-danger cursor-pointer"
                          @click="deleteDisputeItem(data.item, 3, 0)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                    class="text-center"
                  >
                    <div>
                      <feather-icon icon="CheckIcon" class="" size="18" />
                    </div>
                    <div v-if="isDisabledForEdit">
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                        class="text-warning cursor-pointer"
                        style="margin-right: 5px"
                        @click="openEditLetterModal(data.item, 2, 3)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        @click="deleteDisputeItem(data.item, 3, 0)"
                      />
                    </div>
                  </div>
                </template>
                <template #cell(equifax)="data">
                  <div
                    v-if="JSON.parse(data.item.pi).length === 3"
                    class="text-center"
                  >
                    <div>
                      <feather-icon icon="CheckIcon" class="" size="18" />
                    </div>
                    <div v-if="isDisabledForEdit">
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                        class="text-warning cursor-pointer"
                        style="margin-right: 5px"
                        @click="openEditLetterModal(data.item, 3, 3)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        @click="deleteDisputeItem(data.item, 3, 2)"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi).length === 2"
                    class="text-center"
                  >
                    <div
                      v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                      class="text-center"
                    >
                      <div>
                        <feather-icon icon="CheckIcon" class="" size="18" />
                      </div>
                      <div v-if="isDisabledForEdit">
                        <feather-icon
                          icon="EditIcon"
                          size="18"
                          class="text-warning cursor-pointer"
                          style="margin-right: 5px"
                          @click="openEditLetterModal(data.item, 3, 3)"
                        />
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                          class="text-danger cursor-pointer"
                          @click="deleteDisputeItem(data.item, 3, 1)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                    class="text-center"
                  >
                    <div>
                      <feather-icon icon="CheckIcon" class="" size="18" />
                    </div>
                    <div v-if="isDisabledForEdit">
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                        class="text-warning cursor-pointer"
                        style="margin-right: 5px"
                        @click="openEditLetterModal(data.item, 3, 3)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        @click="deleteDisputeItem(data.item, 3, 0)"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="
            !G_EDIT_LETTER_MODAL &&
            items.accounts.length > 0
          "
          class="mt-1 p-0"
        >
          <b-row class="d-flex align-items-center">
            <b-col cols="12">
              <span class="mr-1 font-weight-bolder font-medium-1"
                >ACCOUNTS</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="tableAccounts"
                no-border-collapse
                small
                table-class="table-round-edit"
                class="position-relative mt-1 custom-table"
                :fields="fields"
                show-empty
                :items="items.accounts"
                sticky-header="50vh"
                :busy="isBusy.accounts"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #head(trans)>
                  <span>Transunion</span>
                </template>
                <template #head(experian)>
                  <span class="font-weight-bolder"
                    >Experian</span
                  >
                </template>
                <template #head(equifax)>
                  <span class="font-weight-bolder"
                    >EQUIFAX</span
                  >
                </template>
                <template #cell(date)>
                  <span>Account Name</span><br />
                  <span>Account #</span><br />
                  <span>Balance</span><br />
                  <span>Account Type</span><br />
                </template>
                <template #cell(trans)="data">
                  <div v-if="JSON.parse(data.item.pi) === null">
                    <span
                      >There is no specific information for this record</span
                    >
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        JSON.parse(data.item.pi).length === 3 ||
                        JSON.parse(data.item.pi)[0].bureau_id === 1
                      "
                      class="text-center"
                    >
                      <div
                        v-if="
                          JSON.parse(data.item.pi)[0].bureau_id === 1 ||
                          JSON.parse(data.item.pi)[0].bureau_id === 2
                        "
                      >
                        <item-table-account
                          :item="JSON.parse(data.item.pi)[0]"
                          :is-inside-modal="true"
                        />
                        <div>
                          <feather-icon
                            v-if="validEditAndDelete(1)"
                            size="18"
                            class="mr-half1 cursor-pointer text-warning"
                            icon="EditIcon"
                            @click="
                              validateModal === 1
                                ? editAccount(JSON.parse(data.item.pi)[0].id)
                                : openEditLetterModal(data.item, 1, 4)
                            "
                          />
                          <feather-icon
                            v-if="
                              validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                            "
                            size="18"
                            class="mr-half1 cursor-pointer text-danger"
                            icon="TrashIcon"
                            @click="deleteDisputeItem(data.item, 4, 0)"
                          />
                          <feather-icon
                            icon="HelpCircleIcon"
                            class="text-primary cursor-pointer"
                            v-b-tooltip.hover.top="
                              'Detail of the arguments of the account'
                            "
                            size="16"
                            @click="openDetail(data.item)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(experian)="data">
                  <div v-if="JSON.parse(data.item.pi) === null">
                    <span
                      >There is no specific information for this record</span
                    >
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        JSON.parse(data.item.pi).length === 3 ||
                        JSON.parse(data.item.pi).length === 2
                      "
                      class="text-center"
                    >
                      <div v-if="JSON.parse(data.item.pi)[1].bureau_id === 2">
                        <item-table-account
                          :item="JSON.parse(data.item.pi)[1]"
                          :is-inside-modal="true"
                        />
                        <div>
                          <feather-icon
                            v-if="validEditAndDelete(1)"
                            size="18"
                            class="mr-half1 cursor-pointer text-warning"
                            icon="EditIcon"
                            @click="
                              validateModal === 1
                                ? editAccount(JSON.parse(data.item.pi)[1].id)
                                : openEditLetterModal(data.item, 2, 4)
                            "
                          />
                          <feather-icon
                            v-if="
                              validEditAndDelete(2, JSON.parse(data.item.pi)[1])
                            "
                            size="18"
                            class="mr-half1 cursor-pointer text-danger"
                            icon="TrashIcon"
                            @click="deleteDisputeItem(data.item, 4, 1)"
                          />
                          <feather-icon
                            icon="HelpCircleIcon"
                            class="text-primary cursor-pointer"
                            v-b-tooltip.hover.top="
                              'Detail of the arguments of the account'
                            "
                            size="16"
                            @click="openDetail(data.item)"
                          />
                        </div>
                      </div>
                      <div v-if="JSON.parse(data.item.pi)[0].bureau_id === 2">
                        <item-table-account
                          :item="JSON.parse(data.item.pi)[0]"
                          :is-inside-modal="true"
                        />
                        <div>
                          <feather-icon
                            v-if="validEditAndDelete(1)"
                            size="18"
                            class="mr-half1 cursor-pointer text-warning"
                            icon="EditIcon"
                            @click="
                              validateModal === 1
                                ? editAccount(JSON.parse(data.item.pi)[0].id)
                                : openEditLetterModal(data.item, 2, 4)
                            "
                          />
                          <feather-icon
                            v-if="
                              validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                            "
                            size="18"
                            class="mr-half1 cursor-pointer text-danger"
                            icon="TrashIcon"
                            @click="deleteDisputeItem(data.item, 4, 0)"
                          />
                          <feather-icon
                            icon="HelpCircleIcon"
                            class="text-primary cursor-pointer"
                            v-b-tooltip.hover.top="
                              'Detail of the arguments of the account'
                            "
                            size="16"
                            @click="openDetail(data.item)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 2"
                      class="text-center"
                    >
                      <item-table-account
                        :item="JSON.parse(data.item.pi)[0]"
                        :is-inside-modal="true"
                      />
                      <div>
                        <feather-icon
                          v-if="validEditAndDelete(1)"
                          size="18"
                          class="mr-half1 cursor-pointer text-warning"
                          icon="EditIcon"
                          @click="
                            validateModal === 1
                              ? editAccount(JSON.parse(data.item.pi)[0].id)
                              : openEditLetterModal(data.item, 2, 4)
                          "
                        />
                        <feather-icon
                          v-if="
                            validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                          "
                          size="18"
                          class="mr-half1 cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteDisputeItem(data.item, 4, 0)"
                        />
                        <feather-icon
                          icon="HelpCircleIcon"
                          class="text-primary cursor-pointer"
                          v-b-tooltip.hover.top="
                            'Detail of the arguments of the account'
                          "
                          size="16"
                          @click="openDetail(data.item)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(equifax)="data">
                  <div v-if="JSON.parse(data.item.pi) === null">
                    <span
                      >There is no specific information for this record</span
                    >
                  </div>
                  <div v-else>
                    <div
                      v-if="JSON.parse(data.item.pi).length === 3"
                      class="text-center"
                    >
                      <item-table-account
                        :item="JSON.parse(data.item.pi)[2]"
                        :is-inside-modal="true"
                      />
                      <div>
                        <feather-icon
                          v-if="validEditAndDelete(1)"
                          size="18"
                          class="mr-half1 cursor-pointer text-warning"
                          icon="EditIcon"
                          @click="
                            validateModal === 1
                              ? editAccount(JSON.parse(data.item.pi)[2].id)
                              : openEditLetterModal(data.item, 3, 4)
                          "
                        />
                        <feather-icon
                          v-if="
                            validEditAndDelete(2, JSON.parse(data.item.pi)[2])
                          "
                          size="18"
                          class="mr-half1 cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteDisputeItem(data.item, 4, 2)"
                        />
                        <feather-icon
                          icon="HelpCircleIcon"
                          class="text-primary cursor-pointer"
                          v-b-tooltip.hover.top="
                            'Detail of the arguments of the account'
                          "
                          size="16"
                          @click="openDetail(data.item)"
                        />
                      </div>
                    </div>
                    <div
                      v-else-if="JSON.parse(data.item.pi).length === 2"
                      class="text-center"
                    >
                      <div
                        v-if="JSON.parse(data.item.pi)[1].bureau_id === 3"
                        class="text-center"
                      >
                        <item-table-account
                          :item="JSON.parse(data.item.pi)[1]"
                          :is-inside-modal="true"
                        />
                        <div>
                          <feather-icon
                            v-if="validEditAndDelete(1)"
                            size="18"
                            class="mr-half1 cursor-pointer text-warning"
                            icon="EditIcon"
                            @click="
                              validateModal === 1
                                ? editAccount(JSON.parse(data.item.pi)[1].id)
                                : openEditLetterModal(data.item, 3, 4)
                            "
                          />
                          <feather-icon
                            v-if="
                              validEditAndDelete(2, JSON.parse(data.item.pi)[1])
                            "
                            size="18"
                            class="mr-half1 cursor-pointer text-danger"
                            icon="TrashIcon"
                            @click="deleteDisputeItem(data.item, 4, 1)"
                          />
                          <feather-icon
                            icon="HelpCircleIcon"
                            class="text-primary cursor-pointer"
                            v-b-tooltip.hover.top="
                              'Detail of the arguments of the account'
                            "
                            size="16"
                            @click="openDetail(data.item)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="JSON.parse(data.item.pi)[0].bureau_id === 3"
                      class="text-center"
                    >
                      <item-table-account
                        :item="JSON.parse(data.item.pi)[0]"
                        :is-inside-modal="true"
                      />
                      <div>
                        <feather-icon
                          v-if="validEditAndDelete(1)"
                          size="18"
                          class="mr-half1 cursor-pointer text-warning"
                          icon="EditIcon"
                          @click="
                            validateModal === 1
                              ? editAccount(JSON.parse(data.item.pi)[0].id)
                              : openEditLetterModal(data.item, 3, 4)
                          "
                        />
                        <feather-icon
                          v-if="
                            validEditAndDelete(2, JSON.parse(data.item.pi)[0])
                          "
                          size="18"
                          class="mr-half1 cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteDisputeItem(data.item, 4, 0)"
                        />
                        <feather-icon
                          icon="HelpCircleIcon"
                          class="text-primary cursor-pointer"
                          v-b-tooltip.hover.top="
                            'Detail of the arguments of the account'
                          "
                          size="16"
                          @click="openDetail(data.item)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="
            !emptyTables &&
            isPendingDirectTab &&
            moduleId == 28 &&
            !G_IS_FOOTER_MODAL_ACTIVE
          "
        >
          <b-row
            class="mt-1"
            style="border-radius: 5px"
            :style="`background: ${!isDarkSkin ? '#f4f1f7' : '#2f2e31'}`"
          >
            <b-col cols="3">
              <div
                class="d-flex justify-content-start align-items-center h-full font-weight-bolder"
              >
                CASE NUMBER
              </div>
            </b-col>
            <b-col cols="3" style="padding: 2px 0">
              <div class="d-flex justify-content-center" v-if="G_SUM_TU > 0">
                <ValidationProvider
                  name="trans"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="input-form text-center form-control"
                    type="text"
                    v-model="case_number.cn_tu"
                    :state="errors[0] ? false : null"
                    :disabled="!isPendingDirectTab"
                  />
                </ValidationProvider>
              </div>
            </b-col>
            <b-col cols="3" style="padding: 2px 0">
              <div class="d-flex justify-content-center" v-if="G_SUM_EX > 0">
                <ValidationProvider
                  name="experian"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="input-form text-center form-control"
                    type="text"
                    v-model="case_number.cn_exp"
                    :state="errors[0] ? false : null"
                    :disabled="!isPendingDirectTab"
                  />
                </ValidationProvider>
              </div>
            </b-col>
            <b-col cols="3" style="padding: 2px 0">
              <div class="d-flex justify-content-center" v-if="G_SUM_EQ > 0">
                <ValidationProvider
                  name="equifax"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="input-form text-center form-control"
                    type="text"
                    v-model="case_number.cn_eq"
                    :state="errors[0] ? false : null"
                    :disabled="!isPendingDirectTab"
                  />
                </ValidationProvider>
              </div>
            </b-col>
          </b-row>

          <template
            v-if="
              G_VALID_DONE_DIRECT &&
              !G_IS_FOOTER_MODAL_ACTIVE &&
              !G_CREATE_DETAIL_LETTERS_MODAL
            "
          >
            <b-row class="mt-2">
              <b-col class="d-flex justify-content-end">
                <button
                  class="btn btn-success mr-1 text-black font-weight-bolder"
                  @click="setDirectBureau()"
                  style="background: #147ca6"
                >
                  SAVE
                </button>
                <button
                  :disabled="!ValidationDone"
                  :class="!ValidationDone && 'cursor-not-allowed'"
                  class="btn btn-success text-black font-weight-bolder"
                  @click="setDirectComplete()"
                  style="background: #00cc00"
                >
                  DONE
                </button>
              </b-col>
            </b-row>
          </template></b-col
        >
        <add-accounts
          v-if="accountsModalController"
          :account-name="client.account"
          :name-client="client.client_name"
          :statuses="statutes"
          @close="closeAddAccountsModal"
          @reloadTable="getAllDisputeData"
        />
        <modal-tracking-letter
          v-if="modalTrackingController"
          :id-letter="idToSend"
          :client-name="client.client_name"
          :account="client.account"
          @close="closeModalTracking"
        />
        <b-modal
          v-model="editPIModalController"
          title="Edit Personal Information"
          hide-footer
          :hidden="editPIModalController = false"
        >
          <edit-personal-information-modal
            :statuses="statutes2"
            :user-id="currentUser.user_id"
            :pi-item="itemPi"
            @reload="getAllDisputeData"
          />
        </b-modal>
        <edit-inquirie-modal
          v-if="editInModalController"
          :statuses="statutes2"
          :user-id="currentUser.user_id"
          :in-item="itemIn"
          @reload="getAllDisputeData"
          @close="editInModalController = false"
        />
        <edit-public-record
          v-if="editPrModalController"
          :statuses="statutes"
          :user-id="currentUser.user_id"
          :pr-item="itemIn"
          @reload="getAllDisputeData"
          @close="editPrModalController = false"
        />
        <edit-account-modal
          v-if="editAcModalController"
          :statuses="statutes"
          :user-id="currentUser.user_id"
          :item-id="itemIdToSend"
          @reload="getAllDisputeData"
          @close="editAcModalController = false"
        />
        <edit-letter-modal
          v-if="G_EDIT_LETTER_MODAL"
          :edit-letter-data="editLetterData"
          :bureau-letter="bureauLetter"
          :letter-table="letterTable"
          @close="closeEditLetterModal"
        />
        <b-modal
          v-model="G_CREATE_DETAIL_LETTERS_MODAL" 
          title="Create Detail Letter"
          size="xlg"
          hide-footer
          hide-header-close
          no-close-on-backdrop
          header-class="p-0"
          header-bg-variant="transparent"
        >
          <template v-slot:modal-header>
            <div class="header-modal d-flex justify-content-between">
              <h3 class="text-white m-0 font-weight-bold">
                Create Detail Letter
              </h3>
              <div>
                <feather-icon
                  icon="XIcon"
                  size="22"
                  class="close-button"
                  @click="closeCreateDetailLetterModal"
                />
              </div>
            </div>
          </template>
          <create-detail-letters-modal
            :round-data="roundData"
            :sender-data="dataRound"
            @reload="refreshAll"
            @close="closeCreateDetailLetterModal"
          />
        </b-modal>

        <b-modal
          v-model="showDetail"
          title-class="h3 text-white"
          size="timeline"
          header-class="p-0"
          header-bg-variant="transparent"
          modal-class="custom-modal-amg"
          hide-footer
          centered
          @hidden="showDetail = false"
        >
          <template v-slot:modal-header>
            <div class="header-modal d-flex justify-content-between">
              <h3 class="text-white m-0 font-weight-bold">
                {{
                  detailModal.title.length > 32
                    ? detailModal.title.substring(0, 32) + "..."
                    : detailModal.title
                }}
              </h3>
              <div>
                <feather-icon
                  icon="XIcon"
                  size="22"
                  class="close-button"
                  @click="showDetail = false"
                />
              </div>
            </div>
          </template>
          <div class="d-flex flex-column">
            <span class="sub-title-account"> INSTRUCTION </span>
            <span class="text-justify ml-1">
              {{ detailModal.instruction }}
            </span>
            <span class="sub-title-account mt-1"> REASON </span>
            <span class="text-justify ml-1">
              {{ detailModal.reason }}
            </span>
          </div>
        </b-modal>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import AddAccounts from "@/views/commons/components/clients/dashboard/options/report-module/modals/AddAccounts.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import EditPersonalInformationModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditPersonalInformationModal.vue";
import EditInquirieModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditInquirieModal.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ItemTableAccount from "@/views/commons/components/clients/dashboard/options/report-module/components/ItemTableAccount.vue";
import ModalTrackingLetter from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalTrackingLetter.vue";
import EditPublicRecord from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditPublicRecord.vue";
import EditAccountModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditAccountModal.vue";
import EditLetterModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/edit-letter/EditLetterModal.vue";
import CreateDetailLettersModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/create-detail-letters-modal/CreateDetailLettersModal.vue";
import DirectRoundsServices from "@/views/specialist-digital/views/directs-round/directs-round.service";
export default {
  components: {
    CreateDetailLettersModal,
    EditLetterModal,
    AddAccounts,
    ItemTableAccount,
    ModalTrackingLetter,
    EditPersonalInformationModal,
    EditInquirieModal,
    EditPublicRecord,
    EditAccountModal,
  },
  props: {
    dataRound: {
      type: Object,
      default: null,
    },
    clientData: {
      type: Object,
      default: null,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    validateModal: {
      type: Number,
      default: 0,
    },
    validateStateCreated: {
      type: Boolean,
      default: false,
    },
    showAllLetters: {
      type: Boolean,
      default: true,
    },
    fromGrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statutes: [],
      statutes2: [],
      itemIdToSend: "",
      editInModalController: false,
      editPrModalController: false,
      editAcModalController: false,
      itemsPersonalInfo: [],
      itemsInquiries: [],
      itemsPublicRecords: [],
      itemsAccounts: [],
      items: {
        personalInfo: [],
        inquiries: [],
        publicRecords: [],
        accounts: [],
      },
      itemPi: {},
      itemIn: {},
      editPIModalController: false,
      accountsModalController: false,
      editLetterModal: false,
      editLetterData: {},
      bureauLetter: null,
      letterTable: null,
      fields: [
        {
          key: "date",
          label: "",
          thStyle: {
            textAlign: "center",
            width: "25%",
          },
          tdStyle: { width: "25%" },
        },
        {
          key: "trans",
          label: "TransUnion",
          thStyle: {
            textAlign: "center",
            width: "25%",
          },
          tdStyle: { width: "25%" },
        },
        {
          key: "experian",
          label: "Experian",
          thStyle: {
            textAlign: "center",
            width: "25%",
          },
          tdStyle: { width: "25%" },
        },
        {
          key: "equifax",
          label: "Equifax",
          thStyle: {
            textAlign: "center",
            width: "25%",
          },
          tdStyle: { width: "25%" },
        },
      ],
      isBusy: {
        personalInfo: false,
        inquiries: false,
        publicRecords: false,
        accounts: false,
      },
      modalTrackingController: false,
      state: null,
      idToSend: "",
      direct: true,
      roundData: {},
      case_number: {
        round_id: this.clientData?.idNrl,
        st_tu: null,
        st_exp: null,
        st_eq: null,
        cn_tu: null,
        cn_exp: null,
        cn_eq: null,
        description: null,
      },
      showDetail: false,
      detailModal: {},
    };
  },
  async created() {
    await this.getDirectComplete();
    this.addPreloader();
    await this.validateTypeRound();
    await this.refreshAll();
    this.removePreloader();
    this.closeCreateDetailLetterModal();
  },
  computed: {
    isPendingDirectTab() {
      return this.$route.matched[2].meta.typeTab == "pending";
    },
    emptyTables() {
      return (
        this.items.personalInfo.length == 0 &&
        this.items.inquiries.length == 0 &&
        this.items.accounts.length == 0 &&
        this.items.publicRecords.length == 0
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ValidationDone() {
      if (this.G_SUM_TU > 0) {
        if (this.case_number.cn_tu === "" || this.case_number.cn_tu === null) {
          return false;
        }
      }
      if (this.G_SUM_EX > 0) {
        if (
          this.case_number.cn_exp === "" ||
          this.case_number.cn_exp === null
        ) {
          return false;
        }
      }
      if (this.G_SUM_EQ > 0) {
        if (this.case_number.cn_eq === "" || this.case_number.cn_eq === null) {
          return false;
        }
      }
      return true;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_FOOTER_MODAL_ACTIVE:
        "SpecialistsDigitalRoundLettersStore/G_IS_FOOTER_MODAL_ACTIVE",
      client: "DebtSolutionClients/G_CLIENTS",
      G_EDIT_LETTER_MODAL:
        "SpecialistsDigitalRoundLettersStore/G_EDIT_LETTER_MODAL",
      G_CREATE_DETAIL_LETTERS_MODAL:
        "SpecialistsDigitalRoundLettersStore/G_CREATE_DETAIL_LETTERS_MODAL",
      G_SUM_TU: "SpecialistsDigitalRoundLettersStore/G_SUM_TU",
      G_SUM_EX: "SpecialistsDigitalRoundLettersStore/G_SUM_EX",
      G_SUM_EQ: "SpecialistsDigitalRoundLettersStore/G_SUM_EQ",
      G_VALID_DONE_DIRECT:
        "AnalystDepartamentDirectRoundStore/G_VALID_DONE_DIRECT",
    }),
    isEmptyDispute() {
      return (
        this.items.personalInfo.length == 0 &&
        this.items.inquiries.length == 0 &&
        this.items.publicRecords == 0 &&
        this.items.accounts.length == 0
      );
    },
    isDisabledForEdit() {
      return (
        (this.currentUser.role_id === 1 ||
          ((this.currentUser.role_id === 2 || this.currentUser.role_id === 6) &&
            this.moduleId === 4) ||
          ((this.currentUser.role_id === 2 || this.currentUser.role_id === 3) &&
            this.moduleId === 11) ||
          ((this.currentUser.role_id === 2 || this.currentUser.role_id === 3) &&
            this.moduleId === 25)) &&
        this.validateModal === 0
      );
    },
    itemsTables() {
      return [
        this.items.personalInfo,
        this.items.inquiries,
        this.items.publicRecords,
        this.items.accounts,
      ];
    },
  },
  methods: {
    ...mapMutations({
      UPDATE_SIDEBAR_DIRECT_COUNTER:
        "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
      M_SET_SUM_TU: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_TU",
      M_SET_SUM_EX: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_EX",
      M_SET_SUM_EQ: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_EQ",
    }),
    ...mapActions({
      A_IS_FOOTER_MODAL_ACTIVE:
        "SpecialistsDigitalRoundLettersStore/A_IS_FOOTER_MODAL_ACTIVE",
      A_DETAILS_MODAL_ON:
        "SpecialistsDigitalRoundLettersStore/A_DETAILS_MODAL_ON",
      A_EDIT_LETTER_MODAL:
        "SpecialistsDigitalRoundLettersStore/A_EDIT_LETTER_MODAL",
      A_CREATE_DETAIL_LETTERS_MODAL:
        "SpecialistsDigitalRoundLettersStore/A_CREATE_DETAIL_LETTERS_MODAL",
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
      REFRESH_DISPUTE_TABLE:
        "SpecialistsDigitalRoundLettersStore/CHANGE_REFRESH_TABLE",
      UPDATE_TAB_DIRECT_COUNTER:
        "SpecialistsDigitalDirectRoundsStore/A_SET_COUNTER_PENDING_DIRECT_ROUNDS",
    }),
    openDetail(item) {
      const detail = JSON.parse(item.pi)[0];
      this.detailModal = {
        title: detail.account_name,
        reason: detail.reason,
        instruction: detail.instruction,
      };
      this.showDetail = true;
    },
    async getDirectComplete() {
      try {
        this.addPreloader();
        const response = await DirectRoundsServices.getDataDirect({
          idround: this.clientData?.idNrl,
        });
        if (response.status == 200) {
          if (response.data.length > 0) {
            this.case_number["description"] = response.data[0].observation;
            this.case_number["cn_tu"] = response.data[0].case_number_tu;
            this.case_number["cn_exp"] = response.data[0].case_number_exp;
            this.case_number["cn_eq"] = response.data[0].case_number_eq;
          }
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in getDirectComplete", error);
      }
    },
    async getAllReports() {
      await this.isBusyAll();
      await this.refreshAll();
    },
    validEditAndDelete(type, item = {}) {
      // edit 1
      // delete 2
      if (this.validateStateCreated) {
        if (type === 1) return this.isDisabledForEdit;
        return this.isDisabledForDelete(item);
      }
      return this.isDisabledForEdit;
    },
    isDisabledForDelete(item) {
      return (
        (this.currentUser.role_id === 1 ||
          ((this.currentUser.role_id === 2 || this.currentUser.role_id === 6) &&
            this.moduleId === 4) ||
          ((this.currentUser.role_id === 2 || this.currentUser.role_id === 3) &&
            this.moduleId === 11) ||
          ((this.currentUser.role_id === 2 || this.currentUser.role_id === 3) &&
            this.moduleId === 25)) &&
        this.validateModal === 0 &&
        item.state_created === 1
      );
    },
    closeAddAccountsModal() {
      this.accountsModalController = false;
    },
    closeModalTracking() {
      this.modalTrackingController = false;
    },
    closeModal() {
      this.$emit("close");
    },
    openAddAccountsModal() {
      this.accountsModalController = true;
    },
    /* GET DATA */
    async getAllDisputeData(category) {
      const categories = {
        1: "personalInfo",
        2: "inquiries",
        3: "publicRecords",
        4: "accounts",
      };
      try {
        const services = {
          1: "getDeleteAccountCnPi",
          2: "getDeleteAccountCnIn",
          3: "getDeleteAccountCnPr",
          4: "getDeleteAccountCnAc",
        };
        this.isBusy[categories[category]] = true;
        const data = await ClientsOptionsServices[services[category]]({
          // ********************************
          id: this.dataRound.id,
          status_round: this.dataRound.status_round,
          round_created_at: this.dataRound.round_created_at,
        });
        if (data.status === 200) {
          this.items[categories[category]] = data.data;
          if (category == 1) {
            this.items.personalInfo.sort((a, b) => a.type - b.type);
          }
          this.isBusy[categories[category]] = false;
        }
      } catch (e) {
        this.isBusy[categories[category]] = true;
        this.showErrorSwal(e);
      }
    },
    openModalTracking(id) {
      this.modalTrackingController = true;
      this.idToSend = id;
    },
    /* EDIT METHODS */
    async editAccount(id) {
      this.editAcModalController = true;
      this.itemIdToSend = id;
    },
    async editPersonalInfo(item) {
      this.itemPi = item;
      this.editPIModalController = true;
    },
    // Edit both categories (inquiries & public records)
    openEditLetterModal(item, bureau, letterTable) {
      this.A_EDIT_LETTER_MODAL(true);
      this.bureauLetter = bureau;
      this.letterTable = letterTable;
      this.editLetterData = item;
      this.A_IS_FOOTER_MODAL_ACTIVE(true);
    },
    closeEditLetterModal() {
      this.A_EDIT_LETTER_MODAL(false);
    },
    /* DELETE METHOD */
    async deleteDisputeItem(item, category, value = 0) {
      try {
        const categories = {
          1: "Personal information",
          2: "Inquiry",
          3: "Public record",
          4: "Account",
        };
        const services = {
          1: "deleteDeleteAccountPinw",
          2: "deleteDeleteAccountInnw",
          3: "deleteDeleteAccountPrnw",
          4: "deleteDeleteAccountAcnw",
        };
        const obj = JSON.parse(item.pi);
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const params = {
            id: obj[value].id,
            iduser: this.currentUser.user_id,
          };
          const response = await ClientsOptionsServices[services[category]](
            params
          );
          if (response.status === 200) {
            this.showSuccessSwal(
              `${categories[category]} has been deleted succesfully`
            );

            await this.updateBureauCounters(obj[value]);

            this.REFRESH_DISPUTE_TABLE(true);
            this.getAllDisputeData(category);
            await this.A_UPDATE_TABLE(true);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateBureauCounters(item) {
      if (item.bureau_id == 1) {
        this.M_SET_SUM_TU(this.G_SUM_TU - 1);
      } else if (item.bureau_id == 2) {
        this.M_SET_SUM_EX(this.G_SUM_EX - 1);
      } else {
        this.M_SET_SUM_EQ(this.G_SUM_EQ - 1);
      }
    },
    async validateTypeRound() {
      try {
        const params = {
          id: this.dataRound.id,
        };
        const data = await ClientsOptionsServices.getValidateDirect(params);
        this.direct = data.data.is_direct;
        // Must return an array of items or an empty array if an error occurred
        return this.direct;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    openCreateDetailLetterModal(item) {
      this.roundData = item;
      this.A_CREATE_DETAIL_LETTERS_MODAL(true);
    },
    closeCreateDetailLetterModal() {
      this.A_CREATE_DETAIL_LETTERS_MODAL(false);
    },
    getColorText(number) {
      switch (number) {
        case 1:
          return "text-warning font-weight-bolder";
        case 2:
          return "text-danger font-weight-bolder";
        case 5:
          return "text-primary font-weight-bolder";
        case 6:
          return "text-danger font-weight-bolder";
        default:
          return "text-success font-weight-bolder";
      }
    },
    showviewpdf() {
      this.$emit("viewpdf", this.getDisputedBureaus());
    },
    getDisputedBureaus() {
      const bureaus = [];
      this.itemsTables.forEach((table) => {
        table.forEach((items) => {
          JSON.parse(items.pi)?.forEach((i) => {
            bureaus.push(i.bureau_id);
          });
        });
      });
      return [...new Set(bureaus)];
    },
    async refreshAll() {
      [1, 2, 3, 4].forEach(async (category) => {
        await this.getAllDisputeData(category);
      });
    },
    isBusyAll() {
      this.isBusy.personalInfo = true;
      this.isBusy.inquiries = true;
      this.isBusy.publicRecords = true;
      this.isBusy.accounts = true;
    },
    async setDirectBureau() {
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      try {
        this.addPreloader();
        const setBureau = await DirectRoundsServices.setDataDirect(
          this.case_number
        );
        if (setBureau.status == 200) {
          this.showSuccessSwal();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in setDirectBureau", error);
      }
    },
    async setDirectComplete() {
      this.$emit("setTab");
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      const validateObservation = await this.$swal({
        title: "Your Observation(optional)",
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
      });
      // if (validateObservation.value) {
      try {
        this.addPreloader();
        const setBureau = await DirectRoundsServices.setDataDirect(
          this.case_number
        );
        if (setBureau.status == 200) {
          const processLetters = await DirectRoundsServices.processRoundLetters(
            {
              id: this.clientData?.idNrl,
              user: this.currentUser.user_id,
              type: 7,
              obs: this.case_number["description"],
            }
          );
          if (processLetters.status == 200) {
            this.UPDATE_SIDEBAR_DIRECT_COUNTER({
              routeName: "specialistdigital-directs-round",
              tag:
                this.pendingDirectRounds > 99
                  ? "+99"
                  : this.pendingDirectRounds - 1,
            });
            this.UPDATE_TAB_DIRECT_COUNTER({
              pending: this.pendingDirectRounds - 1,
            });
            this.showSuccessSwal();
            this.$emit("closeModalMain");
            this.REFRESH_DISPUTE_TABLE(true);
            this.A_UPDATE_TABLE(true);
          }
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal("Error in setDirectComplete");
        this.removePreloader();
      }
      // }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.table-round-edit td,
.table-round-edit th {
  vertical-align: top;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
