var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalName",attrs:{"modal-class":"custom-modal-amg","title-class":"h3 text-white","title":"ADD NEW DISPUTE","size":"lg","centered":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"custom-amg"},on:{"click":_vm.save}},[_vm._v(" SAVE ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.isAccount)?_c('b-card',{staticStyle:{"padding":"2px"},attrs:{"no-body":"","header-class":"p-1"}},[_c('label',{staticStyle:{"margin-bottom":"3px"}},[_vm._v("Accounts to dispute:")]),_c('b-table',{staticClass:"table-new-customization",attrs:{"striped":"","items":_vm.selectedElements,"sticky-header":'55vh',"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(bureau_id)",fn:function(ref){
var item = ref.item;
return [(item.bureau_id == 1)?_c('div',[_c('span',[_vm._v("TRANS")]),_c('span',{staticStyle:{"color":"#4d917a"}},[_vm._v("UNION")])]):(item.bureau_id == 2)?_c('span',{staticStyle:{"color":"#0566b7","font-weight":"bold"}},[_vm._v("EXPERIAN")]):_c('span',{staticStyle:{"color":"#822a3a","font-weight":"bold","font-style":"italic"}},[_vm._v("EQUIFAX")])]}}],null,false,4155277031)})],1):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" Dispute type: ")]),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"w-100",class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"options":_vm.optionsDisputeType,"required":"","button-variant":"outline-secondary","name":"radio-btn-default","buttons":""},on:{"change":function($event){_vm.destination = null}},model:{value:(_vm.dispute_type),callback:function ($$v) {_vm.dispute_type=$$v},expression:"dispute_type"}})]}}])})],1),(_vm.isLetterDispute)?_c('b-col',{staticClass:"w-100 mt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v(" Destination:")]),_c('validation-provider',{attrs:{"name":"destination","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"w-100",class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"options":_vm.optionsDestination,"required":"","button-variant":"outline-secondary","name":"radio-btn-default","buttons":""},on:{"change":function($event){(_vm.bureau = null),
                (_vm.round_number = null),
                (_vm.creditorId = null),
                (_vm.creditorName = null)}},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}})]}}],null,false,457172229)})],1):_vm._e(),(_vm.isBureauDestination)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('label',[_vm._v(" Round number:")]),_c('validation-provider',{attrs:{"name":"round","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"id":"select","options":_vm.optionsRound,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(_vm.round_number),callback:function ($$v) {_vm.round_number=$$v},expression:"round_number"}})]}}],null,false,1925410135)})],1):_vm._e(),(_vm.isBureauDestination)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('label',[_vm._v(" Bureau:")]),_c('b-input-group',[_c('b-input-group-text',{staticClass:"w-100 d-flex justify-content-center"},_vm._l((_vm.selectedBureaus),function(bureau){return _c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(bureau.name),expression:"bureau.name",modifiers:{"hover":true,"top":true}}],key:bureau.id,staticClass:"mr-1 ml-1",attrs:{"src":bureau.id == 1
                  ? require('@/assets/images/icons/transunion.png')
                  : bureau.id == 2
                  ? require('@/assets/images/icons/experian.png')
                  : require('@/assets/images/icons/equifax.png')}})}),1)],1)],1):_vm._e(),(_vm.isCreditorDestination)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v("Creditor: ")]),_c('validation-provider',{attrs:{"name":"creditor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"select__creditor",class:errors.length > 0 ? 'border-danger' : '',attrs:{"label":"text","placeholder":"Search...","options":_vm.optionsCreditor,"value":_vm.creditorName},on:{"search":_vm.searchCreditors,"input":_vm.selectCreditor}})]}}],null,false,1689100135)})],1):_vm._e(),(_vm.isLetterDispute)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('label',[_vm._v(" Letter type:")]),_c('validation-provider',{attrs:{"name":"letter_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"id":"select","options":_vm.optionsLetterType,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(_vm.letter_type),callback:function ($$v) {_vm.letter_type=$$v},expression:"letter_type"}})]}}],null,false,2517619732)})],1):_vm._e(),(_vm.isLetterDispute)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('label',[_vm._v(" Format:")]),_c('validation-provider',{attrs:{"name":"format","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"id":"select","options":_vm.optionsFormat,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}})]}}],null,false,1092913675)})],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('label',[_vm._v(" Reason:")]),_c('validation-provider',{attrs:{"name":"reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"id":"select","options":_vm.optionsReason,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('label',[_vm._v(" Instruction:")]),_c('validation-provider',{attrs:{"name":"instruction","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] == 'is required' && 'border-danger rounded',attrs:{"id":"select","options":_vm.optionsInstruction,"required":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select an option")])]},proxy:true}],null,true),model:{value:(_vm.instruction),callback:function ($$v) {_vm.instruction=$$v},expression:"instruction"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }