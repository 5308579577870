<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    :variant="isDarkSkin ? 'dark' : 'white'"
  >
    <div class="d-flex justify-content-end">
      <b-form-select
        v-model="pickedYear"
        :options="availableYears"
        id="yearPicker"
        class="year-picker mb-1"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >Pick a year</b-form-select-option
          >
        </template>
      </b-form-select>
    </div>
    <div
      class="items-flex"
      v-if="gridName == 'showInfoMain' && !this.showFiles"
    >
      <div class="wd-50">
        <b-row>
          <ItemInformation
            :cols="8"
            :icon="'UserIcon'"
            :typeIcon="'feather'"
            :tooltip="'Client Name'"
          >
            <template #content>
              <span class="d-flex align-items-center" style="gap: 5px">
                <b-badge :variant="variantClient">
                  {{ statusClient }}
                </b-badge>
                <router-link
                  class="text-truncate width-name"
                  v-b-tooltip.hover.right="clientData.client_name"
                  target="_blank"
                  :to="{
                    name: clientDashboardRouteName,
                    params: {
                      idClient: `${clientData.id}`,
                    },
                  }"
                >
                  {{ clientData.client_name }}
                </router-link>
              </span>
              <span class="font-weight-bolder">
                {{ clientData.account }}
              </span>
            </template>
          </ItemInformation>
          <ItemInformation
            :cols="4"
            :icon="'CalendarIcon'"
            :typeIcon="'feather'"
            :tooltip="'Date of Birth'"
          >
            <template #content>
              {{ clientData.dob | myGlobal }}
            </template>
          </ItemInformation>
          <ItemInformation
            :cols="4"
            :icon="'UserCheckIcon'"
            :typeIcon="'feather'"
            :documents="documents"
            :tooltip="'Documents'"
          />
          <ItemInformation
            :cols="4"
            :content="clientData.mobile"
            :icon="'PhoneIcon'"
            :typeIcon="'feather'"
            :tooltip="'Phone'"
          />
          <ItemInformation
            :cols="4"
            :content="clientData.email"
            :icon="'MailIcon'"
            :typeIcon="'feather'"
            :tooltip="'Email'"
          />
          <ItemInformation
            :cols="12"
            :icon="'MapPinIcon'"
            :typeIcon="'feather'"
            :tooltip="'Address'"
            :mbNone="true"
          >
            <template #content>
              <span
                class="text-truncate"
                style="max-width: 100%"
                v-b-tooltip.hover.right="addressClient"
              >
                {{ addressClient }}
              </span>
            </template>
          </ItemInformation>
        </b-row>
      </div>
      <div class="wd-50">
        <div class="items-m-grid">
          <div
            class="clickable"
            @click="openSidebar(index)"
            v-for="({ month, number }, index) in months"
            :key="month"
          >
            <ItemMonth :clientId="clientId" :month="month" :number="number" />
          </div>
        </div>
      </div>
    </div>
    <div
      :class="fromModal ? 'grid-files2' : 'grid-files'"
      v-if="gridName == 'showFiles'"
    >
      <GridFiles :data-file="currentClientData" v-if="!loading" />
    </div>
    <div class="grid-files" v-if="gridName == 'showAccessCr'">
      <GridAccesCr :clientId="clientId" />
    </div>
    <b-sidebar
      bg-variant="white"
      shadow
      backdrop
      right
      title="Edit Lead"
      v-model="showSidebar"
      sidebar-class="sidebar-lg"
      no-header
    >
      <div>
        <div class="border-bottom">
          <div class="d-flex justify-content-between align-items-center m-1">
            <h4 class="font-weight-bolder mb-0">Notes</h4>
            <b-button variant="light-primary" @click="openSidebar()" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        </div>

        <div class="mx-05 pt-1 px-1 mt-1">
          <b-form-group label="Choose note type" label-cols="5">
            <v-select
              v-model="currentNotesTypeId"
              :clearable="false"
              :options="noteTypeOptions"
              :reduce="(val) => val.type_id"
              label="type"
              @input="changeNotesType"
            />
          </b-form-group>
        </div>
        <div class="m-1 card-note" v-for="note in notesShow" :key="note.id">
          <div class="content-note" v-html="note.content"></div>

          <div class="bg-primary text-white font-weight-bolder send-by-note">
            <div
              class="d-flex justify-content-between align-items-center content-send"
            >
              <span>{{ note.user_name }}</span>
              <span>{{ note.date | myGlobal }}</span>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";
import ItemInformation from "@/views/commons/components/ncr/components/report/components/client-info/ItemInformation.vue";
import ItemMonth from "@/views/commons/components/ncr/components/report/components/client-info/ItemMonth.vue";
import GridFiles from "@/views/commons/components/clients/dashboard/options/files-module/Files.vue";
import GridAccesCr from "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue";
export default {
  components: {
    ItemInformation,
    ItemMonth,
    GridFiles,
    GridAccesCr,
  },
  props: {
    clientId: String,
    showFiles: Boolean,
    gridName: String,
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientData: {},
      loading: true,
      showSidebar: false,
      months: [],
      notes: [],
      documents: [],
      pickedYear: null,

      noteTypeOptions: [],
      currentNotesTypeId: null,
      notesShow: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    availableYears() {
      const currentYear = new Date().getFullYear();
      const startYear = 2015;
      const years = [];
      for (let year = startYear; year <= currentYear; year++) {
        years.push({
          text: year.toString(),
          value: year,
        });
      }
      return years.reverse(); // reverse the array to show the years w/order desc
    },
    currentClientData() {
      let { id, account, program_id } = this.clientData;
      return { client_account_id: id, account, program_id };
    },
    addressClient() {
      return `${this.clientData.street}, ${this.clientData.city}, ${this.clientData.state_name}, ${this.clientData.zipcode}`;
    },
    moduleId() {
      const { module } = this.$route.matched[0].meta;
      return module == 28 ? 11 : module;
    },
    statusClient() {
      const statuses = {
        1: "Active",
        2: "Hold",
        3: "Transition",
        4: "Canceled",
        5: "Loyal",
        6: "Closed",
      };
      return statuses[this.clientData.status];
    },
    variantClient() {
      const variants = {
        1: "light-success",
        2: "light-danger",
        3: "light-primary",
        4: "light-danger",
        5: "light-danger",
        6: "light-danger",
      };
      return variants[this.clientData.status];
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
  },
  methods: {
    changeNotesType(id) {
      this.currentNotesTypeId = id;
      const index = this.noteTypeOptions.findIndex(
        (item) => item.type_id === this.currentNotesTypeId
      );
      this.notesShow = this.allNotes[index].notes;
    },
    openSidebar(number = 1) {
      this.showSidebar = !this.showSidebar;
      if (this.showSidebar) {
        this.getNotes(number + 1);
      }
    },
    async getNotes(month) {
      try {
        const params = {
          account: this.clientId,
          year: this.pickedYear,
          month: month,
          user_id: this.currentUser.user_id,
          module_id: this.moduleId,
        };
        const { data } = await ReportLeadService.getNotes(params);

        const currentNotes = data;
        console.log(currentNotes, "current notes")
        if (currentNotes.length > 0) {
          this.noteTypeOptions = currentNotes.map((item) => ({
            type: item.type,
            type_id: item.type_id,
          }));
          console.log(this.noteTypeOptions, "note type")

          if (this.noteTypeOptions.length > 0) {
            this.allNotes = data;
            console.log(this.currentNotesTypeId, "current notes type")
            console.log(this.allNotes[0].type_id)
            this.currentNotesTypeId =
              this.currentNotesTypeId == null
                ? this.allNotes[0].type_id
                : this.currentNotesTypeId;

            const index = this.noteTypeOptions.findIndex(
              (item) => item.type_id === this.currentNotesTypeId
            );
            console.log(index, "index")
            console.log(this.allNotes[index])
            this.notesShow = this.allNotes[index].notes;

            this.notesShow.forEach((element) => {
              element.content = element.content.replaceAll(
                "<img",
                "<img style='max-width: 100%;'"
              );
            });
          }
        } else {
          this.notesShow = [];
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getReportLead() {
      try {
        this.loading = true;
        const response = await ReportLeadService.getClientData(this.clientId);
        this.clientData = response?.shift();
        this.generateDocumentsData();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
    generateDocumentsData() {
      if (this.clientData.ssn !== null) {
        this.documents.push({ key: "SSN", value: this.clientData.ssn });
      }
      if (this.clientData.itin !== null) {
        this.documents.push({ key: "ITIN", value: this.clientData.itin });
      }
      if (this.clientData.cpn !== null) {
        this.documents.push({ key: "CPN", value: this.clientData.cpn });
      }
    },
    async getNumberNotes(year) {
      try {
        const params = {
          id: this.clientId,
          year,
          iduser: null,
          typeOfNote: null,
        };
        const { data } = await ReportLeadService.getNumberNotes(params);
        this.months = Object.keys(data).map((key) => {
          return {
            month: key,
            number: data[key],
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
  mounted() {
    this.getReportLead();
    const year = moment().format("YYYY");
    this.pickedYear = year;
    this.getNumberNotes(year);
  },
  watch: {
    pickedYear(value) {
      this.getNumberNotes(value);
    },
  },
};
</script>

<style scoped>
.items-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.wd-50 {
  width: calc(50% - 5px);
}

.text-truncate {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.margin-item {
  margin-bottom: 10px;
}

.width-name {
  max-width: 150px;
}

.mx-05 {
  margin: 0 0.5rem;
}

.items-m-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

@media (max-width: 768px) {
  .width-name {
    max-width: 90px;
  }
  .items-m-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .items-m-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.sidebar-notes {
  width: 450px;
}

.card-note {
  border: 1px solid #3b3b3b;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}
.content-note {
  padding: 10px;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 400;
  word-break: break-word;
  max-height: 400px;
  overflow-y: auto;
}
.send-by-note {
  font-size: 0.8rem;
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
  word-break: break-word;
  max-height: 30px;
  overflow: hidden;
}
.content-send {
  margin: 4px 10px;
}
.grid-files {
  max-height: 200px;
  overflow-y: auto;
}

.grid-files2 {
  max-height: 500px;
  overflow-y: auto;
}

.year-picker {
  max-width: 100px;
}
</style>
